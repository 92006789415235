import React, { useEffect, useRef, useState } from 'react';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { useStores } from 'utils/useStore';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { getLocalizedText } from 'src/utils/helper';

export interface GoGuidesTableOfContentProps {
  parentClassName: string;
  tagElement: string;
  needWrapper?: boolean;
}

const GoGuidesTableOfContent: React.FC<GoGuidesTableOfContentProps> = (props) => {
  const [data, setData] = useState(null);
  const { goGuidesStore } = useStores();

  const track = useEgClickstream();

  let complete = false;
  const checkBoxRef = useRef(null);
  const tocArea = useRef(null);

  const { tagElement, parentClassName, needWrapper } = props;

  const handleClickOutSide = (event) => {
    const isClickInside = tocArea.current.contains(event.target);

    if (!isClickInside) {
      // The click was OUTSIDE the specifiedElement, do something
      checkBoxRef.current.checked = false;
    }
  };

  const toggleClickOutSideEvent = (e) => {
    if (e.target.checked) {
      document.addEventListener('click', handleClickOutSide);
    } else {
      document.removeEventListener('click', handleClickOutSide);
    }
  };

  const generateUniqueId = (text) => {
    // Generate a valid ID. Spaces are replaced with underscores.
    // Check if the ID already exists in the document. If so, append "_1", "_2", etc.
    // until find an unused ID.

    if (text.length === 0) {
      text = '?';
    }

    const baseId = text.replace(/\s+/g, '_');
    let suffix = '',
      count = 1;

    while (document.getElementById(baseId + suffix) !== null) {
      suffix = '_' + count++;
    }

    return baseId + suffix;
  };

  const processMenu = () => {
    const root = document.getElementById('toc');

    const numberOfLink = 10;
    let numberOfSkip = 0;
    const menu = [];

    // Set up some automatic IDs if do not already have them
    const cont1 = document.getElementsByClassName(parentClassName);
    if (cont1.length > 0) {
      const cont = cont1[0];

      const elementsList = cont.getElementsByTagName(tagElement);

      let count = 0;
      for (let i = 0; i < elementsList.length; i++) {
        const elm = elementsList[i];

        if (elm.getAttribute('id') === null) {
          elm.setAttribute('id', generateUniqueId(elm.textContent.trim()));
        }

        if (count >= numberOfLink + numberOfSkip) {
          break;
        }

        if (elm.textContent.trim() === '') {
          numberOfSkip++;
          continue;
        }

        menu.push({ id: elm.getAttribute('id'), text: elm.textContent });
        count++;
      }
      setData(menu);

      // register click event for the hidden checkbox
      // const checkbox = document.getElementById('menu-toc') as HTMLInputElement | null;
      // checkbox.checked = false;
    }

    complete = true;
  };

  const handleClick = (e, id) => {
    e.preventDefault();
    e.stopPropagation();

    const my_element = document.getElementById(id);
    my_element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });

    checkBoxRef.current.checked = false;
  };

  const readyStateChange = () => {
    if (document.readyState == 'complete') {
      processMenu();
    }
  };

  useEffect(() => {
    if (document.readyState == 'complete') {
      processMenu();
    }
    document.addEventListener('readystatechange', readyStateChange);
    return () => {
      document.removeEventListener('readystatechange', readyStateChange);
    };
  }, [complete]);

  const trackObject = {
    event: {
      event_version: '1.0.0',
      event_type: 'Interaction',
      event_name: 'content_menu.selected',
      event_category: 'go_guides',
    },
    experience: goGuidesStore.ClicksteamExperience,
  };

  const lebelClick = () => {
    track(trackObject);
  };

  const buildTOC = () => {
    if (data) {
      return (
        <div className="toc-wrapper">
          <div className="toc-inner" ref={tocArea}>
            <label htmlFor="menu-toc" onClick={lebelClick}>
              {getLocalizedText('toc')} <UitkIcon name="arrow_downward_iOS" size={UitkIconSize.EXTRA_SMALL} />
            </label>
            <span className="arrow-toc"></span>
            <input type="checkbox" id="menu-toc" ref={checkBoxRef} onChange={toggleClickOutSideEvent} />
            <ul id="toc" className="toc">
              {data.map((item) => {
                return (
                  <li key={item.id}>
                    <a onClick={(e) => handleClick(e, item.id)} href={`#${item.id}`} key={item.id}>
                      {item.text}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const RenderTOC = () => {
    const toc = buildTOC();
    if (needWrapper) {
      return (
        <div>
        <div className="container-wrap row">
          <div className="row-inner col-12">{toc}</div>
        </div>
        </div>
      );
    } else {
      return toc;
    }
  };

  return <>{data && <RenderTOC />}</>;
};

export default GoGuidesTableOfContent;
