import React from 'react';
import { CardItemProp } from 'src/components/types/goguides-types.d';
import { checkValue } from 'src/components/functions';
import CardSizeS from './card-s';
import CardSizeM from './card-m';
import CardSizeL from './card-l';
import CardSizeXL from './card-xl';

interface BucketCardProps {
  data: CardItemProp[];
  highlight?: boolean;
}
const BucketThreeCard: React.FC<BucketCardProps> = ({ data, highlight }) => {

  return (
    <ul className={`row item-list-container ${highlight ? '' : 'list3'}`}>
      {data.map((item, index) => (
        checkValue(highlight) ? (
          index === 0 ? (
            <CardSizeXL key={index} data={item} />
          ) : (
            <CardSizeM key={index} data={item} />
          )
        ) : (
          index === 0 ? (
            <CardSizeL key={index} data={item} />
          ) : (
            <CardSizeS key={index} data={item} />
          )
        )
      ))}
    </ul>
  );
};

export default BucketThreeCard;
