import ar_ae from './ar_ae';
import cs_cz from './cs_cz';
import da_dk from './da_dk';
import de_at from './de_at';
import de_be from './de_be';
import de_ch from './de_ch';
import de_de from './de_de';
import el_gr from './el_gr';
import en_as from './en_as';
import en_au from './en_au';
import en_ca from './en_ca';
import en_cn from './en_cn';
import en_gb from './en_gb';
import en_hk from './en_hk';
import en_id from './en_id';
import en_ie from './en_ie';
import en_il from './en_il';
import en_in from './en_in';
import en_jp from './en_jp';
import en_kr from './en_kr';
import en_la from './en_la';
import en_mx from './en_mx';
import en_my from './en_my';
import en_nz from './en_nz';
import en_ph from './en_ph';
import en_sg from './en_sg';
import en_th from './en_th';
import en_tw from './en_tw';
import en_us from './en_us';
import en_vn from './en_vn';
import en_za from './en_za';
import es_ar from './es_ar';
import es_bo from './es_bo';
import es_bz from './es_bz';
import es_cl from './es_cl';
import es_co from './es_co';
import es_cr from './es_cr';
import es_ec from './es_ec';
import es_es from './es_es';
import es_gt from './es_gt';
import es_gy from './es_gy';
import es_hn from './es_hn';
import es_mx from './es_mx';
import es_ni from './es_ni';
import es_pa from './es_pa';
import es_pe from './es_pe';
import es_py from './es_py';
import es_sv from './es_sv';
import es_us from './es_us';
import es_uy from './es_uy';
import es_ve from './es_ve';
import es_xl from './es_xl';
import et_ee from './et_ee';
import fi_fi from './fi_fi';
import fr_be from './fr_be';
import fr_ca from './fr_ca';
import fr_ch from './fr_ch';
import fr_fr from './fr_fr';
import fr_gf from './fr_gf';
import hr_hr from './hr_hr';
import hu_hu from './hu_hu';
import id_id from './id_id';
import is_is from './is_is';
import it_ch from './it_ch';
import it_it from './it_it';
import iw_il from './iw_il';
import ja_jp from './ja_jp';
import ko_kr from './ko_kr';
import lt_lt from './lt_lt';
import lv_lv from './lv_lv';
import ms_my from './ms_my';
import nl_be from './nl_be';
import nl_nl from './nl_nl';
import nl_sr from './nl_sr';
import nb_no from './nb_no';
import pl_pl from './pl_pl';
import pt_br from './pt_br';
import pt_pt from './pt_pt';
import ru_ru from './ru_ru';
import sk_sk from './sk_sk';
import sv_se from './sv_se';
import th_th from './th_th';
import tr_tr from './tr_tr';
import uk_ua from './uk_ua';
import vi_vn from './vi_vn';
import zh_cn from './zh_cn';
import zh_hk from './zh_hk';
import zh_tw from './zh_tw';

export const AVAILABLE_LANGUAGES = {
  ar_ae,
  cs_cz,
  da_dk,
  de_at,
  de_be,
  de_ch,
  de_de,
  el_gr,
  en_as,
  en_au,
  en_ca,
  en_cn,
  en_gb,
  en_hk,
  en_id,
  en_ie,
  en_il,
  en_in,
  en_jp,
  en_kr,
  en_la,
  en_mx,
  en_my,
  en_nz,
  en_ph,
  en_sg,
  en_th,
  en_tw,
  en_us,
  en_vn,
  en_za,
  es_ar,
  es_bo,
  es_bz,
  es_cl,
  es_co,
  es_cr,
  es_ec,
  es_es,
  es_gt,
  es_gy,
  es_hn,
  es_mx,
  es_ni,
  es_pa,
  es_pe,
  es_py,
  es_sv,
  es_us,
  es_uy,
  es_ve,
  es_xl,
  et_ee,
  fi_fi,
  fr_be,
  fr_ca,
  fr_ch,
  fr_fr,
  fr_gf,
  hr_hr,
  hu_hu,
  id_id,
  is_is,
  it_ch,
  it_it,
  iw_il,
  ja_jp,
  ko_kr,
  lt_lt,
  lv_lv,
  ms_my,
  nl_be,
  nl_nl,
  nl_sr,
  nb_no,
  pl_pl,
  pt_br,
  pt_pt,
  ru_ru,
  sk_sk,
  sv_se,
  th_th,
  tr_tr,
  uk_ua,
  vi_vn,
  zh_cn,
  zh_hk,
  zh_tw,
};
