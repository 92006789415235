export default {
    "current_deal": "Oferta actual",
    "lbg.section_properties_theme.pet_friendly": "Ganadores de la categoría \"aceptan mascotas\" en {0}",
    "address": "Dirección",
    "hub.homepage.h2": "¡Tantos lugares por descubrir y tan poco tiempo!",
    "search_hotels_near_this_landmark": "Buscar hoteles<span class=\"extra-label\"> cerca de este punto de referencia</span>",
    "hub.category.h1.things_to_do": "Qué hacer en {0}",
    "lbg.h2.vip_access": "Los alojamientos con VIP Access han recibido altas puntuaciones por las increíbles estancias y experiencias que ofrecen.",
    "lbg.theme.cleanliness": "Limpieza",
    "lbg.theme.lgbtq": "LGTBQ",
    "load_more": "Cargar más",
    "link_long_form_to_listiicle_multiple": "{0}: uno de los puntos de interés más destacados en los siguientes artículos: \"{1}\" y \"{2}\". Descubre aquí todo lo que necesitas saber sobre {3}.",
    "lbg.section_properties_theme.boutique": "Ganadores de la categoría \"hoteles boutique\" en {0}",
    "lbg.h2.luxury": "Disfruta del lujo en todo su esplendor. Por eso mismo, a los huéspedes les han encantado estos alojamientos.",
    "hub.category.h1.information": "Información sobre {0}",
    "families": "Familias",
    "trending_now": "Destinos de moda",
    "lbg.from": "Desde",
    "hub.category.h1.road_trips": "Las mejores rutas de carretera por {0}",
    "lbg.h2.cleanliness": "Estos alojamientos disfrutan de una excelente valoración de sus huéspedes, donde destaca la limpieza con una magnífica puntuación.",
    "single": "Solteros",
    "couples": "Parejas",
    "trust_module.price_guarantee.find_out_more": "Saber más",
    "top_destinations_in": "Los mejores destinos en {0}",
    "hub.category.h1.couples": "Viajes para parejas en {0}",
    "hub.category.h2.tours": "Mejores visitas organizadas en {0}",
    "hub.category.h1.photos": "Viajes ideales para sacar fotos en {0}",
    "category.photos": "Fotos",
    "hub.category.h1.budget": "Viajes para todos los bolsillos en {0}",
    "hub.category.h2.ski": "Los mejores destinos para esquiar en {0}",
    "lbg.section_theme_world_winners.most_wanted": "Ganadores de la categoría \"Los más buscados\"",
    "lbg.theme.home_and_apartments": "Casas y apartamentos",
    "hub.category.h1.luxury": "Viajes de lujo en {0}",
    "hub.category.h2.adventure": "Los mejores viajes de aventura en {0}",
    "hotels_in": "Hoteles en {0}",
    "lbg.section_search_winners": "Descubre más alojamientos premiados",
    "see_more_hotels_in": "Ver más hoteles en {0}",
    "hub.category.h1.beach": "Playas en {0}",
    "open": "Horario:",
    "enter_your_destination": "Introduce tu destino",
    "best_of": "Lo mejor de {0}",
    "top10_hotels_missing_properties": "Es posible que en estos momentos no se muestren algunos alojamientos. Disculpa las molestias.",
    "adventure": "Aventuras",
    "lbg.h1.destination_theme.sustainable": "Ganadores de la categoría \"ecológico\" en {0}",
    "luxury": "Lujo",
    "expand_map_view": "Ampliar la vista de mapa",
    "book_now": "Reservar ya",
    "lbg.h1.destination_theme.ski": "Ganadores de la categoría \"esquí\" en {0}",
    "hub.category.h1.food": "Restaurantes en {0}",
    "category.blogs": "Blog",
    "budget": "Asequible",
    "popular_neighbourhoods_in": "Barrios populares en {0}",
    "hub.category.h1.unusual": "Tesoros ocultos y lugares insólitos en {0}",
    "see_all_accommodation_in": "Ver todos los alojamientos en {0}",
    "hub.category.h1.adventure": "Viajes de aventura en {0}",
    "category_highlight.see_all.shopping": "Más información sobre compras",
    "lbg.section_most_popular_destination": "Los destinos más populares",
    "lbg.section_properties_theme.luxury": "Ganadores de la categoría \"de lujo\" en {0}",
    "unusual": "Poco común",
    "category.shopping": "Tiendas",
    "make_the_most_of_your_trip": "Saca el máximo partido de tu viaje",
    "x_review": "{0,choice,0#|1#{0} comentario|2#{0} comentarios|2<{0} comentarios|4<{0} comentarios|10#{0} comentarios|10<{0} comentarios}",
    "hub.category.h1.single": "Viajes en solitario en {0}",
    "hub.category.h2.custom": "De moda",
    "lbg.section_theme_world_winners.lgbt": "Ganadores de la categoría \"LGTBQ+\"",
    "lbg.section_award_winners_theme_other": "Ganadores de otras categorías en {0}",
    "lbg.supplier_collected_charges": "+ un cargo de {0} que se paga en el alojamiento",
    "in_destination": " en {0}",
    "lbg.section_properties_theme.vip_access": "Ganadores de la categoría \"VIP Access\" en {0}",
    "link_long_form_to_listiicle_single": "{0}: uno de los puntos de interés más destacados en el artículo \"{1}\". Descubre aquí todo lo que necesitas saber sobre {2}.",
    "lbg.section_theme_world_winners.boutique": "Ganadores de la categoría \"hoteles boutique\"",
    "lbg.section_properties_theme.spa_hotel": "Ganadores de la categoría \"alojamientos con spa\" en {0}",
    "lbg.section_theme_world_winners.all_inclusive": "Ganadores de la categoría \"todo incluido\"",
    "hub.category.h2": "Guía de viaje de {0}",
    "lbg.properties.price_tooltip": "Este es el precio más bajo por noche de nuestro sitio web para los próximos 14 días.",
    "hub.category.h2.road_trips": "Las mejores rutas de carretera por {0}",
    "hub.category.h1.ski": "Destinos para esquiar en {0}",
    "food": "Comida",
    "lbg.theme.pet_friendly": "Aceptan mascotas",
    "lbg.theme.business": "Negocios",
    "hub.category.h1.nature": "Los mejores viajes para recorrer lugares naturales de {0}",
    "map": "Mapa",
    "category_highlight.see_all.information": "Más información",
    "hotel_prices_in": "Precios de hoteles en {0}",
    "hub.category.h1.nightlife": "Vida nocturna en {0}",
    "trust_module.free_cancellation.most_hotels": "en la mayoría de los hoteles*",
    "also_popular_in": "Otros lugares populares de {0}",
    "lbg.h1.destination_theme.business": "Ganadores de la categoría \"negocios\" en {0}",
    "lbg.section_theme_world_winners.pet_friendly": "Ganadores de la categoría \"aceptan mascotas\"",
    "hub.category.h1.maps": "Mapas de {0}",
    "lbg.section_properties_theme.most_wanted": "Ganadores de la categoría \"Los más buscados\" en {0}",
    "category.nightlife": "Ambiente nocturno",
    "search_hotels_in_this_area": "Buscar hoteles<span class=\"extra-label\"> en esta zona</span>",
    "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 voto|2#2 votos|2<{1} votos|4<{1} votos|10#10 votos|10<{1} votos})",
    "hub.category.h2.business": "Los mejores viajes de negocios en {0}",
    "category.things_to_do": "Atracciones y puntos de interés",
    "destination_travel_guide_by_category": "Guía de viajes de {0} por categoría",
    "hub.category.h1.families": "Viajes en familia por {0}",
    "lbg.section_theme_world_winners.ski": "Ganadores de la categoría \"esquí\"",
    "you_may_also_like": "Quizá también te interese:",
    "lbg.theme.sustainable": "Ecológico",
    "hub.category.h2.unusual": "Los mejores tesoros ocultos y lugares insólitos en {0}",
    "hub.category.h2.football": "¡Apoya a tu equipo favorito y disfruta al máximo de tu viaje!",
    "top_hotel": "Hotel destacado",
    "lbg.h1.destination_theme.luxury": "Ganadores de la categoría \"de lujo\" en {0}",
    "top10_hotels_empty_alert": "Tenemos problemas técnicos en estos momentos. <span>Disculpa las molestias.</span>",
    "special_deal": "Oferta especial",
    "lbg.h2.sustainable": "¡La sostenibilidad es cada vez más importante! Echa un vistazo a estos alojamientos que la tienen muy en cuenta a la hora de brindarte una estancia excelente.",
    "where_to_stay_in": "Dónde alojarse en {0}",
    "start_planning_your_trip": "Organiza tu viaje",
    "good_for": "Categorías:",
    "category.hotels": "Hoteles",
    "x_accommodation_guide": "Guía sobre alojamientos de {0}",
    "lbg.theme.luxury": "De lujo",
    "hub.category.h2.sports": "Los mejores deportes y actividades en {0}",
    "lbg.section_theme_world_winners.lgbtq": "Ganadores de la categoría \"LGTBQ\"",
    "see_more_neighbourhoods": "Ver más zonas",
    "category_highlight.title.nightlife": "{0}: por dónde salir y qué hacer por las noches",
    "lbg.h1.destination_theme.lgbt": "Ganadores de la categoría \"LGTBQ+\" en {0}",
    "hub.category.h1.blogs": "Blog de viajes de {0}",
    "lbg.h2.business": "Estos alojamientos han recibido puntuaciones altas de los viajeros de negocios.",
    "hotels": "Hoteles",
    "lbg.h2.pet_friendly": "Los huéspedes recomiendan estos alojamientos para disfrutar de una estancia con tu mascota.",
    "popular_landmarks_nearby": "Puntos de referencia cercanos",
    "popular_extractions_in": "Atracciones populares en {0}",
    "lbg.h2.all_inclusive": "Libera tu estrés en estos alojamientos con las mejores valoraciones.",
    "travel_guide_by_category_header": "Lo mejor de {1} en cuanto a {0}",
    "lbg.travel_between": "Fechas de viaje: {0} - {1}",
    "keep_exploring": "Explora otros lugares",
    "what_you_need_to_know_before_you_go": "Información útil antes del viaje",
    "read_it": "Leer más",
    "view_on_map": "Ver mapa",
    "lbg.h1.destination_theme.lgbtq": "Ganadores de la categoría \"LGTBQ\" en {0}",
    "lbg.h2.beach": "A los huéspedes les encantan las escapadas a la playa, sobre todo en estos alojamientos.",
    "lbg.h1.destination_theme.vip_access": "Ganadores de la categoría \"VIP Access\" en {0}",
    "see_all_accommodation": "Ver todos los alojamientos",
    "shoppers": "Ir de compras",
    "whats_around": "Lugares de interés cerca de {0}",
    "group": "Grupos",
    "hub.category.h2.couples": "Los mejores viajes para parejas en {0}",
    "hub.category.h1.shopping": "Tiendas en {0}",
    "travel_community": "{0} <span>&#124;</span> <span>Comunidad de viajeros</span>",
    "hub.category.h2.group": "Los mejores viajes para grupos en {0}",
    "listicle_legal_msg": "En este artículo se incluyen las opiniones del equipo editorial de Go Guides. Los autores de este tipo de artículos reciben una compensación por parte de Hoteles.com, que puede abarcar desde viajes hasta cualquier otro tipo de costes.",
    "lbg.h1.destination_theme.cleanliness": "Ganadores de la categoría \"Limpieza\" en {0}",
    "menu.more": "Más",
    "lbg.section_properties_theme.lgbtq": "Ganadores de la categoría \"LGTBQ\" en {0}",
    "lbg.section_award_winners_destination": "Ganadores por destino",
    "lbg.terms_conditions": "Términos y condiciones",
    "hub.category.h2.nightlife": "Mejores zonas de ambiente nocturno en {0}",
    "hub.neighbourhood.h2": "Actividades en {0}",
    "lbg.section_award_winners_theme_destination": "Ganadores por categoría en {0}",
    "lbg.section_award_world_winners": "Ganadores en todo el mundo",
    "things_to_see_and_do_in_other_cities": "Qué ver y hacer en otras ciudades",
    "button.backToTop": "Volver al principio",
    "lbg.section_theme_world_winners.family": "Ganadores de la categoría \"familia\"",
    "hotels_near": "Hoteles cerca de {0}",
    "category.activity__sports_": "Actividades",
    "trust_module.get_reward_night": "Una noche extra",
    "hub.category.h1.business": "Viajes de negocios en {0}",
    "category.food": "Comida",
    "other_popular_destinations_in": "Otros destinos destacados en {0}",
    "browse_by_category": "{0}: ver por categoría",
    "see_more_attactions": "Ver más atracciones",
    "lbg.theme.lgbt": "LGTBQ+",
    "more_information_about": "Más información sobre {0}",
    "lbg.h1.destination_theme.most_wanted": "Ganadores de la categoría \"Los más buscados\" en {0}",
    "see_all_properties_in": "Ver alojamientos de {0}",
    "category.tours": "Visitas organizadas",
    "hub.category.h1.sports": "Deportes y actividades en {0}",
    "where_will_you_go_next": "¿Cuál es tu siguiente destino?",
    "cities": "Ciudades",
    "lbg.theme.spa_hotel": "Alojamientos con spa",
    "see_guide": "Ver guía",
    "lbg.section_properties_theme.ski": "Ganadores de la categoría \"esquí\" en {0}",
    "related_stories": "Artículos relacionados",
    "hub.destination.h2": "Información útil antes del viaje",
    "show_less": "Ver menos",
    "hub.category.h1.football": "La guía definitiva de {0} para los verdaderos fanáticos del fútbol",
    "hub.category.h2.food": "Mejores restaurantes en {0}",
    "fallback_language_alert": "Esta página todavía no está traducida a tu idioma.",
    "category_highlight.title.information": "Información útil",
    "neighbourhood_guides_for_other_cities": "Guías de barrios para otras ciudades",
    "lbg.h2.hubpage": "Estos alojamientos tienen muchos comentarios, así como una excelente puntuación de sus huéspedes.",
    "travel_advisory.title": "Alerta de viaje",
    "lbg.section_properties_theme.all_inclusive": "Ganadores de la categoría \"todo incluido\" en {0}",
    "lbg.h2.boutique": "Si te gustan los alojamientos boutique, los huéspedes recomiendan estos.",
    "lbg.section_properties_theme.family": "Ganadores de la categoría \"familia\" en {0}",
    "hub.category.h2.families": "Los mejores viajes en familia por {0}",
    "from": "desde",
    "lbg.theme.vip_access": "VIP Access",
    "lbg.section_properties_theme.lgbt": "Ganadores de la categoría \"LGTBQ+\" en {0}",
    "x_star": "{0,choice,0#|1#{0} estrella|1.5#{0} estrellas|2#{0} estrellas|2.5#{0} estrellas|3#{0} estrellas|3.5#{0} estrellas|4#{0} estrellas|4.5#{0} estrellas|5#{0} estrellas}",
    "trust_module.free_cancellation": "Cancelación gratuita",
    "lbg.section_properties_theme.home_and_apartments": "Ganadores de la categoría \"casas y apartamentos\" en {0}",
    "contributing_writer": "{0} <span>&#124;</span> <span>Colaborador/a habitual</span>",
    "things_to_see_and_do_in": "Qué ver y hacer <span class=\"next-line\">en {0}</span>",
    "lbg.check_price": "Ver precio",
    "hub.category.h2.blogs": "escrito por personas expertas en viajar",
    "lbg.h2.home_and_apartments": "¿Quieres sentirte como en casa cuando viajes? Echa un vistazo a estos alojamientos con muy buenas valoraciones.",
    "hub.category.h2.information": "Información útil sobre {0}",
    "search_hotels_in": "Buscar hoteles<span class=\"extra-label\"> en {0}</span>",
    "hub.category.h2.nature": "Los mejores viajes por lugares naturales en {0}",
    "lbg.h1.destination_theme.home_and_apartments": "Ganadores de la categoría \"casas y apartamentos\" en {0}",
    "photo_by": "foto de {0}",
    "more_things_to_do": "Más actividades y puntos de interés",
    "lbg.section_properties_theme.beach": "Ganadores de la categoría \"playa\" en {0}",
    "read_less": "Leer menos",
    "lbg.h2.spa_hotel": "Desconecta y relájate en estos alojamientos con altas puntuaciones muy famosos por su spa.",
    "second_fold_related_stories.see_also": "También te pueden interesar",
    "hub.category.h2.history": "Los mejores lugares históricos en {0}",
    "lbg.section_properties_theme.cleanliness": "Ganadores de la categoría \"Limpieza\" en {0}",
    "lbg.h1.destination_theme.boutique": "Ganadores de la categoría \"hoteles boutique\" en {0}",
    "nightlife_b595ae4": "Ambiente nocturno",
    "lbg.h1.destination_theme.beach": "Ganadores de la categoría \"playa\" en {0}",
    "trust_module.free_cancellation.disclaimer": "En algunos hoteles, las cancelaciones deben realizarse al menos 24-48 horas antes de la entrada. Puedes obtener más información en el sitio web.",
    "lbg.h2.family": "Estos alojamientos han recibido puntuaciones altas de los huéspedes y son ideales para las familias.",
    "lbg.h2.lgbtq": "Estos alojamientos LGTBQ han recibido puntuaciones altas de los huéspedes.",
    "hub.category.h1.group": "Viajes para grupos en {0}",
    "popular_stories": "Historias populares",
    "toc": "Contenido",
    "hub.category.h2.shopping": "Mejores tiendas en {0}",
    "lbg.section_theme_world_winners.beach": "Ganadores de la categoría \"playa\"",
    "page_score": "Puntuación de esta página",
    "lbg.section_theme_world_winners.sustainable": "Ganadores de la categoría \"ecológico\"",
    "lbg.theme.boutique": "Hoteles boutique",
    "trust_module.price_guarantee": "Nuestro precio garantizado",
    "lbg.h1.destination_theme.all_inclusive": "Ganadores de la categoría \"todo incluido\" en {0}",
    "top_destinations": "Destinos principales",
    "hotel_prices_near": "Precios de hoteles cerca de {0}",
    "lbg.score.cleanliness": "Limpieza:",
    "x_stars": "{0,choice,0#|1#{0} estrella|1<{0} estrellas|4<{0} estrellas|10<{0} estrellas}",
    "lbg.theme.all_inclusive": "Todo incluido",
    "hub.category.h1.tours": "Visitas turísticas y excursiones de un día en {0}",
    "lbg.section_award_winners_theme": "Ganadores por categoría",
    "guest_writer": "{0} <span>&#124;</span> <span>Autor/a invitado/a</span>",
    "start_planning_your_x_trip": "Organiza tu viaje a {0}",
    "lbg.section_theme_world_winners.business": "Ganadores de la categoría \"negocios\"",
    "trending_now_in": "Lo mejor de {0}",
    "history": "Historia",
    "search_hotels": "Buscar hoteles",
    "lbg.terms_conditions_details": "Los criterios para seleccionar un alojamiento como ganador en {0} se basan en puntuaciones altas de los huéspedes en {1} y un mínimo de 50 comentarios.",
    "see_nearby_properties": "Ver alojamientos cercanos",
    "hub.category.h1.history": "Lugares históricos en {0}",
    "location": "Dirección:",
    "lbg.section_theme_world_winners.luxury": "Ganadores de la categoría \"de lujo\"",
    "category_highlight.see_all.nightlife": "Más información sobre vida nocturna",
    "also_popular": "Otros lugares populares",
    "hub.category.h2.things_to_do": "Mejores actividades en {0}",
    "lbg.theme.family": "Familia",
    "lbg.h1.destination_theme.pet_friendly": "Ganadores de la categoría \"aceptan mascotas\" en {0}",
    "category_highlight.title.shopping": "{0}: qué comprar y dónde",
    "price": "Precio:",
    "hub.category.h2.beach": "Mejores playas en {0}",
    "neighbourhoods_in": "Zonas de {0}",
    "business": "Negocios",
    "photo": "Fotos",
    "blog.see_all_stories": "Ver todas las historias",
    "category_highlight.see_all.food": "Más información sobre gastronomía",
    "see_all_properties": "Ver todos los alojamientos",
    "lbg.theme.beach": "Playa",
    "what_to_see_and_do_in": "Qué hacer en {0}",
    "hub.category.h2.single": "Los mejores viajes en solitario en {0}",
    "attractions_in": "Atracciones turísticas en {0}",
    "read_more": "Leer más",
    "show_more": "Ver más",
    "hotels_near_this_landmark": "Hoteles cerca de este punto de referencia",
    "check_dates": "Comprobar fechas",
    "featured_stories_and_fun_stuff": "Cosas curiosas y divertidas",
    "phone": "Teléfono:",
    "lbg.section_search_header": "Busca alojamientos premiados",
    "category_highlight.title.food": "{0}: qué comer y dónde",
    "see_all_things_to_do": "Ver todas las actividades",
    "lbg.section_properties_theme.sustainable": "Ganadores de la categoría \"ecológico\" en {0}",
    "lbg.h1.hubpage": "¡Los ganadores del premio Loved by Guests de {0}!",
    "lbg.section_theme_world_winners.spa_hotel": "Ganadores de la categoría \"alojamientos con spa\"",
    "category.maps": "Mapas",
    "lbg.h1.destination_theme.family": "Ganadores de la categoría \"familia\" en {0}",
    "lbg.section_theme_world_winners.cleanliness": "Ganadores de la categoría \"Limpieza\"",
    "lbg.section_theme_world_winners.vip_access": "Ganadores de la categoría \"VIP Access\"",
    "trust_module.get_reward_night.night_you_stay": "por cada 10 noches de alojamiento",
    "modified": "modificada",
    "lbg.section_properties": "Ganadores en {0}",
    "trust_module.header": "¿Por qué reservar con Hoteles.com?",
    "hub.category.h2.luxury": "Los mejores viajes de lujo en {0}",
    "lbg.theme.ski": "Esquí",
    "lbg.h1.destination_theme.spa_hotel": "Ganadores de la categoría \"alojamientos con spa\" en {0}",
    "lbg.h2.most_wanted": "Los huéspedes recomiendan estos alojamientos para disfrutar de una estancia perfecta.",
    "top10_hotels_incomplete_alert": "Hay mucha demanda en estos momentos, por lo que es posible que algunos alojamientos no aparezcan aquí. <span>Disculpa las molestias.</span>",
    "compulsive_traveller": "{0} <span>&#124;</span> <span>Viajero experto</span>",
    "lbg.section_theme_world_winners.home_and_apartments": "Ganadores de la categoría \"casas y apartamentos\"",
    "loved_by_guests_collection_in": "Colección de Loved by Guests en {0}",
    "title.suffix": "{0}: Go Guides",
    "hub.category.h2.maps": "Mapas útiles de {0}",
    "opening_hours": "Horario de apertura",
    "hub.category.h2.photos": "Los mejores viajes para sacar fotos en {0}",
    "lbg.theme.most_wanted": "Los más buscados",
    "blog.stories_container": "Historias del blog",
    "hub.category.h2.budget": "Los mejores viajes para todos los bolsillos en {0}",
    "lbg.section_properties_theme.business": "Ganadores de la categoría \"negocios\" en {0}",
    "not_sure_where_to_stay": "¿No sabes dónde alojarte?",
    "category.information": "Información"
}
