export default {
    "current_deal": "מבצע נוכחי",
    "lbg.section_properties_theme.pet_friendly": "זוכים בפרס הידידותי לחיות מחמד ב{0}",
    "address": "כתובת",
    "hub.homepage.h2": "כל כך הרבה מקומות, כל כך מעט זמן...",
    "search_hotels_near_this_landmark": "חיפוש מלונות<span class=\"extra-label\"> בקרבת ציון דרך זה</span>",
    "hub.category.h1.things_to_do": "מה ניתן לעשות ב{0}",
    "lbg.h2.vip_access": "נכסי VIP Access נבחרו הודות לשהיות עם דירוג גבוה ולחוויות המצוינות שהם מציעים.",
    "lbg.theme.cleanliness": "מידת ניקיון",
    "lbg.theme.lgbtq": "הקהילה הגאה",
    "load_more": "טען עוד",
    "link_long_form_to_listiicle_multiple": "⁦{0}⁩ - אחת מגולות הכותרת של ⁦{1}⁩ ו⁦{2}⁩ (קראו אודות ⁦{3}⁩ כאן)",
    "lbg.section_properties_theme.boutique": "זוכים בפרס הבוטיק ב{0}",
    "lbg.h2.luxury": "בואו לגלות יוקרה יוצאת מהכלל. זו הסיבה שאורחים אוהבים לשהות בנכסים האלו.",
    "hub.category.h1.information": "מידע על {0}",
    "families": "משפחות",
    "trending_now": "הכי פופולרי",
    "lbg.from": "החל מ-",
    "hub.category.h1.road_trips": "הרוד טריפ הכי טוב ב-⁦{0}⁩",
    "lbg.h2.cleanliness": "נכסים אלו קיבלו ציון כללי מעולה בדירוג האורחים וציון מוביל בדירוג האורחים במידת הניקיון.",
    "single": "יחיד",
    "couples": "זוגות",
    "trust_module.price_guarantee.find_out_more": "גלו עוד",
    "top_destinations_in": "יעדים מועדפים ב{0}",
    "hub.category.h1.couples": "נסיעות זוגיות ב⁦{0}⁩",
    "hub.category.h2.tours": "הסיורים הטובים ביותר ב{0}",
    "hub.category.h1.photos": "נסיעות המתאימות לחובבי צילום ב⁦{0}⁩",
    "category.photos": "תמונות",
    "hub.category.h1.budget": "טיולים המתאימים לכל כיס ב⁦{0}⁩",
    "hub.category.h2.ski": "יעדי הסקי הטובים ביותר ב⁦{0}⁩",
    "lbg.section_theme_world_winners.most_wanted": "זוכים בפרס המבוקשים ביותר",
    "lbg.theme.home_and_apartments": "בתים ודירות",
    "hub.category.h1.luxury": "נסיעות יוקרתיות ב⁦{0}⁩",
    "hub.category.h2.adventure": "הטיולים האקטיביים הפופולריים ביותר ב⁦{0}⁩",
    "hotels_in": "מלונות ב{0}",
    "lbg.section_search_winners": "חפשו עוד זוכים בפרס",
    "see_more_hotels_in": "ראו מלונות נוספים ב{0}",
    "hub.category.h1.beach": "חופים ב⁦{0}⁩",
    "open": "שעות פתיחה:",
    "enter_your_destination": "הזינו את היעד שלכם",
    "best_of": "הטוב ביותר ב{0}",
    "top10_hotels_missing_properties": "ייתכן שחלק מהנכסים לא יוצגו בשלב זה. אנו מתנצלים על אי הנוחות.",
    "adventure": "הרפתקאות",
    "lbg.h1.destination_theme.sustainable": "זוכים בפרס הקיימות ב{0}",
    "luxury": "יוקרה",
    "expand_map_view": "הגדלת תצוגת המפה",
    "book_now": "הזמינו עכשיו",
    "lbg.h1.destination_theme.ski": "זוכים בפרס הסקי ב{0}",
    "hub.category.h1.food": "אוכל ומסעדות ב{0}",
    "category.blogs": "בלוג",
    "budget": "חסכוני",
    "popular_neighbourhoods_in": "אזורים פופולריים ב{0}",
    "hub.category.h1.unusual": "פנינות חמד ומקומות ייחודיים ב⁦{0}⁩",
    "see_all_accommodation_in": "צפו בלינות ב⁦{0}⁩",
    "hub.category.h1.adventure": "טיולים אקטיביים ב⁦{0}⁩",
    "category_highlight.see_all.shopping": "מידע נוסף אודות קניות",
    "lbg.section_most_popular_destination": "היעדים הפופולאריים ביותר",
    "lbg.section_properties_theme.luxury": "זוכים בפרס היוקרה ב{0}",
    "unusual": "לא שגרתי",
    "category.shopping": "קניות",
    "make_the_most_of_your_trip": "הפיקו את המרב מהנסיעה שלכם",
    "x_review": "{0,choice,0#|1#{0} חוות דעת|2#{0} חוות דעת|2<{0} חוות דעת|4<{0} חוות דעת|10#{0} חוות דעת|10<{0} חוות דעת}",
    "hub.category.h1.single": "טיולי יחידים ב⁦{0}⁩",
    "hub.category.h2.custom": "פופולרי עכשיו",
    "lbg.section_theme_world_winners.lgbt": "זוכי פרס הקהילה הגאה ב",
    "lbg.section_award_winners_theme_other": "זוכים בפרס בנושאים שונים ב{0}",
    "lbg.supplier_collected_charges": "+ {0} לתשלום בנכס",
    "in_destination": " ב{0}",
    "lbg.section_properties_theme.vip_access": "זוכים בפרס VIP Access ב⁦{0}⁩",
    "link_long_form_to_listiicle_single": "⁦{0}⁩ - אחת מגולות הכותרת של ⁦{1}⁩ (קראו אודות ⁦{2}⁩ כאן)",
    "lbg.section_theme_world_winners.boutique": "זוכים בפרס הבוטיק",
    "lbg.section_properties_theme.spa_hotel": "זוכים בפרס הספא ב{0}",
    "lbg.section_theme_world_winners.all_inclusive": "זוכים בפרס הכל כלול",
    "hub.category.h2": "מדריכי נסיעה ל{0}",
    "lbg.properties.price_tooltip": "זהו המחיר הנמוך ביותר ללילה באתר שלנו למשך 14 הימים הבאים.",
    "hub.category.h2.road_trips": "הרוד טריפ הכי טוב ב-⁦{0}⁩",
    "hub.category.h1.ski": "יעדי סקי ב⁦{0}⁩",
    "food": "אוכל",
    "lbg.theme.pet_friendly": "ידידותי לחיות מחמד",
    "lbg.theme.business": "עסקים",
    "hub.category.h1.nature": "הנסיעות הכי טובות לברוח לטבע ב-⁦{0}⁩",
    "map": "מפה",
    "category_highlight.see_all.information": "מידע נוסף",
    "hotel_prices_in": "מחירי מלונות ב{0}",
    "hub.category.h1.nightlife": "חיי לילה ב{0}",
    "trust_module.free_cancellation.most_hotels": "ברוב המלונות*",
    "also_popular_in": "פופולרי גם ב-{0}",
    "lbg.h1.destination_theme.business": "זוכים בפרס העסקים ב{0}",
    "lbg.section_theme_world_winners.pet_friendly": "זוכים בפרס ידידותי לחיות מחמד",
    "hub.category.h1.maps": "מפות של ⁦{0}⁩",
    "lbg.section_properties_theme.most_wanted": "זוכים בפרס המבוקשים ביותר ב{0}",
    "category.nightlife": "חיי לילה",
    "search_hotels_in_this_area": "חיפוש מלונות<span class=\"extra-label\"> באזור זה</span>",
    "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 הצבעה|2#2 הצבעות|2<{1} הצבעות|4<{1} הצבעות|10#10 הצבעות|10<{1} הצבעות})",
    "hub.category.h2.business": "הנסיעות העסקיות הפופולריות ביותר ב⁦{0}⁩",
    "category.things_to_do": "פעילויות",
    "destination_travel_guide_by_category": "מדריך נסיעה עבור {0} לפי קטגוריה",
    "hub.category.h1.families": "נסיעה משפחתית ב⁦{0}⁩",
    "lbg.section_theme_world_winners.ski": "זוכים בפרס הסקי",
    "you_may_also_like": "אולי תאהבו גם",
    "lbg.theme.sustainable": "קיימות",
    "hub.category.h2.unusual": "פנינות החמד והמקומות הייחודיים הפופולריים ביותר ב⁦{0}⁩",
    "hub.category.h2.football": "תמכו בקבוצת הכדורגל שלכם והפיקו את המקסימום מהנסיעה!",
    "top_hotel": "מלון מוביל",
    "lbg.h1.destination_theme.luxury": "זוכים בפרס היוקרה ב{0}",
    "top10_hotels_empty_alert": "אנו חווים כעת בעיה טכנית. <span>אנו מתנצלים על אי הנוחות.</span>",
    "special_deal": "מבצע מיוחד",
    "lbg.h2.sustainable": "קיימות לפני הכל! בדקו נכסים המציעים שהות מובחרת.",
    "where_to_stay_in": "היכן כדאי לשהות ב{0}",
    "start_planning_your_trip": "התחילו לתכנן את הנסיעה שלכם",
    "good_for": ":מומלץ עבור",
    "category.hotels": "מלונות",
    "x_accommodation_guide": "מדריך הלינה של {0}",
    "lbg.theme.luxury": "יוקרה",
    "hub.category.h2.sports": "אטרקציות הספורט והפעילויות הפופולריות ביותר ב⁦{0}⁩",
    "lbg.section_theme_world_winners.lgbtq": "זוכים בפרס הקהילה הגאה",
    "see_more_neighbourhoods": "ראו אזורים נוספים",
    "category_highlight.title.nightlife": "לאן ללכת ומה לעשות בלילה ב⁦{0}⁩",
    "lbg.h1.destination_theme.lgbt": "זוכי פרס הקהילה הגאה ב{0}",
    "hub.category.h1.blogs": "בלוג נסיעות על {0}",
    "lbg.h2.business": "נכסים אלו קיבלו דירוג גבוה מנוסעים למטרות עסקים.",
    "hotels": "מלונות",
    "lbg.h2.pet_friendly": "אורחים ממליצים מאוד על הנכסים האלה שמקבלים אתכם ואת חיית המחמד שלכם.",
    "popular_landmarks_nearby": "נקודות ציון פופולריות בקרבת מקום",
    "popular_extractions_in": "אטרקציות פופולריות ב{0}",
    "lbg.h2.all_inclusive": "נכסים בעלי דירוג גבוה להזמנה בראש שקט.",
    "travel_guide_by_category_header": "{0} ב{1}",
    "lbg.travel_between": "לשהות בין {0}-{1}",
    "keep_exploring": "המשיכו לחקור",
    "what_you_need_to_know_before_you_go": "מה כדאי לדעת לפני שאתם נוסעים",
    "read_it": "קראו עוד",
    "view_on_map": "לצפייה על המפה",
    "lbg.h1.destination_theme.lgbtq": "זוכים בפרס הקהילה הגאה ב⁦{0}⁩",
    "lbg.h2.beach": "אורחים אוהבים שהות ליד הים, במיוחד בנכסים האלה.",
    "lbg.h1.destination_theme.vip_access": "זוכים בפרס VIP Access ב⁦{0}⁩",
    "see_all_accommodation": "צפו בכל הלינות",
    "shoppers": "צרכנים",
    "whats_around": "מה נמצא בסביבה של {0}",
    "group": "קבוצה",
    "hub.category.h2.couples": "הנסיעות הזוגיות הפופולריות ביותר ב⁦{0}⁩",
    "hub.category.h1.shopping": "קניות ב{0}",
    "travel_community": "{0} <span>&#124;</span> <span>קהילת המטיילים</span>",
    "hub.category.h2.group": "הנסיעות הקבוצתיות הפופולריות ביותר ב⁦{0}⁩",
    "listicle_legal_msg": "כתבה זו כוללת דעות של צוות העורכים של ה-Go Guides. Hotels.com מפצה את הכותבים עבור התוכן המופיע באתר זה. הפיצוי הנ\"ל עשוי לכלול הוצאות עבור נסיעות והוצאות אחרות.",
    "lbg.h1.destination_theme.cleanliness": "זוכים בפרס מידת הניקיון ב⁦{0}⁩",
    "menu.more": "עוד",
    "lbg.section_properties_theme.lgbtq": "זוכים בפרס הקהילה הגאה ב⁦{0}⁩",
    "lbg.section_award_winners_destination": "זוכים בפרס לפי יעד",
    "lbg.terms_conditions": "תנאים והתניות",
    "hub.category.h2.nightlife": "חיי הלילה הטובים ביותר ב{0}",
    "hub.neighbourhood.h2": "מה ניתן לעשות ב{0}",
    "lbg.section_award_winners_theme_destination": "זוכים בפרס לפי נושא ב{0}",
    "lbg.section_award_world_winners": "זוכים בפרס בכל העולם",
    "things_to_see_and_do_in_other_cities": "דברים שכדאי לראות ולעשות בערים אחרות",
    "button.backToTop": "חזרה לראש הדף",
    "lbg.section_theme_world_winners.family": "זוכים בפרס המשפחה",
    "hotels_near": "מלונות ליד {0}",
    "category.activity__sports_": "סיורים",
    "trust_module.get_reward_night": "קבלו לילה במתנה",
    "hub.category.h1.business": "נסיעות עסקיות ב⁦{0}⁩",
    "category.food": "אוכל",
    "other_popular_destinations_in": "יעדים פופולריים אחרים ב{0}",
    "browse_by_category": "צפו ב{0} לפי קטגוריה",
    "see_more_attactions": "ראו אטרקציות נוספות",
    "lbg.theme.lgbt": "הקהילה הגאה",
    "more_information_about": "מידע נוסף על {0}",
    "lbg.h1.destination_theme.most_wanted": "זוכים בפרס המבוקשים ביותר ב{0}",
    "see_all_properties_in": "צפייה בנכסים ב⁦{0}⁩",
    "category.tours": "סיורים",
    "hub.category.h1.sports": "אטרקציות ספורט ופעילויות ב⁦{0}⁩",
    "where_will_you_go_next": "מהו המקום הבא אליו תבחרו לנסוע?",
    "cities": "ערים",
    "lbg.theme.spa_hotel": "ספא",
    "see_guide": "קראו במדריך",
    "lbg.section_properties_theme.ski": "זוכים בפרס הסקי ב{0}",
    "related_stories": "סיפורים קשורים",
    "hub.destination.h2": "מה עליכם לדעת לפני הנסיעה לשם",
    "show_less": "הצג פחות",
    "hub.category.h1.football": "מדריך ⁦{0}⁩ המושלם לאוהדי כדורגל אמיתיים",
    "hub.category.h2.food": "האוכל וההסעדה הטובים ביותר ב{0}",
    "fallback_language_alert": "מתנצלים, עמוד זה לא תורגם לשפה שלכם עדיין...",
    "category_highlight.title.information": "מידע שימושי",
    "neighbourhood_guides_for_other_cities": "מדריכי אזור בערים אחרות",
    "lbg.h2.hubpage": "הנכסים הנהדרים הללו קיבלו דירוגי אורחים גבוהים וחוות דעת רבות.",
    "travel_advisory.title": "התראות נסיעה",
    "lbg.section_properties_theme.all_inclusive": "זוכים בפרס הכל כלול ב{0}",
    "lbg.h2.boutique": "אם אתם אוהבים נכסי בוטיק, אורחים ממליצים על אלו.",
    "lbg.section_properties_theme.family": "זוכים בפרס המשפחה ב{0}",
    "hub.category.h2.families": "הנסיעות המשפחתיות הטובות ביותר ב⁦{0}⁩",
    "from": "החל מ",
    "lbg.theme.vip_access": "VIP Access",
    "lbg.section_properties_theme.lgbt": "זוכי פרס הקהילה הגאה ב{0}",
    "x_star": "{0,choice,0#|1#{0}-כוכבים|1.5#{0}-כוכבים|2#{0}-כוכבים|2.5#{0}-כוכבים|3#{0}-כוכבים|3.5#{0}-כוכבים|4#{0}-כוכבים|4.5#{0}-כוכבים|5#{0}-כוכבים}",
    "trust_module.free_cancellation": "ביטול ללא תשלום",
    "lbg.section_properties_theme.home_and_apartments": "זוכים בפרס הבתים ודירות ב{0}",
    "contributing_writer": "{0} <span>&#124;</span> <span>הכותב</span>",
    "things_to_see_and_do_in": "דברים שכדאי לראות ולעשות <span class=\"next-line\">ב{0}</span>",
    "lbg.check_price": "בדקו את המחיר",
    "hub.category.h2.blogs": "מפי מטיילים מנוסים",
    "lbg.h2.home_and_apartments": "מחפשים בית רחוק מהבית? בדקו נכסים עם דירוג גבוה.",
    "hub.category.h2.information": "מידע שימושי אודות {0}",
    "search_hotels_in": "חיפוש מלונות<span class=\"extra-label\"> ב{0}</span>",
    "hub.category.h2.nature": "הנסיעות הכי טובות בטבע ב-⁦{0}⁩",
    "lbg.h1.destination_theme.home_and_apartments": "זוכים בפרס הבתים ודירות ב{0}",
    "photo_by": "צולם על ידי {0}",
    "more_things_to_do": "עוד פעילויות",
    "lbg.section_properties_theme.beach": "זוכים בפרס חוף הים ב{0}",
    "read_less": "קראו פחות",
    "lbg.h2.spa_hotel": "מנוחה ורוגע בנכסים עם דירוג גבוה שידועים בספא שלהם.",
    "second_fold_related_stories.see_also": "ראו גם",
    "hub.category.h2.history": "המקומות ההיסטוריים הפופולריים ביותר ב⁦{0}⁩",
    "lbg.section_properties_theme.cleanliness": "זוכים בפרס מידת הניקיון ב⁦{0}⁩",
    "lbg.h1.destination_theme.boutique": "זוכים בפרס הבוטיק ב{0}",
    "nightlife_b595ae4": "חיי לילה",
    "lbg.h1.destination_theme.beach": "זוכים בפרס חוף הים ב{0}",
    "trust_module.free_cancellation.disclaimer": "חלק מהמלונות ידרשו מכם לבטל לפחות 24-48 שעות לפני מועד הצ’ק-אין. פרטים נוספים באתר.",
    "lbg.h2.family": "נכסים אלו קיבלו דירוג גבוה מאורחים ומושלמים למשפחות.",
    "lbg.h2.lgbtq": "נכסים ידידותיים לקהילה הגאה עם דירוג גבוה מאורחים.",
    "hub.category.h1.group": "נסיעות קבוצתיות ב⁦{0}⁩",
    "popular_stories": "סיפורים פופולאריים",
    "toc": "תוכן",
    "hub.category.h2.shopping": "הקניות הטובות ביותר ב{0}",
    "lbg.section_theme_world_winners.beach": "זוכים בפרס חוף הים",
    "page_score": "הדירוג של עמוד זה",
    "lbg.section_theme_world_winners.sustainable": "זוכים בפרס הקיימות",
    "lbg.theme.boutique": "בוטיק",
    "trust_module.price_guarantee": "ההתחייבות שלנו למחיר",
    "lbg.h1.destination_theme.all_inclusive": "זוכים בפרס הכל כלול ב{0}",
    "top_destinations": "יעדים מובילים",
    "hotel_prices_near": "מחירי מלונות בקרבת {0}",
    "lbg.score.cleanliness": "מידת הניקיון:",
    "x_stars": "{0,choice,0#|1#{0} כוכב|1<{0} כוכבים|4<{0} כוכבים|10<{0} כוכבים}",
    "lbg.theme.all_inclusive": "הכל כלול",
    "hub.category.h1.tours": "סיורים וטיולי יום ב{0}",
    "lbg.section_award_winners_theme": "זוכים בפרס לפי נושא",
    "guest_writer": "{0} <span>&#124;</span> <span>כותב אורח</span>",
    "start_planning_your_x_trip": "התחילו לתכנן את הנסיעה שלכם ל{0}",
    "lbg.section_theme_world_winners.business": "זוכים בפרס העסקים",
    "trending_now_in": "הכי פופולרי כרגע ב{0}",
    "history": "היסטוריה",
    "search_hotels": "חיפוש מלונות",
    "lbg.terms_conditions_details": "הקריטריונים לכל נכס שנבחר כזוכה בפרסים לשנת ⁦{0}⁩ מבוססים על ציונים גבוהים מהאורחים בשנת ⁦{1}⁩ ומינימום 50 חוות דעת.",
    "see_nearby_properties": "צפו בנכסים בקרבת מקום",
    "hub.category.h1.history": "מקומות היסטוריים ב⁦{0}⁩",
    "location": "מיקום:",
    "lbg.section_theme_world_winners.luxury": "זוכים בפרס היוקרה",
    "category_highlight.see_all.nightlife": "מידע נוסף אודות חיי לילה",
    "also_popular": "פופולרי גם",
    "hub.category.h2.things_to_do": "מה הכי מומלץ לעשות ב{0}",
    "lbg.theme.family": "משפחה",
    "lbg.h1.destination_theme.pet_friendly": "זוכים בפרס הידידותי לחיות מחמד ב{0}",
    "category_highlight.title.shopping": "איפה לעשות קניות ומה לקנות ב⁦{0}⁩",
    "price": "מחיר:",
    "hub.category.h2.beach": "החופים הטובים ביותר ב⁦{0}⁩",
    "neighbourhoods_in": "שכונות ב{0}",
    "business": "עסקים",
    "photo": "תמונה",
    "blog.see_all_stories": "לצפייה בכל הסיפורים",
    "category_highlight.see_all.food": "מידע נוסף אודות אוכל ומסעדות",
    "see_all_properties": "צפו בכל הנכסים",
    "lbg.theme.beach": "חוף ים",
    "what_to_see_and_do_in": "מה כדאי לראות ולעשות ב⁦{0}⁩",
    "hub.category.h2.single": "טיולי היחידים הפופולריים ביותר ב⁦{0}⁩",
    "attractions_in": "אטרקציות ב{0}",
    "read_more": "קראו עוד",
    "show_more": "הצג יותר",
    "hotels_near_this_landmark": "מלונות בקרבת ציון דרך זה",
    "check_dates": "בדיקת תאריכים",
    "featured_stories_and_fun_stuff": "סיפורים ודברים כיפיים",
    "phone": "טלפון:",
    "lbg.section_search_header": "חפשו זוכים בפרסים",
    "category_highlight.title.food": "איפה ומה לאכול ב⁦{0}⁩",
    "see_all_things_to_do": "לצפייה בכל הדברים שכדאי לעשות",
    "lbg.section_properties_theme.sustainable": "זוכים בפרס הקיימות ב{0}",
    "lbg.h1.hubpage": "זוכים בפרס אהוב האורחים לשנת {0}!",
    "lbg.section_theme_world_winners.spa_hotel": "זוכים בפרס הספא",
    "category.maps": "מפות",
    "lbg.h1.destination_theme.family": "זוכים בפרס המשפחה ב{0}",
    "lbg.section_theme_world_winners.cleanliness": "זוכים בפרס מידת הניקיון",
    "lbg.section_theme_world_winners.vip_access": "זוכים בפרס VIP Access",
    "trust_module.get_reward_night.night_you_stay": "עבור כל 10 לילות שתשהו",
    "modified": "נערך",
    "lbg.section_properties": "זוכים בפרס ב{0}",
    "trust_module.header": "למה כדאי להזמין עם Hotels.com?",
    "hub.category.h2.luxury": "הנסיעות היוקרתיות הפופולריות ביותר ב⁦{0}⁩",
    "lbg.theme.ski": "סקי",
    "lbg.h1.destination_theme.spa_hotel": "זוכים בפרס הספא ב{0}",
    "lbg.h2.most_wanted": "אורחים ממליצים מאוד על הנכסים האלה לשהות מושלמת.",
    "top10_hotels_incomplete_alert": "אנו חווים כעת ביקוש גבוה, ולכן חלק מהמאפיינים לא יוצגו בשלב זה. <span>אנו מתנצלים על אי הנוחות.</span>",
    "compulsive_traveller": "{0} <span>&#124;</span> <span>הנוסע הכפייתי</span>",
    "lbg.section_theme_world_winners.home_and_apartments": "זוכים בפרס בתים ודירות",
    "loved_by_guests_collection_in": "רשימת אהוב האורחים ב{0}",
    "title.suffix": "{0} - Go Guides",
    "hub.category.h2.maps": "מפות שימושיות של {0}",
    "opening_hours": "שעות פתיחה",
    "hub.category.h2.photos": "הנסיעות המתאימות לחובבי צילום הפופולריות ביותר ב⁦{0}⁩",
    "lbg.theme.most_wanted": "המבוקשים ביותר",
    "blog.stories_container": "סיפורים מתורמים",
    "hub.category.h2.budget": "הטיולים המתאימים לכל כיס הפופולריים ביותר ב⁦{0}⁩",
    "lbg.section_properties_theme.business": "זוכים בפרס העסקים ב{0}",
    "not_sure_where_to_stay": "לא בטוחים איפה לשהות?",
    "category.information": "מידע"
}
