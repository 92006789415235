import React from 'react';
import { useState } from 'react';
import { useStores } from 'utils/useStore';
import { UitkLink } from 'uitk-react-link';
import { UitkBreadcrumbs } from 'uitk-react-breadcrumbs';
import OneCrumb from './onecrumb';

const GoGuideBreadcrumb: React.FC = () => {
  const { goGuidesStore } = useStores();
  const [showMenu, setShowMenu] = useState(false);
  let crumbs;

  const buildBreadcrumb = data => {
    const breadcrumb = data.map((bread, index) => {
      if (bread.targetUrl) {
        return (
          <UitkLink inline={true} key={index}>
            <a href={bread.targetUrl}>{bread.name}</a>
          </UitkLink>
        );
      } else {
        return <span key={index}>{bread.name}</span>;
      }
    });

    crumbs = breadcrumb;
  };

  const handleClick = () => {
    setShowMenu(!showMenu);
  };
  
  buildBreadcrumb(goGuidesStore.fetchBreadcrumb(goGuidesStore.pageUrlData));

  return (
    <>
      {crumbs &&
        (crumbs.length > 1 ? (
          <UitkBreadcrumbs
            crumbs={crumbs}
            hasDynamicInlineCrumbs={true}
            isOpen={showMenu}
            onTriggerClick={handleClick}
          />
        ) : (
          <OneCrumb crumb={crumbs} />
        ))}
    </>
  );
};

export default GoGuideBreadcrumb;
