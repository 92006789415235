import React from 'react';
import { checkValue } from 'components/functions';

export interface KeepExploringSubProps {
  level: string;
  listing: Array<any>;
  listingClassName?: string;
}

const KeepExploringSub: React.FC<KeepExploringSubProps> = (props) => {
  const { level, listing, listingClassName } = props;

  const levelMapping = {
    neighbourhood: {
      dataNodeName: 'neighbourhoods',
      wrapperClassName: 'neighbourhoods-wrap',
    },
    'main-city': {
      dataNodeName: 'cities',
      wrapperClassName: 'main-city-wrap',
    },
    city: {
      dataNodeName: 'allCities',
      wrapperClassName: 'city-wrap',
    },
    region: {
      dataNodeName: 'regions',
      wrapperClassName: 'country-wrap',
    },
    country: {
      dataNodeName: 'country',
      wrapperClassName: 'country-wrap',
    },
    continent: {
      dataNodeName: '',
      wrapperClassName: 'destination-wrap',
    },
  };

  const config = levelMapping[level];
  // no data for the requested node
  if (config.dataNodeName !== '' && !checkValue(listing[config.dataNodeName])) {
    return null;
  }

  const data = config.dataNodeName === '' ? listing : listing[config.dataNodeName];
  const wrapperClassName = config.wrapperClassName;
  const { targetUrl, name, list } = data;
  const destinationListing = level==="region" ? data : (checkValue(list) ? list : checkValue(data.countries) ? data.countries : null);

  const listHeader = () => {
    if (!checkValue(name)) {
      return null;
    } else {
      return checkValue(targetUrl) ? (
        <a href={targetUrl}>
          <h3>{name}</h3>
        </a>
      ) : (
        <h3>{name}</h3>
      );
    }
  };

  return (
    <>
      <div className={wrapperClassName}>
        {listHeader()}
        {checkValue(destinationListing) ? (
          <ul className={listingClassName}>
            {destinationListing.map((destination, idx) => {
              return (
                <li key={idx}>
                  <a href={destination.targetUrl}>{destination.name}</a>
                </li>
              );
            })}
          </ul>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default KeepExploringSub;
