export default {
    "current_deal": "Aktuálna ponuka",
    "lbg.section_properties_theme.pet_friendly": "Víťazi ocenenia v kategórii Domáce zvieratká vítané: {0}",
    "address": "Adresa",
    "hub.homepage.h2": "Čo všetko sa oplatí vidieť?",
    "search_hotels_near_this_landmark": "Hľadať hotely<span class=\"extra-label\"> blízko tejto zaujímavosti</span>",
    "hub.category.h1.things_to_do": "Aktivity v destinácii {0}",
    "lbg.h2.vip_access": "Ubytovacie zariadenia VIP Access sú vybraté pre vysokohodnotené pobyty a nadštandardné služby.",
    "lbg.theme.cleanliness": "Čistota",
    "lbg.theme.lgbtq": "LGBTQ",
    "load_more": "Načítať ďalšie",
    "link_long_form_to_listiicle_multiple": "{0} – jedna z atrakcií: {1} a {2} (všetko o meste {3} si prečítate tu)",
    "lbg.section_properties_theme.boutique": "Víťazi ocenenia v kategórii Butikové: {0}",
    "lbg.h2.luxury": "Objavte najväčší luxus. Presne preto sa hosťom veľmi páčili pobyty v týchto ubytovacích zariadeniach.",
    "hub.category.h1.information": "{0} — Všeobecné informácie",
    "families": "Rodiny",
    "trending_now": "Teraz populárne",
    "lbg.from": "Od",
    "hub.category.h1.road_trips": "Najlepšie road tripy v destinácii {0}",
    "lbg.h2.cleanliness": "Tieto ubytovacie zariadenia majú vynikajúce celkové skóre hodnotení hostí a vynikajúce skóre od hostí za čistotu.",
    "single": "Jednotlivec",
    "couples": "Páry",
    "trust_module.price_guarantee.find_out_more": "zistiť viac",
    "top_destinations_in": "Najobľúbenejšie destinácie na mieste {0}",
    "hub.category.h1.couples": "Dovolenka pre páry v destinácii {0}",
    "hub.category.h2.tours": "Najlepšie výlety v destinácii {0}",
    "hub.category.h1.photos": "Fotogenická dovolenka v destinácii {0}",
    "category.photos": "Fotografie",
    "hub.category.h1.budget": "Dovolenka podľa rozpočtu v destinácii {0}",
    "hub.category.h2.ski": "Najlepšie lyžiarske destinácie – {0}",
    "lbg.section_theme_world_winners.most_wanted": "Víťazi ocenenia v kategórii Najobľúbenejšie zariadenia",
    "lbg.theme.home_and_apartments": "Domy a apartmány",
    "hub.category.h1.luxury": "Luxusná dovolenka v destinácii {0}",
    "hub.category.h2.adventure": "Najlepšia aktívna dovolenka v destinácii {0}",
    "hotels_in": "Hotely - {0}",
    "lbg.section_search_winners": "Hľadajte viac víťazov ocenenia",
    "see_more_hotels_in": "Zobraziť ďalšie hotely: {0}",
    "hub.category.h1.beach": "Pláže v destinácii {0}",
    "open": "Otvorené:",
    "enter_your_destination": "Zadajte vašu destináciu",
    "best_of": "To najlepšie v destinácii {0}",
    "top10_hotels_missing_properties": "Niektoré ubytovacie zariadenia sa momentálne nemusia zobrazovať. Ospravedlňujeme sa za spôsobené nepríjemnosti.",
    "adventure": "Dobrodružstvo",
    "lbg.h1.destination_theme.sustainable": "Víťazi ocenenia v kategórii Udržateľné: {0}",
    "luxury": "Luxus",
    "expand_map_view": "Rozbaliť zobrazenie na mape",
    "book_now": "Rezervovať",
    "lbg.h1.destination_theme.ski": "Víťazi ocenenia v kategórii Lyžovačka: {0}",
    "hub.category.h1.food": "Reštaurácie v destinácii {0}",
    "category.blogs": "Blog",
    "budget": "Cena",
    "popular_neighbourhoods_in": "Obľúbené štvrte v destinácii {0}",
    "hub.category.h1.unusual": "Skryté poklady a nezvyčajné miesta v destinácii {0}",
    "see_all_accommodation_in": "Zobraziť ubytovacie zariadenia v destinácii {0}",
    "hub.category.h1.adventure": "Aktívna dovolenka v destinácii {0}",
    "category_highlight.see_all.shopping": "Viac o nakupovaní",
    "lbg.section_most_popular_destination": "Najobľúbenejšie destinácie",
    "lbg.section_properties_theme.luxury": "Víťazi ocenenia v kategórii Luxus: {0}",
    "unusual": "Nezvyčajné",
    "category.shopping": "Nakupovanie",
    "make_the_most_of_your_trip": "Užite si svoj výlet naplno",
    "x_review": "{0,choice,0#|1#{0} hodnotenie|2#{0} hodnotenia|2<{0} hodnotenia|4<{0} hodnotení|10#{0} hodnotení|10<{0} hodnotení}",
    "hub.category.h1.single": "Dovolenka pre jedného v destinácii {0}",
    "hub.category.h2.custom": "Teraz populárne",
    "lbg.section_theme_world_winners.lgbt": "Víťazi ocenenia v kategórii LGBTQ+",
    "lbg.section_award_winners_theme_other": "Ďalšie témy s víťazmi ocenenia v destinácii {0}",
    "lbg.supplier_collected_charges": "+ poplatky vo výške {0} splatné v ubytovacom zariadení",
    "in_destination": " v destinácii {0}",
    "lbg.section_properties_theme.vip_access": "Víťazi ocenenia v kategórii VIP Access v destinácii {0}",
    "link_long_form_to_listiicle_single": "{0} – jedna z atrakcií: {1} (všetko o meste {2} si prečítate tu)",
    "lbg.section_theme_world_winners.boutique": "Víťazi ocenenia v kategórii Butikové",
    "lbg.section_properties_theme.spa_hotel": "Víťazi ocenenia v kategórii Wellness: {0}",
    "lbg.section_theme_world_winners.all_inclusive": "Víťazi ocenenia v kategórii All inclusive",
    "hub.category.h2": "{0} – Cestovný sprievodca",
    "lbg.properties.price_tooltip": "Toto je najnižšia cena za noc na našej stránke na najbližších 14 dní.",
    "hub.category.h2.road_trips": "Najlepšie road tripy v destinácii {0}",
    "hub.category.h1.ski": "Lyžiarske destinácie – {0}",
    "food": "Jedlo",
    "lbg.theme.pet_friendly": "Domáce zvieratká vítané",
    "lbg.theme.business": "Služobná cesta",
    "hub.category.h1.nature": "Najlepšie cesty na únik do prírody v destinácii {0}",
    "map": "Mapa",
    "category_highlight.see_all.information": "Ďalšie informácie",
    "hotel_prices_in": "Ceny hotelov v destinácii {0}",
    "hub.category.h1.nightlife": "{0} — Nočný život",
    "trust_module.free_cancellation.most_hotels": "vo väčšine hotelov*",
    "also_popular_in": "Ďalšie obľúbené destinácie v oblasti {0}",
    "lbg.h1.destination_theme.business": "Víťazi ocenenia v kategórii Služobná cesta: {0}",
    "lbg.section_theme_world_winners.pet_friendly": "Víťazi ocenenia v kategórii Domáce zvieratká vítané",
    "hub.category.h1.maps": "Mapy destinácie {0}",
    "lbg.section_properties_theme.most_wanted": "Víťazi ocenenia v kategórii Najobľúbenejšie zariadenia: {0}",
    "category.nightlife": "Nočný život",
    "search_hotels_in_this_area": "Hľadať hotely<span class=\"extra-label\"> v tejto oblasti</span>",
    "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 hlas|2#2 hlasy|2<{1} hlasy|4<{1} hlasov|10#10 hlasov|10<{1} hlasov})",
    "hub.category.h2.business": "Najlepšia služobná cesta v destinácii {0}",
    "category.things_to_do": "Voľný čas",
    "destination_travel_guide_by_category": "{0}: Cestovný sprievodca podľa kategórie",
    "hub.category.h1.families": "Rodinná dovolenka v destinácii {0}",
    "lbg.section_theme_world_winners.ski": "Víťazi ocenenia v kategórii Lyžovačka",
    "you_may_also_like": "Tiež by sa vám mohlo páčiť",
    "lbg.theme.sustainable": "Udržateľné",
    "hub.category.h2.unusual": "Najlepšie skryté poklady a nezvyčajné miesta v destinácii {0}",
    "hub.category.h2.football": "Podporte svoj futbalový tím a vychutnajte si svoj výlet naplno!",
    "top_hotel": "Najobľúbenejší hotel",
    "lbg.h1.destination_theme.luxury": "Víťazi ocenenia v kategórii Luxus: {0}",
    "top10_hotels_empty_alert": "Ľutujeme, ale momentálne riešime technický problém. <span>Ospravedlňujeme sa za spôsobené nepríjemnosti.</span>",
    "special_deal": "Špeciálna ponuka",
    "lbg.h2.sustainable": "Všetko je o udržateľnosti! Pozrite si tieto ubytovacie zariadenia, ktoré ponúkajú skvelé pobyty.",
    "where_to_stay_in": "Kde sa ubytovať v destinácii {0}",
    "start_planning_your_trip": "Začnite plánovať svoju cestu",
    "good_for": "Dobré pre:",
    "category.hotels": "Hotely",
    "x_accommodation_guide": "Sprievodca ubytovaním v destinácii {0}",
    "lbg.theme.luxury": "Luxus",
    "hub.category.h2.sports": "Najlepší šport a aktivity v destinácii {0}",
    "lbg.section_theme_world_winners.lgbtq": "Víťazi ocenenia v kategórii LGBTQ",
    "see_more_neighbourhoods": "Zobraziť viac štvrtí",
    "category_highlight.title.nightlife": "Kam ísť a čo robiť v noci v destinácii {0}?",
    "lbg.h1.destination_theme.lgbt": "Víťazi ocenenia v kategórii LGBTQ+ v lokalite {0}",
    "hub.category.h1.blogs": "Cestovný blog — {0}",
    "lbg.h2.business": "Tieto ubytovacie zariadenia majú vysoké hodnotenia od obchodných cestujúcich.",
    "hotels": "Hotely",
    "lbg.h2.pet_friendly": "Hostia vrelo odporúčajú tieto ubytovacie zariadenia, ktoré privítajú vás aj vášho domáceho miláčika.",
    "popular_landmarks_nearby": "Obľúbené zaujímavosti neďaleko",
    "popular_extractions_in": "Obľúbené atrakcie v destinácii {0}",
    "lbg.h2.all_inclusive": "V týchto najlepšie hodnotených ubytovacích zariadeniach vám žiadny stres nehrozí.",
    "travel_guide_by_category_header": "Top {0} v destinácii {1}",
    "lbg.travel_between": "Cestujte v období od {0} do {1}",
    "keep_exploring": "Objavujte ďalej",
    "what_you_need_to_know_before_you_go": "Čo potrebujete vedieť pred cestou...",
    "read_it": "Prečítajte si",
    "view_on_map": "Zobraziť na mape",
    "lbg.h1.destination_theme.lgbtq": "Víťazi ocenenia v kategórii LGBTQ v destinácii {0}",
    "lbg.h2.beach": "Hosťom sa veľmi páči pobyt pri pláži, najmä v týchto ubytovacích zariadeniach.",
    "lbg.h1.destination_theme.vip_access": "Víťazi ocenenia v kategórii VIP Access v destinácii {0}",
    "see_all_accommodation": "Zobraziť všetky ubytovacie zariadenia",
    "shoppers": "Nakupujúci",
    "whats_around": "Okolie štvrte {0}",
    "group": "Skupina",
    "hub.category.h2.couples": "Najlepšia dovolenka pre páry v destinácii {0}",
    "hub.category.h1.shopping": "{0} — Nakupovanie",
    "travel_community": "{0} <span>&#124;</span> <span>Komunita cestovateľov</span>",
    "hub.category.h2.group": "Najlepšia skupinová dovolenka v destinácii {0}",
    "listicle_legal_msg": "Tento článok obsahuje názory redakčného tímu Go Guides. Hotels.com odmeňuje autorov za ich príspevky zobrazujúce sa na tejto stránke. Odmenou môže byť napríklad uhradenie cestovných alebo iných nákladov.",
    "lbg.h1.destination_theme.cleanliness": "Víťazi ocenenia v kategórii Čistota v destinácii {0}",
    "menu.more": "Viac",
    "lbg.section_properties_theme.lgbtq": "Víťazi ocenenia v kategórii LGBTQ v destinácii {0}",
    "lbg.section_award_winners_destination": "Víťazi ocenenia podľa destinácie",
    "lbg.terms_conditions": "Zmluvné podmienky",
    "hub.category.h2.nightlife": "Najlepší nočný život v destinácii {0}",
    "hub.neighbourhood.h2": "Čo ponúka štvrť {0}",
    "lbg.section_award_winners_theme_destination": "Víťazi ocenenia podľa témy v destinácii {0}",
    "lbg.section_award_world_winners": "Celosvetoví víťazi ocenenia",
    "things_to_see_and_do_in_other_cities": "Čo môžete vidieť a robiť v iných destináciách",
    "button.backToTop": "Späť na začiatok",
    "lbg.section_theme_world_winners.family": "Víťazi ocenenia v kategórii Rodinná dovolenka",
    "hotels_near": "Hotely v blízkosti {0}",
    "category.activity__sports_": "Aktivity",
    "trust_module.get_reward_night": "Získajte bonusovú noc",
    "hub.category.h1.business": "Služobná cesta v destinácii {0}",
    "category.food": "Jedlo",
    "other_popular_destinations_in": "Ďalšie obľúbené destinácie v meste {0}",
    "browse_by_category": "{0} podľa kategórií",
    "see_more_attactions": "Zobraziť ďalšie atrakcie",
    "lbg.theme.lgbt": "LGBTQ+",
    "more_information_about": "Ďalšie informácie o destinácii {0}",
    "lbg.h1.destination_theme.most_wanted": "Víťazi ocenenia v kategórii Najobľúbenejšie zariadenia: {0}",
    "see_all_properties_in": "Zobraziť ubytovacie zariadenia v destinácii {0}",
    "category.tours": "Výlety",
    "hub.category.h1.sports": "Šport a aktivity v destinácii {0}",
    "where_will_you_go_next": "Kam sa vyberiete najbližšie?",
    "cities": "Mestá",
    "lbg.theme.spa_hotel": "Wellness",
    "see_guide": "Pozrieť si sprievodcu",
    "lbg.section_properties_theme.ski": "Víťazi ocenenia v kategórii Lyžovačka: {0}",
    "related_stories": "Súvisiace články",
    "hub.destination.h2": "Čo potrebujete vedieť pred cestou",
    "show_less": "Zobraziť menej",
    "hub.category.h1.football": "Jedinečný sprievodca destináciou {0} pre skutočných futbalových fanúšikov",
    "hub.category.h2.food": "Miesta s najlepším jedlom v destinácii {0}",
    "fallback_language_alert": "Mrzí nás to, ale táto stránka zatiaľ nebola preložená do vášho jazyka...",
    "category_highlight.title.information": "Užitočné informácie",
    "neighbourhood_guides_for_other_cities": "Turistický sprievodca pre ostatné mestá",
    "lbg.h2.hubpage": "Tieto fantastické ubytovacie zariadenia majú vysoké skóre hodnotenia hostí a veľa hodnotení.",
    "travel_advisory.title": "Cestovné upozornenie",
    "lbg.section_properties_theme.all_inclusive": "Víťazi ocenenia v kategórii All inclusive: {0}",
    "lbg.h2.boutique": "Ak sa vám páčia butikové ubytovacie zariadenia, hostia vám vrelo odporúčajú stráviť tu pobyt.",
    "lbg.section_properties_theme.family": "Víťazi ocenenia v kategórii Rodinná dovolenka: {0}",
    "hub.category.h2.families": "Najlepšia rodinná dovolenka v destinácii {0}",
    "from": "od",
    "lbg.theme.vip_access": "VIP Access",
    "lbg.section_properties_theme.lgbt": "Víťazi ocenenia v kategórii LGBTQ+ v lokalite {0}",
    "x_star": "{0,choice,0#|1#{0} hviezdička|1.5#{0} hviezdičky|2#{0} hviezdičky|2.5#{0} hviezdičky|3#{0} hviezdičky|3.5#{0} hviezdičky|4#{0} hviezdičky|4.5#{0} hviezdičky|5#{0} hviezdičiek}",
    "trust_module.free_cancellation": "Bezplatné zrušenie rezervácie",
    "lbg.section_properties_theme.home_and_apartments": "Víťazi ocenenia v kategórii Domy a apartmány: {0}",
    "contributing_writer": "{0} <span>&#124;</span> <span>Prispievateľ/-ka</span>",
    "things_to_see_and_do_in": "Čo môžete vidieť a robiť <span class=\"next-line\">v destinácii {0}</span>",
    "lbg.check_price": "Skontrolovať cenu",
    "hub.category.h2.blogs": "od skúsených cestovateľov",
    "lbg.h2.home_and_apartments": "Hľadáte dovolenkový prenájom ďaleko od domu? Pozrite si tieto vysokohodnotené ubytovacie zariadenia.",
    "hub.category.h2.information": "Užitočné informácie o destinácii {0}",
    "search_hotels_in": "Hľadať hotely<span class=\"extra-label\"> v destinácii {0}</span>",
    "hub.category.h2.nature": "Najlepšie výlety do prírody v destinácii {0}",
    "lbg.h1.destination_theme.home_and_apartments": "Víťazi ocenenia v kategórii Domy a apartmány: {0}",
    "photo_by": "fotografia od {0}",
    "more_things_to_do": "Ďalšie aktivity",
    "lbg.section_properties_theme.beach": "Víťazi ocenenia v kategórii Pláž: {0}",
    "read_less": "Zobraziť menej",
    "lbg.h2.spa_hotel": "Uvoľnite sa a oddýchnite si v týchto najlepšie hodnotených ubytovacích zariadeniach, ktoré sú preslávené svojimi kúpeľami.",
    "second_fold_related_stories.see_also": "Pozrite si tiež",
    "hub.category.h2.history": "Najlepšie historické miesta v destinácii {0}",
    "lbg.section_properties_theme.cleanliness": "Víťazi ocenenia v kategórii Čistota v destinácii {0}",
    "lbg.h1.destination_theme.boutique": "Víťazi ocenenia v kategórii Butikové: {0}",
    "nightlife_b595ae4": "Nočný život",
    "lbg.h1.destination_theme.beach": "Víťazi ocenenia v kategórii Pláž: {0}",
    "trust_module.free_cancellation.disclaimer": "Niektoré hotely vyžadujú, aby ste rezerváciu zrušili minimálne 24 – 48 hodín pred príchodom. Detaily nájdete na stránke.",
    "lbg.h2.family": "Tieto ubytovacie zariadenia majú vysoké hodnotenia hostí a ideálne sa hodia pre rodiny.",
    "lbg.h2.lgbtq": "Tieto ubytovacie zariadenia vhodné pre LGBTQ komunitu majú vysoké hodnotenia hostí.",
    "hub.category.h1.group": "Skupinová dovolenka v destinácii {0}",
    "popular_stories": "Obľúbené príbehy",
    "toc": "Obsah",
    "hub.category.h2.shopping": "Kde na skvelé nákupy v destinácii {0}",
    "lbg.section_theme_world_winners.beach": "Víťazi ocenenia v kategórii Pláž",
    "page_score": "Hodnotenie tejto stránky",
    "lbg.section_theme_world_winners.sustainable": "Víťazi ocenenia v kategórii Udržateľné",
    "lbg.theme.boutique": "Butikové",
    "trust_module.price_guarantee": "Naša záruka cien",
    "lbg.h1.destination_theme.all_inclusive": "Víťazi ocenenia v kategórii All inclusive: {0}",
    "top_destinations": "Najobľúbenejšie destinácie",
    "hotel_prices_near": "Ceny hotelov neďaleko destinácie {0}",
    "lbg.score.cleanliness": "Čistota:",
    "x_stars": "{0,choice,0#|1#{0} hviezdička|1<{0} hviezdičky|4<{0} hviezdičiek|10<{0} hviezdičiek}",
    "lbg.theme.all_inclusive": "All inclusive",
    "hub.category.h1.tours": "Prehliadky a denné výlety v destinácii {0}",
    "lbg.section_award_winners_theme": "Víťazi ocenenia podľa témy",
    "guest_writer": "{0} <span>&#124;</span> <span>Hosťujúci prispievateľ/-ka</span>",
    "start_planning_your_x_trip": "Začnite plánovať svoju cestu do mesta {0}",
    "lbg.section_theme_world_winners.business": "Víťazi ocenenia v kategórii Služobná cesta",
    "trending_now_in": "Teraz populárne v destinácii {0}",
    "history": "História",
    "search_hotels": "Vyhľadávať hotely",
    "lbg.terms_conditions_details": "Kritériá na to, aby bolo ubytovacie zariadenie vybraté ako víťaz ocenenia za {0}, vychádzajú z jeho vysokého skóre hodnotenia hostí a počtu minimálne 50 hodnotení hostí za {1}.",
    "see_nearby_properties": "Zobraziť ubytovacie zariadenie v okolí",
    "hub.category.h1.history": "Historické miesta v destinácii {0}",
    "location": "Lokalita:",
    "lbg.section_theme_world_winners.luxury": "Víťazi ocenenia v kategórii Luxus",
    "category_highlight.see_all.nightlife": "Viac o nočnom živote",
    "also_popular": "Ďalšie obľúbené",
    "hub.category.h2.things_to_do": "Čo zažiť v destinácii {0}",
    "lbg.theme.family": "Rodinná dovolenka",
    "lbg.h1.destination_theme.pet_friendly": "Víťazi ocenenia v kategórii Domáce zvieratká vítané: {0}",
    "category_highlight.title.shopping": "Kde nakupovať a čo kúpiť v destinácii {0}?",
    "price": "Cena:",
    "hub.category.h2.beach": "Najlepšie pláže v destinácii {0}",
    "neighbourhoods_in": "Štvrte v meste {0}",
    "business": "Služobná cesta",
    "photo": "Fotografia",
    "blog.see_all_stories": "Zobraziť všetky príbehy",
    "category_highlight.see_all.food": "Viac o jedle a stravovaní",
    "see_all_properties": "Zobraziť všetky ubytovacie zariadenia",
    "lbg.theme.beach": "Pláž",
    "what_to_see_and_do_in": "Čo sa dá vidieť a robiť v destinácii {0}?",
    "hub.category.h2.single": "Najlepšia dovolenka pre jedného v destinácii {0}",
    "attractions_in": "Atrakcie - {0}",
    "read_more": "Ďalšie informácie",
    "show_more": "Zobraziť viac",
    "hotels_near_this_landmark": "Hotely blízko tejto zaujímavosti",
    "check_dates": "Skontrolovať dátumy",
    "featured_stories_and_fun_stuff": "Hlavné témy a zábavné príbehy",
    "phone": "Telefón:",
    "lbg.section_search_header": "Hľadajte víťazov ocenenia",
    "category_highlight.title.food": "Kde a čo jesť v destinácii {0}?",
    "see_all_things_to_do": "Zobraziť všetky aktivity",
    "lbg.section_properties_theme.sustainable": "Víťazi ocenenia v kategórii Udržateľné: {0}",
    "lbg.h1.hubpage": "Víťazi ocenenia v kategórii Obľúbené u hostí {0}!",
    "lbg.section_theme_world_winners.spa_hotel": "Víťazi ocenenia v kategórii Wellness",
    "category.maps": "Mapy",
    "lbg.h1.destination_theme.family": "Víťazi ocenenia v kategórii Rodinná dovolenka: {0}",
    "lbg.section_theme_world_winners.cleanliness": "Víťazi ocenenia v kategórii Čistota",
    "lbg.section_theme_world_winners.vip_access": "Víťazi ocenenia v kategórii VIP Access",
    "trust_module.get_reward_night.night_you_stay": "za každých 10 nocí pobytu",
    "modified": "upravené",
    "lbg.section_properties": "Víťazi ocenenia v destinácii {0}",
    "trust_module.header": "Prečo rezervovať na Hotels.com?",
    "hub.category.h2.luxury": "Najlepšia luxusná dovolenka v destinácii {0}",
    "lbg.theme.ski": "Lyžovačka",
    "lbg.h1.destination_theme.spa_hotel": "Víťazi ocenenia v kategórii Wellness: {0}",
    "lbg.h2.most_wanted": "Hostia vrelo odporúčajú tieto ubytovacie zariadenia, v ktorých si užijete dokonalý pobyt.",
    "top10_hotels_incomplete_alert": "Z dôvodu vysokého počtu uživateľov na našej webovej stránke sa momentálne nemusia zobraziť všetky ubytovacie zariadenia. <span>Ospravedlňujeme sa za spôsobené nepríjemnosti.</span>",
    "compulsive_traveller": "{0} <span>&#124;</span> <span>Skúsený cestovateľ</span>",
    "lbg.section_theme_world_winners.home_and_apartments": "Víťazi ocenenia v kategórii Domy a apartmány",
    "loved_by_guests_collection_in": "Obľúbené u hostí v destinácii {0}",
    "title.suffix": "{0} – Go Guides",
    "hub.category.h2.maps": "Užitočné mapy destinácie {0}",
    "opening_hours": "Otváracie hodiny",
    "hub.category.h2.photos": "Najlepšia fotogenická dovolenka v destinácii {0}",
    "lbg.theme.most_wanted": "Najobľúbenejšie zariadenia",
    "blog.stories_container": "Príbehy od prispievateľov",
    "hub.category.h2.budget": "Najlepšia dovolenka podľa rozpočtu v destinácii {0}",
    "lbg.section_properties_theme.business": "Víťazi ocenenia v kategórii Služobná cesta: {0}",
    "not_sure_where_to_stay": "Neviete, kde presne sa ubytovať?",
    "category.information": "Informácie"
}
