import React from 'react';
import { useState } from 'react';
import { useStores } from 'utils/useStore';
import MobileNavigation from './navigation-mobile';
import { UitkIcon } from 'uitk-react-icons';
import { UitkSticky } from 'uitk-react-sticky';
import { localization } from 'src/utils/localization';

const Navigation: React.FC = () => {
  const { goGuidesStore } = useStores();
  const [isOpen, setIsOpen] = useState(false);

  const iconMapping = {
    destination: 'place',
    things_to_do: 'camera_alt',
    food: 'local_dining',
    shopping: 'local_shopping',
    nightlife: 'local_bar',
    information: 'info',
    tours: 'directions_bike',
    blogs: 'article',
    hotels: 'lob_hotels',
  };
  const menu = goGuidesStore.fetchNavigation(goGuidesStore.pageUrlData);

  const closeMenu = () => {
    handleClick(false);
  };

  const handleClick = status => {
    setIsOpen(status);
  };

  const loc = new localization();

  let categoryMenu = null;
  if (menu && menu.category && menu.category.length > 0) {
    categoryMenu = menu.category.map(item => {
      const linkTarget = item.categoryKey == 'hotels' ? '_blank' : '_self';
      const categoryName = loc.getLocalizedText(`category.${item.categoryKey}`);

      return (
        <li key={item.categoryKey}>
          <a className={`nav-menu ${item.categoryKey}`} href={item.targetUrl} target={linkTarget}>
            <UitkIcon name={iconMapping[item.categoryKey]} />
            {categoryName}
          </a>
        </li>
      );
    });
  }

  return (
    <>
      {menu && (
        <UitkSticky zIndex={20}>
          <div className={`navigation-wrapper ${menu.category ? '' : 'no-list'}`}>
            <div className="inner">
              <nav className={`main-navi${isOpen ? ' nav-open' : ''}`}>
                <MobileNavigation menu={menu} handleClick={handleClick} iconMapping={iconMapping} />
                <ul className="main">
                  <li className="home">
                    <a className="nav-menu" href={menu.home.targetUrl}>
                      <h3>{menu.home.name}</h3>
                    </a>
                  </li>
                  {menu.destination &&
                    (menu.destination.targetUrl && menu.destination.targetUrl != null ? (
                      <li>
                        <a
                          className={`nav-menu destination ${menu.category ? '' : 'no-cut'}`}
                          href={menu.destination.targetUrl}
                        >
                          <span>
                            <UitkIcon name="place" />
                            {menu.destination.name}
                          </span>
                        </a>
                      </li>
                    ) : (
                      <li className="no-hover">
                        <div className="nav-menu destination no-link">
                          <span>{menu.destination.name}</span>
                        </div>
                      </li>
                    ))}
                  {categoryMenu}
                  <a className="close-button" onClick={closeMenu}>
                    <UitkIcon name="close" />
                  </a>
                </ul>
              </nav>
            </div>
          </div>
        </UitkSticky>
      )}
    </>
  );
};

export default Navigation;
