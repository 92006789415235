import React, { useEffect, useState } from 'react';
import { checkValue } from 'components/functions';
import { GoGuidesListicleItemBucketProps } from 'components/types/goguides-types.d';
import { useStores } from 'utils/useStore';
import Displayitem from './sub-component/listicle-item';
import GoGuidesRelatedStoriesComponent from '../related-stories/related-stories';

const GoGuidesListicleItemBucketComponent: React.FC = () => {
  const { goGuidesStore } = useStores();
  const positionInject = 2;
  const contentData = goGuidesStore.fetchListicleItems(goGuidesStore.pageUrlData);

  let firstXItems = [];
  let remainingItems = [];

  if (checkValue(contentData) && contentData.items) {
    firstXItems = contentData.items.slice(0, positionInject);
    remainingItems = contentData.items.slice(positionInject);
  }

  const hideNumber = checkValue(contentData.features) && checkValue(contentData.features?.hideNumber);

  return (
    <>
      {checkValue(firstXItems) && (
        <div className={`listicle-item-container ${checkValue(hideNumber) ? 'hide-number' : ''}`}>
          <ul>
            {firstXItems.map((item, firstIndex: number) => (
              <Displayitem key={firstIndex} item={item} index={firstIndex} hideNumber={hideNumber} />
            ))}
            <li className="see-also-stories">
              <GoGuidesRelatedStoriesComponent seeAlso={true} />
            </li>
            {remainingItems.map((item, remainIndex: number) => (
              <Displayitem
                key={remainIndex}
                item={item}
                index={firstXItems.length + remainIndex}
                hideNumber={hideNumber}
              />
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default GoGuidesListicleItemBucketComponent;
