import React from 'react';
import { MasterPage } from 'components/common/template/master-page';
import GoGuidesHeroComponent from 'components/modules/hero/hero';
import GoGuidesIntroComponent from 'components/modules/intro/intro';
import GoGuidesKeepExploringComponent from 'components/modules/keep-exploring/keep-exploring';
import GoGuidesListicleItemBucketComponent from 'components/modules/listicle-item-bucket/listicle-item-bucket';
import GoGuidesRelatedStoriesComponent from 'components/modules/related-stories/related-stories';
import GoGuidesWriterProfileComponent from 'components/modules/writer-profile/writer-profile';
import GoGuidesSearchWizard from '../modules/search-wizard/search-wizard';
import GoGuidesBackToTop from '../modules/back-to-top/back-to-top';
import GoGuidesTableOfContent from '../modules/table-of-content/toc';
import LegalMessage from '../modules/generic/legal-message';
import { RecommendationModule } from '../modules/recommendation-module/recommendation';
import { PageLayout } from '../types/goguides-types.d';

const ListiclePage: React.FC = () => {
  return (
    <MasterPage>
      <div className="page-container">
        <GoGuidesHeroComponent />
        <div className="article-body row">
          <div className="content-wrap col-12 col-l8">
            <GoGuidesTableOfContent tagElement="h2" parentClassName="listicle-item-container" />
            <GoGuidesIntroComponent />
            <GoGuidesListicleItemBucketComponent />
            <GoGuidesWriterProfileComponent />
            <LegalMessage />
          </div>
          <div className="sidebar-wrap col-12 col-l4">
            <div className="side-wrap01">
              <GoGuidesSearchWizard />
            </div>
            <div className="side-wrap02">
              <RecommendationModule pageLayout={PageLayout.TWO_COLUMN} />
            </div>
          </div>
          <div className="more-article-wrap col-12">
            <GoGuidesRelatedStoriesComponent />
          </div>
        </div>
        <div className="bottom-wrap row">
          <div className="bottom-inner col-12">
            <GoGuidesKeepExploringComponent />
          </div>
        </div>
        <GoGuidesBackToTop />
      </div>
    </MasterPage>
  );
};

export default ListiclePage;
