import React from 'react';
import { UitkPrimaryButton } from 'uitk-react-button';
import { checkValue } from '../../../functions';

interface ButtonNearbyPropertiesProps {
    itemCtaUrl: string;
    itemCtaKey: string;
    pdpLink?: boolean;
  }

const ButtonNearbyProperties: React.FC<ButtonNearbyPropertiesProps> = ({ itemCtaUrl, itemCtaKey, pdpLink }) => {

  return (
    <>
      {(checkValue(itemCtaUrl) || checkValue(pdpLink)) && (
        <div className="button-wrap">
          <UitkPrimaryButton 
            className={`nearby-properties ${pdpLink ? 'book-now' : ''}`} 
            tag="a" 
            href={itemCtaUrl} 
            type="button" 
            target="_blank" 
            rel="noreferrer" 
          >
            {itemCtaKey}
        </UitkPrimaryButton>
        </div>
      )}
    </>
  );
};

export default ButtonNearbyProperties;