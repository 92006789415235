import React, { useEffect, useState } from 'react';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { viewportCheck } from 'components/utils/helper';

const GoGuidesBackToTop: React.FC = () => {
  const thresholdConfig = 1000;
  let scrollButton: any;

  const [fadeInOut, setFadeInOut] = useState('fadeOut');

  const scrollFunction = () => {
    if (window.scrollY > thresholdConfig) {
      setFadeInOut('fadeIn');
    } else {
      setFadeInOut('fadeOut');
    }
  };

  useEffect(() => {
    scrollButton = document.getElementById('scroll-to-top');

    window.addEventListener('scroll', scrollFunction, { passive: true });
    return () => {
      window.removeEventListener('scroll', scrollFunction);
    };
  });

  const handleClick = (e) => {
    e.preventDefault();

    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <a id="scroll-to-top" href="#" onClick={handleClick} className={fadeInOut}>
        <UitkIcon name="arrow_upward_iOS" size={UitkIconSize.MEDIUM} /> Back to top
      </a>
    </>
  );
};

export default GoGuidesBackToTop;
