export default {
    "current_deal": "Tato nabídka",
    "lbg.section_properties_theme.pet_friendly": "Vítězové ocenění Domácí zvířata v {0}",
    "address": "Adresa",
    "hub.homepage.h2": "Co všechno stojí za vidění?",
    "search_hotels_near_this_landmark": "Hledat hotely<span class=\"extra-label\"> v okolí</span>",
    "hub.category.h1.things_to_do": "Doporučené aktivity v destinaci {0}",
    "lbg.h2.vip_access": "Členská zařízení programu VIP Access poskytují vysoce hodnocené ubytování a fantastické prostředí.",
    "lbg.theme.cleanliness": "Čistota",
    "lbg.theme.lgbtq": "LGBTQ",
    "load_more": "Další",
    "link_long_form_to_listiicle_multiple": "{0} – jedno z nejzajímavějších míst článku {1} a {2} (Zde najdete podrobné informace o destinaci {3}.)",
    "lbg.section_properties_theme.boutique": "Vítězové ocenění Butik v {0}",
    "lbg.h2.luxury": "Dopřejte si luxus na každém kroku. Hostům se díky tomu líbil pobyt v těchto zařízeních.",
    "hub.category.h1.information": "Informace o destinaci {0}",
    "families": "Rodiny",
    "trending_now": "Nenechte si ujít",
    "lbg.from": "Od",
    "hub.category.h1.road_trips": "Nejlepší výlety autem v destinaci {0}",
    "lbg.h2.cleanliness": "Tato ubytovací zařízení mají skvělé celkové skóre hodnocení hostů a nejvyšší hodnocení hostů ohledně čistoty.",
    "single": "Výlet pro jednoho",
    "couples": "Páry",
    "trust_module.price_guarantee.find_out_more": "další informace",
    "top_destinations_in": "Nejoblíbenější destinace – {0}",
    "hub.category.h1.couples": "Romantika na cestách do destinace {0}",
    "hub.category.h2.tours": "Nejlepší výlety v destinaci {0}",
    "hub.category.h1.photos": "Kam za krásnými fotkami z destinace {0}",
    "category.photos": "Fotografie",
    "hub.category.h1.budget": "Levně do destinace {0}",
    "hub.category.h2.ski": "Nejlepší lyžařské destinace – {0}",
    "lbg.section_theme_world_winners.most_wanted": "Vítězové ocenění Most Wanted",
    "lbg.theme.home_and_apartments": "Byty a apartmány",
    "hub.category.h1.luxury": "Luxusní cesty do destinace {0}",
    "hub.category.h2.adventure": "Nejlepší outdoorové aktivity v destinaci {0}",
    "hotels_in": "Hotely – {0}",
    "lbg.section_search_winners": "Hledat další vítěze ocenění",
    "see_more_hotels_in": "Další hotely v destinaci {0}",
    "hub.category.h1.beach": "Pláže v destinaci {0}",
    "open": "Otevírací doba:",
    "enter_your_destination": "Zadejte destinaci.",
    "best_of": "Co stojí za vidění v destinaci {0}?",
    "top10_hotels_missing_properties": "Momentálně se nemusí zobrazit všechna ubytovací zařízení. Děkujeme za pochopení.",
    "adventure": "Adrenalinové sporty",
    "lbg.h1.destination_theme.sustainable": "Vítězové ocenění Udržitelnost v {0}",
    "luxury": "Luxusní",
    "expand_map_view": "Zvětšit mapu",
    "book_now": "Rezervovat",
    "lbg.h1.destination_theme.ski": "Vítězové ocenění Pro lyžaře v {0}",
    "hub.category.h1.food": "Restaurace v destinaci {0}",
    "category.blogs": "Blog",
    "budget": "Cena",
    "popular_neighbourhoods_in": "Oblíbené čtvrti v destinaci {0}",
    "hub.category.h1.unusual": "Tajné tipy na zajímavosti v destinaci {0}",
    "see_all_accommodation_in": "Možnosti ubytování v destinaci {0}",
    "hub.category.h1.adventure": "Outdoorové aktivity v destinaci {0}",
    "category_highlight.see_all.shopping": "Více o nakupování",
    "lbg.section_most_popular_destination": "Nejoblíbenější destinace",
    "lbg.section_properties_theme.luxury": "Vítězové ocenění Luxusní v {0}",
    "unusual": "Nevšední zážitky",
    "category.shopping": "Nakupování",
    "make_the_most_of_your_trip": "Užijte si svůj pobyt naplno",
    "x_review": "{0,choice,0#|1#{0} hodnocení|2#{0} hodnocení|2<{0} hodnocení|4<{0} hodnocení|10#{0} hodnocení|10<{0} hodnocení}",
    "hub.category.h1.single": "Pobyty pro jednoho v destinaci {0}",
    "hub.category.h2.custom": "Nenechte si ujít",
    "lbg.section_theme_world_winners.lgbt": "Vítězové ocenění LGBTQ+",
    "lbg.section_award_winners_theme_other": "Vítězové jiných ocenění v {0}",
    "lbg.supplier_collected_charges": "+ poplatky ve výši {0} v ubytovacím zařízení",
    "in_destination": " ({0})",
    "lbg.section_properties_theme.vip_access": "Vítězové ocenění VIP Access v {0}",
    "link_long_form_to_listiicle_single": "{0} – jedno z nejzajímavějších míst článku {1} (Zde najdete podrobné informace o destinaci {2}.)",
    "lbg.section_theme_world_winners.boutique": "Vítězové ocenění Butik",
    "lbg.section_properties_theme.spa_hotel": "Vítězové ocenění Wellness v {0}",
    "lbg.section_theme_world_winners.all_inclusive": "Vítězové ocenění All inclusive",
    "hub.category.h2": "Průvodce destinací {0}",
    "lbg.properties.price_tooltip": "Toto je nejnižší cena na našem webu za noc během příštích 14 dnů.",
    "hub.category.h2.road_trips": "Nejlepší výlety autem v destinaci {0}",
    "hub.category.h1.ski": "Lyžařské destinace – {0}",
    "food": "Strava",
    "lbg.theme.pet_friendly": "Domácí zvířata",
    "lbg.theme.business": "Pracovní cesta",
    "hub.category.h1.nature": "Nejlepší výlety do přírody v destinaci {0}",
    "map": "Mapa",
    "category_highlight.see_all.information": "Další informace",
    "hotel_prices_in": "Ceny hotelů v destinaci {0}",
    "hub.category.h1.nightlife": "Noční zábava – {0}",
    "trust_module.free_cancellation.most_hotels": "ve většině hotelů*",
    "also_popular_in": "Další oblíbené destinace v oblasti {0}",
    "lbg.h1.destination_theme.business": "Vítězové ocenění Pracovní cesta v {0}",
    "lbg.section_theme_world_winners.pet_friendly": "Vítězové ocenění Domácí zvířata",
    "hub.category.h1.maps": "{0} – mapy",
    "lbg.section_properties_theme.most_wanted": "Vítězové ocenění Most Wanted v {0}",
    "category.nightlife": "Noční zábava",
    "search_hotels_in_this_area": "Hledat hotely<span class=\"extra-label\"> v této oblasti</span>",
    "votes_score_and_count": "<span>{0}</span> (z {1,choice,0#|1#1 hodnocení|2#2 hodnocení|2<{1} hodnocení|4<{1} hodnocení|10#10 hodnocení|10<{1} hodnocení})",
    "hub.category.h2.business": "Nejlepší služební cesty do destinace {0}",
    "category.things_to_do": "Pamětihodnosti",
    "destination_travel_guide_by_category": "{0} – průvodci podle kategorií",
    "hub.category.h1.families": "Rodinné pobyty v destinaci {0}",
    "lbg.section_theme_world_winners.ski": "Vítězové ocenění Pro lyžaře",
    "you_may_also_like": "Další zajímavé destinace",
    "lbg.theme.sustainable": "Udržitelnost",
    "hub.category.h2.unusual": "Tajné tipy na nejlepší zajímavosti v destinaci {0}",
    "hub.category.h2.football": "Podpořte svůj fotbalový tým a užijte si výlet na maximum!",
    "top_hotel": "Výborně hodnocený hotel",
    "lbg.h1.destination_theme.luxury": "Vítězové ocenění Luxusní v {0}",
    "top10_hotels_empty_alert": "Omlouváme se, ale momentálně řešíme technický problém. <span>Děkujeme za pochopení.</span>",
    "special_deal": "Speciální nabídka",
    "lbg.h2.sustainable": "Nejdůležitější je udržitelnost! Prohlédněte si tato zařízení, která nabízejí skvělé ubytování.",
    "where_to_stay_in": "Kde se ubytovat v destinaci {0}",
    "start_planning_your_trip": "Naplánujte si cestu",
    "good_for": "Hosté doporučují:",
    "category.hotels": "Hotely",
    "x_accommodation_guide": "Průvodce ubytováním v destinaci {0}",
    "lbg.theme.luxury": "Luxusní",
    "hub.category.h2.sports": "Nejlepší aktivity a sport v destinaci {0}",
    "lbg.section_theme_world_winners.lgbtq": "Vítězové ocenění LGBTQ+",
    "see_more_neighbourhoods": "Zobrazit další městské části",
    "category_highlight.title.nightlife": "Kam vyrazit a co dělat večer v destinaci {0}",
    "lbg.h1.destination_theme.lgbt": "Vítězové ocenění LGBTQ+ v destinaci {0}",
    "hub.category.h1.blogs": "Cestovní blog – {0}",
    "lbg.h2.business": "Tato ubytovací zařízení získala vysoké hodnocení od hostů na pracovní cestě.",
    "hotels": "Hotely",
    "lbg.h2.pet_friendly": "Hosté často doporučují tato zařízení, která vás přivítají i s vašimi zvířecími mazlíčky.",
    "popular_landmarks_nearby": "Nejoblíbenější pamětihodnosti v okolí",
    "popular_extractions_in": "Oblíbené turistické zajímavosti v destinaci {0}",
    "lbg.h2.all_inclusive": "V těchto nejlépe hodnocených ubytovacích zařízeních zapomenete na stres.",
    "travel_guide_by_category_header": "Nejlepší {0} – {1}",
    "lbg.travel_between": "Cesta v období {0} – {1}",
    "keep_exploring": "Další zajímavé destinace",
    "what_you_need_to_know_before_you_go": "Důležité informace pro vaši cestu",
    "read_it": "Zobrazit více",
    "view_on_map": "Zobrazit na mapě",
    "lbg.h1.destination_theme.lgbtq": "Vítězové ocenění LGBTQ+ v destinaci {0}",
    "lbg.h2.beach": "Hosté mají rádi ubytování u pláže a preferují tato zařízení.",
    "lbg.h1.destination_theme.vip_access": "Vítězové ocenění VIP Access v {0}",
    "see_all_accommodation": "Zobrazit všechny možnosti ubytování",
    "shoppers": "Nákupy",
    "whats_around": "Okolí destinace {0}",
    "group": "Skupiny",
    "hub.category.h2.couples": "Nejlepší romantika na cestách do destinace {0}",
    "hub.category.h1.shopping": "Nakupování – {0}",
    "travel_community": "{0} <span>&#124;</span> <span>Cestovatelská komunita</span>",
    "hub.category.h2.group": "Nejlepší skupinové cesty do destinace {0}",
    "listicle_legal_msg": "V tomto článku jsou názory redakčního týmu Go Guides. Služba Hotels.com odměňuje autory za psaní na tomto webu. Odměny mohou představovat například náklady na cesty nebo jiné náklady.",
    "lbg.h1.destination_theme.cleanliness": "Vítězové ocenění Čistota v destinaci {0}",
    "menu.more": "Další",
    "lbg.section_properties_theme.lgbtq": "Vítězové ocenění LGBTQ+ v destinaci {0}",
    "lbg.section_award_winners_destination": "Vítězové ocenění podle destinace",
    "lbg.terms_conditions": "Smluvní podmínky",
    "hub.category.h2.nightlife": "Nejlepší noční kluby v destinaci {0}",
    "hub.neighbourhood.h2": "Doporučené aktivity v destinaci {0}",
    "lbg.section_award_winners_theme_destination": "Vítězové ocenění podle tématu v {0}",
    "lbg.section_award_world_winners": "Celosvětoví vítězové ocenění",
    "things_to_see_and_do_in_other_cities": "Doporučené aktivity v dalších městech",
    "button.backToTop": "Zpět nahoru",
    "lbg.section_theme_world_winners.family": "Vítězové ocenění Rodinná cesta",
    "hotels_near": "Hotely v okolí – {0}",
    "category.activity__sports_": "Aktivity",
    "trust_module.get_reward_night": "Získejte bonusovou noc",
    "hub.category.h1.business": "Služební cesty do destinace {0}",
    "category.food": "Gastronomie",
    "other_popular_destinations_in": "Další oblíbené destinace – {0}",
    "browse_by_category": "{0} podle kategorií",
    "see_more_attactions": "Zobrazit další zajímavosti",
    "lbg.theme.lgbt": "LGBTQ+",
    "more_information_about": "Další informace o destinaci {0}",
    "lbg.h1.destination_theme.most_wanted": "Vítězové ocenění Most Wanted v {0}",
    "see_all_properties_in": "Ubytovací zařízení v destinaci {0}",
    "category.tours": "Výlety",
    "hub.category.h1.sports": "Sporty a aktivity v destinaci {0}",
    "where_will_you_go_next": "Kam se vypravíte příště?",
    "cities": "Města",
    "lbg.theme.spa_hotel": "Wellness",
    "see_guide": "Zobrazit průvodce",
    "lbg.section_properties_theme.ski": "Vítězové ocenění Pro lyžaře v {0}",
    "related_stories": "Další podobné články",
    "hub.destination.h2": "Důležité informace pro vaši cestu",
    "show_less": "Zobrazit méně",
    "hub.category.h1.football": "Nejlepší průvodce {0} pro věrné fotbalové fanoušky",
    "hub.category.h2.food": "Nejlepší restaurace v destinaci {0}",
    "fallback_language_alert": "Omlouváme se. Tato stránka dosud nebyla přeložena do vašeho jazyka.",
    "category_highlight.title.information": "Užitečné informace",
    "neighbourhood_guides_for_other_cities": "Průvodci po dalších městech",
    "lbg.h2.hubpage": "Tato skvělá ubytovací zařízení mají mnoho hodnocení hostů a vysoké skóre hodnocení.",
    "travel_advisory.title": "Upozornění související s cestováním",
    "lbg.section_properties_theme.all_inclusive": "Vítězové ocenění All inclusive v {0}",
    "lbg.h2.boutique": "Pokud máte rádi butiková zařízení, hosté doporučují zvolit si pobyt zde.",
    "lbg.section_properties_theme.family": "Vítězové ocenění Rodinná cesta v {0}",
    "hub.category.h2.families": "Nejlepší pobyty pro rodiny s dětmi – {0}",
    "from": "od",
    "lbg.theme.vip_access": "VIP Access",
    "lbg.section_properties_theme.lgbt": "Vítězové ocenění LGBTQ+ v destinaci {0}",
    "x_star": "{0,choice,0#|1#{0} hvězdička|1.5#{0} hvězdičky|2#{0} hvězdičky|2.5#{0} hvězdičky|3#{0} hvězdičky|3.5#{0} hvězdičky|4#{0} hvězdičky|4.5#{0} hvězdičky|5#{0} hvězdiček}",
    "trust_module.free_cancellation": "Bezplatné zrušení",
    "lbg.section_properties_theme.home_and_apartments": "Vítězové ocenění Byty a apartmány v {0}",
    "contributing_writer": "{0} <span>&#124;</span> <span>Přispěvatel</span>",
    "things_to_see_and_do_in": "Doporučené aktivity <span class=\"next-line\">v destinaci {0}</span>",
    "lbg.check_price": "Ověřit cenu",
    "hub.category.h2.blogs": "od zkušených cestovatelů",
    "lbg.h2.home_and_apartments": "Hledáte na dovolené pohodlí jako doma? Prohlédněte si tato ubytovací zařízení s vysokým hodnocením.",
    "hub.category.h2.information": "Užitečné informace o destinaci {0}",
    "search_hotels_in": "Hledat hotely<span class=\"extra-label\"> – {0}</span>",
    "hub.category.h2.nature": "Nejlepší pobyty v přírodě v destinaci {0}",
    "lbg.h1.destination_theme.home_and_apartments": "Vítězové ocenění Byty a apartmány v {0}",
    "photo_by": "Autor fotografií: {0}",
    "more_things_to_do": "Další doporučené aktivity",
    "lbg.section_properties_theme.beach": "Vítězové ocenění Pláž v {0}",
    "read_less": "Méně informací",
    "lbg.h2.spa_hotel": "V těchto vysoce hodnocených zařízeních, která jsou proslulá svými wellness službami, se můžete uvolnit a relaxovat.",
    "second_fold_related_stories.see_also": "Podívejte se také na",
    "hub.category.h2.history": "Nejlepší historické památky v destinaci {0}",
    "lbg.section_properties_theme.cleanliness": "Vítězové ocenění Čistota v destinaci {0}",
    "lbg.h1.destination_theme.boutique": "Vítězové ocenění Butik v {0}",
    "nightlife_b595ae4": "Noční zábava",
    "lbg.h1.destination_theme.beach": "Vítězové ocenění Pláž v {0}",
    "trust_module.free_cancellation.disclaimer": "Některá ubytovací zařízení požadují zrušení nejméně 24–48 hodin před příjezdem. Další podrobnosti najdete na našem webu.",
    "lbg.h2.family": "Tato ubytovací zařízení získala od hostů vysoké hodnocení a jsou ideální pro pobyt s dětmi.",
    "lbg.h2.lgbtq": "Tato zařízení vhodná pro hosty LGBTQ získala vysoké hodnocení.",
    "hub.category.h1.group": "Skupinové cesty do destinace {0}",
    "popular_stories": "Oblíbené příběhy",
    "toc": "Obsah",
    "hub.category.h2.shopping": "Nejlepší nákupy v destinaci {0}",
    "lbg.section_theme_world_winners.beach": "Vítězové ocenění Pláž",
    "page_score": "Skóre této stránky",
    "lbg.section_theme_world_winners.sustainable": "Vítězové ocenění Udržitelnost",
    "lbg.theme.boutique": "Butik",
    "trust_module.price_guarantee": "Naše záruka ceny",
    "lbg.h1.destination_theme.all_inclusive": "Vítězové ocenění All Inclusive v {0}",
    "top_destinations": "Nejoblíbenější destinace",
    "hotel_prices_near": "Ceny hotelů – {0} a okolí",
    "lbg.score.cleanliness": "Čistota:",
    "x_stars": "{0,choice,0#|1#{0} hvězdička|1<{0} hvězdičky|4<{0} hvězdiček|10<{0} hvězdiček}",
    "lbg.theme.all_inclusive": "All inclusive",
    "hub.category.h1.tours": "Výlety v destinaci {0} a okolí",
    "lbg.section_award_winners_theme": "Vítězové ocenění podle tématu",
    "guest_writer": "{0} <span>&#124;</span> <span>Hostující autor</span>",
    "start_planning_your_x_trip": "Naplánujte si cestu do destinace {0}",
    "lbg.section_theme_world_winners.business": "Vítězové ocenění Pracovní cesta",
    "trending_now_in": "{0} – nenechte si ujít",
    "history": "Historie",
    "search_hotels": "Hledat hotel",
    "lbg.terms_conditions_details": "Kritéria pro ubytovací zařízení, které bylo vybráno jako vítěz ceny pro rok {0}, byla založena na  vysokých skóre hodnocení hostů za rok {1} a minimálně 50 hodnocení hostů.",
    "see_nearby_properties": "Ubytování v okolí",
    "hub.category.h1.history": "Historické památky v destinaci {0}",
    "location": "Poloha:",
    "lbg.section_theme_world_winners.luxury": "Vítězové ocenění Luxusní",
    "category_highlight.see_all.nightlife": "Více o nočním životě",
    "also_popular": "Další oblíbené destinace",
    "hub.category.h2.things_to_do": "Nejlepší aktivity v destinaci {0}",
    "lbg.theme.family": "Rodinná cesta",
    "lbg.h1.destination_theme.pet_friendly": "Vítězové ocenění Domácí zvířata v {0}",
    "category_highlight.title.shopping": "Kam vyrazit na nákupy a co koupit v destinaci {0}",
    "price": "Cena:",
    "hub.category.h2.beach": "Nejlepší pláže v destinaci {0}",
    "neighbourhoods_in": "části města {0}",
    "business": "Pracovní cesta",
    "photo": "Fotografie",
    "blog.see_all_stories": "Zobrazit všechny články",
    "category_highlight.see_all.food": "Více o jídle a stravování",
    "see_all_properties": "Všechna ubytovací zařízení",
    "lbg.theme.beach": "Pláž",
    "what_to_see_and_do_in": "Kam zajít a co podniknout v destinaci {0}",
    "hub.category.h2.single": "Nejlepší pobyty pro jednoho v destinaci {0}",
    "attractions_in": "{0} – turistické atrakce",
    "read_more": "Další informace",
    "show_more": "Zobrazit více",
    "hotels_near_this_landmark": "Hotely v okolí",
    "check_dates": "Zkontrolovat data",
    "featured_stories_and_fun_stuff": "Co se děje a co stojí za vidění",
    "phone": "Telefon:",
    "lbg.section_search_header": "Hledat vítěze ocenění",
    "category_highlight.title.food": "Kam zajít na jídlo a co si dát v destinaci {0}",
    "see_all_things_to_do": "Zobrazit všechny aktivity",
    "lbg.section_properties_theme.sustainable": "Vítězové ocenění Udržitelnost v {0}",
    "lbg.h1.hubpage": "{0} Vítězové ocenění Hosté doporučují",
    "lbg.section_theme_world_winners.spa_hotel": "Vítězové ocenění Wellness",
    "category.maps": "Mapy",
    "lbg.h1.destination_theme.family": "Vítězové ocenění Rodinná cesta v {0}",
    "lbg.section_theme_world_winners.cleanliness": "Vítězové ocenění Čistota",
    "lbg.section_theme_world_winners.vip_access": "Vítězové ocenění VIP Access",
    "trust_module.get_reward_night.night_you_stay": "za každých 10 nocí vašeho pobytu",
    "modified": "upraveno",
    "lbg.section_properties": "Vítězové ocenění v {0}",
    "trust_module.header": "Proč rezervovat na Hotels.com?",
    "hub.category.h2.luxury": "Nejlepší luxusní cesty do destinace {0}",
    "lbg.theme.ski": "Pro lyžaře",
    "lbg.h1.destination_theme.spa_hotel": "Vítězové ocenění Wellness v {0}",
    "lbg.h2.most_wanted": "Hosté často doporučují tato zařízení jako ideální pro pobyt.",
    "top10_hotels_incomplete_alert": "Z důvodu vysokého počtu uživatelů na našem webu se momentálně nemusí zobrazit všechna ubytovací zařízení. <span>Děkujeme za pochopení.</span>",
    "compulsive_traveller": "{0} <span>&#124;</span> <span>Cestuje často</span>",
    "lbg.section_theme_world_winners.home_and_apartments": "Vítězové ocenění Byty a apartmány",
    "loved_by_guests_collection_in": "Hosté doporučují v destinaci {0}",
    "title.suffix": "{0} – Go Guides",
    "hub.category.h2.maps": "Užitečné mapy destinace {0}",
    "opening_hours": "Otevřeno",
    "hub.category.h2.photos": "Kam za nejlepšími fotkami z destinace {0}",
    "lbg.theme.most_wanted": "Most Wanted",
    "blog.stories_container": "Články od přispěvatelů",
    "hub.category.h2.budget": "Nejlepší levné pobyty v destinaci {0}",
    "lbg.section_properties_theme.business": "Vítězové ocenění Pracovní cesta v {0}",
    "not_sure_where_to_stay": "Váháte, kde se ubytovat?",
    "category.information": "Informace"
}
