import React from 'react';
import { UitkLink } from "uitk-react-link";
import { UitkFigure, UitkFigureAspectRatioType } from "uitk-react-images";
import { ImageProps } from 'src/components/types/goguides-types.d';
import { RenderImage } from 'src/components/utils/responsive-image';

interface ListicleItemImageProps {
  image?: ImageProps;
  name?: string;
  itemTargetUrl?: string;
  linkTargetPdp?: string;
  linkRel?: string;
}

const ListicleItemImage: React.FC<ListicleItemImageProps> = ({
  image,
  name,
  itemTargetUrl,
  linkTargetPdp,
  linkRel,
}) => {

  const imageProps = {
    image: image.imageUrl,
    alt: name,
    params: {
      size: {
        small: '&width=800&height=533',
        medium: '&width=1600&height=1066',
        large: '&width=1600&height=1066',
      }
    }
  }

  return (
    <>
      {image.imageUrl && (
        <div className="img-wrap">
          {itemTargetUrl ? (
            <UitkLink>
              <a href={itemTargetUrl} target={linkTargetPdp} rel={linkRel}>
                <UitkFigure className="img" ratio={UitkFigureAspectRatioType.R3_2} roundcorner>
                  <RenderImage imgProps={imageProps} />
                </UitkFigure>
              </a>
            </UitkLink>
          ) : (
            <UitkFigure className="img" ratio={UitkFigureAspectRatioType.R3_2}>
              <RenderImage imgProps={imageProps} />
            </UitkFigure>
          )}
        </div>
      )}
    </>
  );
};

export default ListicleItemImage;
