import React from "react";
import { checkValue } from "components/functions";
import { UitkSecondaryButton } from "uitk-react-button";
import { UitkHeading } from 'uitk-react-text';
import { useStores } from "utils/useStore";
import { getCategoryHeader } from "src/components/utils/helper";
import { UitkLink } from 'uitk-react-link';
import { localization } from 'src/utils/localization';
import { EgClickstreamData, EgClickstreamImpressionTracker } from '@shared-ui/clickstream-analytics-context';
import BucketCardDefault from "../top-destination/sub-component/bucket-card-default";
import BucketOneCard from "../top-destination/sub-component/bucket-one-card";
import BucketThreeCard from "../top-destination/sub-component/bucket-three-card";
import BucketTwoCard from "../top-destination/sub-component/bucket-two-card";

export interface CategoryStoriesProps {
  category: string;
  maxStories?: any;
}

const GoGuidesCategoryHighLightStoriesComponent: React.FC<CategoryStoriesProps> = (props) => {
  const { category, maxStories = 5 } = props;
  const { goGuidesStore, context } = useStores();
  const contentData = goGuidesStore.fetchCategoryHighLightStories(goGuidesStore.pageUrlData, category, maxStories);

  const loc = new localization();
  const isMobile =
    context?.deviceInformation?.mobile && context.deviceInformation.type === 'MOBILE_PHONE';

  let categoryData = {};
  let destinationName = '';
  let pageType = '';
  let categoryCode = '';
  let snippetText = '';
  let seeAllLink = '';
  let items = [];
  let mainClass = '';

  // we want to track restaurant as food as it more relevant
  const trackingCategory = category == 'RESTAURANT' ? 'FOOD' : category;
  const impressionTrackingObject: EgClickstreamData = {
    event: {
      event_name: 'go_guides_module.presented',
      event_type: 'Impression',
      event_version: '1.0.0',
      event_category: 'go_guides',
    },
    experience: goGuidesStore.ClicksteamExperience,
    module: {
      module_name: 'category highlight: ' + trackingCategory.toLowerCase(),
    },
  };

  if (checkValue(contentData)) {
    categoryData = contentData.categoryData;
    destinationName = contentData.destinationName;
    pageType = contentData.pageType;

    if (checkValue(categoryData)) {
      const { categoryData: {
        categoryCode: tmpCategoryCode,
        snippetText: tmpSnippetText,
        seeAllLink: tmpSeeAllLink,
        items: tmpItems
      } } = contentData;

      categoryCode = checkValue(tmpCategoryCode) ? tmpCategoryCode : '';
      snippetText = checkValue(tmpSnippetText) ? tmpSnippetText : '';
      seeAllLink = checkValue(tmpSeeAllLink) ? tmpSeeAllLink.targetUrl : '';
      items = checkValue(tmpItems) ? tmpItems : [];

      if (categoryCode == 'THINGS_TO_DO') {
        mainClass = 'main'

        if (isMobile) {
          items = items.slice(0, 6);
        }
      }
    }

    const headerName = getCategoryHeader(categoryCode, pageType);
    const bucketHeader = loc.getLocalizedText(headerName, destinationName);
    const subTitle = categoryCode == 'THINGS_TO_DO' ? loc.getLocalizedText('featured_stories_and_fun_stuff') : '';
    const readMoreLabel = loc.getLocalizedText('read_more');
    const showMoreLabel = loc.getLocalizedText('show_more');

    const renderBucket = () => {
      const bucketComponents = {
        1: BucketOneCard,
        2: BucketTwoCard,
        3: BucketThreeCard,
        4: BucketTwoCard
      };
      const BucketComponent = bucketComponents[items.length] || BucketCardDefault;
      return <BucketComponent data={items} stories={true} />;
    };

    return (
      <>
        {checkValue(items) && items.length > 0 && (
          <EgClickstreamImpressionTracker eventData={impressionTrackingObject}>
            <section className={`category-highlight ${mainClass}`}>
              <div className="container-wrap row">
                <div className="row-inner col-12 line-up">
                  <div className="section-header-wrapper">
                    <UitkHeading size={5} tag="h2">
                      {bucketHeader}
                    </UitkHeading>
                    <span className="sub-header">{subTitle}</span>
                    {snippetText &&
                      <div className="header-description">
                        <p dangerouslySetInnerHTML={{ __html: snippetText }} />
                        {seeAllLink &&
                          <UitkLink className="read-more" inline={true}>
                            <a href={seeAllLink}>{readMoreLabel}</a>
                          </UitkLink>
                        }
                      </div>
                    }
                  </div>

                  <div className="item-list-page-wrap">
                    {renderBucket()}
                  </div>
                  {(categoryCode === 'THINGS_TO_DO' ? seeAllLink && items.length >= 9 : seeAllLink && items.length >= 3) && (
                    <div className="see-all-btn-wrap">
                      <UitkSecondaryButton
                        className="see-all-link"
                        tag="a"
                        type="button"
                        href={seeAllLink}
                      >
                        {showMoreLabel}
                      </UitkSecondaryButton>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </EgClickstreamImpressionTracker>
        )}
      </>
    );
  }
};

export default GoGuidesCategoryHighLightStoriesComponent;

