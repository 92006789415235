import { checkValue } from 'components/functions';
import { CardItemProp, FeaturedStoriesProps } from 'components/types/goguides-types.d';
import React, { useEffect, useState } from 'react';
import { UitkPrimaryButton } from 'uitk-react-button';
import { UitkHeading } from 'uitk-react-text';
import { useStores } from 'utils/useStore';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import BucketOneCard from '../top-destination/sub-component/bucket-one-card';
import BucketThreeCard from '../top-destination/sub-component/bucket-three-card';
import BucketTwoCard from '../top-destination/sub-component/bucket-two-card';
import BucketCardDefault from '../top-destination/sub-component/bucket-card-default';
import { localization } from 'src/utils/localization';

const FeaturedStoriesInner: React.FC<FeaturedStoriesProps> = (props) => {
  const { goGuidesStore, context } = useStores();
  const { pageType, destinationName, categoryCode, items = [] } = props;

  const [displayedItems, setDisplayedItems] = useState<CardItemProp[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  const track = useEgClickstream();
  const trackObject = {
    event: {
      event_version: '1.0.0',
      event_type: 'Interaction',
      event_name: 'show_more_button.selected',
      event_category: 'go_guides',
      action_location: 'trending now',
    },
    experience: goGuidesStore.ClicksteamExperience,
  };

  const loc = new localization();
  const itemsLoadMorePerClick = 6;
  const initialItems = pageType === 'categoryhub' ? 12 : 9;

  if (displayedItems.length === 0) {
    setDisplayedItems(items.slice(0, initialItems));
  }

  const loadMoreItems = () => {
    const newItems = items.slice(displayedItems.length, displayedItems.length + itemsLoadMorePerClick);

    setDisplayedItems(prevItems => [...prevItems, ...newItems]);
    setCurrentPage(prevPage => prevPage + 1);
    track(trackObject);
  };
  const showLoadMore = displayedItems.length < items.length;
  const renderBucket = () => {
    const bucketComponents = {
      1: BucketOneCard,
      2: BucketTwoCard,
      3: BucketThreeCard,
      4: BucketTwoCard
    };
    const BucketComponent = bucketComponents[displayedItems.length] || BucketCardDefault;
    return <BucketComponent data={displayedItems} stories={true} />;
  };

  const bucketHeader =
    pageType == 'home'
      ? loc.getLocalizedText('trending_now')
      : checkValue(categoryCode)
        ? loc.getLocalizedText(`hub.category.h2.${categoryCode}`, destinationName)
        : loc.getLocalizedText('trending_now_in', destinationName);

  return (
    <>
      {checkValue(displayedItems) && displayedItems.length > 0 && (
        <section className="feature-stories">
          <div className="container-wrap row">
            <div className="row-inner col-12 line-up">
              <div className="section-header-wrapper">
                <UitkHeading size={5} tag="h2">
                  {bucketHeader}
                </UitkHeading>
                <span className="sub-header">{loc.getLocalizedText('featured_stories_and_fun_stuff')}</span>
              </div>

              <div className="item-list-page-wrap">
                {renderBucket()}
              </div>
              {showLoadMore && (
                <UitkPrimaryButton className="load-more-btn" tag="button" type="button" onClick={loadMoreItems}>
                  {loc.getLocalizedText('load_more')}
                </UitkPrimaryButton>
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default FeaturedStoriesInner;
