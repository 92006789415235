import * as React from 'react';
import { UitkHeading, UitkSubheading } from 'uitk-react-text';
import { UitkImage, UitkFigure, UitkFigureAspectRatioType } from 'uitk-react-images';
import { useStores } from 'utils/useStore';
import { checkValue } from 'components/functions';
import GoGuideBreadcrumb from '../breadcrumb/breadcrumb';
import Navigation from '../navigation/navigation';
import { pageLayoutController } from '../../utils/helper';
import SegmentComponent from '../generic/segments';
import HeroSkim from './hero-skim';
import { EgClickstreamData, EgClickstreamImpressionTracker } from '@shared-ui/clickstream-analytics-context';
import { RenderImage } from 'src/components/utils/responsive-image';
import { useEffect, useState } from 'react';


const GoGuidesHeroComponent: React.FC = () => {
  const { goGuidesStore } = useStores();
  const [showSkim, setShowSkim] = useState(false);

  const trackingObject: EgClickstreamData = {
    event: {
      event_name: 'go_guides_hero_image.presented',
      event_type: 'Impression',
      event_version: '1.0.0',
      event_category: 'go_guides',
    },
    experience: goGuidesStore.ClicksteamExperience,
  };

  const contentData = goGuidesStore.fetchHero(goGuidesStore.pageUrlData);
  let imageUrl = '';
  const { h1, h2, image, pageType, pageCategory } = contentData;
  imageUrl = checkValue(image) ? image.imageUrl : '';

  const imageProps = {
    image: imageUrl,
    params: {
      size: {
        small: '&width=800&height=533',
        medium: '&width=1600&height=1066',
      }
    }
  };
  const pageLayout = pageLayoutController(pageType);

  useEffect(() => {
    setShowSkim(true);
  }, []);

  return (
    <>
      {imageUrl != '' && (
        <EgClickstreamImpressionTracker eventData={trackingObject}>
          <section className={`page-header-section ${pageLayout.header}`}>
            <div className={`hero-modules-container`}>
              <div className="hero-image-comtainer">
                <UitkFigure className="img" ratio={UitkFigureAspectRatioType.R16_9}>
                  <RenderImage imgProps={imageProps} />
                  {showSkim && <HeroSkim header={pageLayout.header} />}
                </UitkFigure>
              </div>
              <Navigation />
              <div className="page-title-container">
                <div className="row content">
                  <GoGuideBreadcrumb />
                  <div className={`hero-text-component ${h1.length > 22 ? 'long-header' : ''}`}>
                    <UitkHeading tag="h1" size={1} className={``}>
                      {h1}
                    </UitkHeading>
                    <UitkSubheading tag="h2" className={``}>
                      {h2}
                    </UitkSubheading>
                    {checkValue(pageCategory) && <SegmentComponent data={pageCategory} pill={true} />}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </EgClickstreamImpressionTracker>
      )}
    </>
  );
};

export default GoGuidesHeroComponent;
