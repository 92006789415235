import React, { useState } from 'react';
import { checkValue } from 'components/functions';
import KeepExploringSub from './keep-exploring-sub';
import { UitkExpandoPeek } from 'uitk-react-expando';
import { localization } from 'src/utils/localization';
import { Viewport, ViewLarge, ViewSmall } from "@shared-ui/viewport-context";

export interface KeepExploringCountryProps {
  listing: any;
}

const KeepExploringCountry: React.FC<KeepExploringCountryProps> = (props) => {
  const { listing } = props;

  const [isVisible, setIsVisible] = useState(false);
  const loc = new localization();

  if (!checkValue(listing.country)) {
    return null;
  } else {
    return (
      <>
        <div className="list-container main-destinations">
          <KeepExploringSub level="neighbourhood" listing={listing.country} />
          <KeepExploringSub level="main-city" listing={listing.country} />
          <Viewport>
            <ViewSmall>
              <UitkExpandoPeek
                isVisible={isVisible}
                handleClick={() => setIsVisible((prevIsVisible) => !prevIsVisible)}
                lines={6}
                whitespace={false}
                expandLabel={loc.getLocalizedText('show_more')}
                collapseLabel={loc.getLocalizedText('show_less')}
              >
                <KeepExploringSub level="city" listing={listing.country} />
              </UitkExpandoPeek>
            </ViewSmall>
            <ViewLarge>
              <KeepExploringSub level="city" listing={listing.country} />
            </ViewLarge>
          </Viewport>
          <KeepExploringSub level="region" listing={listing.country} listingClassName="list-region" />
          <KeepExploringSub level="country" listing={listing.country} />
        </div>
      </>
    );
  }
};

export default KeepExploringCountry;
