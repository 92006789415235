import React from 'react';
import { checkValue } from 'components/functions';
import { CardItemProp } from 'components/types/goguides-types.d';
import { UitkHeading } from 'uitk-react-text';
import { useStores } from 'utils/useStore';
import CarouselComponent from '../generic/render-carousel';
import { UitkCard, UitkCardLink } from 'uitk-react-cards';
import { UitkFigure, UitkFigureAspectRatioType } from 'uitk-react-images';
import { RenderImage } from 'src/components/utils/responsive-image';
import { EgClickstreamData, EgClickstreamImpressionTracker } from '@shared-ui/clickstream-analytics-context';
import { localization } from 'src/utils/localization';

const GoGuidesWhatsAroundComponent: React.FC = () => {
  const { goGuidesStore } = useStores();
  const contentData = goGuidesStore.fetchWhatsAround(goGuidesStore.pageUrlData);
  const loc = new localization();

  let stories: CardItemProp[] = [];
  let trackingModuleName = '';
  let headerText = '';

  if (checkValue(contentData)) {
    const { storiesData, travelBlog, destinationName } = contentData;
    stories = checkValue(storiesData) ? storiesData : [];
    trackingModuleName = travelBlog === true ? 'popular stories' : 'what\'s around';
    headerText = travelBlog === true
      ? loc.getLocalizedText('popular_stories')
      : loc.getLocalizedText('whats_around', destinationName);
  }

  const impressionTrackingObject: EgClickstreamData = {
    event: {
      event_name: 'go_guides_module.presented',
      event_type: 'Impression',
      event_version: '1.0.0',
      event_category: 'go_guides',
    },
    experience: goGuidesStore.ClicksteamExperience,
    module: {
      module_name: trackingModuleName,
    },
  };
  const carouselConfigProps = {
    carouselName: trackingModuleName,
    carouselHeaderText: 'Whats Around Carousel',
    itemsVisible: {
      sm: 2,
      md: 3,
      lg: 4,
      xl: 4,
    },
    pageBy: 4,
    peek: true,
    experience: goGuidesStore.ClicksteamExperience,
  };

  const CarouselItem = ({ item }: { item: CardItemProp }) => {
    const imageProps = {
      image: item.image?.imageUrl,
      alt: item.name,
      params: {
        size: {
          small: '&width=400&height=267',
          medium: '&width=500&height=333',
          large: '&width=600&height=400',
        }
      }
    };

    return (
      <UitkCard border={true}>
        <UitkFigure ratio={UitkFigureAspectRatioType.R16_9}>
          <RenderImage imgProps={imageProps} />
        </UitkFigure>
        <div className="detail-wrapper">
          <UitkHeading tag="h3" size={7}>
            {item.name}
          </UitkHeading>
        </div>
        <UitkCardLink>
          <a href={item.targetUrl}></a>
        </UitkCardLink>
      </UitkCard>
    );
  }

  const carouselData = stories.map((item, index) => (
    <CarouselItem key={index} item={item} />
  ));

  return (
    <>
      {checkValue(stories) && checkValue(stories.length > 0) && (
        <EgClickstreamImpressionTracker eventData={impressionTrackingObject}>
          <section className="teaser-carousel whats-around sec-control">
            <div className="container-wrap row">
              <div className="row-inner col-12 line-up">
                <div className="teaser-wrapper">
                  <div className="section-header-wrapper">
                    <UitkHeading tag="h2">{headerText}</UitkHeading>
                  </div>
                  <CarouselComponent data={carouselData} config={carouselConfigProps} />
                </div>
              </div>
            </div>
          </section>
        </EgClickstreamImpressionTracker>
      )}
    </>
  );
};

export default GoGuidesWhatsAroundComponent;
