import React from 'react';
import {UitkLink} from "uitk-react-link";
import {UitkHeading} from "uitk-react-text";
import { GoGuidesListicleItemProps } from 'src/components/types/goguides-types.d';

interface ListicleItemHeader {
    hideNumber: boolean;
    number: number;
    item: GoGuidesListicleItemProps;
    itemTargetUrl: string;
    linkTargetPdp?: string;
    linkRel?: string;
  }

const ListicleItemHeader: React.FC<ListicleItemHeader> = ({ 
    hideNumber, 
    number, 
    item, 
    itemTargetUrl, 
    linkTargetPdp, 
    linkRel }) => {
        
  return (
    <div className="header-wrap">
      {!hideNumber && <div className="index-num">{number}</div>}
      <div className="header-inner-wrap">
        {itemTargetUrl ? (
          <UitkLink inline={true}>
            <a href={itemTargetUrl} target={linkTargetPdp} rel={linkRel}>
              <UitkHeading tag="h2" size={5}>{item.name}</UitkHeading>
            </a>
          </UitkLink>
        ) : (
          <UitkHeading tag="h2" size={5}>{item.name}</UitkHeading>
        )}
        {item.tagLine && <UitkHeading tag="h3" size={6}>{item.tagLine}</UitkHeading>}
      </div>
    </div>
  );
}

export default ListicleItemHeader;





