import React from 'react';
import { checkValue } from 'components/functions';
import { ContentItemProps } from 'components/types/goguides-types.d';
import { RenderImage, getResponsiveImageUrl } from 'src/components/utils/responsive-image';
import { UitkFigure, UitkFigureAspectRatioType } from 'uitk-react-images';
import { useStores } from 'utils/useStore';
import ImageAttribution from '../generic/image-attribution';

const GoGuidesLongContentsComponent: React.FC = () => {
  const { goGuidesStore } = useStores();
  const contentData = goGuidesStore.fetchLongContents(goGuidesStore.pageUrlData);

  const { contents } = contentData;
  const longformContent = checkValue(contents) ? contents : [];

  const ContentItem = ({ item }: { item: ContentItemProps }) => {

    const imageProps = {
      image: item.image?.imageUrl,
      params: {
        size: {
          small: '&width=800&height=533',
          medium: '&width=1600&height=1066',
          large: '&width=1600&height=1066',
        }
      }
    }

    return (
      <>
        {item.html ? (
          <div dangerouslySetInnerHTML={{ __html: item.html.paragraph }} />
        ) : (
          <>
            <div className="img-wrap">
              <UitkFigure className="img" ratio={UitkFigureAspectRatioType.R3_2} roundcorner>
                <RenderImage imgProps={imageProps} />
              </UitkFigure>
              {item.image.attribution && item.image.attribution.attributionVersion !== 'CC0' && (
                <ImageAttribution attribution={item.image?.attribution} />
              )}
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <>
      {checkValue(longformContent) && (
        <div className="content-container">
          {longformContent.map((item, index) => (
            <ContentItem key={index} item={item} />
          ))}
        </div>
      )}
    </>
  );
};

export default GoGuidesLongContentsComponent;
