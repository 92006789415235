import React from 'react';
import { checkValue } from 'components/functions';
import { OtherNeighbourhoodsItemProps } from 'components/types/goguides-types.d';
import { UitkHeading, UitkParagraph, UitkSubheading } from 'uitk-react-text';
import { useStores } from 'utils/useStore';
import { UitkLink } from 'uitk-react-link';
import CarouselComponent from '../generic/render-carousel';
import { UitkCard } from 'uitk-react-cards';
import { UitkFigure, UitkFigureAspectRatioType, UitkImageLink } from 'uitk-react-images';
import { localization } from 'utils/localization';
import { RenderImage } from 'src/components/utils/responsive-image';
import { EgClickstreamData, EgClickstreamImpressionTracker } from '@shared-ui/clickstream-analytics-context';
import { Viewport, ViewSmall } from "@shared-ui/viewport-context";

const GoGuidesOtherNeighbourhoodsComponent: React.FC = () => {
  const { goGuidesStore } = useStores();
  const contentData = goGuidesStore.fetchOtherNeighbourhoods(goGuidesStore.pageUrlData);
  const loc = new localization();

  const moduleName = 'neighbourhoods in';
  const impressionTrackingObject: EgClickstreamData = {
    event: {
      event_name: 'go_guides_module.presented',
      event_type: 'Impression',
      event_version: '1.0.0',
      event_category: 'go_guides',
    },
    experience: goGuidesStore.ClicksteamExperience,
    module: {
      module_name: moduleName,
    },
  };

  let destinations: OtherNeighbourhoodsItemProps[] = [];
  let headerText = '';
  let seeAllUrl = '';

  if (checkValue(contentData)) {
    const { neighbourhoodsData } = contentData;
    destinations = checkValue(neighbourhoodsData?.neighbourhoodsList) ? neighbourhoodsData.neighbourhoodsList : [];
    headerText = checkValue(neighbourhoodsData)
      ? loc.getLocalizedText('neighbourhoods_in', neighbourhoodsData?.neighbourhoodsDestinationName)
      : '';
    seeAllUrl = checkValue(neighbourhoodsData) ? neighbourhoodsData?.neighbourhoodsSeeAllUrl : '';
  }

  const readMoreLabel = loc.getLocalizedText('read_more');
  const seeAllLabel = loc.getLocalizedText('see_all_properties');
  const carouselConfigProps = {
    carouselName: moduleName,
    carouselHeaderText: 'Other Neighbourhoods Carousel',
    itemsVisible: {
      sm: 1,
      md: 2,
      lg: 3,
      xl: 3,
    },
    pageBy: 3,
    peek: true,
    experience: goGuidesStore.ClicksteamExperience,
  };

  const CarouselItem = ({ item }: { item: OtherNeighbourhoodsItemProps }) => {

    const seeAllNhoodPropLabel = loc.getLocalizedText('see_all_properties_in', item.name);
    const imageProps = {
      image: item.image?.imageUrl,
      params: {
        size: {
          small: '&width=600&height=400',
          medium: '&width=600&height=400',
          large: '&width=800&height=533',
        }
      }
    };

    return (
      <UitkCard border={true}>
        <div className="item-wrap-inner">
          <UitkFigure ratio={UitkFigureAspectRatioType.R16_9}>
            <RenderImage imgProps={imageProps} />
            <UitkImageLink>
              <a href={item.seeAllPropertiesUrl}></a>
            </UitkImageLink>
          </UitkFigure>
          <div className="detail-wrapper">
            <div className="detail-wrapper-inner">
              <UitkLink>
                <a href={item.seeAllPropertiesUrl}>
                  <UitkHeading tag="h3" size={7}>
                    {item.name}
                  </UitkHeading>
                </a>
              </UitkLink>
              <UitkParagraph>{item.snippetText}</UitkParagraph>
              <UitkLink className="read-more" inline={true}>
                <a href={item.targetUrl}>{readMoreLabel}</a>
              </UitkLink>
            </div>
            {checkValue(item.seeAllPropertiesUrl) && (
              <div className="see-all-nh-prop">
                <UitkLink className="nh-prop-link">
                  <a href={item.seeAllPropertiesUrl} target="_blank" rel="noreferrer">
                    {seeAllNhoodPropLabel}
                  </a>
                </UitkLink>
              </div>
            )}
          </div>
        </div>
      </UitkCard>
    );
  }

  const carouselData = destinations.map((item, index) => (
    <CarouselItem key={index} item={item} />
  ));

  return (
    <>
      {checkValue(destinations) && checkValue(destinations.length > 0) && (
        <EgClickstreamImpressionTracker eventData={impressionTrackingObject}>
          <section className="other-neighbourhood sec-control">
            <div className="container-wrap row">
              <div className="row-inner col-12 line-up">
                <div className="other-neighbourhood-inner">
                  <div className="section-header-wrapper">
                    <div className="header-wrapper">
                      <UitkSubheading tag="h2">{headerText}</UitkSubheading>
                    </div>
                    {checkValue(seeAllUrl) && (
                      <div className="see-all-prop desktop">
                        <UitkLink className="see-all-link">
                          <a href={seeAllUrl} target="_blank" rel="noreferrer">
                            {seeAllLabel}
                          </a>
                        </UitkLink>
                      </div>
                    )}
                  </div>

                  <CarouselComponent data={carouselData} config={carouselConfigProps} />

                  {checkValue(seeAllUrl) && (
                    <Viewport>
                      <ViewSmall>
                        <div className="see-all-prop mobile">
                          <UitkLink className="see-all-link" align={'right'}>
                            <a href={seeAllUrl} target="_blank" rel="noreferrer">
                              {seeAllLabel}
                            </a>
                          </UitkLink>
                        </div>
                      </ViewSmall>
                    </Viewport>
                  )}
                </div>
              </div>
            </div>
          </section>
        </EgClickstreamImpressionTracker>
      )}
    </>
  );
};

export default GoGuidesOtherNeighbourhoodsComponent;
