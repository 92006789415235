import React from 'react';
import { checkValue } from 'components/functions';
import { CategoriesItemProps } from 'components/types/goguides-types.d';
import { UitkHeading } from 'uitk-react-text';
import { useStores } from 'utils/useStore';
import { UitkCard, UitkCardLink } from 'uitk-react-cards';
import { UitkFigure, UitkFigureAspectRatioType } from 'uitk-react-images';
import CarouselComponent from '../generic/render-carousel';
import { RenderImage } from 'src/components/utils/responsive-image';
import { localization } from 'src/utils/localization';
import { EgClickstreamData, EgClickstreamImpressionTracker } from '@shared-ui/clickstream-analytics-context';
import { Viewport, ViewMedium, ViewSmall } from "@shared-ui/viewport-context";

const GoGuidesOtherCategoriesComponent: React.FC = () => {
  const { goGuidesStore } = useStores();
  const contentData = goGuidesStore.fetchOtherCategories(goGuidesStore.pageUrlData);

  const moduleName = 'browse by category';
  const impressionTrackingObject: EgClickstreamData = {
    event: {
      event_name: 'go_guides_module.presented',
      event_type: 'Impression',
      event_version: '1.0.0',
      event_category: 'go_guides',
    },
    experience: goGuidesStore.ClicksteamExperience,
    module: {
      module_name: moduleName,
    },
  };

  const loc = new localization();
  let categories: CategoriesItemProps[] = [];
  let headerText = '';

  if (checkValue(contentData)) {
    const { categoriesData, destinationName } = contentData;
    categories = checkValue(categoriesData?.category) ? categoriesData.category : [];
    headerText = loc.getLocalizedText('browse_by_category', destinationName);
  }

  const carouselConfigProps = {
    carouselName: moduleName,
    carouselHeaderText: 'Blog Stories Carousel',
    itemsVisible: {
      sm: 1,
      md: 4,
      lg: 6,
      xl: 6,
    },
    pageBy: 6,
    peek: true,
    experience: goGuidesStore.ClicksteamExperience,
  };

  const CatetoryItem = ({ item }: { item: CategoriesItemProps }) => {

    const categoryName = loc.getLocalizedText(`category.${item.categoryCode}`);
    const imageProps = {
      image: item.image?.imageUrl,
      params: {
        size: {
          small: '&width=400&height=267',
          medium: '&width=400&height=267',
          large: '&width=400&height=267',
        }
      },
    };

    return (
      <UitkCard className='item-wrap'>
        <UitkFigure className="img-wrap" ratio={UitkFigureAspectRatioType.R1_1}>
          <span>{categoryName}</span>
          <RenderImage imgProps={imageProps} />
        </UitkFigure>
        <UitkCardLink>
          <a href={item.targetUrl}></a>
        </UitkCardLink>
      </UitkCard>
    );
  }

  const carouselData = categories.map((item, index) => (
    <CatetoryItem key={index} item={item} />
  ));

  return (
    <>
      {checkValue(categories) && checkValue(categories.length > 0) && (
        <EgClickstreamImpressionTracker eventData={impressionTrackingObject}>
          <section className="theme-hub teaser-carousel sec-control">
            <div className="container-wrap  row">
              <div className="row-inner col-12 line-up">
                <div className="section-header-wrapper">
                  <UitkHeading size={5} tag="h2">
                    {headerText}
                  </UitkHeading>
                </div>
                <Viewport>
                  <ViewSmall>
                    <div className="category-container">
                      <ul className={`row item-list-container`}>
                        {categories.map((item, index) => (
                          <li key={index} className="item-wrap col-6">
                            <CatetoryItem item={item} />
                          </li>
                        ))}
                      </ul>
                    </div>
                  </ViewSmall>
                  <ViewMedium>
                    <CarouselComponent
                      data={carouselData}
                      config={carouselConfigProps}
                    />
                  </ViewMedium>
                </Viewport>
              </div>
            </div>
          </section>
        </EgClickstreamImpressionTracker>
      )}
    </>
  );
};

export default GoGuidesOtherCategoriesComponent;
