import React from 'react';
import {useStores} from 'src/utils/useStore';
import PageLoader from 'src/components/common/modules/loader/page-loader';
import {RouteComponentProps} from 'react-router-dom';
import {checkValue} from 'src/components/functions';

import HomePage from 'src/components/pages/home';
import DestinationHubPage from 'src/components/pages/destination-hub';
import CategoryHubPage from 'src/components/pages/category-hub';
import ListiclePage from 'src/components/pages/listicle';
import LongFormPage from 'src/components/pages/long-form';

export const GoGuidesView: React.FC<RouteComponentProps> = () => {
  const { goGuidesStore } = useStores();

  const renderView = () => {
    let contentTypeId = 0;

    if (checkValue(goGuidesStore.pageUrlData) && checkValue(goGuidesStore.pageUrlData["contentTypeId"])) {
      contentTypeId = goGuidesStore.pageUrlData["contentTypeId"];
    }

    switch (contentTypeId) {
      case 1: {
        return (
          <HomePage />
        );
      }
      case 2: {
        return (
          <>
          <DestinationHubPage />
          </>
        );
      }
      case 3: {
        return (
          <CategoryHubPage />
        );
      }
      case 4: {
        return (
          <ListiclePage />
        );
      }
      case 5: {
        return (
          <LongFormPage />
        );
      }
      case 98: {
        return (
          <pre>{JSON.stringify(goGuidesStore.jsonData, null, 2)}</pre>
        );
      }
      default: {
        window.location.href = "/404/?rfrr=goguides";
      }
    }
  }

  const renderLoader = () => <PageLoader />;

  // if ((typeof window !== 'undefined')
  //   // && (!checkValue(goGuidesStore.pageUrlData))
  //   && ((!checkValue(goGuidesStore) || !goGuidesStore.isRouteChecked))
  //   ) {
  //   return renderLoader();
  // }

  return (
    // (typeof window !== 'undefined')
    // && goGuidesStore.isRouteChecked
    // && checkValue(goGuidesStore.pageUrlData["contentTypeId"])
    // <Suspense fallback={renderLoader()}>{renderView()}</Suspense>
    renderView()
  );

}

GoGuidesView.displayName = 'GoGuidesView';

export default GoGuidesView;
