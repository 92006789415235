import {useWindowSize} from '../common/helper';
import { useEffect, useState } from 'react';


export const viewportCheck = () => {
    const [width] = useWindowSize();
    const screenSize = {
        //s: 599,
        m: 749,
        l: 959,
        xl: 1259
    }
    
    const [windowSize, setWindowSize] = useState('');

    useEffect(() => {
        if (width > screenSize.xl) { setWindowSize('large-desktop'); }
        else if (width > screenSize.l) { setWindowSize('desktop'); }
        else if (width > screenSize.m) { setWindowSize('tablet'); }
        else if (width <= screenSize.m) { setWindowSize('mobile'); }

      },[width]);

      return windowSize
}

export const checkPageType = (page:string) => {
    if (page === 'destinationhub' || page === 'categoryhub'|| page === 'home'){
      return 'hubpage'
    }
    else if (page === 'listicle' || page === 'longform') {
      return 'articlepage'
    }
}

export const pageLayoutController = (page:string) => {
  
  let pageLayout = {
    header: '', // aling-center, align-left
    body: '', // one-col, two-col
  };

  switch (page) {
    case 'home':
    case 'destinationhub':
      pageLayout = {
        header: 'align-center',
        body: 'one-col',
      };
      break;
    case 'categoryhub':
      pageLayout = {
        header: 'align-left',
        body: 'one-col',
      };
      break;
    case 'listicle':
    case 'longform':
      pageLayout = {
        header: 'align-left',
        body: 'two-col',
      };
      break;
    default:
      break;
  }

  return pageLayout;
};

const cardSize = {
  S: 'col-12 col-m4',
  M: 'col-12 col-m6',
  L: 'col-12 col-m8 cover-left',
  XL: 'col-12 highlighted',
};

export const getCardSize = (itemLength, currentIndex, isHighlight, isDestination = false) => {
  let cardSizeToUse;
  let highlightClass = false;
  let imageSize;

  if(isDestination) {
    if (isHighlight) {
      if (itemLength > 3) {
        cardSizeToUse = currentIndex == 0 ? cardSize.XL : cardSize.S;
        highlightClass = currentIndex == 0;
        imageSize = currentIndex == 0 ? 'XL' : 'S';
      } else if (itemLength == 3) {
        cardSizeToUse = currentIndex == 0 ? cardSize.XL : cardSize.M;
        highlightClass = currentIndex == 0;
        imageSize = currentIndex == 0 ? 'XL' : 'M';
      } else if (itemLength <= 2) {
        cardSizeToUse = cardSize.XL;
        highlightClass = true;
        imageSize = 'XL';
      }
    } else {
      if (itemLength > 4) {        
        cardSizeToUse = currentIndex < 2 ? cardSize.M : cardSize.S;
        imageSize = currentIndex < 2 ? 'M' : 'S';
      } else if (itemLength == 4) {
        cardSizeToUse = cardSize.M;
        imageSize = 'M';
      } else if (itemLength == 3) {
        cardSizeToUse = currentIndex === 0 ? cardSize.L : cardSize.S;
        imageSize = currentIndex === 0 ? 'L' : 'S';
      } else if (itemLength == 2) {
        cardSizeToUse = cardSize.M;
        imageSize = 'M';
      } else if (itemLength == 1) {
        cardSizeToUse = cardSize.XL;
        highlightClass = true;
        imageSize = 'XL';
      }
    }
  } else {
    if (itemLength > 5) {
      cardSizeToUse = currentIndex == 0 ? cardSize.L : cardSize.S;
      imageSize = currentIndex == 0 ? 'L' : 'S';
    } else if (itemLength == 5) {
      cardSizeToUse = currentIndex < 2 ? cardSize.M : cardSize.S;
      imageSize = currentIndex < 2 ? 'M' : 'S';
    } else if (itemLength == 4) {
      cardSizeToUse = cardSize.M;
      imageSize = 'M';
    } else if (itemLength == 3) {
      cardSizeToUse = currentIndex === 0 ? cardSize.L : cardSize.S;
      imageSize = currentIndex === 0 ? 'L' : 'S';
    } else if (itemLength == 2) {
      cardSizeToUse = cardSize.M;
      imageSize = 'M';
    } else if (itemLength == 1) {
      cardSizeToUse = cardSize.XL;
      highlightClass = true;
      imageSize = 'XL';
    }
  }

  
  return { imageSize, cardSizeToUse, highlightClass };
};

export const getImageProps = (imageSize, image) => {
  switch (imageSize) {
    case 'S':
      return {
        imageUrl: image?.imageUrl,
        params: {
          size: {
            mobile: '&width=800&height=533',
            tablet: '&width=800&height=533',
            desktop: '&width=800&height=533',
          },
          quality: '&quality=50',
        },
      };
    case 'M':
      return {
        imageUrl: image?.imageUrl,
        params: {
          size: {
            mobile: '&width=800&height=533',
            tablet: '&width=800&height=533',
            desktop: '&width=1200&height=800',
          },
          quality: '&quality=50',
        },
      };
    case 'L':
      return {
        imageUrl: image?.imageUrl,
        params: {
          size: {
            mobile: '&width=800&height=533',
            tablet: '&width=800&height=533',
            desktop: '&width=1600&height=1066',
          },
          quality: '&quality=50',
        },
      };
    case 'XL':
      return {
        imageUrl: image?.imageUrl,
        params: {
          size: {
            mobile: '&width=800&height=533',
            tablet: '&width=800&height=533',
            desktop: '&width=1600&height=1066',
          },
          quality: '&quality=50',
        },
      };
    default:
      return {
        imageUrl: image?.imageUrl,
        params: {
          size: {
            mobile: '&width=800&height=533',
            tablet: '&width=800&height=533',
            desktop: '&width=800&height=533',
          },
          quality: '&quality=50',
        },
      };
  }
};

export const getCategoryHeader = (categoryCode, pageType):string => {
  
  let categoryHeader = '';
  let categoryHeaders = {};
  
  if(pageType == 'category'){
    categoryHeaders = {
      THINGS_TO_DO: 'hub.category.h2.things_to_do',
      RESTAURANT:  'hub.category.h2.food',
      SHOPPING: 'hub.category.h2.shopping',
      NIGHTLIFE: 'hub.category.h2.nightlife',
      INFORMATION: 'hub.category.h2.information',
      ADVENTURE: 'hub.category.h2.adventure',
      BEACH: 'hub.category.h2.beach',
      BLOGS: 'hub.category.h2.blogs',
      BUDGET: 'hub.category.h2.budget',
      BUSINESS: 'hub.category.h2.business',
      COUPLE: 'hub.category.h2.couples',
      FAMILY: 'hub.category.h2.families',
      GROUP: 'hub.category.h2.group',
      HISTORY: 'hub.category.h2.history',
      HOTELS: '',
      LUXURY: 'hub.category.h2.luxury',
      MAPS: 'hub.category.h2.maps',
      NEIGHBORHOOD: '',
      PHOTOS: 'hub.category.h2.photos',
      SINGLE: 'hub.category.h2.single',
      SKI: 'hub.category.h2.ski',
      SPORTS: 'hub.category.h2.sports',
      TOURS: 'hub.category.h2.tours',
      FOOTBALL: 'hub.category.h2.football',
      NATURE: 'hub.category.h2.nature',
      ROAD_TRIPS: 'hub.category.h2.road_trips',
      UNUSUAL: 'hub.category.h2.unusual',
      CUSTOM: 'hub.category.h2.custom',
      UNKNOWN: '',
    }
   }else{
    categoryHeaders = {
      THINGS_TO_DO: 'what_to_see_and_do_in',
      RESTAURANT: 'category_highlight.title.food',
      SHOPPING: 'category_highlight.title.shopping',
      NIGHTLIFE: 'category_highlight.title.nightlife',
      INFORMATION: 'category_highlight.title.information',
    }
   }

    for (const key in categoryHeaders) {
      if (categoryCode === key) {
        categoryHeader = categoryHeaders[key]
          return categoryHeader;
      }
    }

    return categoryHeader;
  }

