export default {
    "current_deal": "Oferta actual",
    "lbg.section_properties_theme.pet_friendly": "Ganadores de la categoría Aceptan mascotas en {0}",
    "address": "Dirección",
    "hub.homepage.h2": "Tantos lugares, tan poco tiempo...",
    "search_hotels_near_this_landmark": "Buscar hoteles<span class=\"extra-label\"> cerca de esta atracción</span>",
    "hub.category.h1.things_to_do": "Atracciones en {0}",
    "lbg.h2.vip_access": "Los establecimientos VIP Access se seleccionaron por su alta puntuación en cuanto a estadía y experiencias.",
    "lbg.theme.cleanliness": "Limpieza",
    "lbg.theme.lgbtq": "LGBTQ",
    "load_more": "Cargar más",
    "link_long_form_to_listiicle_multiple": "{0}: uno de los puntos destacados de {1} y {2} (Descubre aquí todo sobre {3})",
    "lbg.section_properties_theme.boutique": "Ganadores de la categoría Boutique en {0}",
    "lbg.h2.luxury": "Descubre el lujo en su máxima expresión. Por eso los huéspedes eligen estos establecimientos.",
    "hub.category.h1.information": "Información sobre {0}",
    "families": "Familias",
    "trending_now": "Lo más buscado ahora",
    "lbg.from": "Desde",
    "hub.category.h1.road_trips": "Mejores viajes de ruta en {0}",
    "lbg.h2.cleanliness": "Estos establecimientos tienen una excelente puntuación de los huéspedes y están entre los que más destacan por su limpieza.",
    "single": "Solteros",
    "couples": "Parejas",
    "trust_module.price_guarantee.find_out_more": "más información",
    "top_destinations_in": "Principales destinos en {0}",
    "hub.category.h1.couples": "Escapadas para parejas a {0}",
    "hub.category.h2.tours": "Los mejores tours en {0}",
    "hub.category.h1.photos": "Opciones para fotógrafos en {0}",
    "category.photos": "Fotos de ",
    "hub.category.h1.budget": "Opciones económicas en {0}",
    "hub.category.h2.ski": "Los mejores destinos de ski en {0}",
    "lbg.section_theme_world_winners.most_wanted": "Ganadores de la categoría Más elegidos",
    "lbg.theme.home_and_apartments": "Casas y departamentos",
    "hub.category.h1.luxury": "Opciones de lujo en {0}",
    "hub.category.h2.adventure": "Las mejores actividades dinámicas en {0}",
    "hotels_in": "Hoteles en {0}",
    "lbg.section_search_winners": "Busca más ganadores",
    "see_more_hotels_in": "Consulta más hoteles en {0}",
    "hub.category.h1.beach": "Playas de {0}",
    "open": "Horarios:",
    "enter_your_destination": "Escribe tu destino",
    "best_of": "Lo mejor de {0}",
    "top10_hotels_missing_properties": "Es posible que algunos establecimientos no se muestren en este momento. Lamentamos cualquier inconveniente causado.",
    "adventure": "De aventura",
    "lbg.h1.destination_theme.sustainable": "Ganadores de la categoría Sustentables en {0}",
    "luxury": "De lujo",
    "expand_map_view": "Agrandar el mapa",
    "book_now": "Reservar",
    "lbg.h1.destination_theme.ski": "Ganadores de la categoría Ski en {0}",
    "hub.category.h1.food": "Restaurantes en {0}",
    "category.blogs": "Blog",
    "budget": "Económico",
    "popular_neighbourhoods_in": "Zonas famosas en {0}",
    "hub.category.h1.unusual": "Los secretos mejor guardados de {0}",
    "see_all_accommodation_in": "Ver hospedaje en {0}",
    "hub.category.h1.adventure": "Actividades dinámicas en {0}",
    "category_highlight.see_all.shopping": "Más info sobre compras",
    "lbg.section_most_popular_destination": "Principales destinos",
    "lbg.section_properties_theme.luxury": "Ganadores de la categoría Lujo en {0}",
    "unusual": "Exótico",
    "category.shopping": "Para ir de compras en ",
    "make_the_most_of_your_trip": "Aprovecha al máximo tu viaje",
    "x_review": "{0,choice,0#|1#{0} comentario|2#{0} comentarios|2<{0} comentarios|4<{0} comentarios|10#{0} comentarios|10<{0} comentarios}",
    "hub.category.h1.single": "Opciones para personas que viajan solas a {0}",
    "hub.category.h2.custom": "Tendencias",
    "lbg.section_theme_world_winners.lgbt": "Ganadores de la categoría LGBTQ+",
    "lbg.section_award_winners_theme_other": "Otras categoría ganadoras en {0}",
    "lbg.supplier_collected_charges": "+ {0} de cargos a pagar en el establecimiento",
    "in_destination": " {0}",
    "lbg.section_properties_theme.vip_access": "Ganadores de la categoría VIP Access en {0}",
    "link_long_form_to_listiicle_single": "{0}: uno de los puntos destacados de {1} (Descubre aquí todo sobre {2})",
    "lbg.section_theme_world_winners.boutique": "Ganadores de la categoría Boutique",
    "lbg.section_properties_theme.spa_hotel": "Ganadores de la categoría Spa en {0}",
    "lbg.section_theme_world_winners.all_inclusive": "Ganadores de la categoría Todo incluido",
    "hub.category.h2": "Guía de viaje de {0}",
    "lbg.properties.price_tooltip": "Este es el precio más bajo por noche en nuestro sitio para los próximos 14 días. ",
    "hub.category.h2.road_trips": "Mejores viajes de ruta en {0}",
    "hub.category.h1.ski": "Destinos de ski en {0}",
    "food": "Gastronomía",
    "lbg.theme.pet_friendly": "Aceptan mascotas",
    "lbg.theme.business": "Negocios",
    "hub.category.h1.nature": "Mejores viajes para escaparte a la naturaleza en {0}",
    "map": "Mapa",
    "category_highlight.see_all.information": "Más info",
    "hotel_prices_in": "Precios de hoteles en {0}",
    "hub.category.h1.nightlife": "Vida nocturna en {0}",
    "trust_module.free_cancellation.most_hotels": "en la mayoría de los hoteles*",
    "also_popular_in": "Otros destinos elegidos en {0}",
    "lbg.h1.destination_theme.business": "Ganadores de la categoría Negocios en {0}",
    "lbg.section_theme_world_winners.pet_friendly": "Ganadores de la categoría Aceptan mascotas",
    "hub.category.h1.maps": "Mapas de {0}",
    "lbg.section_properties_theme.most_wanted": "Ganadores de la categoría Más elegidos en {0}",
    "category.nightlife": "Vida nocturna en ",
    "search_hotels_in_this_area": "Buscar hoteles<span class=\"extra-label\"> en esta área</span>",
    "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 voto|2#2 votos|2<{1} votos|4<{1} votos|10#10 votos|10<{1} votos})",
    "hub.category.h2.business": "Lo mejor en viajes de negocios a {0}",
    "category.things_to_do": "Atracciones en ",
    "destination_travel_guide_by_category": "Categorías de la guía de viaje de {0}",
    "hub.category.h1.families": "Viajes en familia en {0}",
    "lbg.section_theme_world_winners.ski": "Ganadores de la categoría Ski",
    "you_may_also_like": "También puede gustarte",
    "lbg.theme.sustainable": "Sustentables",
    "hub.category.h2.unusual": "Los secretos mejor guardados y las atracciones más insólitas de {0}",
    "hub.category.h2.football": "Apoya a tu equipo de fútbol favorito y disfruta al máximo de tu viaje",
    "top_hotel": "Hotel destacado",
    "lbg.h1.destination_theme.luxury": "Ganadores de la categoría Lujo en {0}",
    "top10_hotels_empty_alert": "En este momento, tenemos problemas técnicos. <span>Lamentamos cualquier inconveniente causado.</span>",
    "special_deal": "Oferta especial",
    "lbg.h2.sustainable": "La sostenibilidad como prioridad. Consulta estos establecimientos que garantizan una gran estadía.",
    "where_to_stay_in": "Opciones de hospedaje en {0}",
    "start_planning_your_trip": "Organiza tu viaje",
    "good_for": "Categoría:",
    "category.hotels": "Hoteles en ",
    "x_accommodation_guide": "Guía de hospedaje en {0}",
    "lbg.theme.luxury": "Lujo",
    "hub.category.h2.sports": "Lo mejor en deportes y actividades en {0}",
    "lbg.section_theme_world_winners.lgbtq": "Ganadores de la categoría LGBTQ",
    "see_more_neighbourhoods": "Ver más zonas",
    "category_highlight.title.nightlife": "A dónde ir y qué hacer por las noches en {0}",
    "lbg.h1.destination_theme.lgbt": "Ganadores de la categoría LGBTQ+ en {0}",
    "hub.category.h1.blogs": "Blog de viajes de {0}",
    "lbg.h2.business": "Estos establecimientos recibieron una puntuación alta para viajes de negocios.",
    "hotels": "Hoteles",
    "lbg.h2.pet_friendly": "Los huéspedes recomiendan estos establecimientos si viajas con tu mascota.",
    "popular_landmarks_nearby": "Atracciones famosas en los alrededores",
    "popular_extractions_in": "Atracciones famosas en {0}",
    "lbg.h2.all_inclusive": "Estos establecimientos están bien evaluados y son ideales para relajarse.",
    "travel_guide_by_category_header": "{0} en {1}",
    "lbg.travel_between": "Viaja del {0} al {1}",
    "keep_exploring": "Descubre otros destinos",
    "what_you_need_to_know_before_you_go": "Lo que debes saber para tu viaje",
    "read_it": "Leer",
    "view_on_map": "Ver en el mapa",
    "lbg.h1.destination_theme.lgbtq": "Ganadores de la categoría LGBTQ en {0}",
    "lbg.h2.beach": "Los huéspedes aman las estadías en la playa, especialmente en estos establecimientos.",
    "lbg.h1.destination_theme.vip_access": "Ganadores de la categoría VIP Access en {0}",
    "see_all_accommodation": "Ver todos los establecimientos",
    "shoppers": "Ir de compras",
    "whats_around": "Atracciones cerca de {0}",
    "group": "Grupos",
    "hub.category.h2.couples": "Lo mejor en escapadas para parejas a {0}",
    "hub.category.h1.shopping": "Compras en {0}",
    "travel_community": "{0} <span>&#124;</span> <span>Comunidad de viajes</span>",
    "hub.category.h2.group": "Las mejores opciones para grupos en {0}",
    "listicle_legal_msg": "Este artículo incluye opiniones del equipo editorial de Go Guides. Hoteles.com compensa a sus autores por los artículos que se muestran en el sitio. La compensación puede incluir costos de viaje y otros costos.",
    "lbg.h1.destination_theme.cleanliness": "Ganadores de la categoría Limpieza en {0}",
    "menu.more": "Más",
    "lbg.section_properties_theme.lgbtq": "Ganadores de la categoría LGBTQ en {0}",
    "lbg.section_award_winners_destination": "Ganadores por destino",
    "lbg.terms_conditions": "Términos y condiciones",
    "hub.category.h2.nightlife": "La mejor vida nocturna en {0}",
    "hub.neighbourhood.h2": "Actividades en {0}",
    "lbg.section_award_winners_theme_destination": "Ganadores por categoría en {0}",
    "lbg.section_award_world_winners": "Ganadores en todo el mundo",
    "things_to_see_and_do_in_other_cities": "Actividades y atracciones en otras ciudades",
    "button.backToTop": "Volver arriba",
    "lbg.section_theme_world_winners.family": "Ganadores de la categoría Familiar",
    "hotels_near": "Hoteles cerca de {0}",
    "category.activity__sports_": "Paseos en ",
    "trust_module.get_reward_night": "Obtén una noche de regalo",
    "hub.category.h1.business": "Viajes de negocios a {0}",
    "category.food": "Gastronomía en ",
    "other_popular_destinations_in": "Otros destinos destacados en {0}",
    "browse_by_category": "Búsqueda por categorías en {0}",
    "see_more_attactions": "Ver más atracciones",
    "lbg.theme.lgbt": "LGBTQ+",
    "more_information_about": "Más información sobre {0}",
    "lbg.h1.destination_theme.most_wanted": "Ganadores de la categoría Más elegidos en {0}",
    "see_all_properties_in": "Ver todos los establecimientos en {0}",
    "category.tours": "Paseos en ",
    "hub.category.h1.sports": "Deportes y actividades en {0}",
    "where_will_you_go_next": "¿A dónde irás?",
    "cities": "Ciudades",
    "lbg.theme.spa_hotel": "Spa",
    "see_guide": "Ver guía",
    "lbg.section_properties_theme.ski": "Ganadores de la categoría Ski en {0}",
    "related_stories": "Artículos relacionados",
    "hub.destination.h2": "Lo que debes saber para tu viaje",
    "show_less": "Ver menos",
    "hub.category.h1.football": "La mejor guía de {0} si te apasiona el fútbol",
    "hub.category.h2.food": "Los mejores restaurantes en {0}",
    "fallback_language_alert": "Esta página aún no está traducida a tu idioma.",
    "category_highlight.title.information": "Información útil",
    "neighbourhood_guides_for_other_cities": "Guía de zonas de otras ciudades",
    "lbg.h2.hubpage": "Estos establecimientos tienen gran cantidad de comentarios de huéspedes y una puntuación alta",
    "travel_advisory.title": "Alerta de viaje",
    "lbg.section_properties_theme.all_inclusive": "Ganadores de la categoría Todo incluido en {0}",
    "lbg.h2.boutique": "Si te gustan los establecimientos boutique, los huéspedes recomiendan estos lugares.",
    "lbg.section_properties_theme.family": "Ganadores de la categoría Familiar en {0}",
    "hub.category.h2.families": "Los mejores viajes en familia en {0}",
    "from": "desde",
    "lbg.theme.vip_access": "VIP Access",
    "lbg.section_properties_theme.lgbt": "Ganadores de la categoría LGBTQ+ en {0}",
    "x_star": "{0,choice,0#|1#{0} estrella|1.5#{0} estrellas|2#{0} estrellas|2.5#{0} estrellas|3#{0} estrellas|3.5#{0} estrellas|4#{0} estrellas|4.5#{0} estrellas|5#{0} estrellas}",
    "trust_module.free_cancellation": "Cancelación gratis",
    "lbg.section_properties_theme.home_and_apartments": "Ganadores de la categoría Casas y departamentos en {0}",
    "contributing_writer": "{0} <span>&#124;</span> <span>Escritor</span>",
    "things_to_see_and_do_in": "Actividades y atracciones <span class=\"next-line\">en {0}</span>",
    "lbg.check_price": "Verificar precio",
    "hub.category.h2.blogs": "por Experienced travellers",
    "lbg.h2.home_and_apartments": "¿Buscas un hogar lejos de tu hogar? Consulta estos establecimientos con alta puntuación.",
    "hub.category.h2.information": "Información importante sobre {0}",
    "search_hotels_in": "Buscar hoteles<span class=\"extra-label\"> en {0}</span>",
    "hub.category.h2.nature": "Mejores viajes para vivir la naturaleza en {0}",
    "lbg.h1.destination_theme.home_and_apartments": "Ganadores de la categoría Casas y departamentos en {0}",
    "photo_by": "Foto de {0}",
    "more_things_to_do": "Más opciones",
    "lbg.section_properties_theme.beach": "Ganadores de la categoría Playa en {0}",
    "read_less": "Menos información",
    "lbg.h2.spa_hotel": "Relájate en estos establecimientos destacados con sus increíbles spas.",
    "second_fold_related_stories.see_also": "También te recomendamos consultar",
    "hub.category.h2.history": "Los mejores lugares históricos en {0}",
    "lbg.section_properties_theme.cleanliness": "Ganadores de la categoría Limpieza en {0}",
    "lbg.h1.destination_theme.boutique": "Ganadores de la categoría Boutique en {0}",
    "nightlife_b595ae4": "Vida nocturna",
    "lbg.h1.destination_theme.beach": "Ganadores de la categoría Playa en {0}",
    "trust_module.free_cancellation.disclaimer": "Algunos hoteles solicitan que canceles al menos de 24 a 48 horas antes del check-in. Obtén más información en el sitio web.",
    "lbg.h2.family": "Estos establecimientos tienen una puntuación alta y son ideales para las familias.",
    "lbg.h2.lgbtq": "Estos establecimientos LGBTQ friendly recibieron una puntuación alta de los huéspedes.",
    "hub.category.h1.group": "Opciones para grupos en {0}",
    "popular_stories": "Artículos más leídos",
    "toc": "Tabla de contenido",
    "hub.category.h2.shopping": "Las mejores opciones para ir de compras en {0}",
    "lbg.section_theme_world_winners.beach": "Ganadores de la categoría Playa",
    "page_score": "Puntuación de esta página",
    "lbg.section_theme_world_winners.sustainable": "Ganadores de la categoría Sustentables",
    "lbg.theme.boutique": "Boutique",
    "trust_module.price_guarantee": "Nuestro Precio garantizado",
    "lbg.h1.destination_theme.all_inclusive": "Ganadores de la categoría Todo incluido en {0}",
    "top_destinations": "Principales destinos",
    "hotel_prices_near": "Precios de hoteles cerca de {0}",
    "lbg.score.cleanliness": "Limpieza:",
    "x_stars": "{0,choice,0#|1#{0} estrella|1<{0} estrellas|4<{0} estrellas|10<{0} estrellas}",
    "lbg.theme.all_inclusive": "Todo incluido",
    "hub.category.h1.tours": "Tours y excursiones de un día en {0}",
    "lbg.section_award_winners_theme": "Ganadores por categoría",
    "guest_writer": "{0} <span>&#124;</span> <span>Escritor invitado</span>",
    "start_planning_your_x_trip": "Organiza tu viaje a {0}",
    "lbg.section_theme_world_winners.business": "Ganadores de la categoría Negocios",
    "trending_now_in": "Lo más buscado en {0}",
    "history": "Historia",
    "search_hotels": "Buscar hoteles",
    "lbg.terms_conditions_details": "Para seleccionar los establecimientos ganadores en {0}, se tuvieron en cuenta criterios como la alta puntuación en las evaluaciones de los huéspedes en {1} y un mínimo de 50 comentarios.",
    "see_nearby_properties": "Ver establecimientos cercanos",
    "hub.category.h1.history": "Lugares históricos en {0}",
    "location": "Ubicación:",
    "lbg.section_theme_world_winners.luxury": "Ganadores de la categoría Lujo",
    "category_highlight.see_all.nightlife": "Más info sobre vida nocturna",
    "also_popular": "Muy elegidos",
    "hub.category.h2.things_to_do": "Las mejores actividades en {0}",
    "lbg.theme.family": "Familiar",
    "lbg.h1.destination_theme.pet_friendly": "Ganadores de la categoría Aceptan mascotas en {0}",
    "category_highlight.title.shopping": "Dónde y qué comprar en {0}",
    "price": "Precio:",
    "hub.category.h2.beach": "Las mejores playas de {0}",
    "neighbourhoods_in": "zonas en {0}",
    "business": "De negocios",
    "photo": "Foto",
    "blog.see_all_stories": "Ver todas las historias",
    "category_highlight.see_all.food": "Más info sobre gastronomía",
    "see_all_properties": "Ver todos los establecimientos",
    "lbg.theme.beach": "Playa",
    "what_to_see_and_do_in": "Qué ver y hacer en {0}",
    "hub.category.h2.single": "Las mejores opciones para personas que viajan solas a {0}",
    "attractions_in": "Puntos de interés en {0}",
    "read_more": "Más información",
    "show_more": "Ver más",
    "hotels_near_this_landmark": "Hoteles cerca de esta atracción",
    "check_dates": "Verificar fechas",
    "featured_stories_and_fun_stuff": "Artículos destacados y divertidos",
    "phone": "Teléfono:",
    "lbg.section_search_header": "Busca ganadores",
    "category_highlight.title.food": "Dónde y qué comer en {0}",
    "see_all_things_to_do": "Ver todas las actividades",
    "lbg.section_properties_theme.sustainable": "Ganadores de la categoría Sustentables en {0}",
    "lbg.h1.hubpage": "Ganadores de la categoría Favoritos de los huéspedes en {0}",
    "lbg.section_theme_world_winners.spa_hotel": "Ganadores de la categoría Spa",
    "category.maps": "Mapas de ",
    "lbg.h1.destination_theme.family": "Ganadores de la categoría Familiar en {0}",
    "lbg.section_theme_world_winners.cleanliness": "Ganadores de la categoría Limpieza",
    "lbg.section_theme_world_winners.vip_access": "Ganadores de la categoría VIP Access",
    "trust_module.get_reward_night.night_you_stay": "por cada 10 noches de estadía",
    "modified": "modificada",
    "lbg.section_properties": "Ganadores en {0}",
    "trust_module.header": "¿Por qué reservar en Hoteles.com?",
    "hub.category.h2.luxury": "Las mejores opciones de lujo en {0}",
    "lbg.theme.ski": "Ski",
    "lbg.h1.destination_theme.spa_hotel": "Ganadores de la categoría Spa en {0}",
    "lbg.h2.most_wanted": "Los huéspedes recomiendan estos establecimientos para una estadía perfecta.",
    "top10_hotels_incomplete_alert": "En este momento, tenemos mucha demanda, por lo que es posible que algunos establecimientos no se muestren. <span>Lamentamos cualquier inconveniente causado.</span>",
    "compulsive_traveller": "{0} <span>&#124;</span> <span>Huésped compulsivo</span>",
    "lbg.section_theme_world_winners.home_and_apartments": "Ganadores de la categoría Casas y departamentos",
    "loved_by_guests_collection_in": "Colección de Favoritos de los huéspedes en {0}",
    "title.suffix": "{0} - Go Guides",
    "hub.category.h2.maps": "Mapas útiles de {0}",
    "opening_hours": "Horario de atención",
    "hub.category.h2.photos": "Las mejores opciones para fotógrafos en {0}",
    "lbg.theme.most_wanted": "Más elegidos",
    "blog.stories_container": "Historias de bloggers",
    "hub.category.h2.budget": "Las mejores opciones económicas en {0}",
    "lbg.section_properties_theme.business": "Ganadores de la categoría Negocios en {0}",
    "not_sure_where_to_stay": "¿No sabes dónde hospedarte?",
    "category.information": "Información sobre "
}
