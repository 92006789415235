import React from 'react';
import { SearchForm } from '@shared-ui/search-tools-lodging';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { EgClickstreamData, EgClickstreamImpressionTracker } from '@shared-ui/clickstream-analytics-context';
import { useStores } from 'utils/useStore';
import { localization } from 'src/utils/localization';

const GoGuidesSearchWizard: React.FC = (props) => {
  const { goGuidesStore } = useStores();
  const loc = new localization();

  const trackingObject: EgClickstreamData = {
    event: {
      event_name: 'hotel_search_box.presented',
      event_type: 'Impression',
      event_version: '1.0.0',
      event_category: 'go_guides',
    },
    experience: goGuidesStore.ClicksteamExperience,
  };

  return (
    <EgClickstreamImpressionTracker eventData={trackingObject}>
      <section className="sqm-hub sec-control">
        <div className="container-wrap row">
          <div className="row-inner col-12">
            <div className="sqm-container">
              <h2>
                <UitkIcon name="search" size={UitkIconSize.LARGE} />
                {loc.getLocalizedText('start_planning_your_trip')}
              </h2>
              <SearchForm inputs={{}} redirectToSearchResultOnSubmit />
            </div>
          </div>
        </div>
      </section>
    </EgClickstreamImpressionTracker>
  );
};

export default GoGuidesSearchWizard;
