import React from 'react';
import { CardItemProp } from 'src/components/types/goguides-types.d';
import { checkValue } from 'src/components/functions';
import CardSizeS from './card-s';
import CardSizeM from './card-m';
import CardSizeL from './card-l';
import CardSizeXL from './card-xl';

interface BucketCardProps {
  data: CardItemProp[];
  highlight?: boolean;
  stories?: boolean;
}
const BucketCardDefault: React.FC<BucketCardProps> = ({ data, highlight, stories }) => {

  return (
    <ul className={`row item-list-container ${highlight || stories ? '' : 'list5'}`}>
      {data.map((item, index) => (
        checkValue(highlight) ? (
          index === 0 ? (
            <CardSizeXL key={index} data={item} />
          ) : (
            <CardSizeS key={index} data={item} />
          )
        ) : (
          checkValue(stories) ? (
            index === 0 ? (
              <CardSizeL key={index} data={item} />
            ) : (
              <CardSizeS key={index} data={item} />
            )
          ) : (
            index < 2 ? (
              <CardSizeM key={index} data={item} />
            ) : (
              <CardSizeS key={index} data={item} />
            )
          )
        )))}
    </ul>
  );
};

export default BucketCardDefault;
