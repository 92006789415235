import React from 'react';
import { GeoLocationProps } from 'src/components/types/goguides-types.d';
import MapComponent from 'src/components/modules/generic/maps';
import { UitkSpacingHr } from 'uitk-react-spacing';
import { checkValue } from '../../../functions';

interface ListicleItemMapProps {
    name: string;
    geo?: GeoLocationProps;
    i18nKey: string;
    nearbyProperties
    pdpLink
  }

const ListicleItemMap: React.FC<ListicleItemMapProps> = ({ name, geo, nearbyProperties, pdpLink, i18nKey }) => {

  return (
    <>
      {geo && geo.coord ? (
        <MapComponent name={name} geo={geo} i18nKey={i18nKey} />
      ) : (
        (checkValue(nearbyProperties) || checkValue(pdpLink)) && <UitkSpacingHr blockMargin="four" />
      )}
    </>
  );
};

export default ListicleItemMap;





