import GoGuidesContributorsStoriesComponent from "components/modules/contributors-stories/contributors-stories";
import GoGuidesTableOfContent from "components/modules/table-of-content/toc";
import React from 'react';
import { MasterPage } from 'components/common/template/master-page';
import GoGuidesAlsoPopularComponent from 'components/modules/also-popular/also-popular';
import GoGuidesFeaturedStoriesComponent from 'components/modules/featured-stories/featured-stories';
import GoGuidesHeroComponent from 'components/modules/hero/hero';
import GoGuidesIntroComponent from 'components/modules/intro/intro';
import GoGuidesKeepExploringComponent from 'components/modules/keep-exploring/keep-exploring';
import GoGuidesOtherCategoriesComponent from 'components/modules/other-categories/other-categories';
import GoGuidesWhatsAroundComponent from 'src/components/modules/whats-around/whats-around';
import GoGuidesSearchWizard from 'components/modules/search-wizard/search-wizard';
import GoGuidesBackToTop from '../modules/back-to-top/back-to-top';
import { RecommendationModule } from '../modules/recommendation-module/recommendation';
import { PageLayout } from '../types/goguides-types.d';

const CategoryHubPage: React.FC = () => {
  return (
    <MasterPage>
      <div className="page-container">
        <GoGuidesHeroComponent />
        <div className="hub-body">
          <GoGuidesTableOfContent tagElement='h2' parentClassName='hub-body' needWrapper={true} />
          <GoGuidesIntroComponent />
          <GoGuidesFeaturedStoriesComponent />
          <GoGuidesWhatsAroundComponent />
          <GoGuidesContributorsStoriesComponent />
          <GoGuidesOtherCategoriesComponent />
          <RecommendationModule pageLayout={PageLayout.ONE_COLUMN} />
          <GoGuidesSearchWizard />
          <GoGuidesAlsoPopularComponent />
          <GoGuidesKeepExploringComponent />
        </div>
        <GoGuidesBackToTop />
      </div>
    </MasterPage>
  );
};

export default CategoryHubPage;
