import React from 'react';

/**
 * Component to render only Go breadcrumb since uitk version is not support only one crumb
 */

interface OneCrumbProps {
  crumb: any;
}

const OneCrumb: React.FC<OneCrumbProps> = (props) => {
  const { crumb } = props;
  return (
    <>
      {crumb && (
        <nav aria-label="breadcrumb" className="uitk-breadcrumbs uitk-type-200">
          <ol className="uitk-breadcrumbs-list">
            {crumb.map((item, index) => {
              return <li className="uitk-breadcrumbs-item" key={`${item}-${index}`}>{item}</li>;
            })}
          </ol>
        </nav>
      )}
    </>
  );
};

export default OneCrumb;
