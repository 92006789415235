export interface GoGuidesHeaderProps {
  h1: string;
  h2: string;
  image: ImageProps;
  pageType: string;
  pageCategory?: SegmentItem[]
}
export interface GoGuidesIntroTextProps {
  introText: string;
  pageType: string;
}
export interface ImageAttribution {
  photographerName: string;
  linkToOriginal?: string;
  attributionVersion: string;
  linkToAttributionPage: string;
}
export interface ImageProps {
  imageUrl: string;
  attribution?:ImageAttribution;
}

export interface CardDefaultProps {
  name: string;
  description?: string;
  image?: ImageProps;
  targetUrl?: string
}
export interface CardItemProp extends CardDefaultProps {
  snippetText?: string;
  createdDate?: string;
  index?: number;
}

export interface CardItemBucketProps {
  destinationType?: string;
  destinationName?: string;
  pageType?: string;
}

export interface TopDestinationProps extends CardItemBucketProps {
  items?: CardItemProp[]
}

export interface FeaturedStoriesProps extends CardItemBucketProps {
  items?: CardItemProp[]
  categoryCode?: string;
}

export interface CategoryHighLightItemProps extends CardItemBucketProps {
  items?: CardItemProp[]
  categoryCode?: string;
  snippetText?: string;
  seeAllLink?: {
    linkText?: string;
    targetUrl?: string;
  }
}

export interface CategoryHighLightObjectProps {
  categoryData?: CategoryHighLightItemProps
  pageType: string,
  destinationName: string
}

export interface GeoLocationProps {
  coord: {
    lat: string;
    lon: string;
  }
}
export interface SegmentItem {
  segmentCode: string;
  targetUrl: string;
}
export interface GoGuidesListicleItemProps extends CardDefaultProps {
  tagLine?: string;
  info?:{
    location?: string;
    phoneNumber?: string;
    openingHours?: string;
    price?: string;
    geo?: GeoLocationProps;
  }
  hcomGeoId?: string;
  nearbyProperties?: {
    targetUrl: string;
  }
  goodFor?: SegmentItem[];
  segmentCodes?: any;
  categoryCodes?: any;
  tagCodeNames?: any;
  pdpLink?: {
    targetUrl: string;
  }
}
export interface GoGuidesListicleItemBucketProps {
  features?: {
    hideNumber: boolean
  }
  items?: GoGuidesListicleItemProps[];
}
export interface GoGuidesRelatedStoriesItemProps extends CardDefaultProps {
  countryName: string;
  isBlog: boolean;
}
export interface GoGuidesRelatedStoriesProps {
  relatedStories?: GoGuidesRelatedStoriesItemProps[];
  seeAlso?: boolean;
  showSeeAlso?: boolean;
}

export interface GoGuidesKeepExploringProps {
  destinationList?: any,
  isHomePage?: boolean | false;
  worldLevel?: boolean | false;
}
export interface CategoriesItemProps extends CardItemProp {
  categoryCode?: string;
}
export interface OtherCategoriesProps {
  destinationName?: string;
  categoriesData?: {
    category: CategoriesItemProps[]
  };
}
export interface ContributorsStoriesProps {
  storiesData?: {
    storiesList?: CardItemProp[];
    seeAllStoriesLink?: {
      targetUrl: string;
    }
  }
}
export interface GoGuidesAlsoPopularProps {
  parentDestinationName: string;
  destinationData: CardItemProp[];
}
export interface OtherNeighbourhoodsItemProps extends CardItemProp {
  seeAllPropertiesUrl?: string;
}
export interface OtherNeighbourhoodsProps {
  neighbourhoodsData?: {
    neighbourhoodsDestinationName: string;
    neighbourhoodsSeeAllUrl?: string;
    neighbourhoodsList: OtherNeighbourhoodsItemProps[];
  }
}
export interface GoGuidesFreeTextProps {
  freeText: any,
  destinationName: string
}
export interface GoGuidesWhatsAroundProps {
  storiesData?: CardItemProp[];
  destinationName: string;
  travelBlog?: boolean
}

export interface GoGuidesWriterProfileProps {
  writer?: {
    name?: string;
    imageUrl?: string;
    descriptorKey?: string
  }
}

export interface GoGuidesLocationInfoProps {
  info?: {
    location?: string;
    phoneNumber?: string;
    openingHours?: string;
    price?: string;
    title?: string;
  }
  isListicle?: boolean;
}

interface HtmlProp {
  paragraph: string;
}
export interface ContentItemProps {
  image?: ImageProps;
  html?: HtmlProp;
}
export interface GoGuidesLongContentsProps {
  contents: ContentItemProps[];
}

export interface GoGuidesUrlProps {
  domain: string;
  pageUrl: string;
  queryString: string;
  locale: string;
  umbracoLocale: string;
}

export enum PageLayout {
  ONE_COLUMN = 'one-column',
  TWO_COLUMN = 'two-column',
}
