import React from 'react';
import { viewportCheck } from '../../utils/helper';
import { UitkScrim, UitkScrimType } from 'uitk-react-scrim';

interface HeroSkimProps {
  header: string;
}

const HeroSkim: React.FC<HeroSkimProps> = ({ header }) => {
  const currentViewPort = viewportCheck();

  return (
    <>
      {(currentViewPort === 'desktop' || currentViewPort === 'large-desktop') &&
        (header === 'align-center' ? (
          <UitkScrim type={UitkScrimType.OVERLAY} />
        ) : (
          header === 'align-left' && <UitkScrim type={UitkScrimType.BOTTOM} />
        ))}
    </>
  );
};

export default HeroSkim;
