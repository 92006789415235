import React from 'react';
import { CardItemProp } from 'src/components/types/goguides-types.d';
import { RenderImage } from 'src/components/utils/responsive-image';
import { localization } from 'src/utils/localization';
import { UitkPrimaryButton } from 'uitk-react-button';
import { UitkCard, UitkCardLink } from 'uitk-react-cards';
import { UitkFigure, UitkFigureAspectRatioType } from 'uitk-react-images';
import { UitkHeading } from 'uitk-react-text';

interface CardSizeSProps {
  data: CardItemProp
}

const CardSizeS: React.FC<CardSizeSProps> = (props) => {
  const { data } = props
  const { name, targetUrl, snippetText, image, index } = data;

  const imageProps = {
    image: image.imageUrl,
    alt: name,
    params: {
      size: {
        small: '&width=800&height=533',
        medium: '&width=800&height=533'
      }
    }
  };
  const loc = new localization();
  const buttonKey = loc.getLocalizedText('read_more');

  return (
    <li key={index} className='col-12 col-m4'>
      <UitkCard className='item-list-wrapper'>
        <UitkFigure
          className={'img-wrapper'}
          ratio={UitkFigureAspectRatioType.R16_9}
        >
          <RenderImage imgProps={imageProps} />
        </UitkFigure>
        <div className="detail-wrapper">
          <div className="header-wrap">
            <UitkHeading tag="h3">{name}</UitkHeading>
          </div>
          <p className="text-wrap" dangerouslySetInnerHTML={{ __html: snippetText }} />
          <UitkPrimaryButton className="teaser-read-more-btn" tag="button" type="button">
            {buttonKey}
          </UitkPrimaryButton>
        </div>

        <UitkCardLink>
          <a href={targetUrl}></a>
        </UitkCardLink>
      </UitkCard>
    </li>
  );
};

export default CardSizeS;
