import PageLoader from 'components/common/modules/loader/page-loader';
import { checkValue } from 'components/functions';
import { FeaturedStoriesProps } from 'components/types/goguides-types.d';
import React, { useEffect, useState } from 'react';
import { useStores } from 'utils/useStore';
import FeaturedStoriesInner from './featured-stories-inner';
import { EgClickstreamData, EgClickstreamImpressionTracker } from '@shared-ui/clickstream-analytics-context';

const GoGuidesFeaturedStoriesComponent: React.FC = () => {
  const { goGuidesStore } = useStores();
  const contentData = goGuidesStore.fetchFeaturedStories(goGuidesStore.pageUrlData);

  const impressionTrackingObject: EgClickstreamData = {
    event: {
      event_name: 'go_guides_module.presented',
      event_type: 'Impression',
      event_version: '1.0.0',
      event_category: 'go_guides',
    },
    experience: goGuidesStore.ClicksteamExperience,
    module: {
      module_name: 'trending now',
    },
  };

  return (
    <>
      {checkValue(contentData) && (
        <EgClickstreamImpressionTracker eventData={impressionTrackingObject}>
          <FeaturedStoriesInner {...contentData} />
        </EgClickstreamImpressionTracker>
      )}
    </>
  );
};

export default GoGuidesFeaturedStoriesComponent;
