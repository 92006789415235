import React, { useState } from 'react';
import { UitkCarousel, UitkCarouselPaginationDots } from 'uitk-react-carousel';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';

interface CarouselProps {
  data: JSX.Element[];
  config?: any;
  pagination?: boolean;
}

const CarouselComponent: React.FC<CarouselProps> = (props) => {

  const { data, config, pagination } = props;

  const carouselRef = React.useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const track = useEgClickstream();
  const trackObject = {
    event: {
      event_version: '1.0.0',
      event_type: 'Interaction',
      event_name: 'more_cards.presented',
      event_category: 'go_guides',
      action_location: config.carouselName,
    },
    experience: config.experience,
  };

  const trackScrollRight = (id) => {
    if (id.match(/\.scrollRight$/)) {
      track(trackObject);
    }
  }

  const setupTracker = (id, description) => {
    const callback = trackScrollRight;
    return {
      callback,
      description,
      id,
    };
  };

  const onIndexChange = (index) => {
    setCurrentIndex(index);
  };

  const analytics = setupTracker('UITK', 'Carousel Interaction');

  const defaultConfig = {
    analytics,
    carouselName: 'DefaultCarousel',
    carouselHeaderText: 'Default Carousel Header',
    itemsMaxHeight: true,
    itemsVisible: {
      sm: 2,
      md: 3,
      lg: 4,
      xl: 4,
    },
    pageBy: 4,
    peek: false,
    wrapItems: true,
    shouldSnapOnScroll: true,
    onIndexChange,
    navigationIconId: {
      next: 'next-button-gallery-inset-pagination-dots',
      prev: 'prev-button-gallery-inset-pagination-dots',
    },
    buttonText: {
      nextButton: 'Show next card',
      prevButton: 'Show previous card',
    },
  };

  const configProps = {
    ...defaultConfig,
    ...config,
  };

  const cards = data;
  
  return (
    <>
      <UitkCarousel {...configProps} carouselRef={carouselRef}>
        {cards}
      </UitkCarousel>

      {pagination &&
       carouselRef.current && 
       carouselRef.current.changeIndex &&
       cards.length > configProps.pageBy && (
        <UitkCarouselPaginationDots
          activeIndex={currentIndex}
          itemCount={cards.length}
          changeIndex={carouselRef.current.changeIndex}
        />
      )}
    </>
  );
};

export default CarouselComponent;
