import React, { useRef } from 'react';
import { useLocalization } from '@shared-ui/localization-context';
import { UitkEGMaps, UitkEGMapsType, UitkEGMapsQualifier } from 'uitk-react-map';
import { UitkSheet } from 'uitk-react-sheet';
import { UitkToolbar } from 'uitk-react-toolbar';
import { GeoLocationProps } from 'src/components/types/goguides-types.d';
import { UitkLink } from 'uitk-react-link';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { checkValue } from 'src/components/functions';
import { useStores } from 'utils/useStore';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { EGMapConfiguration, FeatureObject, Viewport } from '@eg-maps/core';

interface MapComponentProps {
  name: string;
  i18nKey?: string;
  geo: GeoLocationProps;
  openMap?: boolean;
}

const MapComponent: React.FC<MapComponentProps> = ({ name, i18nKey, geo, openMap = false }) => {
  const [isGoogleMapOpen, setIsGoogleMapOpen] = React.useState(false);
  const { goGuidesStore, context } = useStores();
  const locale = context.locale;
  const track = useEgClickstream();
  const trackObject = {
    event: {
      event_version: '1.0.0',
      event_type: 'Outcome',
      event_name: 'go_guides_map.opened',
      event_category: 'go_guides',
    },
    experience: goGuidesStore.ClicksteamExperience,
  };

  const openGoogleMap = () => {
    setIsGoogleMapOpen(true);
    track(trackObject);
  };

  const closeGoogleMap = () => {
    setIsGoogleMapOpen(false);
  };

  const { formatText } = useLocalization();

  const toolBarAttributesGoogle = {
    navigationContent: {
      onClick: closeGoogleMap,
      navType: 'close',
      navIconLabel: formatText('module.map.close.a11yLabel'),
    },
    toolbarTitle: name,
  };

  const cfg: EGMapConfiguration = React.useMemo(
    () => ({
      locale: locale,
      markers: {
        property: {
          markerType: {
            portOfCall: 'text',
          },
        },
        place: {
          textLabel: {
            show: {
              default: 'always',
            },
          },
          markerType: {
            customQualifier: 'text'
          },
        },
      },
      "googleMaps": {
        "gmapsAddConfig": {
          "fullscreenControl": true,
          "streetViewControl": true,
          "streetViewControlOptions": {
            "position": 9
          },
          "zoomControlOptions": {
            "position": 9
          },
          "MapTypeId": "terrain",
          "mapTypeControl": true,
          "mapTypeControlOptions": {
            "MapTypeId": ["terrain"]
          }
        }
      }
    }),
    [],
  );
  const features: FeatureObject[] = [
    {
      id: 'POI',
      type: 'place',
      lat: Number(geo.coord?.lat),
      lng: Number(geo.coord?.lon),
      name: name,
      status: 'unknown',
      qualifiers: [UitkEGMapsQualifier.PINNED],
    },
  ];
  const initialViewport: Viewport = React.useMemo(
    () => ({ center: { lat: Number(geo.coord?.lat), lng: Number(geo.coord?.lon) }, zoom: 16 }),
    [geo.coord],
  );

  const googleToken = 'AIzaSyAxvbW1mVZ1eFyl6VQQ_0j2couckwy95z4';

  const egMapsGoogleComponent = (
    <UitkEGMaps
      accessToken={googleToken}
      mapType={UitkEGMapsType.GOOGLE}
      mapConfig={cfg}
      features={features}
      initialViewport={initialViewport}
    />
  );

  const googleMapTriggerRef = useRef<HTMLAnchorElement | null>(null);
  const sheetAttributes = {
    triggerRef: googleMapTriggerRef,
  };

  const withFullscreen = (component: any, toolBarAttributes: any, sheetAttributes: any) => (
    <div className={'egds-eg-maps'} style={{ zIndex: 600, position: 'fixed', left: 0 }}>
      <UitkSheet isVisible type="full" {...sheetAttributes}>
        <UitkToolbar {...toolBarAttributes} />
        {component}
      </UitkSheet>
    </div>
  );

  return (
    <>
      {checkValue(openMap) ? (
        <div className="map-container">
          <UitkEGMaps
            accessToken={googleToken}
            mapType={UitkEGMapsType.GOOGLE}
            mapConfig={cfg}
            features={features}
            initialViewport={initialViewport}
          />
        </div>
      ) : (
        <>
          <UitkLink inline={true} className="map-button">
            <a onClick={openGoogleMap} ref={googleMapTriggerRef}>
              <div className="map-wrapper">
                <span>
                  <UitkIcon className="icon" name="place" size={UitkIconSize.EXTRA_SMALL} />
                  {i18nKey}
                </span>
              </div>
            </a>
          </UitkLink>

          {isGoogleMapOpen &&
            googleToken &&
            withFullscreen(egMapsGoogleComponent, { ...toolBarAttributesGoogle }, sheetAttributes)}
        </>
      )}
    </>
  );
};
export default MapComponent;
