export default {
    "current_deal": "Oferta atual",
    "lbg.section_properties_theme.pet_friendly": "Vencedores na categoria \"Amigo dos animais\" em {0}",
    "address": "Endereço",
    "hub.homepage.h2": "Tantos locais para visitar, tão pouco tempo...",
    "search_hotels_near_this_landmark": "Procurar hotéis<span class=\"extra-label\"> perto deste ponto de referência</span>",
    "hub.category.h1.things_to_do": "Atividades e pontos de interesse em {0}",
    "lbg.h2.vip_access": "Os estabelecimentos VIP Access destacam-se pelas suas ótimas avaliações e o seu serviço de excelência.",
    "lbg.theme.cleanliness": "Limpeza",
    "lbg.theme.lgbtq": "LGBTQ",
    "load_more": "Carregar mais",
    "link_long_form_to_listiicle_multiple": "{0}: este ponto de interesse aparece em destaque nos seguintes artigos: “{1}” e “{2}”. Aceda à página de {3} para obter mais informações sobre este destino.",
    "lbg.section_properties_theme.boutique": "Vencedores na categoria \"Hotéis boutique\" em {0}",
    "lbg.h2.luxury": "Procura uma estadia de luxo? Estes estabelecimentos muito apreciados pelos clientes são a escolha perfeita para si.",
    "hub.category.h1.information": "Informações sobre {0}",
    "families": "Famílias",
    "trending_now": "Tendências atuais",
    "lbg.from": "Desde",
    "hub.category.h1.road_trips": "Faça-se à estrada em {0}",
    "lbg.h2.cleanliness": "Estes estabelecimentos foram muito bem avaliados pelos clientes, especialmente ao nível da limpeza.",
    "single": "Solteiros",
    "couples": "Casais",
    "trust_module.price_guarantee.find_out_more": "mais informações",
    "top_destinations_in": "Destinos mais populares em {0}",
    "hub.category.h1.couples": "Viagens a dois em {0}",
    "hub.category.h2.tours": "As melhores excursões em {0}",
    "hub.category.h1.photos": "Viagens para tirar fotografias em {0}",
    "category.photos": "Fotografias",
    "hub.category.h1.budget": "Viagens económicas em {0}",
    "hub.category.h2.ski": "Os melhores destinos de esqui em {0}",
    "lbg.section_theme_world_winners.most_wanted": "Vencedores na categoria \"Os mais procurados\"",
    "lbg.theme.home_and_apartments": "Casas e apartamentos",
    "hub.category.h1.luxury": "Viagens de luxo em {0}",
    "hub.category.h2.adventure": "As melhores viagens de aventura em {0}",
    "hotels_in": "Hotéis em {0}",
    "lbg.section_search_winners": "Pesquisar mais vencedores",
    "see_more_hotels_in": "Ver mais hotéis em {0}",
    "hub.category.h1.beach": "Praias em {0}",
    "open": "Horário de funcionamento:",
    "enter_your_destination": "Introduza o seu destino",
    "best_of": "O melhor de {0}",
    "top10_hotels_missing_properties": "Alguns estabelecimentos podem não estar a ser exibidos. Pedimos desculpa pelo incómodo.",
    "adventure": "Aventura",
    "lbg.h1.destination_theme.sustainable": "Vencedores na categoria \"Sustentabilidade\" em {0}",
    "luxury": "Luxo",
    "expand_map_view": "Expandir vista de mapa",
    "book_now": "Reservar agora",
    "lbg.h1.destination_theme.ski": "Vencedores na categoria \"Esqui\" em {0}",
    "hub.category.h1.food": "Gastrononomia em {0}",
    "category.blogs": "Blog",
    "budget": "Económico",
    "popular_neighbourhoods_in": "Áreas envolventes populares em {0}",
    "hub.category.h1.unusual": "Tesouros escondidos e locais incomuns em {0}",
    "see_all_accommodation_in": "Ver alojamentos em {0}",
    "hub.category.h1.adventure": "Viagens de aventura em {0}",
    "category_highlight.see_all.shopping": "Mais informações sobre compras",
    "lbg.section_most_popular_destination": "Destinos mais populares",
    "lbg.section_properties_theme.luxury": "Vencedores na categoria \"Luxo\" em {0}",
    "unusual": "Invulgar",
    "category.shopping": "Compras",
    "make_the_most_of_your_trip": "Aproveite a sua viagem ao máximo",
    "x_review": "{0,choice,0#|1#{0} avaliação|2#{0} avaliações|2<{0} avaliações|4<{0} avaliações|10#{0} avaliações|10<{0} avaliações}",
    "hub.category.h1.single": "Viagens a sós em {0}",
    "hub.category.h2.custom": "Tendências atuais",
    "lbg.section_theme_world_winners.lgbt": "Vencedores na categoria \"LGBTQ+\"",
    "lbg.section_award_winners_theme_other": "Vencedores noutras categorias em {0}",
    "lbg.supplier_collected_charges": "+ {0} de taxas a pagar no estabelecimento",
    "in_destination": " ({0})",
    "lbg.section_properties_theme.vip_access": "Vencedores na categoria \"VIP Access\" em {0}",
    "link_long_form_to_listiicle_single": "{0}: este ponto de interesse aparece em destaque neste artigo: “{1}”. Aceda à página de {2} para obter mais informações sobre este destino.",
    "lbg.section_theme_world_winners.boutique": "Vencedores na categoria \"Hotéis boutique\"",
    "lbg.section_properties_theme.spa_hotel": "Vencedores na categoria \"Spa\" em {0}",
    "lbg.section_theme_world_winners.all_inclusive": "Vencedores na categoria \"Tudo incluído\"",
    "hub.category.h2": "Guia de viagem de {0}",
    "lbg.properties.price_tooltip": "Este é o preço mais baixo por noite no nosso website para os próximos 14 dias.",
    "hub.category.h2.road_trips": "As nossas recomendações para explorar {0} de carro",
    "hub.category.h1.ski": "Destinos de esqui em {0}",
    "food": "Comida",
    "lbg.theme.pet_friendly": "Amigo dos animais",
    "lbg.theme.business": "Negócios",
    "hub.category.h1.nature": "Escapadinhas para os amantes da natureza em {0}",
    "map": "Mapa",
    "category_highlight.see_all.information": "Mais informações",
    "hotel_prices_in": "Preços de hotel em {0}",
    "hub.category.h1.nightlife": "Vida noturna em {0}",
    "trust_module.free_cancellation.most_hotels": "na maioria dos hotéis*",
    "also_popular_in": "Outros destinos populares em {0}",
    "lbg.h1.destination_theme.business": "Vencedores na categoria \"Negócios\" em {0}",
    "lbg.section_theme_world_winners.pet_friendly": "Vencedores na categoria \"Amigo dos animais\"",
    "hub.category.h1.maps": "Mapas de {0}",
    "lbg.section_properties_theme.most_wanted": "Vencedores na categoria \"Os mais procurados\" em {0}",
    "category.nightlife": "Diversão noturna",
    "search_hotels_in_this_area": "Procurar hotéis<span class=\"extra-label\"> nesta área</span>",
    "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 voto|2#2 votos|2<{1} votos|4<{1} votos|10#10 votos|10<{1} votos})",
    "hub.category.h2.business": "As melhores viagens de negócios em {0}",
    "category.things_to_do": "Pontos de interesse e atrações",
    "destination_travel_guide_by_category": "{0} – guia de viagem por categoria",
    "hub.category.h1.families": "Viagens em família em {0}",
    "lbg.section_theme_world_winners.ski": "Vencedores na categoria \"Esqui\"",
    "you_may_also_like": "Também poderá gostar destes artigos",
    "lbg.theme.sustainable": "Sustentabilidade",
    "hub.category.h2.unusual": "Os melhores tesouros escondidos e locais incomuns em {0}",
    "hub.category.h2.football": "Apoie a sua equipa de futebol e passe umas férias inesquecíveis!",
    "top_hotel": "Hotel de topo",
    "lbg.h1.destination_theme.luxury": "Vencedores na categoria \"Luxo\" em {0}",
    "top10_hotels_empty_alert": "Estamos atualmente a debater-nos com problemas técnicos. <span>Pedimos desculpa pelo incómodo.</span>",
    "special_deal": "Oferta especial",
    "lbg.h2.sustainable": "Procura um alojamento sustentável? Espreite estes estabelecimentos e desfrute de uma estadia inesquecível.",
    "where_to_stay_in": "Onde ficar <span class=\"next-line\">em {0}</span>",
    "start_planning_your_trip": "Comece a planear a sua viagem",
    "good_for": "Ideal para:",
    "category.hotels": "Hotéis",
    "x_accommodation_guide": "Guia de alojamentos em {0}",
    "lbg.theme.luxury": "Luxo",
    "hub.category.h2.sports": "Desportos e atividades mais populares em {0}",
    "lbg.section_theme_world_winners.lgbtq": "Vencedores na categoria “LGBTQ”",
    "see_more_neighbourhoods": "Ver mais áreas envolventes",
    "category_highlight.title.nightlife": "Diversão noturna em {0}",
    "lbg.h1.destination_theme.lgbt": "Vencedores na categoria \"LGBTQ+\" em {0}",
    "hub.category.h1.blogs": "Blog de viagens de {0}",
    "lbg.h2.business": "Estes estabelecimentos receberam boas avaliações de viajantes de negócios.",
    "hotels": "Hotéis",
    "lbg.h2.pet_friendly": "Estes estabelecimentos são altamente recomendados pelos clientes que viajam com animais de estimação.",
    "popular_landmarks_nearby": "Pontos de interesse populares nas imediações",
    "popular_extractions_in": "Atrações populares em {0}",
    "lbg.h2.all_inclusive": "Estes estabelecimentos com ótimas avaliações são perfeitos para relaxar.",
    "travel_guide_by_category_header": "{1} – {0}",
    "lbg.travel_between": "Viaje entre {0} e {1}",
    "keep_exploring": "Explore outros destinos",
    "what_you_need_to_know_before_you_go": "Informação importante antes de partir",
    "read_it": "Ler mais",
    "view_on_map": "Ver no mapa",
    "lbg.h1.destination_theme.lgbtq": "Vencedores na categoria “LGBTQ” em {0}",
    "lbg.h2.beach": "Os clientes adoram umas férias à beira-mar, sobretudo nestes estabelecimentos.",
    "lbg.h1.destination_theme.vip_access": "Vencedores na categoria \"VIP Access\" em {0}",
    "see_all_accommodation": "Ver todos os alojamentos",
    "shoppers": "Compras",
    "whats_around": "atrações nas imediações de {0}",
    "group": "Grupos",
    "hub.category.h2.couples": "As melhores viagens a dois em {0}",
    "hub.category.h1.shopping": "Compras em {0}",
    "travel_community": "{0} <span>&#124;</span> <span>Comunidade de viajantes</span>",
    "hub.category.h2.group": "As melhores viagens em grupo em {0}",
    "listicle_legal_msg": "Este artigo inclui opiniões da equipa editorial dos Go Guides. O Hoteis.com atribui uma compensação aos autores dos textos publicados neste site. Esta compensação serve para cobrir despesas de viagem e outros encargos.",
    "lbg.h1.destination_theme.cleanliness": "Vencedores na categoria “Limpeza” em {0}",
    "menu.more": "Mais",
    "lbg.section_properties_theme.lgbtq": "Vencedores na categoria “LGBTQ” em {0}",
    "lbg.section_award_winners_destination": "Vencedores por destino",
    "lbg.terms_conditions": "Termos e Condições",
    "hub.category.h2.nightlife": "Os melhores bares e discotecas em {0}",
    "hub.neighbourhood.h2": "Atividades em {0}",
    "lbg.section_award_winners_theme_destination": "Vencedores por categoria em {0}",
    "lbg.section_award_world_winners": "Vencedores a nível mundial",
    "things_to_see_and_do_in_other_cities": "Atrações e atividades noutras cidades",
    "button.backToTop": "Voltar ao início",
    "lbg.section_theme_world_winners.family": "Vencedores na categoria \"Família\"",
    "hotels_near": "Hotéis perto de {0}",
    "category.activity__sports_": "Atividades",
    "trust_module.get_reward_night": "Receba uma noite de recompensa",
    "hub.category.h1.business": "Viagens de negócios em {0}",
    "category.food": "Comida",
    "other_popular_destinations_in": "Outros destinos populares em {0}",
    "browse_by_category": "Explorar {0} por categoria",
    "see_more_attactions": "Ver mais atrações",
    "lbg.theme.lgbt": "LGBTQ+",
    "more_information_about": "Mais informações sobre {0}",
    "lbg.h1.destination_theme.most_wanted": "Vencedores na categoria \"Os mais procurados\" em {0}",
    "see_all_properties_in": "Ver todos os estabelecimentos em {0}",
    "category.tours": "Excursões",
    "hub.category.h1.sports": "Desportos e atividades em {0}",
    "where_will_you_go_next": "Já escolheu o seu próximo destino?",
    "cities": "Cidades",
    "lbg.theme.spa_hotel": "Spa",
    "see_guide": "Ver guia",
    "lbg.section_properties_theme.ski": "Vencedores na categoria \"Esqui\" em {0}",
    "related_stories": "Histórias relacionadas",
    "hub.destination.h2": "Informação importante antes de partir",
    "show_less": "Exibir menos",
    "hub.category.h1.football": "Guia imperdível de {0} para os adeptos de futebol",
    "hub.category.h2.food": "Os melhores pratos e restaurantes em {0}",
    "fallback_language_alert": "Lamentamos, mas esta página ainda não foi traduzida no seu idioma...",
    "category_highlight.title.information": "Informações úteis",
    "neighbourhood_guides_for_other_cities": "Guias de áreas envolventes para outras cidades",
    "lbg.h2.hubpage": "Estes estabelecimentos fantásticos contam com uma pontuação elevada dos clientes e muitas avaliações.",
    "travel_advisory.title": "Alerta de viagem",
    "lbg.section_properties_theme.all_inclusive": "Vencedores na categoria \"Tudo incluído\" em {0}",
    "lbg.h2.boutique": "Se aprecia alojamentos “boutique”, dê uma vista de olhos a estes estabelecimentos muito recomendados.",
    "lbg.section_properties_theme.family": "Vencedores na categoria \"Família\" em {0}",
    "hub.category.h2.families": "As melhores viagens em família em {0}",
    "from": "desde",
    "lbg.theme.vip_access": "VIP Access",
    "lbg.section_properties_theme.lgbt": "Vencedores na categoria \"LGBTQ+\" em {0}",
    "x_star": "{0,choice,0#|1#{0} estrela|1.5#{0} estrelas|2#{0} estrelas|2.5#{0} estrelas|3#{0} estrelas|3.5#{0} estrelas|4#{0} estrelas|4.5#{0} estrelas|5#{0} estrelas}",
    "trust_module.free_cancellation": "Cancelamento gratuito",
    "lbg.section_properties_theme.home_and_apartments": "Vencedores na categoria \"Casas e apartamentos\" em {0}",
    "contributing_writer": "{0} <span>&#124;</span> <span> Autor(a)</span>",
    "things_to_see_and_do_in": "Atrações e atividades <span class=\"next-line\">em {0}</span>",
    "lbg.check_price": "Ver preço",
    "hub.category.h2.blogs": "por viajantes experientes",
    "lbg.h2.home_and_apartments": "Procura um lugar acolhedor enquanto viaja? Dê uma vista de olhos a estes estabelecimentos muito bem avaliados.",
    "hub.category.h2.information": "Informações úteis sobre {0}",
    "search_hotels_in": "Procurar hotéis<span class=\"extra-label\"> em {0}</span>",
    "hub.category.h2.nature": "As nossas recomendações para apreciar a beleza natural de {0}",
    "lbg.h1.destination_theme.home_and_apartments": "Vencedores na categoria \"Casas e apartamentos\" em {0}",
    "photo_by": "fotografia de {0}",
    "more_things_to_do": "Mais atividades e pontos de interesse",
    "lbg.section_properties_theme.beach": "Vencedores na categoria \"Praia\" em {0}",
    "read_less": "Ler menos",
    "lbg.h2.spa_hotel": "Aproveite para descontrair nestes estabelecimentos com boas avaliações e populares pelos seus spas.",
    "second_fold_related_stories.see_also": "Ver igualmente",
    "hub.category.h2.history": "Os melhores locais históricos em {0}",
    "lbg.section_properties_theme.cleanliness": "Vencedores na categoria “Limpeza” em {0}",
    "lbg.h1.destination_theme.boutique": "Vencedores na categoria \"Hotéis boutique\" em {0}",
    "nightlife_b595ae4": "Diversão noturna",
    "lbg.h1.destination_theme.beach": "Vencedores na categoria \"Praia\" em {0}",
    "trust_module.free_cancellation.disclaimer": "* Alguns hotéis exigem aos clientes que efetuem o cancelamento, pelo menos, 24 a 48 horas antes do check-in. Aceda ao nosso site para obter mais informações.",
    "lbg.h2.family": "Estes estabelecimentos receberam boas avaliações dos clientes e são perfeitos para famílias.",
    "lbg.h2.lgbtq": "Estes estabelecimentos LGBTQ foram muito bem avaliados pelos clientes.",
    "hub.category.h1.group": "Viagens em grupo em {0}",
    "popular_stories": "Artigos populares",
    "toc": "Índice",
    "hub.category.h2.shopping": "As melhores lojas em {0}",
    "lbg.section_theme_world_winners.beach": "Vencedores na categoria \"Praia\"",
    "page_score": "A pontuação desta página",
    "lbg.section_theme_world_winners.sustainable": "Vencedores na categoria \"Sustentabilidade\"",
    "lbg.theme.boutique": "Boutique",
    "trust_module.price_guarantee": "A nossa Garantia de Preço",
    "lbg.h1.destination_theme.all_inclusive": "Vencedores na categoria \"Tudo incluído\" em {0}",
    "top_destinations": "Principais destinos",
    "hotel_prices_near": "Preços de hotel perto de {0}",
    "lbg.score.cleanliness": "Limpeza:",
    "x_stars": "{0,choice,0#|1#{0} estrela|1<{0} estrelas|4<{0} estrelas|10<{0} estrelas}",
    "lbg.theme.all_inclusive": "Tudo incluído",
    "hub.category.h1.tours": "Visitas turísticas e excursões de um dia em {0}",
    "lbg.section_award_winners_theme": "Vencedores por categoria",
    "guest_writer": "{0} <span>&#124;</span> <span>Autor(a) convidado(a)</span>",
    "start_planning_your_x_trip": "Comece a planear a sua viagem para {0}",
    "lbg.section_theme_world_winners.business": "Vencedores na categoria \"Negócios\"",
    "trending_now_in": "A não perder em {0}",
    "history": "Interesse histórico",
    "search_hotels": "Procurar hotéis",
    "lbg.terms_conditions_details": "Os critérios para selecionar os estabelecimentos vencedores do prémio em {0} basearam-se nas pontuações elevadas atribuídas pelos clientes em {1} e num mínimo de 50 avaliações recebidas.",
    "see_nearby_properties": "Ver estabelecimentos nas imediações",
    "hub.category.h1.history": "Locais históricos em {0}",
    "location": "Localização:",
    "lbg.section_theme_world_winners.luxury": "Vencedores na categoria \"Luxo\"",
    "category_highlight.see_all.nightlife": "Mais informações sobre diversão noturna",
    "also_popular": "Outros destinos populares",
    "hub.category.h2.things_to_do": "As melhores atrações e atividades em {0}",
    "lbg.theme.family": "Família",
    "lbg.h1.destination_theme.pet_friendly": "Vencedores na categoria \"Amigo dos animais\" em {0}",
    "category_highlight.title.shopping": "Compras em {0}",
    "price": "Preço:",
    "hub.category.h2.beach": "As melhores praias em {0}",
    "neighbourhoods_in": "bairros em {0}",
    "business": "Negócios",
    "photo": "Fotografia",
    "blog.see_all_stories": "Ver todos os artigos",
    "category_highlight.see_all.food": "Mais informações sobre gastronomia e restaurantes",
    "see_all_properties": "Ver todos os estabelecimentos",
    "lbg.theme.beach": "Praia",
    "what_to_see_and_do_in": "Atrações em {0}",
    "hub.category.h2.single": "As melhores viagens a sós em {0}",
    "attractions_in": "Atrações em {0}",
    "read_more": "Ler mais",
    "show_more": "Exibir mais",
    "hotels_near_this_landmark": "Hotéis perto deste ponto de referência",
    "check_dates": "Verificar datas",
    "featured_stories_and_fun_stuff": "Artigos em destaque e atividades interessantes",
    "phone": "Telefone:",
    "lbg.section_search_header": "Pesquisar vencedores do prémio",
    "category_highlight.title.food": "Gastronomia e restaurantes em {0}",
    "see_all_things_to_do": "Ver todas as atrações",
    "lbg.section_properties_theme.sustainable": "Vencedores na categoria \"Sustentabilidade\" em {0}",
    "lbg.h1.hubpage": "Vencedores do prémio \"Loved by Guests\" de {0}",
    "lbg.section_theme_world_winners.spa_hotel": "Vencedores na categoria \"Spa\"",
    "category.maps": "Mapas",
    "lbg.h1.destination_theme.family": "Vencedores na categoria \"Família\" em {0}",
    "lbg.section_theme_world_winners.cleanliness": "Vencedores na categoria “Limpeza”",
    "lbg.section_theme_world_winners.vip_access": "Vencedores na categoria \"VIP Access\"",
    "trust_module.get_reward_night.night_you_stay": "por cada 10 noites de estadia",
    "modified": "modificado",
    "lbg.section_properties": "Vencedores em {0}",
    "trust_module.header": "Porquê reservar no Hoteis.com?",
    "hub.category.h2.luxury": "As melhores viagens de luxo em {0}",
    "lbg.theme.ski": "Esqui",
    "lbg.h1.destination_theme.spa_hotel": "Vencedores na categoria \"Spa\" em {0}",
    "lbg.h2.most_wanted": "Os clientes recomendam estes estabelecimentos porque oferecem estadias de qualidade.",
    "top10_hotels_incomplete_alert": "Estamos atualmente a lidar com um elevado número de procuras, pelo que alguns estabelecimentos podem não estar a ser exibidos. <span>Pedimos desculpa pelo incómodo.</span>",
    "compulsive_traveller": "{0} <span>&#124;</span> <span>Viajante Compulsivo</span>",
    "lbg.section_theme_world_winners.home_and_apartments": "Vencedores na categoria \"Casas e apartamentos\"",
    "loved_by_guests_collection_in": "Coleção \"Adorado pelos nossos clientes\" em {0}",
    "title.suffix": "{0} – Go Guides",
    "hub.category.h2.maps": "Mapas úteis de {0}",
    "opening_hours": "Horário de funcionamento",
    "hub.category.h2.photos": "As melhores viagens para tirar fotografias em {0}",
    "lbg.theme.most_wanted": "Os mais procurados",
    "blog.stories_container": "Artigos dos nossos colaboradores",
    "hub.category.h2.budget": "As melhores viagens económicas em {0}",
    "lbg.section_properties_theme.business": "Vencedores na categoria \"Negócios\" em {0}",
    "not_sure_where_to_stay": "Não sabe onde ficar?",
    "category.information": "Informações"
}
