import { checkValue } from 'components/functions';
import React, { useState } from 'react';
import { UitkExpandoPeek } from 'uitk-react-expando';
import { useStores } from 'utils/useStore';
import { localization } from 'src/utils/localization';
import { Viewport, ViewLarge, ViewSmall } from "@shared-ui/viewport-context";

const GoGuidesIntroComponent: React.FC = () => {
  const { goGuidesStore } = useStores();

  const [isVisible, setIsVisible] = useState(false);
  const contentData = goGuidesStore.fetchIntrotext(goGuidesStore.pageUrlData);
  if (!checkValue(contentData)) {
    return null;
  }

  const loc = new localization();

  const introText = checkValue(contentData.introText) ? contentData.introText : null;
  const pageType = checkValue(contentData.pageType) ? contentData.pageType : '';

  return (
    <>
      {introText &&
        (pageType === 'longform' ? (
          <div className="intro-container">
            <div className="intro-inner" dangerouslySetInnerHTML={{ __html: introText }} />
          </div>
        ) : pageType === 'listicle' ? (
          <div className="intro-container">
            <Viewport>
              <ViewSmall>
                <UitkExpandoPeek
                  isVisible={isVisible}
                  handleClick={() => setIsVisible((prevIsVisible) => !prevIsVisible)}
                  lines={6}
                  whitespace={false}
                  expandLabel={loc.getLocalizedText('show_more')}
                  collapseLabel={loc.getLocalizedText('show_less')}
                >
                  <div className="intro-inner" dangerouslySetInnerHTML={{ __html: introText }} />
                </UitkExpandoPeek>
              </ViewSmall>
              <ViewLarge>
                <div className="intro-inner" dangerouslySetInnerHTML={{ __html: introText }} />
              </ViewLarge>
            </Viewport>
          </div>
        ) : (
          <>
            <section className="intro">
              <div className="container-wrap row">
                <div className="row-inner col-12">
                  <div className="intro-container">
                    <div className="expandable-wrapper">
                      <Viewport>
                        <ViewSmall>
                          <UitkExpandoPeek
                            isVisible={isVisible}
                            handleClick={() => setIsVisible((prevIsVisible) => !prevIsVisible)}
                            lines={6}
                            whitespace={false}
                            expandLabel={loc.getLocalizedText('show_more')}
                            collapseLabel={loc.getLocalizedText('show_less')}
                          >
                            <div className="intro-inner" dangerouslySetInnerHTML={{ __html: introText }} />
                          </UitkExpandoPeek>
                        </ViewSmall>
                        <ViewLarge>
                          <div className="intro-inner" dangerouslySetInnerHTML={{ __html: introText }} />
                        </ViewLarge>
                      </Viewport>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        ))}
    </>
  );
};

export default GoGuidesIntroComponent;
