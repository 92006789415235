import PageLoader from 'components/common/modules/loader/page-loader';
import { checkValue } from 'components/functions';
import { GoGuidesLocationInfoProps } from 'components/types/goguides-types.d';
import React, { useEffect, useState } from 'react';
import { UitkHeading, UitkParagraph, UitkText } from 'uitk-react-text';
import { useStores } from 'utils/useStore';
import MapComponent from '../generic/maps';
import { getLocalizedText } from 'src/utils/helper';
import { UitkSpacingHr } from 'uitk-react-spacing';

const GoGuidesLocationInfoComponent: React.FC<GoGuidesLocationInfoProps> = props => {
  const { goGuidesStore } = useStores();
  const { isListicle } = props;

  let info;
  if (!checkValue(props.info)) {
    info = {
      location: null,
      phoneNumber: null,
      openingHours: null,
      price: null,
      title: null,
    };
  }

  let contentData;

  if (isListicle) {
    contentData = props;
  } else {
    contentData = goGuidesStore.fetchLocationInfo(goGuidesStore.pageUrlData);
  }
  if (checkValue(contentData)) {
    info = contentData.info;
  }

  const checkInfo = () => {
    const { location, openingHours, phoneNumber, price } = info;
    return checkValue(location) || checkValue(openingHours) || checkValue(phoneNumber) || checkValue(price);
  };

  const renderInfoItem = (property: string, label: string) => {
    if (info && info[property]) {
      return (
        <UitkParagraph size={1}>
          <UitkText inline="strong">{label} </UitkText>
          {info[property]}
        </UitkParagraph>
      );
    }
    return null;
  };

  return (
    <>
      {checkValue(info) && (
        <div className="info-bullet-wrap">
          <UitkHeading tag="h3">{info.title}</UitkHeading>
          {renderInfoItem('location', getLocalizedText('location'))}
          {renderInfoItem('openingHours', getLocalizedText('open'))}
          {renderInfoItem('phoneNumber', getLocalizedText('phone'))}
          {renderInfoItem('price', getLocalizedText('price'))}
        </div>
      )}
      {checkValue(info.geo) && checkValue(!isListicle) && (
        <MapComponent name={info.title} geo={info.geo} openMap={true} />
      )}
    </>
  );
};

export default GoGuidesLocationInfoComponent;
