export default {
    "current_deal": "Pris just nu",
    "lbg.section_properties_theme.pet_friendly": "Vinnare i kategorin Husdjursvänligt för {0}",
    "address": "Adress",
    "hub.homepage.h2": "Så mycket att se, så lite tid...",
    "search_hotels_near_this_landmark": "Sök hotell<span class=\"extra-label\"> nära det här landmärket</span>",
    "hub.category.h1.things_to_do": "Saker att göra för {0}",
    "lbg.h2.vip_access": "VIP Access-boenden har valts ut för sina höga betyg och exklusiva upplevelser.",
    "lbg.theme.cleanliness": "Renlighet",
    "lbg.theme.lgbtq": "HBTQ",
    "load_more": "Se mer",
    "link_long_form_to_listiicle_multiple": "{0} - en av höjdpunkterna från {1} och {2} (Läs allt om {3} här)",
    "lbg.section_properties_theme.boutique": "Vinnare i kategorier Boutiquehotell för {0}",
    "lbg.h2.luxury": "Upptäck lyx när den är som bäst. Det är därför gäster älskar de här boendena.",
    "hub.category.h1.information": "Information om {0}",
    "families": "Familjer",
    "trending_now": "Trendigt nu",
    "lbg.from": "Från",
    "hub.category.h1.road_trips": "Bäst roadtrips i {0}",
    "lbg.h2.cleanliness": "Gästerna har gett dessa boenden höga övergripande betyg och toppbetyg för renlighet.",
    "single": "Singlar",
    "couples": "Par",
    "trust_module.price_guarantee.find_out_more": "Läs mer",
    "top_destinations_in": "Toppdestinationer i {0}",
    "hub.category.h1.couples": "Parresor i {0}",
    "hub.category.h2.tours": "De bästa turerna runt {0}",
    "hub.category.h1.photos": "Fotovänliga resor i {0}",
    "category.photos": "Foton",
    "hub.category.h1.budget": "Budgetresor i {0}",
    "hub.category.h2.ski": "De bästa skiddestinationerna i {0}",
    "lbg.section_theme_world_winners.most_wanted": "Vinnare i kategorin Mest efterfrågat",
    "lbg.theme.home_and_apartments": "Hem och lägenheter",
    "hub.category.h1.luxury": "Lyxresor i {0}",
    "hub.category.h2.adventure": "De bästa aktiva resorna i {0}",
    "hotels_in": "Hotell i {0}",
    "lbg.section_search_winners": "Sök efter fler boenden som vunnit utmärkelser",
    "see_more_hotels_in": "Se fler hotell för {0}",
    "hub.category.h1.beach": "Stränder i {0}",
    "open": "Öppettider:",
    "enter_your_destination": "Ange din destination",
    "best_of": "Det bästa i {0}",
    "top10_hotels_missing_properties": "Vissa boenden kan inte visas just nu. Vi beklagar besväret.",
    "adventure": "Äventyr",
    "lbg.h1.destination_theme.sustainable": "Vinnare i kategorin Hållbarhet för {0}",
    "luxury": "Lyx",
    "expand_map_view": "Större karta",
    "book_now": "Boka nu",
    "lbg.h1.destination_theme.ski": "Vinnare i kategorin Skidåkning för {0}",
    "hub.category.h1.food": "Mat och dryck för {0}",
    "category.blogs": "Blogg",
    "budget": "Budget",
    "popular_neighbourhoods_in": "Populära områden i {0}",
    "hub.category.h1.unusual": "Smultronställen och udda platser i {0}",
    "see_all_accommodation_in": "Se boenden i {0}",
    "hub.category.h1.adventure": "Aktiva resor i {0}",
    "category_highlight.see_all.shopping": "Mer om shopping",
    "lbg.section_most_popular_destination": "De hetaste destinationerna",
    "lbg.section_properties_theme.luxury": "Vinnare i kategorin Lyx för {0}",
    "unusual": "Unika upplevelser",
    "category.shopping": "Shopping",
    "make_the_most_of_your_trip": "Få ut mer av din resa",
    "x_review": "{0,choice,0#|1#{0} recension|2#{0} recensioner|2<{0} recensioner|4<{0} recensioner|10#{0} recensioner|10<{0} recensioner}",
    "hub.category.h1.single": "Soloresor i {0}",
    "hub.category.h2.custom": "Trendar nu",
    "lbg.section_theme_world_winners.lgbt": "Vinnare i kategorin HBTQ+",
    "lbg.section_award_winners_theme_other": "Andra vinnarkategorier för {0}",
    "lbg.supplier_collected_charges": "+ {0} som ska betalas på boendet",
    "in_destination": " i {0}",
    "lbg.section_properties_theme.vip_access": "Vinnare i kategorin VIP Access i {0}",
    "link_long_form_to_listiicle_single": "{0} - en av höjdpunkterna från {1} (Läs allt om {2} här)",
    "lbg.section_theme_world_winners.boutique": "Vinnare i kategorin Boutiquehotell",
    "lbg.section_properties_theme.spa_hotel": "Vinnare i kategorin Spahotell för {0}",
    "lbg.section_theme_world_winners.all_inclusive": "Vinnare i kategorin All-inclusive",
    "hub.category.h2": "Reseguide {0}",
    "lbg.properties.price_tooltip": "Det här är det lägsta priset per natt på vår sida de kommande 14 dagarna.",
    "hub.category.h2.road_trips": "Bäst roadtrips i {0}",
    "hub.category.h1.ski": "Skiddestinationer i {0}",
    "food": "Mat",
    "lbg.theme.pet_friendly": "Husdjursvänligt",
    "lbg.theme.business": "Affärsresa",
    "hub.category.h1.nature": "Bäst resor för att uppleva naturen i {0}",
    "map": "Karta",
    "category_highlight.see_all.information": "Mer information",
    "hotel_prices_in": "Hotellpriser i {0}",
    "hub.category.h1.nightlife": "Nattliv i {0}",
    "trust_module.free_cancellation.most_hotels": "på de flesta hotell*",
    "also_popular_in": "Sevärt i {0}",
    "lbg.h1.destination_theme.business": "Vinnare i kategorin Affärsresa för {0}",
    "lbg.section_theme_world_winners.pet_friendly": "Vinnare i kategorin Husdjursvänligt",
    "hub.category.h1.maps": "Kartor över {0}",
    "lbg.section_properties_theme.most_wanted": "Vinnare i kategorin Mest efterfrågat för {0}",
    "category.nightlife": "Nattliv",
    "search_hotels_in_this_area": "Sök hotell<span class=\"extra-label\"> i det här området</span>",
    "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 röst|2#2 röster|2<{1} röster|4<{1} röster|10#10 röster|10<{1} röster})",
    "hub.category.h2.business": "De bästa affärsresorna i {0}",
    "category.things_to_do": "Saker att göra",
    "destination_travel_guide_by_category": "Reseguider till {0} sorterade i kategorier",
    "hub.category.h1.families": "Familjeresor i {0}",
    "lbg.section_theme_world_winners.ski": "Vinnare i kategorin Skidåkning",
    "you_may_also_like": "Du kanske också gillar",
    "lbg.theme.sustainable": "Hållbarhet",
    "hub.category.h2.unusual": "De bästa resorna för smultronställen och udda platser i {0}",
    "hub.category.h2.football": "Stötta ditt fotbollslag och få ut så mycket som möjligt av resan!",
    "top_hotel": "Topphotell",
    "lbg.h1.destination_theme.luxury": "Vinnare i kategorin Lyx för {0}",
    "top10_hotels_empty_alert": "För tillfället har vi tekniska problem. <span>Vi beklagar besväret.</span>",
    "special_deal": "Specialerbjudande",
    "lbg.h2.sustainable": "Hållbarhet är nyckelordet! Kolla in de här boendena som erbjuder en fantastisk vistelse.",
    "where_to_stay_in": "Var ska man bo i {0}",
    "start_planning_your_trip": "Börja planera din resa",
    "good_for": "Bra för:",
    "category.hotels": "Hotell",
    "x_accommodation_guide": "Boendeguide för {0}",
    "lbg.theme.luxury": "Lyx",
    "hub.category.h2.sports": "De bästa sporterna och aktiviteterna i {0}",
    "lbg.section_theme_world_winners.lgbtq": "Vinnare i kategorin HBTQ",
    "see_more_neighbourhoods": "Se fler områden",
    "category_highlight.title.nightlife": "Var nattlivet finns och vad som erbjuds i {0}",
    "lbg.h1.destination_theme.lgbt": "Vinnare i kategorin HBTQ+ för {0}",
    "hub.category.h1.blogs": "Reseblogg om {0}",
    "lbg.h2.business": "Dessa boenden har fått höga betyg av affärsresenärer.",
    "hotels": "Hotell",
    "lbg.h2.pet_friendly": "Gäster rekommenderar varmt de här boendena som välkomnar både dig och ditt husdjur.",
    "popular_landmarks_nearby": "Populära landmärken i närheten",
    "popular_extractions_in": "Populära sevärdheter i {0}",
    "lbg.h2.all_inclusive": "Dessa boenden har fått högsta betyg och erbjuder en avkopplande vistelse.",
    "travel_guide_by_category_header": "{0} i {1} – utvalda favoriter",
    "lbg.travel_between": "Res mellan {0} och {1}",
    "keep_exploring": "Fortsätt utforska",
    "what_you_need_to_know_before_you_go": "Bra att veta innan man reser",
    "read_it": "Läs",
    "view_on_map": "Se på kartan",
    "lbg.h1.destination_theme.lgbtq": "Vinnare i kategorin HBTQ i {0}",
    "lbg.h2.beach": "Gäster älskar att bo nära stranden, särskilt på de här boendena.",
    "lbg.h1.destination_theme.vip_access": "Vinnare i kategorin VIP Access i {0}",
    "see_all_accommodation": "Se alla boenden",
    "shoppers": "Shopping",
    "whats_around": "I närheten av {0}",
    "group": "Grupper",
    "hub.category.h2.couples": "De bästa parresorna i {0}",
    "hub.category.h1.shopping": "Shopping i {0}",
    "travel_community": "{0} <span>&#124;</span> <span>Resenärscommunity</span>",
    "hub.category.h2.group": "De bästa gruppresorna i {0}",
    "listicle_legal_msg": "Artikeln innehåller åsikter från redaktionsteamet på Go Guides. Hotels.com kompenserar sina författare för texter som visas på sidan. Kompensationen kan motsvara resekostnader eller andra kostnader.",
    "lbg.h1.destination_theme.cleanliness": "Vinnare i kategorin Renlighet i {0}",
    "menu.more": "Fler",
    "lbg.section_properties_theme.lgbtq": "Vinnare i kategorin HBTQ i {0}",
    "lbg.section_award_winners_destination": "Vinnare efter destination",
    "lbg.terms_conditions": "Regler och villkor",
    "hub.category.h2.nightlife": "{0} - här är det bästa nattlivet",
    "hub.neighbourhood.h2": "Saker att göra i {0}",
    "lbg.section_award_winners_theme_destination": "Vinnare efter typ för {0}",
    "lbg.section_award_world_winners": "Vinnare över hela världen",
    "things_to_see_and_do_in_other_cities": "Att se och göra i andra städer",
    "button.backToTop": "Gå högst upp",
    "lbg.section_theme_world_winners.family": "Vinnare i kategorin Familjevänligt",
    "hotels_near": "Hotell nära {0}",
    "category.activity__sports_": "Aktiviteter",
    "trust_module.get_reward_night": "Få en bonusnatt",
    "hub.category.h1.business": "Affärsresor i {0}",
    "category.food": "Mat",
    "other_popular_destinations_in": "Andra populära destinationer i {0}",
    "browse_by_category": "Upptäck {0} efter kategori",
    "see_more_attactions": "Se fler sevärdheter",
    "lbg.theme.lgbt": "HBTQ+",
    "more_information_about": "Mer fakta om {0}",
    "lbg.h1.destination_theme.most_wanted": "Vinnare i kategorin Mest efterfrågat för {0}",
    "see_all_properties_in": "{0} - se boenden",
    "category.tours": "Turer",
    "hub.category.h1.sports": "Sport och aktiviteter i {0}",
    "where_will_you_go_next": "Vart vill du åka?",
    "cities": "Städer",
    "lbg.theme.spa_hotel": "Spahotell",
    "see_guide": "Läs guiden",
    "lbg.section_properties_theme.ski": "Vinnare i kategorin Skidåkning för {0}",
    "related_stories": "Liknande ämnen",
    "hub.destination.h2": "Bra att veta innan du åker",
    "show_less": "Visa mindre",
    "hub.category.h1.football": "Den ultimata guiden om {0} för riktiga fotbollsfans",
    "hub.category.h2.food": "{0} - här är de bästa restaurangerna",
    "fallback_language_alert": "Denna sida har tyvärr inte översatts till ditt språk än...",
    "category_highlight.title.information": "Användbar information",
    "neighbourhood_guides_for_other_cities": "Områdesguider till andra städer",
    "lbg.h2.hubpage": "Dessa fantastiska boenden har höga gästbetyg och massor av recensioner.",
    "travel_advisory.title": "Resemeddelande",
    "lbg.section_properties_theme.all_inclusive": "Vinnare i kategorin All-inclusive för {0}",
    "lbg.h2.boutique": "Om du älskar boutiqueboenden är de här boendena varmt rekommenderade.",
    "lbg.section_properties_theme.family": "Vinnare i kategorin Familjevänligt för {0}",
    "hub.category.h2.families": "De bästa familjeresorna i {0}",
    "from": "från",
    "lbg.theme.vip_access": "VIP Access",
    "lbg.section_properties_theme.lgbt": "Vinnare i kategorin HBTQ+ för {0}",
    "x_star": "{0,choice,0#|1#{0}-stjärnigt|1.5#{0}-stjärnigt|2#{0}-stjärnigt|2.5#{0}-stjärnigt|3#{0}-stjärnigt|3.5#{0}-stjärnigt|4#{0}-stjärnigt|4.5#{0}-stjärnigt|5#{0}-stjärnigt}",
    "trust_module.free_cancellation": "Gratis avbokning",
    "lbg.section_properties_theme.home_and_apartments": "Vinnare i kategorin Hem och lägenheter för {0}",
    "contributing_writer": "{0} <span>&#124;</span> <span>Skribent</span>",
    "things_to_see_and_do_in": "Att se och göra <span class=\"next-line\">i {0}</span>",
    "lbg.check_price": "Kolla pris",
    "hub.category.h2.blogs": "av erfarna resenärer",
    "lbg.h2.home_and_apartments": "Vill du känna dig som hemma när du reser? Kolla in de här boendena med höga betyg.",
    "hub.category.h2.information": "Matnyttig info om {0}",
    "search_hotels_in": "Sök hotell<span class=\"extra-label\"> i {0}</span>",
    "hub.category.h2.nature": "Bäst resor med fokus på natur i {0}",
    "lbg.h1.destination_theme.home_and_apartments": "Vinnare i kategorin Hem och lägenheter för {0}",
    "photo_by": "foto av {0}",
    "more_things_to_do": "Fler saker att göra",
    "lbg.section_properties_theme.beach": "Vinnare i kategorin Strand för {0}",
    "read_less": "Visa mindre",
    "lbg.h2.spa_hotel": "Varva ner och koppla av på de här boendena som fått högsta betyg och är välkända för sina spa.",
    "second_fold_related_stories.see_also": "Kolla in",
    "hub.category.h2.history": "De bästa historiska platserna i {0}",
    "lbg.section_properties_theme.cleanliness": "Vinnare i kategorin Renlighet i {0}",
    "lbg.h1.destination_theme.boutique": "Vinnare i kategorier Boutiquehotell för {0}",
    "nightlife_b595ae4": "Nattliv",
    "lbg.h1.destination_theme.beach": "Vinnare i kategorin Strand för {0}",
    "trust_module.free_cancellation.disclaimer": "För vissa hotell måste du avboka minst 24-48 timmar före incheckning. Mer info på vår sida.",
    "lbg.h2.family": "De här boendena har fått höga gästbetyg och passar perfekt för familjer.",
    "lbg.h2.lgbtq": "De här HBTQ-vänliga boendena har fått höga betyg av gäster.",
    "hub.category.h1.group": "Gruppresor i {0}",
    "popular_stories": "Populära berättelser",
    "toc": "Innehåll",
    "hub.category.h2.shopping": "{0} - de bästa shoppingupplevelserna",
    "lbg.section_theme_world_winners.beach": "Vinnare i kategorin Strand",
    "page_score": "Sidans betyg",
    "lbg.section_theme_world_winners.sustainable": "Vinnare i kategorin Hållbarhet",
    "lbg.theme.boutique": "Boutiquehotell",
    "trust_module.price_guarantee": "Vår prisgaranti",
    "lbg.h1.destination_theme.all_inclusive": "Vinnare i kategorin All-inclusive för {0}",
    "top_destinations": "Populära destinationer",
    "hotel_prices_near": "Hotellpriser nära {0}",
    "lbg.score.cleanliness": "Städning:",
    "x_stars": "{0,choice,0#|1#{0}-stjärniga |1<{0}-stjärniga|4<{0}-stjärniga|10<{0}-stjärniga}",
    "lbg.theme.all_inclusive": "All-inclusive",
    "hub.category.h1.tours": "Turer och dagsutflykter för {0}",
    "lbg.section_award_winners_theme": "Vinnare efter typ",
    "guest_writer": "{0} <span>&#124;</span> <span>Gästskribent</span>",
    "start_planning_your_x_trip": "Börja planera din resa till {0}",
    "lbg.section_theme_world_winners.business": "Vinnare i kategorin Affärsresa",
    "trending_now_in": "Trender i {0} just nu",
    "history": "Historia",
    "search_hotels": "Sök hotell",
    "lbg.terms_conditions_details": "Kriterierna för alla boenden som utsetts till vinnare {0} baseras på att de hade höga gästbetyg {1} och minst 50 recensioner.",
    "see_nearby_properties": "Se boenden i närheten",
    "hub.category.h1.history": "Historiska platser i {0}",
    "location": "Adress:",
    "lbg.section_theme_world_winners.luxury": "Vinnare i kategorin Lyx",
    "category_highlight.see_all.nightlife": "Mer om nattliv",
    "also_popular": "Också populärt",
    "hub.category.h2.things_to_do": "{0} - populära saker att göra",
    "lbg.theme.family": "Familjevänligt",
    "lbg.h1.destination_theme.pet_friendly": "Vinnare i kategorin Husdjursvänligt för {0}",
    "category_highlight.title.shopping": "Var shoppingen finns och vad du kan köpa i {0}",
    "price": "Pris:",
    "hub.category.h2.beach": "De bästa stränderna i {0}",
    "neighbourhoods_in": "områden i {0}",
    "business": "Business",
    "photo": "Fotografering",
    "blog.see_all_stories": "Se alla bloggar",
    "category_highlight.see_all.food": "Mer om mat och dryck",
    "see_all_properties": "Visa alla boenden",
    "lbg.theme.beach": "Strand",
    "what_to_see_and_do_in": "Vad du kan se och göra i {0}",
    "hub.category.h2.single": "De bästa soloresorna i {0}",
    "attractions_in": "Sevärdheter i {0}",
    "read_more": "Visa mer",
    "show_more": "Visa mer",
    "hotels_near_this_landmark": "Hotell i närheten",
    "check_dates": "Visa datum",
    "featured_stories_and_fun_stuff": "Utvalda berättelser och roliga grejer",
    "phone": "Telefonnummer:",
    "lbg.section_search_header": "Sök efter boenden som vunnit utmärkelser",
    "category_highlight.title.food": "Var och vad du kan äta i {0}",
    "see_all_things_to_do": "Se alla saker att göra",
    "lbg.section_properties_theme.sustainable": "Vinnare i kategorin Hållbarhet för {0}",
    "lbg.h1.hubpage": "Vinnare av {0} ”Gäster älskar det”! ",
    "lbg.section_theme_world_winners.spa_hotel": "Vinnare i kategorin Spahotell",
    "category.maps": "Kartor",
    "lbg.h1.destination_theme.family": "Vinnare i kategorin Familjevänligt för {0}",
    "lbg.section_theme_world_winners.cleanliness": "Vinnare i kategorin Städning",
    "lbg.section_theme_world_winners.vip_access": "Vinnare i kategorin VIP Access",
    "trust_module.get_reward_night.night_you_stay": "för var tionde natt du bor",
    "modified": "redigerad",
    "lbg.section_properties": "Vinnare för {0}",
    "trust_module.header": "Varför boka med Hotels.com?",
    "hub.category.h2.luxury": "De bästa lyxresorna i {0}",
    "lbg.theme.ski": "Skidåkning",
    "lbg.h1.destination_theme.spa_hotel": "Vinnare i kategorin Spahotell för {0}",
    "lbg.h2.most_wanted": "Gäster rekommenderar varmt de här boendena för en perfekt vistelse.",
    "top10_hotels_incomplete_alert": "För tillfället har vi hög kundvolym, så alla boenden kan inte visas just nu. <span>Vi beklagar besväret.</span>",
    "compulsive_traveller": "{0} <span>&#124;</span> <span>Erfaren resenär</span>",
    "lbg.section_theme_world_winners.home_and_apartments": "Vinnare i kategorin Hem och lägenheter",
    "loved_by_guests_collection_in": "Boenden för {0} som vunnit ”Gäster älskar det”",
    "title.suffix": "{0} - Go Guides",
    "hub.category.h2.maps": "Praktiska kartor av {0}",
    "opening_hours": "Öppettider",
    "hub.category.h2.photos": "De bästa fotovänliga resorna i {0}",
    "lbg.theme.most_wanted": "Mest efterfrågat",
    "blog.stories_container": "Bloggartiklar",
    "hub.category.h2.budget": "De bästa budgetresorna i {0}",
    "lbg.section_properties_theme.business": "Vinnare i kategorin Affärsresa för {0}",
    "not_sure_where_to_stay": "Vet du inte var du vill bo?",
    "category.information": "Info"
}
