import React from 'react';
import { CardItemProp } from 'src/components/types/goguides-types.d';
import { checkValue } from 'src/components/functions';
import CardSizeM from './card-m';
import CardSizeXL from './card-xl';

interface BucketCardProps {
  data: CardItemProp[];
  highlight?: boolean;
}
const BucketTwoCard: React.FC<BucketCardProps> = ({ data, highlight }) => {

  return (
    <ul className={`row item-list-container ${highlight ? '' : 'list2'}`}>
      {data.map((item, index) => (
        checkValue(highlight) ? (
          index < 2 &&
          <CardSizeXL key={index} data={item} />
        ) : (
          <CardSizeM key={index} data={item} />
        )
      ))}
    </ul>
  );
};

export default BucketTwoCard;
