import React from 'react';
import { checkValue } from 'components/functions';
import { GoGuidesListicleItemProps } from 'components/types/goguides-types.d';
import ListicleItemHeader from './item-header';
import ListicleItemImage from './item-image';
import SegmentComponent from '../../generic/segments';
import GoGuidesLocationInfoComponent from '../../location-info/location-info';
import ListicleItemMap from './item-map';
import ButtonNearbyProperties from './item-nearby-properties';
import { EgClickstreamData, EgClickstreamImpressionTracker } from '@shared-ui/clickstream-analytics-context';
import { useStores } from 'utils/useStore';
import { getLocalizedText } from 'src/utils/helper';
import ImageAttribution from '../../generic/image-attribution';
import { UitkCard, UitkCardContentSection } from 'uitk-react-cards';
import { localization } from 'src/utils/localization';

export interface DisplayItemProps {
  item: GoGuidesListicleItemProps;
  hideNumber: boolean;
  index: number;
}

const Displayitem: React.FC<DisplayItemProps> = (props) => {
  const { item, index, hideNumber } = props;
  const itemNumber = index + 1;
  const { goGuidesStore } = useStores();

  let itemTargetUrl = '';
  let itemCtaUrl = '';
  let itemCtaKey = '';
  
  const loc = new localization();
  

  if (checkValue(item.targetUrl)) {
    itemTargetUrl = item.targetUrl;
  } else if (checkValue(item.pdpLink)) {
    itemTargetUrl = item.pdpLink.targetUrl;
  }

  if (checkValue(item.nearbyProperties)) {
    itemCtaUrl = item.nearbyProperties.targetUrl;
    itemCtaKey = loc.getLocalizedText('see_nearby_properties');
  } else if (checkValue(item.pdpLink)) {
    itemCtaUrl = item.pdpLink.targetUrl;
    itemCtaKey = loc.getLocalizedText('book_now');
  }

  const linkTargetPdp = checkValue(item.pdpLink) ? '_blank' : undefined;
  const linkRel = checkValue(item.pdpLink) ? 'noreferrer' : undefined;

  const trackingObject: EgClickstreamData = {
    event: {
      event_name: 'go_guides_module.presented',
      event_type: 'Impression',
      event_version: '1.0.0',
      event_category: 'go_guides',
    },
    experience: goGuidesStore.ClicksteamExperience,
    module: {
      module_name: 'Listicle Item',
      module_sort_position: itemNumber,
    },
  };

  return (
    <>
      <li
        className={`listicle-item
              ${item.image && checkValue(item.image.imageUrl) ? '' : ' no-img'}
              ${checkValue(item.tagLine && !hideNumber) ? '' : ' no-tagline'}
              ${checkValue(item.goodFor) ? '' : ' no-tag'}
            `}
        key={index}
      >
        <EgClickstreamImpressionTracker eventData={trackingObject}>
          <UitkCard border={true}>
            <UitkCardContentSection className="listicle-item-wrap">
              <UitkCardContentSection>
                <ListicleItemHeader
                  hideNumber={hideNumber}
                  number={itemNumber}
                  item={item}
                  itemTargetUrl={itemTargetUrl}
                  linkTargetPdp={linkTargetPdp}
                  linkRel={linkRel}
                />
                {item.image && checkValue(item.image.imageUrl) && (
                  <ListicleItemImage
                    image={item.image}
                    name={item.name}
                    itemTargetUrl={itemTargetUrl}
                    linkTargetPdp={linkTargetPdp}
                    linkRel={linkRel}
                  />
                )}
              </UitkCardContentSection>
              <UitkCardContentSection className="item-content-wrap">
                {checkValue(item.goodFor) && (
                  <SegmentComponent data={item.goodFor} i18nKey={loc.getLocalizedText('good_for')} />
                )}
                <div className="description-wrap">
                  <div className="inner-warp" dangerouslySetInnerHTML={{ __html: item.description }} />
                  {item.targetUrl && (
                    <a href={item.targetUrl} className="read-more">
                      {loc.getLocalizedText('read_more')}
                    </a>
                  )}
                </div>
                <GoGuidesLocationInfoComponent info={item.info} isListicle={true} />
                <ListicleItemMap
                  name={item.name}
                  geo={item.info?.geo}
                  i18nKey={loc.getLocalizedText('map')}
                  nearbyProperties={item.nearbyProperties}
                  pdpLink={item.pdpLink}
                />
                {checkValue(itemCtaUrl) && (
                  <ButtonNearbyProperties
                    itemCtaUrl={itemCtaUrl}
                    itemCtaKey={itemCtaKey}
                    pdpLink={checkValue(item.pdpLink)}
                  />
                )}
              </UitkCardContentSection>
            </UitkCardContentSection>
          </UitkCard>
          {item.image && item.image.attribution && item.image.attribution.attributionVersion !== 'CC0' && (
            <ImageAttribution attribution={item.image?.attribution} />
          )}
        </EgClickstreamImpressionTracker>
      </li>
    </>
  );
};

export default Displayitem;
