export default {
    "current_deal": "Promo saat ini",
    "lbg.section_properties_theme.pet_friendly": "Pemenang penghargaan properti ramah hewan peliharaan di {0}",
    "address": "Alamat",
    "hub.homepage.h2": "Begitu banyak tempat, begitu sedikit waktu...",
    "search_hotels_near_this_landmark": "Cari hotel<span class=\"extra-label\"> dekat landmark ini</span>",
    "hub.category.h1.things_to_do": "Atraksi Wisata di {0}",
    "lbg.h2.vip_access": "Properti VIP Access dipilih karena memiliki nilai yang sangat tingi dan memberikan pengalaman menginap yang luar biasa.",
    "lbg.theme.cleanliness": "Kebersihan",
    "lbg.theme.lgbtq": "LGBTQ",
    "load_more": "Muat lainnya",
    "link_long_form_to_listiicle_multiple": "{0} - salah satu keunggulan {1} dan {2} (Baca semua tentang {3} di sini)",
    "lbg.section_properties_theme.boutique": "Pemenang penghargaan properti butik di {0}",
    "lbg.h2.luxury": "Kemewahan terbaik. Itulah mengapa tamu suka menginap di properti ini.",
    "hub.category.h1.information": "Informasi mengenai {0}",
    "families": "Keluarga",
    "trending_now": "Yang populer sekarang",
    "lbg.from": "Dari",
    "hub.category.h1.road_trips": "Perjalanan Darat Terbaik di {0}",
    "lbg.h2.cleanliness": "Properti ini memiliki skor tamu keseluruhan yang bagus dan skor tamu yang tinggi untuk kebersihan.",
    "single": "Single",
    "couples": "Pasangan",
    "trust_module.price_guarantee.find_out_more": "ketahui lebih lanjut",
    "top_destinations_in": "Destinasi populer di {0}",
    "hub.category.h1.couples": "Perjalanan untuk Pasangan di {0}",
    "hub.category.h2.tours": "Tur terbaik di {0}",
    "hub.category.h1.photos": "Perjalanan dengan Aktivitas Fotografi di {0}",
    "category.photos": "Foto",
    "hub.category.h1.budget": "Perjalanan Budget di {0}",
    "hub.category.h2.ski": "Destinasi ski terbaik di {0}",
    "lbg.section_theme_world_winners.most_wanted": "Pemenang penghargaan properti favorit",
    "lbg.theme.home_and_apartments": "Rumah & Apartemen",
    "hub.category.h1.luxury": "Perjalanan Mewah di {0}",
    "hub.category.h2.adventure": "Perjalanan terbaik dengan aktivitas seru di {0}",
    "hotels_in": "Hotel di {0}",
    "lbg.section_search_winners": "Cari pemenang penghargaan lainnya",
    "see_more_hotels_in": "Lihat hotel lainnya di {0}",
    "hub.category.h1.beach": "Pantai di {0}",
    "open": "Buka:",
    "enter_your_destination": "Masukkan destinasi Anda",
    "best_of": "Terbaik di {0}",
    "top10_hotels_missing_properties": "Beberapa properti mungkin tidak ditampilkan saat ini. Kami mohon maaf atas ketidaknyamanan ini.",
    "adventure": "Petualangan",
    "lbg.h1.destination_theme.sustainable": "Pemenang Penghargaan Properti Ramah Lingkungan di {0}",
    "luxury": "Mewah",
    "expand_map_view": "Perbesar tampilan peta",
    "book_now": "Pesan sekarang",
    "lbg.h1.destination_theme.ski": "Pemenang Penghargaan Properti Ski di {0}",
    "hub.category.h1.food": "Makanan dan Restoran di {0}",
    "category.blogs": "Blog",
    "budget": "Budget",
    "popular_neighbourhoods_in": "Kawasan yang populer di {0}",
    "hub.category.h1.unusual": "Tempat Tersembunyi dan Tidak Biasa di {0}",
    "see_all_accommodation_in": "Lihat akomodasi di {0}",
    "hub.category.h1.adventure": "Perjalanan dengan Aktivitas Seru di {0}",
    "category_highlight.see_all.shopping": "Selengkapnya tentang belanja",
    "lbg.section_most_popular_destination": "Destinasi paling populer",
    "lbg.section_properties_theme.luxury": "Pemenang penghargaan properti mewah di {0}",
    "unusual": "Tidak Biasa",
    "category.shopping": "Belanja",
    "make_the_most_of_your_trip": "Maksimalkan perjalanan Anda",
    "x_review": "{0,choice,0#|1#{0} ulasan|2#{0} ulasan|2<{0} ulasan|4<{0} ulasan|10#{0} ulasan|10<{0} ulasan}",
    "hub.category.h1.single": "Perjalanan Sendiri di {0}",
    "hub.category.h2.custom": "Yang populer sekarang",
    "lbg.section_theme_world_winners.lgbt": "Pemenang penghargaan properti LGBTQ+",
    "lbg.section_award_winners_theme_other": "Tema pemenang penghargaan lainnya di {0}",
    "lbg.supplier_collected_charges": "+ biaya {0} yang harus dibayarkan di properti",
    "in_destination": " di {0}",
    "lbg.section_properties_theme.vip_access": "Pemenang penghargaan VIP Access di {0}",
    "link_long_form_to_listiicle_single": "{0} - salah satu keunggulan {1} (Baca semua tentang {2} di sini)",
    "lbg.section_theme_world_winners.boutique": "Pemenang penghargaan properti butik",
    "lbg.section_properties_theme.spa_hotel": "Pemenang penghargaan properti spa di {0}",
    "lbg.section_theme_world_winners.all_inclusive": "Pemenang penghargaan properti all inclusive",
    "hub.category.h2": "Panduan perjalanan {0}",
    "lbg.properties.price_tooltip": "Ini adalah harga terendah per malam di situs kami untuk 14 hari ke depan. ",
    "hub.category.h2.road_trips": "Perjalanan Darat Terbaik di {0}",
    "hub.category.h1.ski": "Destinasi ski di {0}",
    "food": "Makanan",
    "lbg.theme.pet_friendly": "Properti Ramah Hewan Peliharaan",
    "lbg.theme.business": "Properti Bisnis",
    "hub.category.h1.nature": "Perjalanan Terbaik untuk Menikmati Alam di {0}",
    "map": "Peta",
    "category_highlight.see_all.information": "Informasi selengkapnya",
    "hotel_prices_in": "Harga hotel di {0}",
    "hub.category.h1.nightlife": "Hiburan malam di {0}",
    "trust_module.free_cancellation.most_hotels": "untuk hampir semua hotel*",
    "also_popular_in": "Populer juga di {0}",
    "lbg.h1.destination_theme.business": "Pemenang Penghargaan Properti Bisnis di {0}",
    "lbg.section_theme_world_winners.pet_friendly": "Pemenang penghargaan properti ramah hewan peliharaan",
    "hub.category.h1.maps": "Peta {0}",
    "lbg.section_properties_theme.most_wanted": "Pemenang penghargaan properti favorit di {0}",
    "category.nightlife": "Hiburan malam",
    "search_hotels_in_this_area": "Cari hotel<span class=\"extra-label\"> di area ini</span>",
    "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 suara|2#2 suara|2<{1} suara|4<{1} suara|10#10 suara|10<{1} suara})",
    "hub.category.h2.business": "Perjalanan bisnis terbaik di {0}",
    "category.things_to_do": "Atraksi Wisata",
    "destination_travel_guide_by_category": "Panduan Perjalanan di {0} berdasarkan Kategori",
    "hub.category.h1.families": "Perjalanan Keluarga di {0}",
    "lbg.section_theme_world_winners.ski": "Pemenang penghargaan properti ski",
    "you_may_also_like": "Anda mungkin juga suka",
    "lbg.theme.sustainable": "Properti Ramah Lingkungan",
    "hub.category.h2.unusual": "Tempat terbaik yang tersembunyi dan tidak biasa di {0}",
    "hub.category.h2.football": "Dukung tim sepak bola Anda dan maksimalkan perjalanan Anda!",
    "top_hotel": "Hotel Populer",
    "lbg.h1.destination_theme.luxury": "Pemenang Penghargaan Properti Mewah di {0}",
    "top10_hotels_empty_alert": "Saat ini kami sedang mengalami masalah teknis. <span>Kami mohon maaf atas ketidaknyamanan ini.</span>",
    "special_deal": "Promo spesial",
    "lbg.h2.sustainable": "Semua yang ramah lingkungan! Cek properti ini untuk pengalaman menginap yang menyenangkan.",
    "where_to_stay_in": "Tempat menginap di {0}",
    "start_planning_your_trip": "Mulai merencanakan perjalanan Anda",
    "good_for": "Bagus untuk:",
    "category.hotels": "Hotel",
    "x_accommodation_guide": "Panduan Akomodasi di {0}",
    "lbg.theme.luxury": "Properti Mewah",
    "hub.category.h2.sports": "Olahraga dan aktivitas terbaik di {0}",
    "lbg.section_theme_world_winners.lgbtq": "Pemenang penghargaan LGBTQ",
    "see_more_neighbourhoods": "Lihat kawasan lainnya",
    "category_highlight.title.nightlife": "Atraksi & aktivitas malam di {0}",
    "lbg.h1.destination_theme.lgbt": "Pemenang penghargaan properti LGBTQ+ di {0}",
    "hub.category.h1.blogs": "Blog Perjalanan ke {0}",
    "lbg.h2.business": "Properti ini mendapatkan nilai yang sangat tinggi dari traveler bisnis.",
    "hotels": "Hotel",
    "lbg.h2.pet_friendly": "Tamu sangat merekomendasikan properti ini yang ramah hewan peliharaan.",
    "popular_landmarks_nearby": "Landmark populer di sekitar",
    "popular_extractions_in": "Atraksi populer di {0}",
    "lbg.h2.all_inclusive": "Properti favorit ini bebas stres.",
    "travel_guide_by_category_header": "{0} terbaik di {1}",
    "lbg.travel_between": "Perjalanan antara {0} - {1}",
    "keep_exploring": "Terus menjelajah",
    "what_you_need_to_know_before_you_go": "Apa yang Harus Anda Ketahui Sebelum Pergi",
    "read_it": "Baca",
    "view_on_map": "Lihat di peta",
    "lbg.h1.destination_theme.lgbtq": "Pemenang penghargaan LGBTQ di {0}",
    "lbg.h2.beach": "Tamu menyukai penginapan di tepi pantai, khususnya properti ini.",
    "lbg.h1.destination_theme.vip_access": "Pemenang penghargaan VIP Access di {0}",
    "see_all_accommodation": "Lihat semua akomodasi",
    "shoppers": "Shopper",
    "whats_around": "di sekitar properti {0}",
    "group": "Rombongan",
    "hub.category.h2.couples": "Perjalanan terbaik untuk pasangan di {0}",
    "hub.category.h1.shopping": "Belanja di {0}",
    "travel_community": "{0} <span>&#124;</span> <span>Komunitas Perjalanan</span>",
    "hub.category.h2.group": "Perjalanan terbaik untuk rombongan di {0}",
    "listicle_legal_msg": "Artikel ini berisi opini dari tim editorial Go Guides. Hotels.com memberikan kompensasi kepada penulis yang tulisannya muncul di situs web ini; kompensasi tersebut dapat termasuk biaya perjalanan dan biaya lainnya.",
    "lbg.h1.destination_theme.cleanliness": "Pemenang penghargaan kebersihan di {0}",
    "menu.more": "Lainnya",
    "lbg.section_properties_theme.lgbtq": "Pemenang penghargaan LGBTQ di {0}",
    "lbg.section_award_winners_destination": "Pemenang penghargaan menurut destinasi",
    "lbg.terms_conditions": "Syarat & Ketentuan",
    "hub.category.h2.nightlife": "Hiburan malam terbaik di {0}",
    "hub.neighbourhood.h2": "Aktivitas di {0}",
    "lbg.section_award_winners_theme_destination": "Pemenang penghargaan menurut tema di {0}",
    "lbg.section_award_world_winners": "Pemenang penghargaan di seluruh dunia",
    "things_to_see_and_do_in_other_cities": "Hal yang harus dilihat dan dilakukan di kota lain",
    "button.backToTop": "Kembali ke atas",
    "lbg.section_theme_world_winners.family": "Pemenang penghargaan properti keluarga",
    "hotels_near": "Hotel dekat {0}",
    "category.activity__sports_": "Aktivitas",
    "trust_module.get_reward_night": "Mendapatkan malam reward",
    "hub.category.h1.business": "Perjalanan Bisnis di {0}",
    "category.food": "Makanan",
    "other_popular_destinations_in": "Destinasi populer lainnya di {0}",
    "browse_by_category": "Jelajahi {0} berdasarkan kategori",
    "see_more_attactions": "Lihat atraksi lainnya",
    "lbg.theme.lgbt": "LGBTQ+",
    "more_information_about": "Informasi selengkapnya mengenai {0}",
    "lbg.h1.destination_theme.most_wanted": "Pemenang Penghargaan Properti Favorit di {0}",
    "see_all_properties_in": "Lihat properti di {0}",
    "category.tours": "Tur",
    "hub.category.h1.sports": "Olahraga dan Aktivitas di {0}",
    "where_will_you_go_next": "Ke mana Anda akan pergi selanjutnya?",
    "cities": "Kota",
    "lbg.theme.spa_hotel": "Properti Spa",
    "see_guide": "Lihat panduan",
    "lbg.section_properties_theme.ski": "Pemenang penghargaan properti ski di {0}",
    "related_stories": "Kisah terkait",
    "hub.destination.h2": "Apa yang harus Anda ketahui sebelum pergi",
    "show_less": "Tampilkan lebih sedikit",
    "hub.category.h1.football": "Panduan Utama untuk Penggemar Sepak Bola Sejati di {0}",
    "hub.category.h2.food": "Makanan dan tempat makan terbaik di {0}",
    "fallback_language_alert": "Maaf, halaman ini belum diterjemahkan ke dalam bahasa Anda…",
    "category_highlight.title.information": "Informasi bermanfaat",
    "neighbourhood_guides_for_other_cities": "Panduan kawasan untuk kota lain",
    "lbg.h2.hubpage": "Properti fantastis ini memiliki skor penilaian tamu yang tinggi dan memiliki banyak ulasan.",
    "travel_advisory.title": "Peringatan perjalanan",
    "lbg.section_properties_theme.all_inclusive": "Pemenang penghargaan properti all inclusive di {0}",
    "lbg.h2.boutique": "Jika Anda menyukai properti butik, tamu sangat merekomendasikan untuk menginap di sini.",
    "lbg.section_properties_theme.family": "Pemenang penghargaan properti keluarga di {0}",
    "hub.category.h2.families": "Perjalanan keluarga terbaik di {0}",
    "from": "dari",
    "lbg.theme.vip_access": "VIP Access",
    "lbg.section_properties_theme.lgbt": "Pemenang penghargaan properti LGBTQ+ di {0}",
    "x_star": "{0,choice,0#|1#{0} bintang|1.5#{0} bintang|2#{0} bintang|2.5#{0} bintang|3#{0} bintang|3.5#{0} bintang|4#{0} bintang|4.5#{0} bintang|5#{0} bintang}",
    "trust_module.free_cancellation": "Pembatalan gratis",
    "lbg.section_properties_theme.home_and_apartments": "Pemenang penghargaan rumah & apartemen di {0}",
    "contributing_writer": "{0} <span>&#124;</span> <span>Penulis yang Berkontribusi</span>",
    "things_to_see_and_do_in": "Hal yang harus dilihat dan dilakukan <span class=\"next-line\">di {0}</span>",
    "lbg.check_price": "Cek harga",
    "hub.category.h2.blogs": "oleh Traveler yang berpengalaman",
    "lbg.h2.home_and_apartments": "Mencari rumah saat berada jauh dari rumah? Cek properti dengan nilai sangat tinggi ini.",
    "hub.category.h2.information": "Informasi bermanfaat tentang {0}",
    "search_hotels_in": "Cari hotel<span class=\"extra-label\"> di {0}</span>",
    "hub.category.h2.nature": "Perjalanan Terbaik untuk Menikmati Alam di {0}",
    "lbg.h1.destination_theme.home_and_apartments": "Pemenang Penghargaan Rumah & Apartemen di {0}",
    "photo_by": "foto dari {0}",
    "more_things_to_do": "Atraksi wisata lainnya",
    "lbg.section_properties_theme.beach": "Pemenang penghargaan properti pantai di {0}",
    "read_less": "Baca sedikit",
    "lbg.h2.spa_hotel": "Bersantai dan rileks di properti favorit ini yang terkenal karena fasilitas spanya.",
    "second_fold_related_stories.see_also": "Lihat juga",
    "hub.category.h2.history": "Tempat bersejarah terbaik di {0}",
    "lbg.section_properties_theme.cleanliness": "Pemenang penghargaan kebersihan di {0}",
    "lbg.h1.destination_theme.boutique": "Pemenang Penghargaan Properti Butik di {0}",
    "nightlife_b595ae4": "Hiburan malam",
    "lbg.h1.destination_theme.beach": "Pemenang Penghargaan Properti Pantai di {0}",
    "trust_module.free_cancellation.disclaimer": "Beberapa hotel mengharuskan Anda untuk membatalkan setidaknya 24-48 jam sebelum check-in. Rincian di situs web.",
    "lbg.h2.family": "Properti ini mendapatkan nilai yang sangat tinggi dari tamu dan cocok untuk keluarga.",
    "lbg.h2.lgbtq": "Properti ramah LGBTQ ini mendapatkan nilai yang sangat tinggi dari tamu.",
    "hub.category.h1.group": "Perjalanan dengan Rombongan di {0}",
    "popular_stories": "Kisah populer",
    "toc": "Konten",
    "hub.category.h2.shopping": "Tempat belanja terbaik di {0}",
    "lbg.section_theme_world_winners.beach": "Pemenang penghargaan properti pantai",
    "page_score": "Skor halaman ini",
    "lbg.section_theme_world_winners.sustainable": "Pemenang penghargaan properti ramah lingkungan",
    "lbg.theme.boutique": "Properti Butik",
    "trust_module.price_guarantee": "Garansi harga kami",
    "lbg.h1.destination_theme.all_inclusive": "Pemenang Penghargaan Properti All Inclusive di {0}",
    "top_destinations": "Destinasi terpopuler",
    "hotel_prices_near": "Harga hotel dekat {0}",
    "lbg.score.cleanliness": "Kebersihan:",
    "x_stars": "{0,choice,0#|1#{0} bintang|1<{0} bintang|4<{0} bintang|10<{0} bintang}",
    "lbg.theme.all_inclusive": "Properti All-Inclusive",
    "hub.category.h1.tours": "Tur dan Trip Sehari di {0}",
    "lbg.section_award_winners_theme": "Pemenang penghargaan menurut tema",
    "guest_writer": "{0} <span>&#124;</span> <span>Penulis Tamu</span>",
    "start_planning_your_x_trip": "Mulai merencanakan perjalanan Anda ke {0}",
    "lbg.section_theme_world_winners.business": "Pemenang penghargaan properti bisnis",
    "trending_now_in": "Yang populer sekarang di {0}",
    "history": "Sejarah",
    "search_hotels": "Cari hotel",
    "lbg.terms_conditions_details": "Kriteria untuk properti yang dipilih sebagai pemenang award di tahun {0} berdasarkan pada tingginya skor penilaian tamu di tahun {1} dan minimal memiliki 50 ulasan tamu.",
    "see_nearby_properties": "Lihat properti di sekitar",
    "hub.category.h1.history": "Tempat Bersejarah di {0}",
    "location": "Lokasi:",
    "lbg.section_theme_world_winners.luxury": "Pemenang penghargaan properti mewah",
    "category_highlight.see_all.nightlife": "Selengkapnya tentang hiburan malam",
    "also_popular": "Juga populer",
    "hub.category.h2.things_to_do": "Atraksi wisata terbaik di {0}",
    "lbg.theme.family": "Properti Keluarga",
    "lbg.h1.destination_theme.pet_friendly": "Pemenang Penghargaan Properti Ramah Hewan Peliharaan di {0}",
    "category_highlight.title.shopping": "Tempat belanja & suvenir di {0}",
    "price": "Harga:",
    "hub.category.h2.beach": "Pantai terbaik di {0}",
    "neighbourhoods_in": "lingkungan di {0}",
    "business": "Bisnis",
    "photo": "Foto",
    "blog.see_all_stories": "Lihat semua cerita",
    "category_highlight.see_all.food": "Selengkapnya tentang Makanan & Restoran",
    "see_all_properties": "Lihat semua properti",
    "lbg.theme.beach": "Properti Pantai",
    "what_to_see_and_do_in": "Atraksi wisata di {0}",
    "hub.category.h2.single": "Perjalanan sendiri terbaik di {0}",
    "attractions_in": "Atraksi di {0}",
    "read_more": "Baca selengkapnya",
    "show_more": "Tampilkan selengkapnya",
    "hotels_near_this_landmark": "Hotel dekat landmark ini",
    "check_dates": "Cek tanggal",
    "featured_stories_and_fun_stuff": "Kisah unggulan & hal yang menyenangkan",
    "phone": "Telepon:",
    "lbg.section_search_header": "Cari untuk peraih penghargaan",
    "category_highlight.title.food": "Makanan & tempat makan di {0}",
    "see_all_things_to_do": "Lihat semua atraksi wisata",
    "lbg.section_properties_theme.sustainable": "Pemenang penghargaan properti ramah lingkugan di {0}",
    "lbg.h1.hubpage": "{0} Pemenang Penghargaan Properti Disukai Tamu!",
    "lbg.section_theme_world_winners.spa_hotel": "Pemenang penghargaan properti spa",
    "category.maps": "Peta",
    "lbg.h1.destination_theme.family": "Pemenang Penghargaan Properti Keluarga di {0}",
    "lbg.section_theme_world_winners.cleanliness": "Pemenang penghargaan kebersihan",
    "lbg.section_theme_world_winners.vip_access": "Pemenang penghargaan VIP Access",
    "trust_module.get_reward_night.night_you_stay": "untuk setiap 10 malam Anda menginap",
    "modified": "dimodifikasi",
    "lbg.section_properties": "Pemenang penghargaan di {0}",
    "trust_module.header": "Kenapa pesan melalui Hotels.com?",
    "hub.category.h2.luxury": "Perjalanan mewah terbaik di {0}",
    "lbg.theme.ski": "Properti Ski",
    "lbg.h1.destination_theme.spa_hotel": "Pemenang Penghargaan Properti Spa di {0}",
    "lbg.h2.most_wanted": "Tamu sangat merekomendasikan properti ini untuk pengalaman menginap terbaik.",
    "top10_hotels_incomplete_alert": "Saat ini permintaan sedang tinggi, sehingga beberapa properti mungkin tidak ditampilkan. <span>Kami mohon maaf atas ketidaknyamanan ini.</span>",
    "compulsive_traveller": "{0} <span>|</span> <span>Traveler Kompulsif</span>",
    "lbg.section_theme_world_winners.home_and_apartments": "Pemenang penghargaan rumah & apartemen",
    "loved_by_guests_collection_in": "Koleksi Properti Loved by Guests di {0}",
    "title.suffix": "{0} - Panduan Perjalanan",
    "hub.category.h2.maps": "Peta {0} bermanfaat",
    "opening_hours": "Jam buka",
    "hub.category.h2.photos": "Perjalanan dengan aktivitas fotografi terbaik di {0}",
    "lbg.theme.most_wanted": "Properti Favorit",
    "blog.stories_container": "Cerita dari kontributor",
    "hub.category.h2.budget": "Perjalanan budget terbaik di {0}",
    "lbg.section_properties_theme.business": "Pemenang penghargaan properti bisnis di {0}",
    "not_sure_where_to_stay": "Tidak yakin di mana harus menginap?",
    "category.information": "Informasi"
}
