import React from 'react';
import { useStores } from 'src/utils/useStore';
import {
  DiscoveryCardContentSize,
  DiscoveryContainerType,
  DiscoveryOfferingType,
  DiscoveryRecommendationInputContext,
  DiscoveryRecommendationStrategy,
  LineOfBusinessDomain,
  RecommendationsModule,
} from '@shared-ui/retail-recommendation-module';
import { PageLayout } from 'src/components/types/goguides-types.d';
import { localization } from 'src/utils/localization';
import { Viewport, ViewLarge, ViewSmall } from "@shared-ui/viewport-context";
import { checkValue } from 'src/components/functions';

interface RecommendationModuleProps {
  pageLayout: PageLayout;
}

const RecommendationModule: React.FC<RecommendationModuleProps> = (props) => {
  const { goGuidesStore } = useStores();
  const { pageLayout } = props;
  const loc = new localization();

  const data = goGuidesStore.fetchGaiaIdAndDestinationName(goGuidesStore.pageUrlData);
  if (!checkValue(data)) {
    return null;
  }

  const header = loc.getLocalizedText('where_to_stay_in', data?.destinationName);

  // strip out html from the header
  const headerText = checkValue(header) ? header.replace(/<\/?[^>]+(>|$)/g, "") : null;

  const recommendationInputs = {
    contentSize: DiscoveryCardContentSize.FOUNDATION,
    offeringType: DiscoveryOfferingType.PRODUCT,
    strategy: DiscoveryRecommendationStrategy.POPULAR,
    input: [DiscoveryRecommendationInputContext.DESTINATION],
    recommendationContext: {
      outputLineOfBusiness: LineOfBusinessDomain.LODGING,
      destination: {
        placeId: {
          id: data.gaiaId,
        },
      },
    },
    placementId: '185',
    configurationIdentifier: '0174e98a-57fc-4acb-965a-b3c283aa80fc'
  };

  return (
    <>
      {data && (
        <section className="recommendation-hotels sec-control">
          <div className="container-wrap row">
            <div className="row-inner col-12 line-up">
              <Viewport>
                <ViewSmall>
                  <RecommendationsModule
                    inputs={{
                      ...recommendationInputs,
                      containerType: DiscoveryContainerType.CAROUSEL
                    }}
                    customTitle={headerText}
                    titleProps={{ tag: 'h2' }}
                  />
                </ViewSmall>
                <ViewLarge>
                  <RecommendationsModule
                    inputs={{
                      ...recommendationInputs,
                      containerType: pageLayout === PageLayout.TWO_COLUMN ?
                        DiscoveryContainerType.LIST : DiscoveryContainerType.CAROUSEL,
                      configurationIdentifier: pageLayout === PageLayout.TWO_COLUMN ?
                        '8e9ec552-4809-402a-9410-dda3faf74df7' : '0174e98a-57fc-4acb-965a-b3c283aa80fc'
                    }}
                    customTitle={headerText}
                    titleProps={{ tag: 'h2' }}
                  />
                </ViewLarge>
              </Viewport>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export { RecommendationModule };
