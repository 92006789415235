import { checkValue } from 'components/functions';
import { CardItemProp } from 'components/types/goguides-types.d';
import React from 'react';
import { UitkHeading } from 'uitk-react-text';
import { useStores } from 'utils/useStore';
import { UitkCard, UitkCardLink } from 'uitk-react-cards';
import { UitkFigure, UitkFigureAspectRatioType } from 'uitk-react-images';
import { RenderImage } from 'src/components/utils/responsive-image';
import { EgClickstreamData, EgClickstreamImpressionTracker } from '@shared-ui/clickstream-analytics-context';
import { localization } from 'src/utils/localization';

const GoGuidesAlsoPopularComponent: React.FC = () => {
  const { goGuidesStore } = useStores();
  const loc = new localization();

  const impressionTrackingObject: EgClickstreamData = {
    event: {
      event_name: 'go_guides_module.presented',
      event_type: 'Impression',
      event_version: '1.0.0',
      event_category: 'go_guides',
    },
    experience: goGuidesStore.ClicksteamExperience,
    module: {
      module_name: 'also popular',
    },
  };

  const contentData = goGuidesStore.fetchAlsoPopular(goGuidesStore.pageUrlData);
  let items = [];
  let parentName = '';

  if (checkValue(contentData)) {
    const { parentDestinationName, destinationData } = contentData;
    items = checkValue(destinationData) ? destinationData : [];
    parentName = checkValue(parentDestinationName) ? parentDestinationName : '';
  }

  const bucketHeader =
    parentName === '' ? loc.getLocalizedText('also_popular') : loc.getLocalizedText('also_popular_in', parentName);

  const CardItem = ({ item }: { item: CardItemProp }) => {
    const imageProps = {
      image: item.image?.imageUrl,
      params: {
        size: {
          small: '&width=400&height=267',
          medium: '&width=400&height=267',
          large: '&width=600&height=400',
        }
      }
    };

    return (
      <li className="item-wrap col-6 col-m3">
        <UitkCard>
          <UitkFigure className="img-wrap" ratio={UitkFigureAspectRatioType.R16_9}>
            <span>{item.name}</span>
            <RenderImage imgProps={imageProps} />
          </UitkFigure>
          <UitkCardLink>
            <a href={item.targetUrl}></a>
          </UitkCardLink>
        </UitkCard>
      </li>
    );
  };

  return (
    <>
      {checkValue(items) && items.length > 0 && (
        <EgClickstreamImpressionTracker eventData={impressionTrackingObject}>
          <section className="other-hub sec-control">
            <div className="container-wrap row">
              <div className="row-inner col-12 no-button">
                <div className="section-header-wrapper">
                  <UitkHeading size={5} tag="h2">
                    {bucketHeader}
                  </UitkHeading>
                </div>
                <div className="item-list-page-wrap">
                  <ul className={`row item-list-container`}>
                    {items.map((item, index) => (
                      <CardItem key={index} item={item} />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </EgClickstreamImpressionTracker>
      )}
    </>
  );
};

export default GoGuidesAlsoPopularComponent;
