import { useWindowSize } from '../components/common/helper';
import { useEffect, useState } from 'react';
import { AVAILABLE_LANGUAGES } from './localization-data';
import { useStores } from './useStore';

export const viewportCheck = () => {
  const [width] = useWindowSize();
  const screenSize = {
    //s: 599,
    m: 749,
    l: 959,
    xl: 1259,
  };

  const [windowSize, setWindowSize] = useState('');

  useEffect(() => {
    if (width > screenSize.xl) {
      setWindowSize('large-desktop');
    } else if (width > screenSize.l) {
      setWindowSize('desktop');
    } else if (width > screenSize.m) {
      setWindowSize('tablet');
    } else if (width <= screenSize.m) {
      setWindowSize('mobile');
    }
  }, [width]);

  return windowSize;
};

export const checkPageType = (page: string) => {
  if (page === 'destinationhub' || page === 'categoryhub' || page === 'home') {
    return 'hubpage';
  } else if (page === 'listicle' || page === 'longform') {
    return 'articlepage';
  }
};

export const pageLayoutController = (page: string) => {
  let pageLayout = {
    header: '', // aling-center, align-left
    body: '', // one-col, two-col
  };

  switch (page) {
    case 'home':
    case 'destinationhub':
      pageLayout = {
        header: 'align-center',
        body: 'one-col',
      };
      break;
    case 'categoryhub':
      pageLayout = {
        header: 'align-left',
        body: 'one-col',
      };
      break;
    case 'listicle':
    case 'longform':
      pageLayout = {
        header: 'align-left',
        body: 'two-col',
      };
      break;
    default:
      break;
  }

  return pageLayout;
};

export const getLocalizedText = (msgKey: string, ...args: any) => {
  const { context } = useStores();
  const langLocalization = AVAILABLE_LANGUAGES[context?.locale?.toLocaleLowerCase()] || AVAILABLE_LANGUAGES['en_us'];
  let msg = langLocalization[msgKey];
  if (msg && args && args.length) {
    args.map((arg, idx) => {
      msg = msg.replace(`{${idx}}`, arg);
    });
  }
  return msg;
};
