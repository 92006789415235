export default {
    "current_deal": "Bieżąca oferta",
    "lbg.section_properties_theme.pet_friendly": "Laureaci w kategorii Przyjazne dla zwierząt {0}",
    "address": "Adres",
    "hub.homepage.h2": "Jest jeszcze tyle miejsc do zwiedzenia!",
    "search_hotels_near_this_landmark": "Znajdź hotele<span class=\"extra-label\"> w pobliżu tego punktu</span>",
    "hub.category.h1.things_to_do": "{0} — atrakcje",
    "lbg.h2.vip_access": "Obiekty VIP Access zostały wybrane ze względu na wysoko oceniane pobyty i doskonałe wrażenia.",
    "lbg.theme.cleanliness": "Czystość",
    "lbg.theme.lgbtq": "LGBTQ",
    "load_more": "Pokaż więcej",
    "link_long_form_to_listiicle_multiple": "{0} — jest jedną z atrakcji z kategorii {1} i {2} (więcej informacji o miejscu takim jak {3} znajdziesz tutaj)",
    "lbg.section_properties_theme.boutique": "Laureaci w kategorii Butikowe {0}",
    "lbg.h2.luxury": "Odkryj luksus w najlepszym wydaniu. Dlatego goście uwielbiają przebywać w tych obiektach.",
    "hub.category.h1.information": "{0} — informacje",
    "families": "Rodziny",
    "trending_now": "Popularne",
    "lbg.from": "Od",
    "hub.category.h1.road_trips": "{0} — najlepsze wycieczki samochodowe",
    "lbg.h2.cleanliness": "Te obiekty mają świetną ogólną ocenę gości i najwyższe oceny za czystość.",
    "single": "Single",
    "couples": "Pary",
    "trust_module.price_guarantee.find_out_more": "dowiedz się więcej",
    "top_destinations_in": "Najpopularniejsze kierunki podróży w kraju {0}",
    "hub.category.h1.couples": "{0} – podróże dla par",
    "hub.category.h2.tours": "{0} — najlepsze wycieczki",
    "hub.category.h1.photos": "{0} – podróże z aparatem",
    "category.photos": "Zdjęcia",
    "hub.category.h1.budget": "{0} – tanie podróże",
    "hub.category.h2.ski": "{0} — najlepsze ośrodki narciarskie",
    "lbg.section_theme_world_winners.most_wanted": "Laureaci w kategorii Najpopularniejsze",
    "lbg.theme.home_and_apartments": "Domy i apartamenty",
    "hub.category.h1.luxury": "{0} – podróże luksusowe",
    "hub.category.h2.adventure": "{0} – najlepsze aktywne podróże",
    "hotels_in": "Hotele — {0}",
    "lbg.section_search_winners": "Wyszukaj więcej laureatów",
    "see_more_hotels_in": "Wyświetl więcej hoteli w tej lokalizacji: {0}",
    "hub.category.h1.beach": "{0} — plaże",
    "open": "Otwarte:",
    "enter_your_destination": "Podaj kierunek podróży",
    "best_of": "{0} — najlepsze atrakcje",
    "top10_hotels_missing_properties": "Niektóre obiekty mogą nie być wyświetlane. Przepraszamy za utrudnienia.",
    "adventure": "Przygoda",
    "lbg.h1.destination_theme.sustainable": "Laureaci w kategorii Obiekty promujące zrównoważony rozwój ({0})",
    "luxury": "Luksus",
    "expand_map_view": "Rozwiń widok mapy",
    "book_now": "Zarezerwuj teraz",
    "lbg.h1.destination_theme.ski": "Laureaci w kategorii Obiekty w regionach narciarskich ({0})",
    "hub.category.h1.food": "{0} — gastronomia",
    "category.blogs": "Blog",
    "budget": "Niskie ceny",
    "popular_neighbourhoods_in": "Popularne dzielnice w {0}",
    "hub.category.h1.unusual": "{0} – ukryte perełki i niezwykłe miejsca",
    "see_all_accommodation_in": "Wyświetl oferty zakwaterowania ({0})",
    "hub.category.h1.adventure": "{0} – aktywne podróże",
    "category_highlight.see_all.shopping": "Więcej o zakupach",
    "lbg.section_most_popular_destination": "Najpopularniejsze kierunki podróży",
    "lbg.section_properties_theme.luxury": "Laureaci w kategorii Luksusowe {0}",
    "unusual": "Nietypowo",
    "category.shopping": "Zakupy",
    "make_the_most_of_your_trip": "Skorzystaj jak najwięcej z podróży",
    "x_review": "{0,choice,0#|1#{0} opinia|2#{0} opinie|2<{0} opinie|4<{0} opinii|10#{0} opinii|10<{0} opinii}",
    "hub.category.h1.single": "{0} – podróże w pojedynkę",
    "hub.category.h2.custom": "Popularne",
    "lbg.section_theme_world_winners.lgbt": "Laureaci w kategorii Obiekty LGBTQ+",
    "lbg.section_award_winners_theme_other": "Laureaci w innych kategoriach ({0})",
    "lbg.supplier_collected_charges": "+ opłaty w wysokości {0} do zapłaty w obiekcie",
    "in_destination": "— {0}",
    "lbg.section_properties_theme.vip_access": "Laureaci w kategorii Obiekty VIP Access ({0})",
    "link_long_form_to_listiicle_single": "{0} — jest jedną z atrakcji z kategorii {1} (więcej informacji o miejscu takim jak {2} znajdziesz tutaj)",
    "lbg.section_theme_world_winners.boutique": "Laureaci w kategorii Butikowe",
    "lbg.section_properties_theme.spa_hotel": "Laureaci w kategorii Obiekty ze spa ({0})",
    "lbg.section_theme_world_winners.all_inclusive": "Laureaci w kategorii All Inclusive",
    "hub.category.h2": "{0} — przewodnik",
    "lbg.properties.price_tooltip": "To jest najniższa cena za noc na naszej stronie na następne 14 dni.",
    "hub.category.h2.road_trips": "{0} — najlepsze wycieczki samochodowe",
    "hub.category.h1.ski": "{0} — ośrodki narciarskie",
    "food": "Jedzenie",
    "lbg.theme.pet_friendly": "Przyjazne dla zwierząt",
    "lbg.theme.business": "Biznesowe",
    "hub.category.h1.nature": "{0} — najlepsze wycieczki z wypoczynkiem na łonie natury",
    "map": "Mapa",
    "category_highlight.see_all.information": "Więcej informacji",
    "hotel_prices_in": "Ceny hoteli w {0}",
    "hub.category.h1.nightlife": "{0} — nocne życie",
    "trust_module.free_cancellation.most_hotels": "w większości hoteli*",
    "also_popular_in": "{0} — inne popularne miejsca w tym rejonie",
    "lbg.h1.destination_theme.business": "Laureaci w kategorii Biznesowe {0}",
    "lbg.section_theme_world_winners.pet_friendly": "Laureaci w kategorii Przyjazne dla zwierząt",
    "hub.category.h1.maps": "{0} – mapy",
    "lbg.section_properties_theme.most_wanted": "Laureaci w kategorii Najpopularniejsze {0}",
    "category.nightlife": "Życie nocne",
    "search_hotels_in_this_area": "Znajdź hotele<span class=\"extra-label\"> na tym obszarze</span>",
    "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 głos|2#2 głosy|2<{1} głosy|4<{1} głosów|10#10 głosów|10<{1} głosów})",
    "hub.category.h2.business": "{0} – najlepsze podróże biznesowe",
    "category.things_to_do": "Atrakcje",
    "destination_travel_guide_by_category": "{0} Przewodnik z podziałem na kategorie",
    "hub.category.h1.families": "{0} – podróże rodzinne",
    "lbg.section_theme_world_winners.ski": "Laureaci w kategorii Obiekty w regionach narciarskich",
    "you_may_also_like": "Może Cię także zainteresować",
    "lbg.theme.sustainable": "Zrównoważony rozwój",
    "hub.category.h2.unusual": "{0} – najlepsze ukryte perełki i niezwykłe miejsca",
    "hub.category.h2.football": "Kibicuj swojej drużynie i baw się świetnie przez całą podróż!",
    "top_hotel": "Najlepszy hotel",
    "lbg.h1.destination_theme.luxury": "Laureaci w kategorii Luksusowe {0}",
    "top10_hotels_empty_alert": "Wystąpił błąd techniczny. <span>Przepraszamy za utrudnienia.</span>",
    "special_deal": "Oferta specjalna",
    "lbg.h2.sustainable": "Ekologia przede wszystkim! Sprawdź te obiekty, które gwarantują udany pobyt.",
    "where_to_stay_in": "Gdzie nocować w {0}",
    "start_planning_your_trip": "Zacznij planować swoją podróż",
    "good_for": "Główne atrakcje / typy podróżnych:",
    "category.hotels": "Hotele",
    "x_accommodation_guide": "{0} — przewodnik po opcjach zakwaterowania",
    "lbg.theme.luxury": "Luksusowe",
    "hub.category.h2.sports": "{0} – najlepsze sporty i aktywności",
    "lbg.section_theme_world_winners.lgbtq": "Laureaci w kategorii Obiekty LGBTQ",
    "see_more_neighbourhoods": "Zobacz więcej dzielnic",
    "category_highlight.title.nightlife": "{0} — gdzie można pójść i co robić wieczorem",
    "lbg.h1.destination_theme.lgbt": "Laureaci w kategorii Obiekty LGBTQ+ ({0})",
    "hub.category.h1.blogs": "{0} — blog podróżniczy",
    "lbg.h2.business": "Te obiekty są wysoko oceniane przez podróżnych biznesowych.",
    "hotels": "Hotele",
    "lbg.h2.pet_friendly": "Goście gorąco polecają te obiekty, które akceptują zwierzęta.",
    "popular_landmarks_nearby": "Popularne punkty orientacyjne w sąsiedztwie",
    "popular_extractions_in": "Popularne atrakcje w {0}",
    "lbg.h2.all_inclusive": "W tych najwyżej ocenianych obiektach możesz rezerwować noclegi bez obaw.",
    "travel_guide_by_category_header": "{0} — {1}",
    "lbg.travel_between": "Podróż między {0} a {1}",
    "keep_exploring": "Szukaj dalej",
    "what_you_need_to_know_before_you_go": "Co warto wiedzieć przed przyjazdem",
    "read_it": "Przeczytaj",
    "view_on_map": "Zobacz na mapie",
    "lbg.h1.destination_theme.lgbtq": "Laureaci w kategorii Obiekty LGBTQ ({0})",
    "lbg.h2.beach": "Goście uwielbiają wakacje przy plaży, zwłaszcza w tych obiektach.",
    "lbg.h1.destination_theme.vip_access": "Laureaci w kategorii Obiekty VIP Access ({0})",
    "see_all_accommodation": "Wyświetl wszystkie oferty zakwaterowania",
    "shoppers": "Zakupoholicy",
    "whats_around": "{0} — atrakcje w pobliżu",
    "group": "Grupa",
    "hub.category.h2.couples": "{0} – najlepsze podróże dla par",
    "hub.category.h1.shopping": "{0} — zakupy",
    "travel_community": "{0} <span>&#124;</span> <span>Wspólnota podróżnych</span>",
    "hub.category.h2.group": "{0} – najlepsze podróże grupowe",
    "listicle_legal_msg": "Artykuł zawiera opinie zespołu redakcyjnego Go Guides. Autorzy tekstów publikowanych na tej stronie otrzymują od firmy Hotels.com wynagrodzenie, które może obejmować m.in. koszty podróży.",
    "lbg.h1.destination_theme.cleanliness": "Laureaci w kategorii Czystość ({0})",
    "menu.more": "Więcej",
    "lbg.section_properties_theme.lgbtq": "Laureaci w kategorii Obiekty LGBTQ ({0})",
    "lbg.section_award_winners_destination": "Laureaci według kierunku podróży",
    "lbg.terms_conditions": "Zasady i warunki",
    "hub.category.h2.nightlife": "{0} — życie nocne",
    "hub.neighbourhood.h2": "{0} — atrakcje",
    "lbg.section_award_winners_theme_destination": "Laureaci według kategorii ({0})",
    "lbg.section_award_world_winners": "Laureaci z całego świata",
    "things_to_see_and_do_in_other_cities": "Co zobaczyć i co robić w innych miastach",
    "button.backToTop": "Do góry",
    "lbg.section_theme_world_winners.family": "Laureaci w kategorii Rodzinne",
    "hotels_near": "Hotele w okolicy — {0}",
    "category.activity__sports_": "Zajęcia",
    "trust_module.get_reward_night": "Bonusowy nocleg:",
    "hub.category.h1.business": "{0} – podróże biznesowe",
    "category.food": "Jedzenie",
    "other_popular_destinations_in": "{0} — inne popularne kierunki podróży",
    "browse_by_category": "{0} — przeglądaj wg kategorii",
    "see_more_attactions": "Zobacz więcej atrakcji",
    "lbg.theme.lgbt": "LGBTQ+",
    "more_information_about": "{0} — więcej informacji",
    "lbg.h1.destination_theme.most_wanted": "Laureaci w kategorii Najpopularniejsze {0}",
    "see_all_properties_in": "Zobacz obiekty w mieście {0}",
    "category.tours": "Wycieczki",
    "hub.category.h1.sports": "{0} – sporty i aktywności",
    "where_will_you_go_next": "Dokąd chcesz wyjechać?",
    "cities": "Miasta",
    "lbg.theme.spa_hotel": "Spa",
    "see_guide": "Zobacz przewodnik",
    "lbg.section_properties_theme.ski": "Laureaci w kategorii Obiekty w regionach narciarskich ({0})",
    "related_stories": "Powiązane tematy",
    "hub.destination.h2": "Co warto wiedzieć przed przyjazdem",
    "show_less": "Pokaż mniej",
    "hub.category.h1.football": "{0} — wyjątkowy przewodnik dla prawdziwych fanów piłki nożnej",
    "hub.category.h2.food": "{0} — najlepsze restauracje",
    "fallback_language_alert": "Ta strona nie została jeszcze przetłumaczona na Twój język...",
    "category_highlight.title.information": "Przydatne informacje",
    "neighbourhood_guides_for_other_cities": "Przewodniki po dzielnicach innych miast",
    "lbg.h2.hubpage": "Fantastyczne obiekty, którym goście przyznali w wielu opiniach wysokie oceny.",
    "travel_advisory.title": "Powiadomienie dotyczące podróży",
    "lbg.section_properties_theme.all_inclusive": "Laureaci w kategorii All Inclusive ({0})",
    "lbg.h2.boutique": "Jeśli lubisz butikowe hotele, goście polecają ten obiekt.",
    "lbg.section_properties_theme.family": "Laureaci w kategorii Rodzinne {0}",
    "hub.category.h2.families": "{0} – najlepsze podróże rodzinne",
    "from": "od",
    "lbg.theme.vip_access": "VIP Access",
    "lbg.section_properties_theme.lgbt": "Laureaci w kategorii Obiekty LGBTQ+ ({0})",
    "x_star": "{0,choice,0#|1#{0}-gwiazdkowy|1.5#{0}-gwiazdkowy|2#{0}-gwiazdkowy|2.5#{0}-gwiazdkowy|3#{0}-gwiazdkowy|3.5#{0}-gwiazdkowy|4#{0}-gwiazdkowy|4.5#{0}-gwiazdkowy|5#{0}-gwiazdkowy}",
    "trust_module.free_cancellation": "Bezpłatne anulowanie:",
    "lbg.section_properties_theme.home_and_apartments": "Laureaci w kategorii Domy i apartamenty ({0})",
    "contributing_writer": "{0} <span>&#124;</span> <span>Autor</span>",
    "things_to_see_and_do_in": "Co zobaczyć i co robić <span class=\"next-line\">w {0}</span>",
    "lbg.check_price": "Sprawdź cenę",
    "hub.category.h2.blogs": "według doświadczonych podróżnych",
    "lbg.h2.home_and_apartments": "Szukasz domu z dala od domu? Sprawdź te wysoko oceniane obiekty.",
    "hub.category.h2.information": "{0} — przydatne informacje",
    "search_hotels_in": "Znajdź hotele<span class=\"extra-label\"> w {0}</span>",
    "hub.category.h2.nature": "{0} — najlepsze wycieczki przyrodnicze",
    "lbg.h1.destination_theme.home_and_apartments": "Laureaci w kategorii Domy i apartamenty ({0})",
    "photo_by": "zdjęcie: {0}",
    "more_things_to_do": "Więcej atrakcji",
    "lbg.section_properties_theme.beach": "Laureaci w kategorii Obiekty przy plaży ({0})",
    "read_less": "Mniej informacji",
    "lbg.h2.spa_hotel": "Odpręż się i zrelaksuj w tych najwyżej ocenianych obiektach, które słyną ze swoich spa.",
    "second_fold_related_stories.see_also": "Zobacz też",
    "hub.category.h2.history": "{0} – najlepsze historyczne miejsca",
    "lbg.section_properties_theme.cleanliness": "Laureaci w kategorii Czystość ({0})",
    "lbg.h1.destination_theme.boutique": "Laureaci w kategorii Butikowe {0}",
    "nightlife_b595ae4": "Życie nocne",
    "lbg.h1.destination_theme.beach": "Laureaci w kategorii Obiekty przy plaży ({0})",
    "trust_module.free_cancellation.disclaimer": "Niektóre hotele wymagają anulowania rezerwacji na co najmniej 24–48 godzin przed datą zameldowania. Szczegóły znajdziesz na naszej stronie.",
    "lbg.h2.family": "Te obiekty są wysoko oceniane przez gości i idealne dla rodzin.",
    "lbg.h2.lgbtq": "Te obiekty przyjazne osobom LGBTQ zostały wysoko ocenione przez gości.",
    "hub.category.h1.group": "{0} – podróże grupowe",
    "popular_stories": "Popularne historie",
    "toc": "Treści",
    "hub.category.h2.shopping": "{0} — najlepsze miejsca na zakupy",
    "lbg.section_theme_world_winners.beach": "Laureaci w kategorii Obiekty przy plaży",
    "page_score": "Ocena tej strony",
    "lbg.section_theme_world_winners.sustainable": "Laureaci w kategorii Obiekty promujące zrównoważony rozwój",
    "lbg.theme.boutique": "Butikowe",
    "trust_module.price_guarantee": "Gwarancja ceny:",
    "lbg.h1.destination_theme.all_inclusive": "Laureaci w kategorii All Inclusive ({0})",
    "top_destinations": "Najpopularniejsze kierunki podróży",
    "hotel_prices_near": "Ceny hoteli w pobliżu {0}",
    "lbg.score.cleanliness": "Czystość: ",
    "x_stars": "{0,choice,0#|1#{0} gwiazdka|1<{0} gwiazdki|4<{0} gwiazdek|10<{0} gwiazdki}",
    "lbg.theme.all_inclusive": "All inclusive",
    "hub.category.h1.tours": "{0} — wycieczki",
    "lbg.section_award_winners_theme": "Laureaci według motywu",
    "guest_writer": "{0} <span>&#124;</span> <span>Artykuł gościnny</span>",
    "start_planning_your_x_trip": "{0} — zacznij planować swoją podróż",
    "lbg.section_theme_world_winners.business": "Laureaci w kategorii Biznesowe",
    "trending_now_in": "{0} — co jest teraz na topie",
    "history": "Historia",
    "search_hotels": "Znajdź hotele",
    "lbg.terms_conditions_details": "Kryteria wyboru jakiegokolwiek obiektu na zdobywcę nagrody w {0} roku były oparte na jego wysokich ocenach gości i minimum 50 opiniach napisanych przez gości w {1} roku.",
    "see_nearby_properties": "Zobacz obiekty w pobliżu",
    "hub.category.h1.history": "{0} – historyczne miejsca",
    "location": "Lokalizacja:",
    "lbg.section_theme_world_winners.luxury": "Laureaci w kategorii Luksusowe",
    "category_highlight.see_all.nightlife": "Więcej o życiu nocnym",
    "also_popular": "Także popularne",
    "hub.category.h2.things_to_do": "{0} — najlepsze atrakcje",
    "lbg.theme.family": "Rodzinne",
    "lbg.h1.destination_theme.pet_friendly": "Laureaci w kategorii Przyjazne dla zwierząt {0}",
    "category_highlight.title.shopping": "{0} — co i gdzie można kupić",
    "price": "Cena:",
    "hub.category.h2.beach": "{0} — najlepsze plaże",
    "neighbourhoods_in": "{0} — dzielnice",
    "business": "Biznes",
    "photo": "Zdjęcie",
    "blog.see_all_stories": "Wyświetl wszystkie historie",
    "category_highlight.see_all.food": "Więcej o ofercie gastronomicznej",
    "see_all_properties": "Zobacz wszystkie obiekty",
    "lbg.theme.beach": "Plaża",
    "what_to_see_and_do_in": "{0} — co można zwiedzać i co robić",
    "hub.category.h2.single": "{0} – najlepsze podróże w pojedynkę",
    "attractions_in": "Atrakcje — {0}",
    "read_more": "Więcej informacji",
    "show_more": "Pokaż więcej",
    "hotels_near_this_landmark": "Hotele w pobliżu tego punktu",
    "check_dates": "Sprawdź daty",
    "featured_stories_and_fun_stuff": "Wyróżnione artykuły i ciekawostki",
    "phone": "Telefon:",
    "lbg.section_search_header": "Wyszukaj laureatów",
    "category_highlight.title.food": "{0} — co i gdzie można zjeść",
    "see_all_things_to_do": "Wyświetl wszystkie atrakcje",
    "lbg.section_properties_theme.sustainable": "Laureaci w kategorii Obiekty promujące zrównoważony rozwój ({0})",
    "lbg.h1.hubpage": "Laureaci nagród w programie Uwielbiany przez gości w {0} r.",
    "lbg.section_theme_world_winners.spa_hotel": "Laureaci w kategorii Obiekty ze spa",
    "category.maps": "Mapy",
    "lbg.h1.destination_theme.family": "Laureaci w kategorii Rodzinne {0}",
    "lbg.section_theme_world_winners.cleanliness": "Laureaci w kategorii Czystość",
    "lbg.section_theme_world_winners.vip_access": "Laureaci w kategorii Obiekty VIP Access",
    "trust_module.get_reward_night.night_you_stay": "za każde 10 nocy pobytu",
    "modified": "zostało poddane edycji",
    "lbg.section_properties": "Laureaci ({0})",
    "trust_module.header": "Dlaczego warto rezerwować na Hotels.com?",
    "hub.category.h2.luxury": "{0} – najlepsze podróże luksusowe",
    "lbg.theme.ski": "W regionie narciarskim",
    "lbg.h1.destination_theme.spa_hotel": "Laureaci w kategorii Obiekty ze spa ({0})",
    "lbg.h2.most_wanted": "Goście gorąco polecają te obiekty, jeśli chcesz mieć udany pobyt.",
    "top10_hotels_incomplete_alert": "Nasza strona jest obecnie przeciążona, więc niektóre obiekty mogą nie być wyświetlane. <span>Przepraszamy za utrudnienia.</span>",
    "compulsive_traveller": "{0} <span>&#124;</span> <span>Pasjonat podróży</span>",
    "lbg.section_theme_world_winners.home_and_apartments": "Laureaci w kategorii Domy i apartamenty",
    "loved_by_guests_collection_in": "{0} — kolekcja „Uwielbiane przez gości”",
    "title.suffix": "{0} — Go Guides",
    "hub.category.h2.maps": "{0} — przydatne mapy",
    "opening_hours": "Godziny otwarcia",
    "hub.category.h2.photos": "{0} – najlepsze podróże z aparatem",
    "lbg.theme.most_wanted": "Najpopularniejsze",
    "blog.stories_container": "Historie twórców",
    "hub.category.h2.budget": "{0} – najlepsze tanie podróże",
    "lbg.section_properties_theme.business": "Laureaci w kategorii Biznesowe {0}",
    "not_sure_where_to_stay": "Nie wiesz, gdzie zanocować?",
    "category.information": "Informacje"
}
