import { checkValue } from 'components/functions';
import React from 'react';
import { getLocalizedText } from 'src/utils/helper';
import { UitkFigure, UitkFigureAspectRatioType, UitkImage } from 'uitk-react-images';
import { UitkParagraph, UitkText } from 'uitk-react-text';
import { useStores } from 'utils/useStore';

const GoGuidesWriterProfileComponent: React.FC = () => {
  const { goGuidesStore } = useStores();

  const contentData = goGuidesStore.fetchWriter(goGuidesStore.pageUrlData);

  let writerName;
  let writerImageUrl;
  let writerDescriptorKey;

  if (checkValue(contentData)) {
    const { writer } = contentData;
    if (checkValue(writer)) {
      writerName = writer.name;
      writerImageUrl = writer.imageUrl;
      writerDescriptorKey = writer.descriptorKey;
    }
  }

  return (
    <>
      {checkValue(writerName) && (
        <div className="author-wrap">
          <div className="media-wrap">
            <div className="author-profile">
              <UitkFigure className="img" ratio={UitkFigureAspectRatioType.R1_1} roundcorner={['max']}>
                <UitkImage blankDefault={false} lazyLoading="lazy" alt="" src={writerImageUrl} />
              </UitkFigure>
              <div className="profile-wrap">
                {writerDescriptorKey != 'none' ? (
                  <div
                    className="author-name"
                    dangerouslySetInnerHTML={{ __html: getLocalizedText(writerDescriptorKey, writerName) }}
                  />
                ) : (
                  <div className="author-name">
                    <UitkParagraph>
                      <UitkText inline="strong">{writerName}</UitkText>
                    </UitkParagraph>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GoGuidesWriterProfileComponent;
