import React from 'react';
import { ImageAttribution } from 'components/types/goguides-types.d';
import { localization } from 'utils/localization';

interface ImageAttributionProps {
  attribution?: ImageAttribution;
}
const ImageAttribution: React.FC<ImageAttributionProps> = (props) => {
  const { attribution } = props;
  const loc = new localization();

  const attributionLink =
    attribution.linkToOriginal
      ? `<a href="${attribution.linkToOriginal}" target="_blank" rel="noreferrer">${attribution.photographerName}</a> `
      : attribution.photographerName;

  const attributionPageLink = attribution.linkToAttributionPage
    ? `(<a href="${attribution.linkToAttributionPage}" target="_blank" rel="noreferrer">${attribution.attributionVersion}</a>) `
    : '';

  const attributionModified = loc.getLocalizedText('modified');
  const attributionPhotoBy = loc.getLocalizedText('photo_by', attributionLink);

  return (
    <p
      className="photo-attribution"
      dangerouslySetInnerHTML={{
        __html: `${attributionPhotoBy} ${attributionPageLink} ${attributionModified}`,
      }}
    />
  );
};
export default ImageAttribution;
