import { useStores } from './useStore';
import { AVAILABLE_LANGUAGES } from './localization-data';

export class localization {
  private locale = 'en_gb';
  private availableLanguages = [];

  public constructor() {
    const { context } = useStores();
    this.locale = context?.locale;
    this.availableLanguages = AVAILABLE_LANGUAGES[this.locale.toLocaleLowerCase()];
  }
  
  public getLocalizedText(msgKey: string, ...args: any) {
    let msg = this.availableLanguages[msgKey];
    if (msg && args && args.length) {
      args.map((arg, idx) => {
        msg = msg.replace(`{${idx}}`, arg);
      });
    }
    return msg;
  }

  public getSpecificLocText(locale: string, msgKey: string, ...args: any) {
    let msg = AVAILABLE_LANGUAGES[locale.toLocaleLowerCase()][msgKey];
    if (msg && args && args.length) {
      args.map((arg, idx) => {
        msg = msg.replace(`{${idx}}`, arg);
      });
    }
    return msg;
  }

}
