import React, { useState } from 'react';
import { checkValue } from 'components/functions';
import KeepExploringSub from './keep-exploring-sub';
import { UitkExpandoPeek } from 'uitk-react-expando';
import { localization } from 'src/utils/localization';
import { Viewport, ViewLarge, ViewSmall } from "@shared-ui/viewport-context";

export interface KeepExploringContinentProps {
  listing: any,
  isHomePage?: boolean | false
  worldLevel?: boolean | false
}

const KeepExploringContinent: React.FC<KeepExploringContinentProps> = (props) => {
  const { listing, isHomePage, worldLevel } = props;

  const [isVisible, setIsVisible] = useState(false);
  const loc = new localization();

  /*
  // PAGE TYPE CLASS NAME
  //  <div class="list-container {if $pageId == null}hp{/if}">
  */

  if (!checkValue(listing.continents)) {
    return null;
  } else {
    return (
      <>
        <div className={isHomePage || worldLevel ? 'hp list-container' : 'list-container'} >
          {listing.continents.map((continent, idx) => {
            return (
              <div className="list-continent" key={idx}>

                {idx === 0 ? (
                  <Viewport>
                    <ViewSmall>
                      <UitkExpandoPeek
                        isVisible={isVisible}
                        handleClick={() => setIsVisible((prevIsVisible) => !prevIsVisible)}
                        lines={6}
                        whitespace={false}
                        expandLabel={loc.getLocalizedText('show_more')}
                        collapseLabel={loc.getLocalizedText('show_less')}
                      >
                        <KeepExploringSub level="continent" listing={continent} />
                      </UitkExpandoPeek>
                    </ViewSmall>
                    <ViewLarge>
                      <KeepExploringSub level="continent" listing={continent} />
                    </ViewLarge>
                  </Viewport>
                  ) : (
                    <KeepExploringSub level="continent" listing={continent} />
                  )
                }
              </div>

            )
          })}
        </div>
      </>
    );
  }
};

export default KeepExploringContinent;
