export default {
    "current_deal": "現時價格",
    "lbg.section_properties_theme.pet_friendly": "{0}得獎寵物友善住宿",
    "address": "地址",
    "hub.homepage.h2": "世界大，時間少...",
    "search_hotels_near_this_landmark": "搜尋<span class=\"extra-label\">此地標附近的</span>酒店",
    "hub.category.h1.things_to_do": "{0}當地玩樂",
    "lbg.h2.vip_access": "VIP Access 住宿服務備受好評，給你頂級入住體驗。",
    "lbg.theme.cleanliness": "清潔",
    "lbg.theme.lgbtq": "LGBTQ",
    "load_more": "載入更多",
    "link_long_form_to_listiicle_multiple": "{0}名列{1}和{2} (閱讀有關{3}的資料)",
    "lbg.section_properties_theme.boutique": "{0}得獎精品住宿",
    "lbg.h2.luxury": "豪華極致體驗，旅客高度推薦。",
    "hub.category.h1.information": "{0}旅遊資訊",
    "families": "家庭",
    "trending_now": "流行資訊",
    "lbg.from": "低至",
    "hub.category.h1.road_trips": "{0}最佳公路旅程",
    "lbg.h2.cleanliness": "這些住宿的整體旅客評分優良，而且客房整潔度亦獲得最高的旅客評分。",
    "single": "單人旅客",
    "couples": "伴侶",
    "trust_module.price_guarantee.find_out_more": "了解更多",
    "top_destinations_in": "{0}熱門目的地",
    "hub.category.h1.couples": "{0}情侶行程",
    "hub.category.h2.tours": "{0}最佳遊覽團",
    "hub.category.h1.photos": "{0}打卡行程",
    "category.photos": "相片",
    "hub.category.h1.budget": "{0}平價行程",
    "hub.category.h2.ski": "{0}最佳滑雪目的地",
    "lbg.section_theme_world_winners.most_wanted": "得獎最受歡迎住宿",
    "lbg.theme.home_and_apartments": "民宿及公寓",
    "hub.category.h1.luxury": "{0}豪華行程",
    "hub.category.h2.adventure": "{0}熱門戶外行程",
    "hotels_in": "{0}酒店",
    "lbg.section_search_winners": "搜尋更多得獎住宿",
    "see_more_hotels_in": "查看更多{0}酒店",
    "hub.category.h1.beach": "{0}海灘",
    "open": "開放時間：",
    "enter_your_destination": "請輸入目的地",
    "best_of": "{0}之最",
    "top10_hotels_missing_properties": "某些住宿現在可能沒有顯示。不便之處，懇請原諒。",
    "adventure": "遊歷",
    "lbg.h1.destination_theme.sustainable": "{0}得獎環保住宿",
    "luxury": "豪華住宿",
    "expand_map_view": "展開地圖檢視",
    "book_now": "立即預訂",
    "lbg.h1.destination_theme.ski": "{0}得獎滑雪住宿",
    "hub.category.h1.food": "{0}吃貨",
    "category.blogs": "網誌",
    "budget": "經濟實惠",
    "popular_neighbourhoods_in": "{0}熱門區域",
    "hub.category.h1.unusual": "{0}秘景",
    "see_all_accommodation_in": "查看所有{0}住宿",
    "hub.category.h1.adventure": "{0}戶外行程",
    "category_highlight.see_all.shopping": "更多購物推介",
    "lbg.section_most_popular_destination": "最熱門目的地",
    "lbg.section_properties_theme.luxury": "{0}得獎豪華住宿",
    "unusual": "非一般行程",
    "category.shopping": "購物",
    "make_the_most_of_your_trip": "盡享行程",
    "x_review": "{0,choice,0#|1#{0} 則評價|2#{0} 則評價|2<{0} 則評價|4<{0} 則評價|10#{0} 則評價|10<{0} 則評價}",
    "hub.category.h1.single": "{0}獨遊行程",
    "hub.category.h2.custom": "今期熱玩",
    "lbg.section_theme_world_winners.lgbt": "性別友善+ 得獎住宿",
    "lbg.section_award_winners_theme_other": "按主題分類的{0}其他得獎住宿",
    "lbg.supplier_collected_charges": "+ {0} 入住時須付的收費",
    "in_destination": "{0}",
    "lbg.section_properties_theme.vip_access": "{0}得獎 VIP Access 住宿",
    "link_long_form_to_listiicle_single": "{0}名列{1} (閱讀有關{2}的資料)",
    "lbg.section_theme_world_winners.boutique": "得獎精品住宿",
    "lbg.section_properties_theme.spa_hotel": "{0}得獎 SPA 住宿",
    "lbg.section_theme_world_winners.all_inclusive": "得獎全包式住宿",
    "hub.category.h2": "{0}旅遊指南",
    "lbg.properties.price_tooltip": "這是未來 14 日我們網站的每晚最低價格。",
    "hub.category.h2.road_trips": "{0}最佳公路旅程",
    "hub.category.h1.ski": "{0}滑雪目的地",
    "food": "品嚐美食",
    "lbg.theme.pet_friendly": "寵物友善住宿",
    "lbg.theme.business": "商務住宿",
    "hub.category.h1.nature": "{0}最佳大自然出走之旅",
    "map": "地圖",
    "category_highlight.see_all.information": "更多詳情",
    "hotel_prices_in": "{0}酒店房價",
    "hub.category.h1.nightlife": "{0}夜生活",
    "trust_module.free_cancellation.most_hotels": "適用於大部分酒店*",
    "also_popular_in": "{0}其他熱門去處",
    "lbg.h1.destination_theme.business": "{0}得獎商務住宿",
    "lbg.section_theme_world_winners.pet_friendly": "得獎寵物友善住宿",
    "hub.category.h1.maps": "{0}地圖",
    "lbg.section_properties_theme.most_wanted": "{0}得獎最受歡迎住宿",
    "category.nightlife": "晚間娛樂",
    "search_hotels_in_this_area": "搜尋<span class=\"extra-label\">區內</span>酒店",
    "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 票|2#2 票|2<{1} 票|4<{1} 票|10#10 票|10<{1} 票})",
    "hub.category.h2.business": "{0}熱門商務行程",
    "category.things_to_do": "景點",
    "destination_travel_guide_by_category": "{0}旅遊指南",
    "hub.category.h1.families": "{0}親子行程",
    "lbg.section_theme_world_winners.ski": "得獎滑雪住宿",
    "you_may_also_like": "你可能亦喜歡",
    "lbg.theme.sustainable": "環保住宿",
    "hub.category.h2.unusual": "{0}熱門秘景",
    "hub.category.h2.football": "支持心愛球隊，旅程加倍精彩！",
    "top_hotel": "優異酒店",
    "lbg.h1.destination_theme.luxury": "{0}得獎豪華住宿",
    "top10_hotels_empty_alert": "我們遇到技術問題。<span>不便之處，懇請原諒。</span>",
    "special_deal": "特別優惠",
    "lbg.h2.sustainable": "享受環保住宿體驗，踏上全新難忘旅程。",
    "where_to_stay_in": "{0}住宿推介",
    "start_planning_your_trip": "開始計劃行程",
    "good_for": "推介：",
    "category.hotels": "酒店",
    "x_accommodation_guide": "{0}住宿指南",
    "lbg.theme.luxury": "豪華住宿",
    "hub.category.h2.sports": "{0}熱門運動和活動",
    "lbg.section_theme_world_winners.lgbtq": "得獎 LGBTQ 住宿",
    "see_more_neighbourhoods": "查看更多區域",
    "category_highlight.title.nightlife": "{0}夜生活推介",
    "lbg.h1.destination_theme.lgbt": "{0}性別友善+ 得獎住宿",
    "hub.category.h1.blogs": "{0}旅遊網誌",
    "lbg.h2.business": "一流住宿體驗，商務旅客首選。",
    "hotels": "酒店",
    "lbg.h2.pet_friendly": "毛孩放心入住，旅客出遊之選。",
    "popular_landmarks_nearby": "鄰近熱門地標",
    "popular_extractions_in": "{0}熱門景點",
    "lbg.h2.all_inclusive": "入住熱門住宿，煩惱一掃而空。",
    "travel_guide_by_category_header": "{1}{0}",
    "lbg.travel_between": "住宿期限：{0} - {1}",
    "keep_exploring": "繼續探索",
    "what_you_need_to_know_before_you_go": "出發前須知",
    "read_it": "閱讀",
    "view_on_map": "於地圖上查看",
    "lbg.h1.destination_theme.lgbtq": "{0}得獎 LGBTQ 住宿",
    "lbg.h2.beach": "海灘休閒度假，難忘住宿體驗。",
    "lbg.h1.destination_theme.vip_access": "{0}得獎 VIP Access 住宿",
    "see_all_accommodation": "查看所有住宿",
    "shoppers": "購物",
    "whats_around": "{0}一帶的景點",
    "group": "團體",
    "hub.category.h2.couples": "{0}熱門情侶行程",
    "hub.category.h1.shopping": "{0}購物",
    "travel_community": "{0} <span>&#124;</span> <span>旅遊群組</span>",
    "hub.category.h2.group": "{0}熱門團體行程",
    "listicle_legal_msg": "此文章含有 Go Guides 編輯團隊的個人意見。Hotels.com 會向於本網站上撰文的作者付費，其中或包括旅遊及其他費用。",
    "lbg.h1.destination_theme.cleanliness": "{0}得獎清潔住宿",
    "menu.more": "更多",
    "lbg.section_properties_theme.lgbtq": "{0}得獎 LGBTQ 住宿",
    "lbg.section_award_winners_destination": "按目的地分類的得獎住宿",
    "lbg.terms_conditions": "條款及細則",
    "hub.category.h2.nightlife": "{0}最佳晚間娛樂",
    "hub.neighbourhood.h2": "{0}好去處",
    "lbg.section_award_winners_theme_destination": "按主題分類的{0}得獎住宿",
    "lbg.section_award_world_winners": "全球得獎住宿",
    "things_to_see_and_do_in_other_cities": "其他城市的觀光和活動",
    "button.backToTop": "返回頁首",
    "lbg.section_theme_world_winners.family": "得獎家庭住宿",
    "hotels_near": "{0}附近的酒店",
    "category.activity__sports_": "活動",
    "trust_module.get_reward_night": "賺取獎賞住宿",
    "hub.category.h1.business": "{0}商務行程",
    "category.food": "美食",
    "other_popular_destinations_in": "{0}其他人氣目的地",
    "browse_by_category": "{0}分類指南",
    "see_more_attactions": "查看更多景點",
    "lbg.theme.lgbt": "性別友善+",
    "more_information_about": "有關{0}的更多資料",
    "lbg.h1.destination_theme.most_wanted": "{0}得獎最受歡迎住宿",
    "see_all_properties_in": "查看所有{0}住宿",
    "category.tours": "導覽",
    "hub.category.h1.sports": "{0}運動和活動",
    "where_will_you_go_next": "下一站去邊好？",
    "cities": "城市",
    "lbg.theme.spa_hotel": "SPA 住宿",
    "see_guide": "查看指南",
    "lbg.section_properties_theme.ski": "{0}得獎滑雪住宿",
    "related_stories": "相關介紹",
    "hub.destination.h2": "出發前須知",
    "show_less": "顯示較少",
    "hub.category.h1.football": "球迷終極{0}指南",
    "hub.category.h2.food": "{0}最佳飲食",
    "fallback_language_alert": "很抱歉，此頁尚未翻譯成你的語言…",
    "category_highlight.title.information": "實用資料",
    "neighbourhood_guides_for_other_cities": "其他城市的旅遊指南",
    "lbg.h2.hubpage": "這些一流的住宿擁有很高的旅客評分和大量旅客評價。",
    "travel_advisory.title": "旅遊提示",
    "lbg.section_properties_theme.all_inclusive": "{0}得獎全包式住宿",
    "lbg.h2.boutique": "人氣精品住宿，旅客一致推崇。",
    "lbg.section_properties_theme.family": "{0}得獎家庭住宿",
    "hub.category.h2.families": "{0}熱門親子行程",
    "from": "低至",
    "lbg.theme.vip_access": "VIP Access",
    "lbg.section_properties_theme.lgbt": "{0}性別友善+ 得獎住宿",
    "x_star": "{0,choice,0#|1#{0} 星級|1.5#{0} 星級|2#{0} 星級|2.5#{0} 星級|3#{0} 星級|3.5#{0} 星級|4#{0} 星級|4.5#{0} 星級|5#{0} 星級}",
    "trust_module.free_cancellation": "免費取消",
    "lbg.section_properties_theme.home_and_apartments": "{0}得獎民宿及公寓",
    "contributing_writer": "{0} <span>&#124;</span> <span>特約撰稿人</span>",
    "things_to_see_and_do_in": "<span class=\"next-line\">{0}</span>觀光和活動",
    "lbg.check_price": "查看價格",
    "hub.category.h2.blogs": "由旅遊達人提供",
    "lbg.h2.home_and_apartments": "入住熱門住宿，享受家的感覺。",
    "hub.category.h2.information": "{0}實用資訊",
    "search_hotels_in": "搜尋<span class=\"extra-label\">{0}</span>酒店",
    "hub.category.h2.nature": "{0}最佳大自然旅程",
    "lbg.h1.destination_theme.home_and_apartments": "{0}得獎民宿及公寓",
    "photo_by": "相片由 {0} 提供",
    "more_things_to_do": "更多玩樂活動",
    "lbg.section_properties_theme.beach": "{0}得獎海灘住宿",
    "read_less": "閱讀較少資訊",
    "lbg.h2.spa_hotel": "入住著名 SPA 住宿，盡情充電再去玩。",
    "second_fold_related_stories.see_also": "延伸閱讀",
    "hub.category.h2.history": "{0}熱門歷史景點",
    "lbg.section_properties_theme.cleanliness": "{0}得獎清潔住宿",
    "lbg.h1.destination_theme.boutique": "{0}得獎精品住宿",
    "nightlife_b595ae4": "晚間娛樂",
    "lbg.h1.destination_theme.beach": "{0}得獎海灘住宿",
    "trust_module.free_cancellation.disclaimer": "部分酒店須於入住至少 24-48 小時前取消預訂，詳情請見網站。",
    "lbg.h2.family": "親子旅客大推，一家大細都愛。",
    "lbg.h2.lgbtq": "入住 LGBTQ 住宿，享受旅客最愛共融環境。",
    "hub.category.h1.group": "{0}團體行程",
    "popular_stories": "熱門文章",
    "toc": "目錄",
    "hub.category.h2.shopping": "{0}最佳購物",
    "lbg.section_theme_world_winners.beach": "得獎海灘住宿",
    "page_score": "此頁的得分為",
    "lbg.section_theme_world_winners.sustainable": "得獎環保住宿",
    "lbg.theme.boutique": "精品住宿",
    "trust_module.price_guarantee": "價格保證承諾",
    "lbg.h1.destination_theme.all_inclusive": "{0}得獎全包式住宿",
    "top_destinations": "熱門目的地",
    "hotel_prices_near": "{0}附近酒店房價",
    "lbg.score.cleanliness": "整潔度：",
    "x_stars": "{0,choice,0#|1#{0} 星級|1<{0} 星級|4<{0} 星級|10<{0} 星級}",
    "lbg.theme.all_inclusive": "全包式住宿",
    "hub.category.h1.tours": "{0}導賞團及一天遊",
    "lbg.section_award_winners_theme": "按主題分類的得獎住宿",
    "guest_writer": "{0} <span>&#124;</span> <span>自由撰稿人</span>",
    "start_planning_your_x_trip": "開始計劃你的{0}行程",
    "lbg.section_theme_world_winners.business": "得獎商務住宿",
    "trending_now_in": "{0}流行資訊",
    "history": "歷史文化",
    "search_hotels": "搜尋酒店",
    "lbg.terms_conditions_details": "住宿必須於 {1} 年獲得很高的旅客評分和至少 50 則旅客評價，方可獲選為 {0} 年得獎住宿。",
    "see_nearby_properties": "查看附近住宿",
    "hub.category.h1.history": "{0}歷史景點",
    "location": "位置：",
    "lbg.section_theme_world_winners.luxury": "得獎豪華住宿",
    "category_highlight.see_all.nightlife": "更多夜生活推介",
    "also_popular": "其他熱門旅遊勝地",
    "hub.category.h2.things_to_do": "{0}最佳景點",
    "lbg.theme.family": "家庭住宿",
    "lbg.h1.destination_theme.pet_friendly": "{0}得獎寵物友善住宿",
    "category_highlight.title.shopping": "{0}購物推介",
    "price": "價格：",
    "hub.category.h2.beach": "最佳{0}海灘",
    "neighbourhoods_in": "{0}附近一帶",
    "business": "商務出差",
    "photo": "拍照",
    "blog.see_all_stories": "瀏覽所有網誌",
    "category_highlight.see_all.food": "更多飲食推介",
    "see_all_properties": "查看所有住宿",
    "lbg.theme.beach": "海灘住宿",
    "what_to_see_and_do_in": "{0}玩樂推介",
    "hub.category.h2.single": "{0}熱門獨遊行程",
    "attractions_in": "{0}景點",
    "read_more": "閱讀更多資訊",
    "show_more": "顯示更多",
    "hotels_near_this_landmark": "此地標附近的酒店",
    "check_dates": "查看日期",
    "featured_stories_and_fun_stuff": "精選指南及趣聞",
    "phone": "電話：",
    "lbg.section_search_header": "搜尋得獎住宿",
    "category_highlight.title.food": "{0}飲食推介",
    "see_all_things_to_do": "查看所有玩樂推介",
    "lbg.section_properties_theme.sustainable": "{0}得獎環保住宿",
    "lbg.h1.hubpage": "{0}得獎旅客最喜愛住宿！",
    "lbg.section_theme_world_winners.spa_hotel": "得獎 SPA 住宿",
    "category.maps": "地圖",
    "lbg.h1.destination_theme.family": "{0}得獎家庭住宿",
    "lbg.section_theme_world_winners.cleanliness": "得獎清潔住宿",
    "lbg.section_theme_world_winners.vip_access": "得獎 VIP Access 住宿",
    "trust_module.get_reward_night.night_you_stay": "只需住滿 10 晚即可",
    "modified": "(經修改)",
    "lbg.section_properties": "{0}得獎住宿",
    "trust_module.header": "透過 Hotels.com 預訂的好處",
    "hub.category.h2.luxury": "{0}熱門豪華行程",
    "lbg.theme.ski": "滑雪住宿",
    "lbg.h1.destination_theme.spa_hotel": "{0}得獎 SPA 住宿",
    "lbg.h2.most_wanted": "完美住宿體驗，旅客一致好評。",
    "top10_hotels_incomplete_alert": "我們現正出現大量需求，部分住宿可能無法顯示。<span>不便之處，懇請原諒。</span>",
    "compulsive_traveller": "{0} <span>&#124;</span> <span>旅遊常客</span>",
    "lbg.section_theme_world_winners.home_and_apartments": "得獎民宿及公寓",
    "loved_by_guests_collection_in": "{0}的旅客最喜愛住宿",
    "title.suffix": "{0} - Go Guides",
    "hub.category.h2.maps": "{0}實用地圖",
    "opening_hours": "開放時間",
    "hub.category.h2.photos": "{0}熱門打卡行程",
    "lbg.theme.most_wanted": "最受歡迎住宿",
    "blog.stories_container": "網誌文章",
    "hub.category.h2.budget": "{0}熱門平價行程",
    "lbg.section_properties_theme.business": "{0}得獎商務住宿",
    "not_sure_where_to_stay": "不知在哪裡住宿好？",
    "category.information": "資訊"
}
