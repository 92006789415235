export default {
    "current_deal": "Jelenlegi ajánlat",
    "lbg.section_properties_theme.pet_friendly": "Állatbarát szállás kategóriagyőztesek {0} területén",
    "address": "Cím",
    "hub.homepage.h2": "Rengeteg látnivaló, de vajon lesz rá elég idő?",
    "search_hotels_near_this_landmark": "Szálláskeresés<span class=\"extra-label\"> a nevezetesség környékén</span>",
    "hub.category.h1.things_to_do": "Programok {0} területén",
    "lbg.h2.vip_access": "A VIP Access-szálláshelyeket a kiváló értékelések és az általuk nyújtott kiemelkedő élmények alapján válogatjuk össze.",
    "lbg.theme.cleanliness": "Tisztaság",
    "lbg.theme.lgbtq": "LMBTQ",
    "load_more": "Továbbiak betöltése",
    "link_long_form_to_listiicle_multiple": "{0} – {1} és {2} egyike ({3} látnivalóiról itt olvashatsz)",
    "lbg.section_properties_theme.boutique": "Boutique hotel kategóriagyőztesek {0} területén",
    "lbg.h2.luxury": "Fedezd fel a luxus legjavát. Ezért érezték magukat annyira jól a vendégek ezeken a szálláshelyeken.",
    "hub.category.h1.information": "{0} – Tudnivalók",
    "families": "Családoknak",
    "trending_now": "Jelenleg közkedvelt",
    "lbg.from": "Kezdőár",
    "hub.category.h1.road_trips": "A legjobb autós utazások {0} területén",
    "lbg.h2.cleanliness": "Ezek a szálláshelyek nagyszerű általános értékelési pontszámokat kaptak a vendégektől, tisztaságukat pedig a legmagasabbra értékelték.",
    "single": "Egyedülállóknak",
    "couples": "Pároknak",
    "trust_module.price_guarantee.find_out_more": "további információ",
    "top_destinations_in": "A legnépszerűbb úti célok {0} területén",
    "hub.category.h1.couples": "Utazások pároknak itt: {0}",
    "hub.category.h2.tours": "A legjobb vezetett túrák {0} területén",
    "hub.category.h1.photos": "Látványos helyek itt: {0}",
    "category.photos": "Képek",
    "hub.category.h1.budget": "Olcsó utazások itt: {0}",
    "hub.category.h2.ski": "{0} legjobb síterepei",
    "lbg.section_theme_world_winners.most_wanted": "A legnépszerűbb kategóriagyőztesek",
    "lbg.theme.home_and_apartments": "Lakások és apartmanok",
    "hub.category.h1.luxury": "Luxusutazások itt: {0}",
    "hub.category.h2.adventure": "A legjobb aktív utazások itt: {0}",
    "hotels_in": "Szállások {0} területén ",
    "lbg.section_search_winners": "További díjazottak keresése",
    "see_more_hotels_in": "További szállások {0} területén",
    "hub.category.h1.beach": "Strandok itt: {0}",
    "open": "Nyitvatartás:",
    "enter_your_destination": "Add meg az úti célt",
    "best_of": "{0} legjava",
    "top10_hotels_missing_properties": "Előfordulhat, hogy bizonyos szállások nem jelennek meg jelenleg. Elnézést kérünk az okozott kellemetlenségekért.",
    "adventure": "Kaland",
    "lbg.h1.destination_theme.sustainable": "Fenntartható szállás kategóriagyőztesek {0} területén",
    "luxury": "Luxus",
    "expand_map_view": "Térképnézet megnagyobbítása",
    "book_now": "Foglalás most",
    "lbg.h1.destination_theme.ski": "Síszállás kategóriagyőztesek {0} területén",
    "hub.category.h1.food": "Gasztronómia és étkezési lehetőségek {0} területén",
    "category.blogs": "Blog",
    "budget": "Olcsó",
    "popular_neighbourhoods_in": "Népszerű negyedek {0} területén",
    "hub.category.h1.unusual": "Rejtett gyöngyszemek és szokatlan helyek itt: {0}",
    "see_all_accommodation_in": "Tekintse meg a szállásokat itt: {0}",
    "hub.category.h1.adventure": "Aktív utazások itt: {0}",
    "category_highlight.see_all.shopping": "További részletek a vásárlásról",
    "lbg.section_most_popular_destination": "A legnépszerűbb úti célok",
    "lbg.section_properties_theme.luxury": "Luxushotel kategóriagyőztesek {0} területén",
    "unusual": "Szokatlan",
    "category.shopping": "Vásárlás",
    "make_the_most_of_your_trip": "Hozd ki a legtöbbet az utadból! ",
    "x_review": "{0,choice,0#|1#{0} értékelés|2#{0} értékelés|2<{0} értékelés|4<{0} értékelés|10#{0} értékelés|10<{0} értékelés}",
    "hub.category.h1.single": "Utazás egyedül itt: {0}",
    "hub.category.h2.custom": "Jelenleg népszerű",
    "lbg.section_theme_world_winners.lgbt": "LMBTQ+-barát kategóriagyőztesek",
    "lbg.section_award_winners_theme_other": "Egyéb típusok kategóriagyőztesei {0} területén",
    "lbg.supplier_collected_charges": "+ {0} összegű költség, amely a szálláshelyen fizetendő",
    "in_destination": " {0} területén",
    "lbg.section_properties_theme.vip_access": "A VIP Access kategória {0}. évi győztesei",
    "link_long_form_to_listiicle_single": "{0} – {1} egyike ({2} látnivalóiról itt olvashatsz)",
    "lbg.section_theme_world_winners.boutique": "Boutique hotel kategóriagyőztesek",
    "lbg.section_properties_theme.spa_hotel": "Wellnessfürdős szállás kategóriagyőztesek {0} területén",
    "lbg.section_theme_world_winners.all_inclusive": "All-inclusive kategóriagyőztesek ",
    "hub.category.h2": "{0} – útikalauz",
    "lbg.properties.price_tooltip": "Ez a legalacsonyabb éjszakánkénti ár az oldalunkon a következő 14 napra. ",
    "hub.category.h2.road_trips": "A legjobb autós utazások {0} területén",
    "hub.category.h1.ski": "{0} síterepei",
    "food": "Ételek",
    "lbg.theme.pet_friendly": "Állatbarát",
    "lbg.theme.business": "Business",
    "hub.category.h1.nature": "A legjobb kiruccanások a természetbe {0} területén",
    "map": "Térkép",
    "category_highlight.see_all.information": "További részletek",
    "hotel_prices_in": "Szállásárak {0} területén",
    "hub.category.h1.nightlife": "{0} – Éjszakai élet",
    "trust_module.free_cancellation.most_hotels": "a legtöbb szálláshelyen*",
    "also_popular_in": "Szintén népszerű {0} területén",
    "lbg.h1.destination_theme.business": "Business kategóriagyőztesek {0} területén",
    "lbg.section_theme_world_winners.pet_friendly": "Állatbarát szállás kategóriagyőztesek",
    "hub.category.h1.maps": "{0} térképei",
    "lbg.section_properties_theme.most_wanted": "A legnépszerűbb kategóriagyőztesek {0} területén",
    "category.nightlife": "Éjszakai élet",
    "search_hotels_in_this_area": "Szálláskeresés<span class=\"extra-label\"> ezen a környéken</span>",
    "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 szavazat|2#2 szavazat|2<{1} szavazat|4<{1} szavazat|10#10 szavazat|10<{1} szavazat})",
    "hub.category.h2.business": "A legjobb üzleti utazások itt: {0}",
    "category.things_to_do": "Programlehetőségek",
    "destination_travel_guide_by_category": "Útikalauzok kategória szerint {0} területére",
    "hub.category.h1.families": "Családi utazás, ha {0} az úti cél",
    "lbg.section_theme_world_winners.ski": "Síszállás kategóriagyőztesek",
    "you_may_also_like": "Ami még érdekelhet",
    "lbg.theme.sustainable": "Fenntartható",
    "hub.category.h2.unusual": "A legjobb rejtett gyöngyszemek és szokatlan helyek itt: {0}",
    "hub.category.h2.football": "Szurkolj kedvenc csapatodnak, és hozd ki a legtöbbet az utazásból!",
    "top_hotel": "Top Hotel",
    "lbg.h1.destination_theme.luxury": "Luxushotel kategóriagyőztesek {0} területén",
    "top10_hotels_empty_alert": "Technikai probléma merült fel a rendszerünkben. <span>Elnézést kérünk az okozott kellemetlenségekért.</span>",
    "special_deal": "Különleges ajánlat",
    "lbg.h2.sustainable": "Középpontban a fenntarthatóság! Nézd meg ezeket a szálláshelyeket: kiválóak a kikapcsolódáshoz.",
    "where_to_stay_in": "Hol érdemes megszállni <span class=\"next-line\"> {0}</span> területén",
    "start_planning_your_trip": "Kezdj hozzá az utad tervezéséhez! ",
    "good_for": "Ehhez ideális:",
    "category.hotels": "Szállások",
    "x_accommodation_guide": "{0} szálláshelyi ajánlója",
    "lbg.theme.luxury": "Luxus",
    "hub.category.h2.sports": "A legjobb sportlehetőségek és tevékenységek itt: {0}",
    "lbg.section_theme_world_winners.lgbtq": "Az LMBTQ kategória győztesei",
    "see_more_neighbourhoods": "További negyedek",
    "category_highlight.title.nightlife": "{0} – Hol és mit érdemes csinálni este?",
    "lbg.h1.destination_theme.lgbt": "LMBTQ+ kategóriagyőztesek {0} területén",
    "hub.category.h1.blogs": "{0} – Utazói blog",
    "lbg.h2.business": "Az üzleti utazók nagyra értékelik ezeket a jellemzőket.",
    "hotels": "Szállások",
    "lbg.h2.pet_friendly": "A vendégek melegen ajánlják ezeket a szálláshelyeket a kedvencükkel utazóknak.",
    "popular_landmarks_nearby": "Népszerű látnivalók a környéken",
    "popular_extractions_in": "Népszerű nevezetességek {0} területén",
    "lbg.h2.all_inclusive": "Ezeken a kiváló értékelésű szállásokon kieresztheted a gőzt.",
    "travel_guide_by_category_header": "{0} {1} területén",
    "lbg.travel_between": "Utazz {0} és {1} között",
    "keep_exploring": "Folytasd a keresgélést",
    "what_you_need_to_know_before_you_go": "Mit érdemes tudnod indulás előtt",
    "read_it": "Olvass tovább",
    "view_on_map": "Megtekintés térképen",
    "lbg.h1.destination_theme.lgbtq": "Az LMBTQ kategória {0}. évi győztesei",
    "lbg.h2.beach": "A vendégek imádnak a vízparton megszállni, különösen ezeken a helyeken.",
    "lbg.h1.destination_theme.vip_access": "A VIP Access kategória {0}. évi győztesei",
    "see_all_accommodation": "Tekintse meg az összes szállást",
    "shoppers": "Vásárlási lehetőségek",
    "whats_around": "Mi található {0} környékén?",
    "group": "Csoportoknak",
    "hub.category.h2.couples": "A legjobb utazások pároknak itt: {0}",
    "hub.category.h1.shopping": "{0} – Vásárlási lehetőségek",
    "travel_community": "{0} <span>&#124;</span> <span>Utazói közösség</span>",
    "hub.category.h2.group": "Legjobb csoportos utazások itt: {0}",
    "listicle_legal_msg": "Ez a cikk a Go Guides szerkesztőségének véleményét ismerteti. A Hotels.com ellentételezést nyújt a szerzőknek az ezen a weboldalon megjelenő írásaikért; az ilyen ellentételezések körébe tartozhat az utazási és egyéb költségek megtérítése.",
    "lbg.h1.destination_theme.cleanliness": "A tisztasági kategória {0}. évi győztesei ",
    "menu.more": "Tovább",
    "lbg.section_properties_theme.lgbtq": "Az LMBTQ kategória {0}. évi győztesei",
    "lbg.section_award_winners_destination": "Díjazottak helyszín szerint",
    "lbg.terms_conditions": "Szerződési feltételek",
    "hub.category.h2.nightlife": "A legjobb éjszakai szórakozási lehetőségek {0} területén",
    "hub.neighbourhood.h2": "Programok {0} területén",
    "lbg.section_award_winners_theme_destination": "Díjazottak szállástípus szerint {0} területén",
    "lbg.section_award_world_winners": "Díjazottak világszerte",
    "things_to_see_and_do_in_other_cities": "Látnivalók és programok egyéb városokban",
    "button.backToTop": "Vissza a lap tetejére",
    "lbg.section_theme_world_winners.family": "Családbarát kategóriagyőztesek",
    "hotels_near": "Szállások {0} közelében",
    "category.activity__sports_": "Programlehetőségek",
    "trust_module.get_reward_night": "Kapsz egy bónuszéjszakát",
    "hub.category.h1.business": "Üzleti utazások itt: {0}",
    "category.food": "Ételek",
    "other_popular_destinations_in": "Egyéb népszerű úti célok {0} területén",
    "browse_by_category": "Keresés {0} területén kategóriánként",
    "see_more_attactions": "További nevezetességek",
    "lbg.theme.lgbt": "LMBTQ+",
    "more_information_about": "{0} – további információk",
    "lbg.h1.destination_theme.most_wanted": "A legnépszerűbb kategóriagyőztesek {0} területén",
    "see_all_properties_in": "Az összes szálláshely megtekintése {0} területén",
    "category.tours": "Túrák",
    "hub.category.h1.sports": "Sport és tevékenységek itt: {0}",
    "where_will_you_go_next": "Hová utazol legközelebb? ",
    "cities": "Városok",
    "lbg.theme.spa_hotel": "Wellnessfürdős",
    "see_guide": "Útikalauz megtekintése",
    "lbg.section_properties_theme.ski": "Síszállás kategóriagyőztesek {0} területén",
    "related_stories": "Kapcsolódó bejegyzések",
    "hub.destination.h2": "Mit érdemes tudnod indulás előtt",
    "show_less": "Kevesebb megjelenítése",
    "hub.category.h1.football": "{0} – útikalauz valódi focirajongóknak",
    "hub.category.h2.food": "A legjobb gasztronómiai élmények {0} területén",
    "fallback_language_alert": "Sajnáljuk, ezt az oldalt még nem fordították le a nyelvedre.",
    "category_highlight.title.information": "Hasznos információk",
    "neighbourhood_guides_for_other_cities": "Útikalauzok egyéb városok környékeiről",
    "lbg.h2.hubpage": "Ezekre a fantasztikus szálláshelyekre magas pontszámot adtak a vendégek, és sok értékelést írtak róluk.",
    "travel_advisory.title": "Utazási értesítő",
    "lbg.section_properties_theme.all_inclusive": "All-inclusive kategóriagyőztesek {0} területén",
    "lbg.h2.boutique": "Ha szereted a boutique hangulatot, a vendégek ezt a szállást ajánlják.",
    "lbg.section_properties_theme.family": "Családbarát kategóriagyőztesek {0} területén",
    "hub.category.h2.families": "Fantasztikus családi utazás, ha {0} az úti cél",
    "from": "Legalacsonyabb ár:",
    "lbg.theme.vip_access": "VIP Access",
    "lbg.section_properties_theme.lgbt": "LMBTQ+ kategóriagyőztesek {0} területén",
    "x_star": "{0,choice,0#|1#{0} csillagos|1.5#{0} csillagos|2#{0} csillagos|2.5#{0} csillagos|3#{0} csillagos|3.5#{0} csillagos|4#{0} csillagos|4.5#{0} csillagos|5#{0} csillagos}",
    "trust_module.free_cancellation": "Ingyenes lemondás",
    "lbg.section_properties_theme.home_and_apartments": "Lakások és apartmanok kategóriagyőztesei {0} területén",
    "contributing_writer": "{0} <span>&#124;</span> <span> Szabadúszó szerző</span>",
    "things_to_see_and_do_in": "Látnivalók és programok <span class=\"next-line\"> {0}</span> területén",
    "lbg.check_price": "Ár megtekintése",
    "hub.category.h2.blogs": "tapasztalt utazóktól",
    "lbg.h2.home_and_apartments": "Második otthonodat keresed? Nézd meg ezeket a kiváló értékelésű szálláshelyeket.",
    "hub.category.h2.information": "Hasznos információk ha {0} az úti cél",
    "search_hotels_in": "Szálláskeresés<span class=\"extra-label\"> {0}</span> területén",
    "hub.category.h2.nature": "A legjobb utazások a természetbe {0} területén",
    "lbg.h1.destination_theme.home_and_apartments": "Lakások és apartmanok kategóriagyőztesei {0} területén",
    "photo_by": "A kép készítője: {0}",
    "more_things_to_do": "További programok",
    "lbg.section_properties_theme.beach": "Tengerparti szállás kategóriagyőztesek {0} területén",
    "read_less": "Kevesebb mutatása",
    "lbg.h2.spa_hotel": "Lazulj el és kapcsolódj ki ezeken a kiváló értékelésű szálláshelyeken, amelyek wellnessrészlegükről ismertek",
    "second_fold_related_stories.see_also": "Ezek is érdekelhetnek",
    "hub.category.h2.history": "A legjobb történelmi helyek itt: {0}",
    "lbg.section_properties_theme.cleanliness": "A tisztasági kategória {0}. évi győztesei",
    "lbg.h1.destination_theme.boutique": "Boutique hotel kategóriagyőztesek {0} területén",
    "nightlife_b595ae4": "Éjszakai élet",
    "lbg.h1.destination_theme.beach": "Tengerparti szállás kategóriagyőztesek {0} területén",
    "trust_module.free_cancellation.disclaimer": "Egyes szállásokat legalább a bejelentkezés előtt 24–48 órával le kell mondani. Részleteket a weboldalon találhatsz.",
    "lbg.h2.family": "Ezeket a szálláshelyeket nagyra értékelik a vendégek, és tökéletesek a családok számára.",
    "lbg.h2.lgbtq": "Ezek az LMBTQ-barát szálláshelyek kiváló értékeléseket kaptak a vendégektől.",
    "hub.category.h1.group": "Csoportos utazások itt: {0}",
    "popular_stories": "Népszerű történetek",
    "toc": "Tartalom",
    "hub.category.h2.shopping": "A legjobb helyszínek vásárláshoz {0} területén",
    "lbg.section_theme_world_winners.beach": "Tengerparti szállás kategóriagyőztesek",
    "page_score": "Az oldal pontszáma",
    "lbg.section_theme_world_winners.sustainable": "Fenntartható szállás kategóriagyőztesek",
    "lbg.theme.boutique": "Boutique",
    "trust_module.price_guarantee": "Árgaranciánk",
    "lbg.h1.destination_theme.all_inclusive": "All-inclusive kategóriagyőztesek {0} területén",
    "top_destinations": "A legnépszerűbb úti célok",
    "hotel_prices_near": "Szállásárak {0} közelében",
    "lbg.score.cleanliness": "Tisztaság:",
    "x_stars": "{0,choice,0#|1#{0} csillagos|1<{0} csillagos|4<{0} csillagos|10<{0} csillagos}",
    "lbg.theme.all_inclusive": "All-inclusive",
    "hub.category.h1.tours": "Túrák és egynapos utak {0} területén",
    "lbg.section_award_winners_theme": "Díjazottak szállástípus szerint",
    "guest_writer": "{0} <span>&#124;</span> <span>Vendégszerző</span>",
    "start_planning_your_x_trip": "Kezdd el szervezni {0} területére tervezett utadat! ",
    "lbg.section_theme_world_winners.business": "Business kategóriagyőztesek",
    "trending_now_in": "Jelenleg népszerű programlehetőségek {0} területén",
    "history": "Történelmi környezet",
    "search_hotels": "Szállás keresése",
    "lbg.terms_conditions_details": "A {0}. évben olyan szálláshelyeket díjaztunk, amelyek {1} során magas értékelési pontszámot kaptak, valamint amelyekről legalább 50 értékelést írtak.",
    "see_nearby_properties": "Közeli szállások",
    "hub.category.h1.history": "Történelmi helyek itt: {0}",
    "location": "Elhelyezkedés:",
    "lbg.section_theme_world_winners.luxury": "Luxushotel kategóriagyőztesek",
    "category_highlight.see_all.nightlife": "További részletek az éjszakai életről",
    "also_popular": "Szintén népszerűek",
    "hub.category.h2.things_to_do": "A legjobb programlehetőségek {0} területén",
    "lbg.theme.family": "Családbarát",
    "lbg.h1.destination_theme.pet_friendly": "Állatbarát szállás kategóriagyőztesek {0} területén",
    "category_highlight.title.shopping": "{0} – Hol és mit érdemes vásárolni?",
    "price": "Ár:",
    "hub.category.h2.beach": "A legjobb strandok itt: {0}",
    "neighbourhoods_in": "{0} környékei",
    "business": "Üzleti",
    "photo": "Kép",
    "blog.see_all_stories": "Összes történet",
    "category_highlight.see_all.food": "További részletek a gasztronómiáról és étkezési lehetőségekről",
    "see_all_properties": "Összes szállás megtekintése",
    "lbg.theme.beach": "Tengerparti",
    "what_to_see_and_do_in": "{0} programjai és látnivalói",
    "hub.category.h2.single": "A legjobb utazások egyedül itt: {0}",
    "attractions_in": "Látnivalók {0} területén",
    "read_more": "Több mutatása",
    "show_more": "Több megjelenítése",
    "hotels_near_this_landmark": "Szállások a nevezetesség környékén",
    "check_dates": "Dátumok ellenőrzése",
    "featured_stories_and_fun_stuff": "Kiemelt cikkek és érdekes tudnivalók",
    "phone": "Telefonszám: ",
    "lbg.section_search_header": "Díjazottak keresése",
    "category_highlight.title.food": "{0} – Hol és mit érdemes enni?",
    "see_all_things_to_do": "Az összes program",
    "lbg.section_properties_theme.sustainable": "Fenntartható szállás kategóriagyőztesek {0} területén",
    "lbg.h1.hubpage": "{0} „Loved by Guests” kategóriagyőztesek",
    "lbg.section_theme_world_winners.spa_hotel": "Wellnessfürdős szállás kategóriagyőztesek",
    "category.maps": "Térképek",
    "lbg.h1.destination_theme.family": "Családbarát kategóriagyőztesek {0} területén",
    "lbg.section_theme_world_winners.cleanliness": "A tisztasági kategória győztesei",
    "lbg.section_theme_world_winners.vip_access": "A VIP Access kategória győztesei",
    "trust_module.get_reward_night.night_you_stay": "minden 10. eltöltött éjszaka után",
    "modified": "módosítva",
    "lbg.section_properties": "Díjazottak {0} területén",
    "trust_module.header": "Miért érdemes a Hotels.com oldalán foglalni?",
    "hub.category.h2.luxury": "A legjobb luxusutazások itt: {0}",
    "lbg.theme.ski": "Sí",
    "lbg.h1.destination_theme.spa_hotel": "Wellnessfürdős szállás kategóriagyőztesek {0} területén",
    "lbg.h2.most_wanted": "A vendégek melegen ajánlják ezeket a szálláshelyeket: szerintük tökéletesek.",
    "top10_hotels_incomplete_alert": "A magas kereslet okozta forgalom miatt néhány szálláshely jelenleg nem jelenik meg az oldalunkon. <span>Elnézést kérünk az okozott kellemetlenségekért.</span>",
    "compulsive_traveller": "{0} <span>&#124;</span> <span>Gyakori utazó</span>",
    "lbg.section_theme_world_winners.home_and_apartments": "Lakások és apartmanok kategóriagyőztesei",
    "loved_by_guests_collection_in": "Loved by Guests-válogatás {0} területén",
    "title.suffix": "{0} – Go Guides",
    "hub.category.h2.maps": "{0} hasznos térképei",
    "opening_hours": "Nyitvatartási idő",
    "hub.category.h2.photos": "A leglátványosabb helyek itt: {0}",
    "lbg.theme.most_wanted": "Legnépszerűbb",
    "blog.stories_container": "Közreműködők történetei",
    "hub.category.h2.budget": "A legjobb olcsó utazások itt: {0}",
    "lbg.section_properties_theme.business": "Business kategóriagyőztesek {0} területén",
    "not_sure_where_to_stay": "Nem vagy biztos benne hol szállnál meg?",
    "category.information": "Információ"
}
