import React from 'react';
import { MasterPage } from 'components/common/template/master-page';
import GoGuidesFeaturedStoriesComponent from 'components/modules/featured-stories/featured-stories';
import GoGuidesHeroComponent from 'components/modules/hero/hero';
import GoGuidesIntroComponent from 'components/modules/intro/intro';
import GoGuidesKeepExploringComponent from 'components/modules/keep-exploring/keep-exploring';
import GoGuidesTopDestinationComponent from 'components/modules/top-destination/top-destination';
import GoGuidesSearchWizard from 'components/modules/search-wizard/search-wizard';
import GoGuidesBackToTop from '../modules/back-to-top/back-to-top';

const HomePage: React.FC = () => {
  return (
    <MasterPage>
      <div className="page-container">
        <GoGuidesHeroComponent />
        <div className="hub-body">
          <GoGuidesIntroComponent />
          <GoGuidesTopDestinationComponent />
          <GoGuidesFeaturedStoriesComponent />
          <GoGuidesSearchWizard />
          <GoGuidesKeepExploringComponent isHomePage={true} />
        </div>
        <GoGuidesBackToTop />
      </div>
    </MasterPage>
  );
};

export default HomePage;
