import React, { useEffect } from 'react';
import { getLocalizedText } from 'src/utils/helper';
import { UitkIcon } from 'uitk-react-icons';

const FallbackLanguageAlert: React.FC = () => {
  const [show, setShow] = React.useState(true);
  const [tick, setTick] = React.useState(false);

  const closeAlert = () => {
    setShow(false);
    window.removeEventListener('scroll', () => {
      timedCloseAlert();
    });
  };

  const timedCloseAlert = () => {
    if (show && !tick) {
      setTimeout(() => {
        setShow(false);
      }, 8000);
      setTick(true);
    }
  };

  useEffect(() => {
    // register event listener for window scroll, and the alert after scroll for 8 seconds
    window.addEventListener('scroll', () => {
      timedCloseAlert();
    });

    return () => {
      window.removeEventListener('scroll', () => {
        timedCloseAlert();
      });
    };
  });

  return (
    <>
      {show && (
        <div className="fallback-lang-alert">
          <div className="inner">
            {getLocalizedText('fallback_language_alert')}
            <button className="close-btn" onClick={closeAlert}>
              <UitkIcon name="close" />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default FallbackLanguageAlert;
