import React, { useEffect, useState } from 'react';
import { UitkSubheading } from 'uitk-react-text';
import { useStores } from 'utils/useStore';
import { getLocalizedText } from 'src/utils/helper';
import { UitkExpandoPeek } from 'uitk-react-expando';
import { localization } from 'src/utils/localization';
import { checkValue } from 'src/components/functions';

const GoGuidesFreetextComponent: React.FC = () => {
  const { goGuidesStore } = useStores();
  const contentData = goGuidesStore.fetchFreeText(goGuidesStore.pageUrlData);
  const [isVisible, setIsVisible] = useState(false);

  const loc = new localization();
  let content = ''
  let headerKey = ''
  let dName = ''
  if (checkValue(contentData)) {
    const { freeText, destinationName } = contentData;
    content = checkValue(freeText?.content) ? freeText.content : '';
    headerKey = checkValue(freeText?.headerKey) ? freeText.headerKey : '';
    dName = checkValue(destinationName) ? destinationName : '';
  }

  const headerText = loc.getLocalizedText(headerKey, dName);

  return (
    <>
      {checkValue(content) && (
        <section className="free-text sec-control">
          <div className="container-wrap row">
            <div className="row-inner col-12 line-up">
              <div className="section-header-wrapper">
                <UitkSubheading tag="h2">{headerText}</UitkSubheading>
              </div>
              <div className="free-text-wrapper">
                <div className="expandable-wrapper">
                  <UitkExpandoPeek
                    isVisible={isVisible}
                    handleClick={() => setIsVisible((prevIsVisible) => !prevIsVisible)}
                    lines={10}
                    whitespace={false}
                    expandLabel={loc.getLocalizedText('show_more')}
                    collapseLabel={loc.getLocalizedText('show_less')}
                  >
                    <div className="free-text-details" dangerouslySetInnerHTML={{ __html: content }} />
                  </UitkExpandoPeek>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default GoGuidesFreetextComponent;
