import React from 'react';
import { CardItemProp } from 'src/components/types/goguides-types.d';
import CardSizeXL from './card-xl';

interface BucketCardProps {
  data: CardItemProp[];
}

const BucketOneCard: React.FC<BucketCardProps> = ({ data }) => {

  return (
    <ul className={`row item-list-container`}>
      {data.map((item, index) => (
        <CardSizeXL key={index} data={item} />
      ))}
    </ul>
  );
};

export default BucketOneCard;
