import React, { useEffect } from 'react';
import { Header as HeaderBernie } from '@gc/global-components';
import { Footer as FooterBernie } from '@gc/global-components';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { useStores } from 'utils/useStore';
import FallbackLanguageAlert from 'src/components/modules/fallback-language-alert/fallback-language-alert';

interface MasterPageProps {
  children: React.ReactNode;
}

const MasterPage: React.FC<MasterPageProps> = (props) => {
  const { children } = props;
  const { goGuidesStore } = useStores();

  const track = useEgClickstream();

  useEffect(() => {
    let trackObject = {
      event: {
        event_version: '1.0.0',
        event_type: 'Page View',
        event_name: 'go_guides.viewed',
        event_category: 'go_guides',
      },
      experience: goGuidesStore.ClicksteamExperience,
    };

    if (goGuidesStore.gaiaId) {
      const destination = {
        destination: {
          destination_geo_id: goGuidesStore.gaiaId,
        },
      };
      trackObject = { ...trackObject, ...destination };
    }

    track(trackObject);
  }, []);

  const FallBackAlert = () => {
    return goGuidesStore.isFallbackLanguage ? <FallbackLanguageAlert /> : null;
  };

  return (
    <>
      <HeaderBernie />
      {children}
      <FallBackAlert />
      <FooterBernie />
    </>
  );
};

export { MasterPageProps, MasterPage };
