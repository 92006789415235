import { TopDestinationProps, CardItemProp } from 'components/types/goguides-types.d';
import React, { useEffect, useState } from 'react';
import { UitkPrimaryButton } from 'uitk-react-button';
import { UitkHeading } from 'uitk-react-text';
import { useStores } from 'utils/useStore';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { localization } from 'src/utils/localization';
import BucketOneCard from './sub-component/bucket-one-card';
import BucketCardDefault from './sub-component/bucket-card-default';
import BucketTwoCard from './sub-component/bucket-two-card';
import BucketThreeCard from './sub-component/bucket-three-card';
import { checkValue } from 'src/components/functions';
import BucketFourCard from './sub-component/bucket-four-card';

const TopDestinationInner: React.FC<TopDestinationProps> = (props) => {
  const { goGuidesStore, context } = useStores();
  const { pageType, destinationType, destinationName, items = [] } = props;

  const [displayedItems, setDisplayedItems] = useState<CardItemProp[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  const track = useEgClickstream();
  const trackObject = {
    event: {
      event_version: '1.0.0',
      event_type: 'Interaction',
      event_name: 'show_more_button.selected',
      event_category: 'go_guides',
      action_location: 'top destination',
    },
    experience: goGuidesStore.ClicksteamExperience,
  };

  const loc = new localization();
  const itemsLoadMorePerClick = 6;
  const initialItems = destinationType === 'country' ? 7 : 11;

  if (displayedItems.length === 0) {
    setDisplayedItems(items.slice(0, initialItems));
  }

  const loadMoreItems = () => {
    const newItems = items.slice(displayedItems.length, displayedItems.length + itemsLoadMorePerClick);

    setDisplayedItems(prevItems => [...prevItems, ...newItems]);
    setCurrentPage(prevPage => prevPage + 1);
    track(trackObject);
  };
  const showLoadMore = displayedItems.length < items.length;

  const renderBucket = () => {
    const bucketComponents = {
      1: BucketOneCard,
      2: BucketTwoCard,
      3: BucketThreeCard,
      4: BucketFourCard
    };

    const BucketComponent = bucketComponents[displayedItems.length] || BucketCardDefault;
    const highlight = destinationType === 'country';

    return <BucketComponent highlight={highlight} data={displayedItems} />;
  };

  const bucketHeader =
    pageType == 'home'
      ? loc.getLocalizedText('top_destinations')
      : loc.getLocalizedText('top_destinations_in', destinationName);

  return (
    <>
      {checkValue(displayedItems) && displayedItems.length > 0 && (
        <section className="top-destination">
          <div className="container-wrap row">
            <div className="row-inner col-12 line-up">
              <div className="section-header-wrapper">
                <UitkHeading size={5} tag="h2">
                  {bucketHeader}
                </UitkHeading>
                <span className="sub-header">{loc.getLocalizedText('where_will_you_go_next')}</span>
              </div>

              <div className="item-list-page-wrap">
                {renderBucket()}
              </div>
              {showLoadMore && (
                <UitkPrimaryButton className="load-more-btn" tag="button" type="button" onClick={loadMoreItems}>
                  {loc.getLocalizedText('load_more')}
                </UitkPrimaryButton>
              )}
            </div>
          </div>
        </section>
      )}

    </>
  );
};

export default TopDestinationInner;
