import React from 'react';
import { UitkParagraph } from 'uitk-react-text';
import { getLocalizedText } from "src/utils/helper";

const LegalMessage: React.FC = () => {
    return (
        <>
            <UitkParagraph className='legal_message'>{getLocalizedText('listicle_legal_msg')}</UitkParagraph>
        </>
    );
};
export default LegalMessage;