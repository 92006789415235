import React from "react";
import { checkValue } from "components/functions";
import { CardItemProp } from "components/types/goguides-types.d";
import { UitkLink } from 'uitk-react-link';
import { UitkCard, UitkCardLink } from "uitk-react-cards";
import { UitkFigure, UitkFigureAspectRatioType } from "uitk-react-images";
import { UitkHeading, UitkSubheading } from "uitk-react-text";
import { useStores } from "utils/useStore";
import CarouselComponent from "../generic/render-carousel";
import { RenderImage } from "src/components/utils/responsive-image";
import { EgClickstreamData, EgClickstreamImpressionTracker } from '@shared-ui/clickstream-analytics-context';
import { localization } from "src/utils/localization";

const GoGuidesContributorsStoriesComponent: React.FC = () => {
  const { goGuidesStore } = useStores();
  const contentData = goGuidesStore.fetchContributorsStories(goGuidesStore.pageUrlData);
  const loc = new localization();

  const moduleName = 'blog container';
  const impressionTrackingObject: EgClickstreamData = {
    event: {
      event_name: 'go_guides_module.presented',
      event_type: 'Impression',
      event_version: '1.0.0',
      event_category: 'go_guides',
    },
    experience: goGuidesStore.ClicksteamExperience,
    module: {
      module_name: moduleName,
    },
  };

  let stories: CardItemProp[] = [];
  let seeAllUrl = '';
  if (checkValue(contentData)) {
    const { storiesData } = contentData;
    stories = checkValue(storiesData.storiesList) ? storiesData.storiesList : [];
    seeAllUrl = checkValue(storiesData.seeAllStoriesLink) ? storiesData.seeAllStoriesLink.targetUrl : '';
  }

  const headerText = loc.getLocalizedText('blog.stories_container');
  const seeAllLabel = loc.getLocalizedText('blog.see_all_stories');
  const blogLabel = loc.getLocalizedText('category.blogs');

  const carouselConfigProps = {
    carouselName: moduleName,
    carouselHeaderText: 'Blog Stories Carousel',
    itemsVisible: {
      sm: 1,
      md: 2,
      lg: 3,
      xl: 3,
    },
    pageBy: 3,
    peek: true,
    experience: goGuidesStore.ClicksteamExperience,
  };

  const CarouselItem = ({ item }: { item: CardItemProp }) => {

    const imageProps = {
      image: item.image?.imageUrl,
      alt: item.name,
      params: {
        size: {
          small: '&width=600&height=400',
          medium: '&width=600&height=400',
          large: '&width=800&height=533',
        }
      }
    };

    return (
      <UitkCard>
        <div className="item-wrap">
          <UitkFigure className="img-wrapper" ratio={UitkFigureAspectRatioType.R16_9}>
            <RenderImage imgProps={imageProps} />
            <span className="red-tab">{blogLabel}</span>
          </UitkFigure>
          <div className="detail-wrapper">
            <UitkHeading tag="h3" size={7}>
              {item.name}
            </UitkHeading>
          </div>
          <UitkCardLink>
            <a href={item.targetUrl}></a>
          </UitkCardLink>
        </div>
      </UitkCard>
    );
  }

  const carouselData = stories.map((item, index) => (
    <CarouselItem key={index} item={item} />
  ));

  return (
    <>
      {checkValue(stories) && checkValue(stories.length > 0) && (
        <EgClickstreamImpressionTracker eventData={impressionTrackingObject}>
          <section className="blog-stories-container sec-control">
            <div className="container-wrap row">
              <div className="row-inner col-12 line-up">
                <div className="blog-stories-inner">
                  <div className="section-header-wrapper">
                    <div className="header-wrapper">
                      <UitkSubheading tag="h2" className={``}>
                        {headerText}
                      </UitkSubheading>
                    </div>
                    {checkValue(seeAllUrl) && (
                      <div className="see-all-stories desktop">
                        <UitkLink className="see-all-link">
                          <a href={seeAllUrl} target="_blank" rel="noreferrer">
                            {seeAllLabel}
                          </a>
                        </UitkLink>
                      </div>
                    )}
                  </div>
                  <CarouselComponent
                    data={carouselData}
                    config={carouselConfigProps}
                  />
                </div>
              </div>
            </div>
          </section>
        </EgClickstreamImpressionTracker>
      )}
    </>
  );
}

export default GoGuidesContributorsStoriesComponent;
