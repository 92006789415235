export default {
    "current_deal": "当前优惠",
    "lbg.section_properties_theme.pet_friendly": "{0}“欢迎宠物”获奖住宿",
    "address": "地址",
    "hub.homepage.h2": "好玩的太多，时间又太少……",
    "search_hotels_near_this_landmark": "搜索<span class=\"extra-label\">此地标附近的</span>酒店",
    "hub.category.h1.things_to_do": "{0}当地玩乐",
    "lbg.h2.vip_access": "VIP Access 住宿因其获得高度评价和卓越的住客体验而入选。",
    "lbg.theme.cleanliness": "最清洁",
    "lbg.theme.lgbtq": "LGBTQ",
    "load_more": "加载更多",
    "link_long_form_to_listiicle_multiple": "{0} - {1}和{2}的亮点之一（在此了解关于{3}的一切）",
    "lbg.section_properties_theme.boutique": "{0}获奖精品住宿",
    "lbg.h2.luxury": "住客可尽享最佳奢华体验。因此，旅客喜欢入住这些住宿。 ",
    "hub.category.h1.information": "关于{0}",
    "families": "家庭",
    "trending_now": "人气景点",
    "lbg.from": "起价",
    "hub.category.h1.road_trips": "{0}热门公路之旅",
    "lbg.h2.cleanliness": "这些住宿的总体住客评分很高，而且清洁度得分特别出众。",
    "single": "单身旅客",
    "couples": "情侣/夫妇",
    "trust_module.price_guarantee.find_out_more": "了解更多信息",
    "top_destinations_in": "{0}的热门目的地",
    "hub.category.h1.couples": "{0}情侣旅行",
    "hub.category.h2.tours": "{0}最佳观光团",
    "hub.category.h1.photos": "{0}摄影旅行",
    "category.photos": "照片",
    "hub.category.h1.budget": "{0}经济型旅行",
    "hub.category.h2.ski": "{0}最佳滑雪目的地",
    "lbg.section_theme_world_winners.most_wanted": "“最想入住”获奖住宿",
    "lbg.theme.home_and_apartments": "度假屋和公寓",
    "hub.category.h1.luxury": "{0}豪华旅行",
    "hub.category.h2.adventure": "{0}最佳运动型旅行",
    "hotels_in": "{0}酒店",
    "lbg.section_search_winners": "搜索更多获奖住宿",
    "see_more_hotels_in": "查看更多{0}酒店",
    "hub.category.h1.beach": "{0}海滩",
    "open": "开放时间",
    "enter_your_destination": "请输入您的目的地",
    "best_of": "{0}魅力所在",
    "top10_hotels_missing_properties": "目前可能无法显示部分住宿。由此造成不便，我们非常抱歉。",
    "adventure": "冒险",
    "lbg.h1.destination_theme.sustainable": "{0}获奖环保住宿",
    "luxury": "豪华",
    "expand_map_view": "展开地图视图",
    "book_now": "立即预订",
    "lbg.h1.destination_theme.ski": "{0}获奖滑雪住宿",
    "hub.category.h1.food": "{0}餐饮推荐",
    "category.blogs": "博客",
    "budget": "经济",
    "popular_neighbourhoods_in": "{0}的热门附近区域",
    "hub.category.h1.unusual": "{0}小众景点",
    "see_all_accommodation_in": "查看{0}住宿",
    "hub.category.h1.adventure": "{0}运动型旅行",
    "category_highlight.see_all.shopping": "关于购物的更多信息",
    "lbg.section_most_popular_destination": "最热门目的地",
    "lbg.section_properties_theme.luxury": "{0}获奖豪华住宿",
    "unusual": "与众不同",
    "category.shopping": "购物",
    "make_the_most_of_your_trip": "尽享旅程",
    "x_review": "{0,choice,0#|1#{0} 条点评|2#{0} 条点评|2<{0} 条点评|4<{0} 条点评|10#{0} 条点评|10<{0} 条点评}",
    "hub.category.h1.single": "{0}单人旅行",
    "hub.category.h2.custom": "人气景点",
    "lbg.section_theme_world_winners.lgbt": "获奖 LGBTQ+ 住宿",
    "lbg.section_award_winners_theme_other": "{0}的其他住宿奖项主题",
    "lbg.supplier_collected_charges": "+ {0} 到付",
    "in_destination": "{0}",
    "lbg.section_properties_theme.vip_access": "{0}获奖 VIP Access 住宿",
    "link_long_form_to_listiicle_single": "{0} - {1}的亮点之一（在此了解关于{2}的一切）",
    "lbg.section_theme_world_winners.boutique": "获奖精品住宿",
    "lbg.section_properties_theme.spa_hotel": "{0}获奖 SPA 住宿",
    "lbg.section_theme_world_winners.all_inclusive": "获奖全包住宿",
    "hub.category.h2": "{0}旅行指南",
    "lbg.properties.price_tooltip": "这是我们网站上未来 14 天内最低的单晚房价。",
    "hub.category.h2.road_trips": "{0}热门公路之旅",
    "hub.category.h1.ski": "{0}滑雪目的地",
    "food": "餐饮",
    "lbg.theme.pet_friendly": "欢迎宠物",
    "lbg.theme.business": "商务",
    "hub.category.h1.nature": "{0}热门自然休闲之旅",
    "map": "地图",
    "category_highlight.see_all.information": "更多信息",
    "hotel_prices_in": "{0}酒店价格",
    "hub.category.h1.nightlife": "{0}夜生活",
    "trust_module.free_cancellation.most_hotels": "适用于大多数酒店*",
    "also_popular_in": "{0}其他热门地区",
    "lbg.h1.destination_theme.business": "{0}获奖商务住宿",
    "lbg.section_theme_world_winners.pet_friendly": "“欢迎宠物”获奖住宿",
    "hub.category.h1.maps": "{0}地图",
    "lbg.section_properties_theme.most_wanted": "{0}“最想入住”获奖住宿",
    "category.nightlife": "夜生活",
    "search_hotels_in_this_area": "搜索<span class=\"extra-label\">此区域的</span>酒店",
    "votes_score_and_count": "<span>{0}</span>（{1,choice,0#|1#1 票|2#2 票|2<{1} 票|4<{1} 票|10#10 票|10<{1} 票}）",
    "hub.category.h2.business": "{0}最佳商务旅行",
    "category.things_to_do": "娱乐项目",
    "destination_travel_guide_by_category": "{0}旅行指南（按类别）",
    "hub.category.h1.families": "在{0}进行家庭旅行",
    "lbg.section_theme_world_winners.ski": "获奖滑雪住宿",
    "you_may_also_like": "您可能也喜欢",
    "lbg.theme.sustainable": "环保",
    "hub.category.h2.unusual": "{0}最佳小众景点",
    "hub.category.h2.football": "支持您的足球队并充分利用您的行程！",
    "top_hotel": "首选酒店",
    "lbg.h1.destination_theme.luxury": "{0}获奖豪华住宿",
    "top10_hotels_empty_alert": "临时出现技术问题。<span>由此造成不便，我们非常抱歉。</span>",
    "special_deal": "特价优惠",
    "lbg.h2.sustainable": "一切都和可持续环保有关！快来查看这些带来美好体验的住宿吧。",
    "where_to_stay_in": "{0}住宿攻略",
    "start_planning_your_trip": "开始计划您的旅行",
    "good_for": "适合：",
    "category.hotels": "酒店",
    "x_accommodation_guide": "{0}住宿指南",
    "lbg.theme.luxury": "豪华",
    "hub.category.h2.sports": "{0}最佳运动和活动",
    "lbg.section_theme_world_winners.lgbtq": "获奖 LGBTQ 住宿",
    "see_more_neighbourhoods": "查看更多附近区域",
    "category_highlight.title.nightlife": "{0}夜生活指南",
    "lbg.h1.destination_theme.lgbt": "{0}获奖 LGBTQ+ 住宿",
    "hub.category.h1.blogs": "{0}旅游博客",
    "lbg.h2.business": "这些住宿受到商务旅客的高度评价。",
    "hotels": "酒店",
    "lbg.h2.pet_friendly": "我们的住客强烈推荐这些欢迎您和宠物的住宿。",
    "popular_landmarks_nearby": "附近的热门地标",
    "popular_extractions_in": "{0}热门景点",
    "lbg.h2.all_inclusive": "这些评分最高的住宿可以让您的所有压力随风飘散。",
    "travel_guide_by_category_header": "{1}最佳{0}",
    "lbg.travel_between": "在 {0}至 {1}住宿",
    "keep_exploring": "继续探索",
    "what_you_need_to_know_before_you_go": "您的旅行须知",
    "read_it": "阅读",
    "view_on_map": "查看地图",
    "lbg.h1.destination_theme.lgbtq": "{0}获奖 LGBTQ 住宿",
    "lbg.h2.beach": "住客喜欢海滩度假，尤其是这些住宿旁的海滩。",
    "lbg.h1.destination_theme.vip_access": "{0}获奖 VIP Access 住宿",
    "see_all_accommodation": "查看所有住宿",
    "shoppers": "购物者",
    "whats_around": "{0}附近有什么",
    "group": "团体",
    "hub.category.h2.couples": "{0}最佳情侣旅行",
    "hub.category.h1.shopping": "{0}购物体验",
    "travel_community": "{0} <span>&#124;</span> <span>旅游社区</span>",
    "hub.category.h2.group": "{0}最佳团体旅行",
    "listicle_legal_msg": "本文包含 Go Guides 编辑团队的意见。Hotels.com 好订网对作者出现在本网站上的文章给予补偿；此类补偿可能包括差旅费和其他费用。",
    "lbg.h1.destination_theme.cleanliness": "{0}获奖最清洁住宿",
    "menu.more": "更多信息",
    "lbg.section_properties_theme.lgbtq": "{0}获奖 LGBTQ 住宿",
    "lbg.section_award_winners_destination": "目的地获奖住宿",
    "lbg.terms_conditions": "条款和条件",
    "hub.category.h2.nightlife": "{0}最佳夜生活场所",
    "hub.neighbourhood.h2": "{0}旅游项目",
    "lbg.section_award_winners_theme_destination": "{0}获奖主题住宿",
    "lbg.section_award_world_winners": "全球获奖住宿",
    "things_to_see_and_do_in_other_cities": "其他城市的观光景点和活动",
    "button.backToTop": "返回顶部",
    "lbg.section_theme_world_winners.family": "获奖家庭住宿",
    "hotels_near": "{0}附近的酒店",
    "category.activity__sports_": "旅游活动",
    "trust_module.get_reward_night": "获得 1 晚奖励住宿",
    "hub.category.h1.business": "{0}商务旅行",
    "category.food": "餐饮",
    "other_popular_destinations_in": "{0}其他热门目的地",
    "browse_by_category": "{0}分类指南",
    "see_more_attactions": "查看更多景点",
    "lbg.theme.lgbt": "LGBTQ+",
    "more_information_about": "关于{0}的更多信息",
    "lbg.h1.destination_theme.most_wanted": "{0}“最想入住”获奖住宿",
    "see_all_properties_in": "查看{0}的所有住宿",
    "category.tours": "游览",
    "hub.category.h1.sports": "{0}运动和活动",
    "where_will_you_go_next": "下一站去哪里？",
    "cities": "城市",
    "lbg.theme.spa_hotel": "SPA",
    "see_guide": "查看指南",
    "lbg.section_properties_theme.ski": "{0}获奖滑雪住宿",
    "related_stories": "相关故事",
    "hub.destination.h2": "您的旅行须知",
    "show_less": "收起",
    "hub.category.h1.football": "足球迷的终极{0}指南",
    "hub.category.h2.food": "{0}最佳餐饮",
    "fallback_language_alert": "很抱歉，此页面尚未翻译成您的语言……",
    "category_highlight.title.information": "实用信息",
    "neighbourhood_guides_for_other_cities": "其他城市的街区指南",
    "lbg.h2.hubpage": "这些绝佳住宿拥有很高的住客评分和众多点评。",
    "travel_advisory.title": "旅行提醒",
    "lbg.section_properties_theme.all_inclusive": "{0}获奖全包住宿",
    "lbg.h2.boutique": "如果您喜欢精品住宿，我们的住客强烈建议入住这里。",
    "lbg.section_properties_theme.family": "{0}获奖家庭住宿",
    "hub.category.h2.families": "在{0}的最佳家庭旅行",
    "from": "最低",
    "lbg.theme.vip_access": "VIP Access",
    "lbg.section_properties_theme.lgbt": "{0}获奖 LGBTQ+ 住宿",
    "x_star": "{0,choice,0#|1#{0} 分（基础）|1.5#{0} 分（基础）|2#{0} 分（经济）|2.5#{0} 分（经济）|3#{0} 分（舒适）|3.5#{0} 分（舒适）|4#{0} 分（高档）|4.5#{0} 分（高档）|5#{0} 分（豪华）}",
    "trust_module.free_cancellation": "免费取消",
    "lbg.section_properties_theme.home_and_apartments": "{0}获奖度假屋和公寓",
    "contributing_writer": "{0} <span>&#124;</span> <span>撰稿人</span>",
    "things_to_see_and_do_in": "<span class=\"next-line\">{0}</span>观光景点和活动",
    "lbg.check_price": "查看价格",
    "hub.category.h2.blogs": "由资深旅客撰写",
    "lbg.h2.home_and_apartments": "寻找让您倍感宾至如归的住宿？查看这些获得高度评价的住宿。",
    "hub.category.h2.information": "有关{0}的实用信息",
    "search_hotels_in": "搜索<span class=\"extra-label\">{0}</span>酒店",
    "hub.category.h2.nature": "{0}热门探寻自然之旅",
    "lbg.h1.destination_theme.home_and_apartments": "{0}获奖度假屋和公寓",
    "photo_by": "照片来源：{0}",
    "more_things_to_do": "更多当地玩乐活动",
    "lbg.section_properties_theme.beach": "{0}获奖海滩住宿",
    "read_less": "隐藏部分内容",
    "lbg.h2.spa_hotel": "不妨在这些以水疗中心闻名的顶级住宿中放松身心。",
    "second_fold_related_stories.see_also": "更多选择",
    "hub.category.h2.history": "{0}最佳历史型旅行",
    "lbg.section_properties_theme.cleanliness": "{0}获奖最清洁住宿",
    "lbg.h1.destination_theme.boutique": "{0}获奖精品住宿",
    "nightlife_b595ae4": "夜生活",
    "lbg.h1.destination_theme.beach": "{0}获奖海滩住宿",
    "trust_module.free_cancellation.disclaimer": "部分酒店要求您在入住前至少 24-48 小时取消。在网站上查看详细信息。",
    "lbg.h2.family": "这些住宿受到住客的高度评价，非常适合家庭入住。",
    "lbg.h2.lgbtq": "这些 LGBTQ 友好住宿受到了住客的高度评价。",
    "hub.category.h1.group": "{0}团体旅行",
    "popular_stories": "热门故事",
    "toc": "内容",
    "hub.category.h2.shopping": "{0}最佳购物场所",
    "lbg.section_theme_world_winners.beach": "获奖海滩住宿",
    "page_score": "此页面得分",
    "lbg.section_theme_world_winners.sustainable": "获奖环保住宿",
    "lbg.theme.boutique": "精品",
    "trust_module.price_guarantee": "我们的价格保证",
    "lbg.h1.destination_theme.all_inclusive": "{0}获奖全包住宿",
    "top_destinations": "热门目的地",
    "hotel_prices_near": "{0}附近酒店价格",
    "lbg.score.cleanliness": "清洁度：",
    "x_stars": "{0,choice,0#|1#{0} 分|1<{0} 分|4<{0} 分|10<{0} 分}",
    "lbg.theme.all_inclusive": "全包",
    "hub.category.h1.tours": "{0}导览和一日游",
    "lbg.section_award_winners_theme": "获奖主题住宿",
    "guest_writer": "{0} <span>&#124;</span> <span>特约撰稿人</span>",
    "start_planning_your_x_trip": "开始计划您的{0}旅行",
    "lbg.section_theme_world_winners.business": "获奖商务住宿",
    "trending_now_in": "{0}人气酒店",
    "history": "历史",
    "search_hotels": "搜索酒店",
    "lbg.terms_conditions_details": "对于在 {0} 年获奖的任何住宿，评审标准都是基于其 {1} 年较高的住客评分和至少 50 条住客点评。",
    "see_nearby_properties": "查看附近住宿",
    "hub.category.h1.history": "{0}历史型旅行",
    "location": "地址：",
    "lbg.section_theme_world_winners.luxury": "获奖豪华住宿",
    "category_highlight.see_all.nightlife": "关于夜生活的更多信息",
    "also_popular": "其他热门",
    "hub.category.h2.things_to_do": "{0}最佳游览观光活动",
    "lbg.theme.family": "家庭",
    "lbg.h1.destination_theme.pet_friendly": "{0}“欢迎宠物”获奖住宿",
    "category_highlight.title.shopping": "{0}购物指南",
    "price": "价格：",
    "hub.category.h2.beach": "{0}最佳海滩",
    "neighbourhoods_in": "{0}街区",
    "business": "商务差旅",
    "photo": "照片",
    "blog.see_all_stories": "查看所有故事",
    "category_highlight.see_all.food": "关于餐饮的更多信息",
    "see_all_properties": "查看所有住宿",
    "lbg.theme.beach": "海滩",
    "what_to_see_and_do_in": "{0}游览观光指南",
    "hub.category.h2.single": "{0}最佳单人旅行",
    "attractions_in": "{0}景点",
    "read_more": "阅读更多内容",
    "show_more": "显示更多",
    "hotels_near_this_landmark": "此地标附近的酒店",
    "check_dates": "查看空房情况",
    "featured_stories_and_fun_stuff": "特色故事和有趣的事物",
    "phone": "电话：",
    "lbg.section_search_header": "搜索获奖住宿",
    "category_highlight.title.food": "{0}餐饮指南",
    "see_all_things_to_do": "查看所有游览观光活动",
    "lbg.section_properties_theme.sustainable": "{0}获奖环保住宿",
    "lbg.h1.hubpage": "{0}“住客喜爱”获奖住宿",
    "lbg.section_theme_world_winners.spa_hotel": "获奖 SPA 住宿",
    "category.maps": "地图",
    "lbg.h1.destination_theme.family": "{0}获奖家庭住宿",
    "lbg.section_theme_world_winners.cleanliness": "获奖最清洁住宿",
    "lbg.section_theme_world_winners.vip_access": "获奖 VIP Access 住宿",
    "trust_module.get_reward_night.night_you_stay": "每住宿 10 晚即可",
    "modified": "已修改",
    "lbg.section_properties": "{0}获奖住宿",
    "trust_module.header": "为什么选择 Hotels.com 好订网进行预订？",
    "hub.category.h2.luxury": "{0}最佳豪华旅行",
    "lbg.theme.ski": "滑雪",
    "lbg.h1.destination_theme.spa_hotel": "{0}获奖 SPA 住宿",
    "lbg.h2.most_wanted": "我们的住客强烈推荐这些提供完美体验的住宿。",
    "top10_hotels_incomplete_alert": "当前需求量很大，某些住宿可能没有展示出来。<span>由此造成不便，我们非常抱歉。</span>",
    "compulsive_traveller": "{0} <span>&#124;</span> <span>旅行达人</span>",
    "lbg.section_theme_world_winners.home_and_apartments": "获奖度假屋和公寓",
    "loved_by_guests_collection_in": "{0}“住客喜爱”精选",
    "title.suffix": "{0} - 旅行指南",
    "hub.category.h2.maps": "{0}的实用地图",
    "opening_hours": "营业时间",
    "hub.category.h2.photos": "{0}最佳摄影旅行",
    "lbg.theme.most_wanted": "最想入住",
    "blog.stories_container": "来自投稿人的故事",
    "hub.category.h2.budget": "{0}最佳经济型旅行",
    "lbg.section_properties_theme.business": "{0}获奖商务住宿",
    "not_sure_where_to_stay": "还没决定住哪吗？",
    "category.information": "信息"
}
