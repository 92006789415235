export default {
    "current_deal": "العرض الحالي",
    "lbg.section_properties_theme.pet_friendly": "الفائزون بجائزة المنشآت المناسبة لاصطحاب الحيوانات الأليفة في {0}",
    "address": "العنوان",
    "hub.homepage.h2": "العديد من الأماكن، ووقت قليل...",
    "search_hotels_near_this_landmark": "ابحث عن فنادق<span class=\"extra-label\"> قرب هذا المعلم</span>",
    "hub.category.h1.things_to_do": "أشياء يوصى بالقيام بها في {0}",
    "lbg.h2.vip_access": "تم اختيار منشآت VIP Access بفضل إقاماتها الحاصلة على تقييم عالٍ والتجارب الرائعة التي تقدمها.",
    "lbg.theme.cleanliness": "النظافة",
    "lbg.theme.lgbtq": "مناسبة للمثليين",
    "load_more": "تحميل المزيد",
    "link_long_form_to_listiicle_multiple": "⁦{0}⁩ - من أفضل المعالم البارزة في ⁦{1}⁩ و⁦{2}⁩ (اقرأ جميع المعلومات حول ⁦{3}⁩ هنا)",
    "lbg.section_properties_theme.boutique": "الفائزون بجائزة المنشآت البوتيك في {0}",
    "lbg.h2.luxury": "استمتع بالرفاهية في أبهى صورها. لهذا السبب يُحب النزلاء الإقامة في هذه المنشآت.",
    "hub.category.h1.information": "معلومات عن {0}",
    "families": "العائلات",
    "trending_now": "ما هو رائج الآن",
    "lbg.from": "بسعر يبدأ من",
    "hub.category.h1.road_trips": "أفضل رحلات الطريق في ⁦{0}⁩",
    "lbg.h2.cleanliness": "تتمتع هذه المنشآت بتقييمات عالية رائعة من النزلاء، وأعلى درجات تقييم للنظافة.",
    "single": "الرحلات المنفردة",
    "couples": "الأزواج",
    "trust_module.price_guarantee.find_out_more": "اكتشف المزيد",
    "top_destinations_in": "أشهر الوجهات السياحية في {0}",
    "hub.category.h1.couples": "سفر للأزواج في ⁦{0}⁩",
    "hub.category.h2.tours": "أفضل الجولات في {0}",
    "hub.category.h1.photos": "سفر لالتقاط الصور في ⁦{0}⁩",
    "category.photos": "صور",
    "hub.category.h1.budget": "سفر بميزانية بسيطة في ⁦{0}⁩",
    "hub.category.h2.ski": "أفضل وجهات للتزلج على الجليد في ⁦{0}⁩",
    "lbg.section_theme_world_winners.most_wanted": "الفائزون بجائزة المنشآت الأكثر طلبا",
    "lbg.theme.home_and_apartments": "منازل وشقق",
    "hub.category.h1.luxury": "سفر للترفيه في ⁦{0}⁩",
    "hub.category.h2.adventure": "أفضل أماكن ممارسة الأنشطة في ⁦{0}⁩",
    "hotels_in": "فنادق في {0}",
    "lbg.section_search_winners": "ابحث على المزيد من المنشآت الحاصلة على جوائز",
    "see_more_hotels_in": "تصفّح المزيد من الفنادق في {0}",
    "hub.category.h1.beach": "الشواطئ في ⁦{0}⁩",
    "open": "أوقات العمل:",
    "enter_your_destination": "أدخل وجهتك",
    "best_of": "أفضل ما في {0}",
    "top10_hotels_missing_properties": "قد لا تظهر بعض المنشآت حاليّا. نعتذر عن أي إزعاج قد يسبّبه ذلك.",
    "adventure": "المغامرة",
    "lbg.h1.destination_theme.sustainable": "الفائزون بجائزة المنشآت الصديقة للبيئة في {0}",
    "luxury": "الفخامة",
    "expand_map_view": "تكبير مربع الخريطة",
    "book_now": "احجز الآن",
    "lbg.h1.destination_theme.ski": "الفائزون بجائزة المنشآت المناسبة للتزلج في {0}",
    "hub.category.h1.food": "المأكولات وتناول الطعام في {0}",
    "category.blogs": "مدونات",
    "budget": "الميزانية",
    "popular_neighbourhoods_in": "أحياء شهيرة في {0}",
    "hub.category.h1.unusual": "مواقع استثنائية وكنوز خفية في ⁦{0}⁩",
    "see_all_accommodation_in": "شاهد أماكن الإقامة في ⁦{0}⁩",
    "hub.category.h1.adventure": "سفر لممارسة أنشطة في ⁦{0}⁩",
    "category_highlight.see_all.shopping": "المزيد عن أماكن التسوق",
    "lbg.section_most_popular_destination": "أكثر الوجهات شيوعًا",
    "lbg.section_properties_theme.luxury": "الفائزون بجائزة المنشآت الفاخرة في {0}",
    "unusual": "الأمور غير الاعتيادية",
    "category.shopping": "التسوق",
    "make_the_most_of_your_trip": "حقق أقصى استفادة من رحلتك",
    "x_review": "{0,choice,0#|1#{0} تقييم|2#{0} من التقييمات|2<{0} تقييمات|4<{0} تقييمات|10#{0} تقييمات|10<{0} تقييما}",
    "hub.category.h1.single": "سفر للأفراد في ⁦{0}⁩",
    "hub.category.h2.custom": "رائج الآن",
    "lbg.section_theme_world_winners.lgbt": "LGBTQ+ award winners ",
    "lbg.section_award_winners_theme_other": "فئات جوائز أخرى في {0}",
    "lbg.supplier_collected_charges": "+ {0} للرسوم الواجب سدادها في المنشأة",
    "in_destination": " في {0}",
    "lbg.section_properties_theme.vip_access": "المنشآت الفائزة بجائزة VIP Access في ⁦{0}⁩",
    "link_long_form_to_listiicle_single": "⁦{0}⁩ - من أفضل المعالم البارزة في ⁦{1}⁩ (اقرأ جميع المعلومات حول ⁦{2}⁩ هنا)",
    "lbg.section_theme_world_winners.boutique": "الفائزون بجائزة المنشآت البوتيك",
    "lbg.section_properties_theme.spa_hotel": "الفائزون بجائزة المنشآت التي تشمل منتجعا صحيّا في {0}",
    "lbg.section_theme_world_winners.all_inclusive": "الفائزون بجائزة المنشآت ذات السعر الشامل لجميع الخدمات",
    "hub.category.h2": "أدلة السفر إلى {0}",
    "lbg.properties.price_tooltip": "هذا أقل سعر متوفر على موقعنا في الليلة الواحدة لفترة الـ 14 يوما القادمة.",
    "hub.category.h2.road_trips": "أفضل رحلات الطريق في ⁦{0}⁩",
    "hub.category.h1.ski": "وجهات للتزلج على الجليد في ⁦{0}⁩",
    "food": "المأكولات",
    "lbg.theme.pet_friendly": "تسمح باصطحاب الحيوانات الأليفة",
    "lbg.theme.business": "مناسبة لرجال الأعمال",
    "hub.category.h1.nature": "أفضل رحلات الاستمتاع بالطبيعة في ⁦{0}⁩",
    "map": "الخريطة",
    "category_highlight.see_all.information": "المزيد من المعلومات",
    "hotel_prices_in": "أسعار الفنادق في {0}",
    "hub.category.h1.nightlife": "الحياة الليلية في {0}",
    "trust_module.free_cancellation.most_hotels": "في أغلب الفنادق*",
    "also_popular_in": "شائع أيضًا في {0}",
    "lbg.h1.destination_theme.business": "الفائزون بجائزة المنشآت المناسبة لرجال الأعمال في {0}",
    "lbg.section_theme_world_winners.pet_friendly": "الفائزون بجائزة المنشآت المناسبة لاصطحاب الحيوانات الأليفة",
    "hub.category.h1.maps": "خرائط ⁦{0}⁩",
    "lbg.section_properties_theme.most_wanted": "الفائزون بجائزة المنشآت الأكثر طلبا في {0}",
    "category.nightlife": "الحياة الليلية",
    "search_hotels_in_this_area": "ابحث عن فنادق<span class=\"extra-label\"> في هذه المنطقة</span>",
    "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#صوت واحد|2#صوتان|2<{1} أصوات|4<{1} أصوات|10#10 أصوات|10<{1} صوتا})",
    "hub.category.h2.business": "أفضل المنشآت للسفر للعمل في ⁦{0}⁩",
    "category.things_to_do": "المعالم السياحية",
    "destination_travel_guide_by_category": "دليل السفر إلى {0} حسب الفئة",
    "hub.category.h1.families": "الرحلات العائلية في ⁦{0}⁩",
    "lbg.section_theme_world_winners.ski": "الفائزون بجائزة المنشآت المناسبة للتزلج",
    "you_may_also_like": "قد يُعجبك أيضا",
    "lbg.theme.sustainable": "صديقة للبيئة",
    "hub.category.h2.unusual": "أفضل المواقع الاستثنائية والكنوز الخفية في ⁦{0}⁩",
    "hub.category.h2.football": "شجع فريق كرة القدم المفضل لديك وحقق أقصى استفادة من رحلتك!",
    "top_hotel": "فندق مميز",
    "lbg.h1.destination_theme.luxury": "الفائزون بجائزة المنشآت الفاخرة في {0}",
    "top10_hotels_empty_alert": "نواجه حاليّا مشكلة تقنيّة. <span>ونعتذر عن أي إزعاج قد تسبّب به ذلك.</span>",
    "special_deal": "عرض خاص",
    "lbg.h2.sustainable": "الأمر كله يتعلق بالحفاظ على البيئة! استكشف هذه المنشآت التي تمنحك إقامة رائعة.",
    "where_to_stay_in": "أفضل الأماكن للإقامة <span class=\"next-line\">في {0}</span>",
    "start_planning_your_trip": "ابدأ بالتخطيط لرحلتك",
    "good_for": ":مناسب لأغراض",
    "category.hotels": "الفنادق",
    "x_accommodation_guide": "دليل أماكن الإقامة في {0} ",
    "lbg.theme.luxury": "فاخرة",
    "hub.category.h2.sports": "أفضل الأماكن لممارسة الرياضات والأنشطة في ⁦{0}⁩",
    "lbg.section_theme_world_winners.lgbtq": "المنشآت الحائزة على جائزة LGBTQ",
    "see_more_neighbourhoods": "استعراض مزيد من الأحياء",
    "category_highlight.title.nightlife": "أين تذهب وماذا تفعل عند قضاء ليلة في ⁦{0}⁩",
    "lbg.h1.destination_theme.lgbt": "LGBTQ+ award winners in {0}",
    "hub.category.h1.blogs": "مدونة السفر إلى {0}",
    "lbg.h2.business": "منح المسافرون لأغراض العمل تقييمًا عاليًا لهذه المنشآت.",
    "hotels": "الفنادق",
    "lbg.h2.pet_friendly": "يُوصي النزلاء بشدّة بهذه المنشآت التي ترحب بك وبحيوانك الأليف.",
    "popular_landmarks_nearby": "معالم شهيرة في الجوار",
    "popular_extractions_in": "معالم شهيرة في {0}",
    "lbg.h2.all_inclusive": "تمنحك هذه المنشآت الأعلى تقييمًا شعورًا كاملاً بالهدوء والسكينة.",
    "travel_guide_by_category_header": "أفضل {0} في {1}",
    "lbg.travel_between": "سافر بين {0} و{1}",
    "keep_exploring": "تابع الاستكشاف",
    "what_you_need_to_know_before_you_go": "ما تحتاج إلى معرفته قبل الذهاب",
    "read_it": "انقر للقراءة",
    "view_on_map": "مشاهدة على الخريطة",
    "lbg.h1.destination_theme.lgbtq": "المنشآت الحائزة على جائزة LGBTQ في ⁦{0}⁩",
    "lbg.h2.beach": "جلسة على الشاطئ هي ما يحبه النزلاء، لا سيّما في هذه المنشآت الفندقية.",
    "lbg.h1.destination_theme.vip_access": "المنشآت الفائزة بجائزة VIP Access في ⁦{0}⁩",
    "see_all_accommodation": "شاهد جميع أماكن الإقامة",
    "shoppers": "التسوّق",
    "whats_around": "ماذا بجوار {0}",
    "group": "المجموعات",
    "hub.category.h2.couples": "أفضل الأماكن لسفر الأزواج في ⁦{0}⁩",
    "hub.category.h1.shopping": "التسوّق في {0}",
    "travel_community": "{0} <span>&#124;</span> <span>مجتمع السفر</span>",
    "hub.category.h2.group": "أفضل الأماكن لسفر الأفواج في ⁦{0}⁩",
    "listicle_legal_msg": "يحتوي هذا المقال على آراء من فريق تحرير Go Guides. يعوض موقع Hotels.com الكتّاب عن مقالاتهم التي تظهر على هذا الموقع؛ وقد يتضمن هذا التعويض السفر وتكاليف أخرى.",
    "lbg.h1.destination_theme.cleanliness": "المنشآت الحائزة على جائزة النظافة في ⁦{0}⁩",
    "menu.more": "المزيد",
    "lbg.section_properties_theme.lgbtq": "المنشآت الحائزة على جائزة LGBTQ في ⁦{0}⁩",
    "lbg.section_award_winners_destination": "المنشآت الحاصلة على جوائز حسب الوجهة",
    "lbg.terms_conditions": "الشروط والأحكام",
    "hub.category.h2.nightlife": "أفضل أماكن الترفيه الليلي في {0}",
    "hub.neighbourhood.h2": "الأمور التي يوصى بالقيام بها في {0}",
    "lbg.section_award_winners_theme_destination": "المنشآت الحاصلة على جوائز في {0} حسب الفئة",
    "lbg.section_award_world_winners": "المنشآت الحاصلة على جوائز حول العالم",
    "things_to_see_and_do_in_other_cities": "أشياء يوصى بمشاهدتها والقيام بها في مدن أخرى",
    "button.backToTop": "عودة إلى أعلى",
    "lbg.section_theme_world_winners.family": "الفائزون بجائزة المنشآت المناسبة للعائلات",
    "hotels_near": "فنادق قرب {0}",
    "category.activity__sports_": "الجولات السياحية",
    "trust_module.get_reward_night": "احصل على ليلة هدية",
    "hub.category.h1.business": "سفر لأغراض العمل في ⁦{0}⁩",
    "category.food": "المأكولات",
    "other_popular_destinations_in": "وجهات أخرى رائجة في {0}",
    "browse_by_category": "استعراض {0} حسب الفئة",
    "see_more_attactions": "شاهد المزيد من المعالم",
    "lbg.theme.lgbt": "LGBTQ+",
    "more_information_about": "مزيد من المعلومات حول {0}",
    "lbg.h1.destination_theme.most_wanted": "الفائزون بجائزة المنشآت الأكثر طلبا في {0}",
    "see_all_properties_in": "استعرض منشآت سياحية في ⁦{0}⁩",
    "category.tours": "الجولات السياحية",
    "hub.category.h1.sports": "الرياضات والأنشطة في ⁦{0}⁩",
    "where_will_you_go_next": "ما هي وجهتك التالية؟",
    "cities": "مدن",
    "lbg.theme.spa_hotel": "مع منتجع صحّي",
    "see_guide": "شاهد الدليل الإرشادي",
    "lbg.section_properties_theme.ski": "الفائزون بجائزة المنشآت المناسبة للتزلج في {0}",
    "related_stories": "مواضيع مشابهة",
    "hub.destination.h2": "ما تحتاج إلى معرفته قبل البدء",
    "show_less": "عرض أقل",
    "hub.category.h1.football": "دليل ⁦{0}⁩ المطلق لمشجعي كرة القدم المخلصين",
    "hub.category.h2.food": "أفضل الأطعمة وأماكن تناول الطعام في {0}",
    "fallback_language_alert": "عذرا، لم تتم ترجمة هذه الصفحة إلى لغتك بعد...",
    "category_highlight.title.information": "معلومات مفيدة",
    "neighbourhood_guides_for_other_cities": "أدلة إرشادية لأحياء المدن الأخرى",
    "lbg.h2.hubpage": "هذه المنشآت الفندقية الرائعة حصلت على درجات تقييم عالية من النزلاء وحصّلت العديد من التقييمات.",
    "travel_advisory.title": "تنبيه خاص بالسفر",
    "lbg.section_properties_theme.all_inclusive": "الفائزون بجائزة المنشآت ذات السعر الشامل لجميع الخدمات في {0}",
    "lbg.h2.boutique": "إذا كنت تحب المنشآت الصغيرة الفاخرة، فالنزلاء يُوصون بشدّة بالإقامة هنا.",
    "lbg.section_properties_theme.family": "الفائزون بجائزة المنشآت المناسبة للعائلات في {0}",
    "hub.category.h2.families": "أفضل الرحلات العائلية في ⁦{0}⁩",
    "from": "من",
    "lbg.theme.vip_access": "VIP Access",
    "lbg.section_properties_theme.lgbt": "LGBTQ+ award winners in {0}",
    "x_star": "{0,choice,0#|1#{0} نجمة|1.5#{0} نجمة|2#{0} نجمة|2.5#{0} نجمة|3#{0} نجوم|3.5#{0} نجمة|4#{0} نجوم|4.5#{0} نجمة|5#{0} نجوم}",
    "trust_module.free_cancellation": "إلغاء مجاني",
    "lbg.section_properties_theme.home_and_apartments": "الفائزون بجائزة المنازل والشقق في {0}",
    "contributing_writer": "{0} <span>&#124;</span> <span>كاتب مُساهم</span>",
    "things_to_see_and_do_in": "أشياء يوصى بمشاهدتها والقيام بها <span class=\"next-line\">في {0}</span>",
    "lbg.check_price": "تحقّق من السعر",
    "hub.category.h2.blogs": "مُعدٌ بواسطة المسافرين ذوي الخبرة",
    "lbg.h2.home_and_apartments": "هل تبحث عن دفء المنزل بعيدًا عن منزلك؟ استكشف هذه المنشآت ذات التقييم العالي.",
    "hub.category.h2.information": "معلومات مفيدة عن {0}",
    "search_hotels_in": "ابحث عن فنادق<span class=\"extra-label\"> في {0}</span>",
    "hub.category.h2.nature": "أفضل رحلات العودة إلى الطبيعة في ⁦{0}⁩",
    "lbg.h1.destination_theme.home_and_apartments": "الفائزون بجائزة المنازل والشقق في {0}",
    "photo_by": "الصورة من تقدمة {0}",
    "more_things_to_do": "أمور أخرى يمكنك القيام بها",
    "lbg.section_properties_theme.beach": "الفائزون بجائزة المنشآت الشاطئية في {0}",
    "read_less": "قراءة تفاصيل أقل",
    "lbg.h2.spa_hotel": "استرخ واستمتع في هذه المنشآت ذات التقييم العالي والمشهورة بمرافق السبا المتوفرة بها. ",
    "second_fold_related_stories.see_also": "شاهد أيضًا",
    "hub.category.h2.history": "أفضل المواقع التاريخية في ⁦{0}⁩",
    "lbg.section_properties_theme.cleanliness": "المنشآت الحائزة على جائزة النظافة في ⁦{0}⁩",
    "lbg.h1.destination_theme.boutique": "الفائزون بجائزة المنشآت البوتيك في {0}",
    "nightlife_b595ae4": "الترفيه الليلي",
    "lbg.h1.destination_theme.beach": "الفائزون بجائزة المنشآت الشاطئية في {0}",
    "trust_module.free_cancellation.disclaimer": "تشترط بعض الفنادق إلغاء حجزك قبل تسجيل الوصول بمدّة 24 إلى 48 ساعة على الأقل. التفاصيل متوفرة على الموقع.",
    "lbg.h2.family": "هذه المنشآت حاصلة على تقييم عالٍ من النزلاء وهي مثالية للعائلات.",
    "lbg.h2.lgbtq": "حصلت هذه المنشآت التي ترحب بالمثليين على تقييم عالٍ من النزلاء.",
    "hub.category.h1.group": "سفر للأفواج في ⁦{0}⁩",
    "popular_stories": "قصص مشهورة",
    "toc": "المحتوى",
    "hub.category.h2.shopping": "أفضل أماكن التسوق في {0}",
    "lbg.section_theme_world_winners.beach": "الفائزون بجائزة المنشآت الشاطئية",
    "page_score": "درجة هذه الصفحة",
    "lbg.section_theme_world_winners.sustainable": "الفائزون بجائزة المنشآت الصديقة للبيئة",
    "lbg.theme.boutique": "بوتيك",
    "trust_module.price_guarantee": "ضمان مطابقة السعر الخاص بنا",
    "lbg.h1.destination_theme.all_inclusive": "الفائزون بجائزة المنشآت ذات السعر الشامل لجميع الخدمات في {0}",
    "top_destinations": "أفضل وجهات السفر",
    "hotel_prices_near": "أسعار الفنادق قرب {0}",
    "lbg.score.cleanliness": "النظافة:",
    "x_stars": "{0,choice,0#|1#{0} نجمة|1<{0} نجوم|4<{0} نجوم|10<{0} نجمة}",
    "lbg.theme.all_inclusive": "بسعر شامل لجميع الخدمات",
    "hub.category.h1.tours": "الجولات السياحية والرحلات اليومية في {0}",
    "lbg.section_award_winners_theme": "المنشآت الحاصلة على جوائز حسب الفئة",
    "guest_writer": "{0} <span>&#124;</span> <span>كاتب ضيف</span>",
    "start_planning_your_x_trip": "ابدأ بالتخطيط لرحلتك إلى {0}",
    "lbg.section_theme_world_winners.business": "الفائزون بجائزة المنشآت المناسبة لرجال الأعمال",
    "trending_now_in": "ما هو رائج الآن في {0}",
    "history": "الطابع التاريخي",
    "search_hotels": "بحث عن الفنادق",
    "lbg.terms_conditions_details": "استندت معايير اختيار أي منشأة فندقية للفوز بالجائزة في عام ⁦{0}⁩ إلى منحها درجات تقييم عالية من النزلاء في عام ⁦{1}⁩ وإلى حصولها على 50 تقييمًا من النزلاء بحدٍ أدنى.",
    "see_nearby_properties": "شاهد المنشآت القريبة",
    "hub.category.h1.history": "مواقع تاريخية في ⁦{0}⁩",
    "location": "الموقع:",
    "lbg.section_theme_world_winners.luxury": "الفائزون بجائزة المنشآت الفاخرة",
    "category_highlight.see_all.nightlife": "المزيد عن أماكن الترفيه الليلي",
    "also_popular": "وجهات أخرى رائجة",
    "hub.category.h2.things_to_do": "أفضل الأمور التي يوصى بالقيام بها في {0}",
    "lbg.theme.family": "مناسبة للعائلات",
    "lbg.h1.destination_theme.pet_friendly": "الفائزون بجائزة المنشآت المناسبة لاصطحاب الحيوانات الأليفة في {0}",
    "category_highlight.title.shopping": "أين تتسوق وماذا تشتري من ⁦{0}⁩",
    "price": "السعر:",
    "hub.category.h2.beach": "أفضل الشواطئ في ⁦{0}⁩",
    "neighbourhoods_in": "أحياء في {0}",
    "business": "العمل",
    "photo": "الصور",
    "blog.see_all_stories": "شاهد جميع القصص",
    "category_highlight.see_all.food": "المزيد عن الأطعمة وأماكن تناول الطعام",
    "see_all_properties": "استعرض جميع المنشآت السياحية",
    "lbg.theme.beach": "شاطئية",
    "what_to_see_and_do_in": "ماذا يجب أن تشاهد وتفعل في ⁦{0}⁩",
    "hub.category.h2.single": "أفضل الأماكن لسفر الأفراد في ⁦{0}⁩",
    "attractions_in": "أماكن الجذب في {0}",
    "read_more": "قراءة المزيد",
    "show_more": "عرض المزيد",
    "hotels_near_this_landmark": "فنادق قرب هذا المعلم",
    "check_dates": "تحقّق من التواريخ",
    "featured_stories_and_fun_stuff": "مواضيع متميّزة وأشياء ممتعة تقوم بها",
    "phone": "رقم الهاتف:",
    "lbg.section_search_header": "ابحث على المنشآت الحاصلة على جوائز",
    "category_highlight.title.food": "ماذا وأين تأكل في ⁦{0}⁩",
    "see_all_things_to_do": "اطلع على جميع الأمور التي يمكنك القيام بها",
    "lbg.section_properties_theme.sustainable": "الفائزون بجائزة المنشآت الصديقة للبيئة في {0}",
    "lbg.h1.hubpage": "المنشآت الحاصلة على جائزة «محبوب من النزلاء» لعام {0}!",
    "lbg.section_theme_world_winners.spa_hotel": "الفائزون بجائزة المنشآت التي تشمل منتجعا صحيّا ",
    "category.maps": "خرائط",
    "lbg.h1.destination_theme.family": "الفائزون بجائزة المنشآت المناسبة للعائلات في {0}",
    "lbg.section_theme_world_winners.cleanliness": "المنشآت الفائزة بجائزة النظافة",
    "lbg.section_theme_world_winners.vip_access": "الفائزون بجائزة VIP Access",
    "trust_module.get_reward_night.night_you_stay": "عن كل 10 ليالٍ تقيمها",
    "modified": "معدّلة",
    "lbg.section_properties": "المنشآت الحاصلة على جوائز في {0}",
    "trust_module.header": "ما هي مزايا الحجز عبر موقع Hotels.com؟",
    "hub.category.h2.luxury": "أفضل أماكن السفر للترفيه في ⁦{0}⁩",
    "lbg.theme.ski": "مناسبة للتزلج",
    "lbg.h1.destination_theme.spa_hotel": "الفائزون بجائزة المنشآت التي تشمل منتجعا صحيّا في {0}",
    "lbg.h2.most_wanted": "يُوصي النزلاء بشدة بهذه المنشآت للاستمتاع بإقامة مثالية.",
    "top10_hotels_incomplete_alert": "الطلب لدينا مرتفع الآن، لذا فإن بعض المنشآت قد لا تظهر حاليا. <span>نعتذر عن أي إزعاج قد يسببه ذلك.</span>",
    "compulsive_traveller": "{0} <span>&#124;</span> <span>مسافر مثابر</span>",
    "lbg.section_theme_world_winners.home_and_apartments": "الفائزون بجائزة المنازل والشقق",
    "loved_by_guests_collection_in": "مجموعة أماكن الإقامة «المحبوبة من النزلاء» في {0}",
    "title.suffix": "{0} - Go Guides",
    "hub.category.h2.maps": "خرائط مفيدة عن {0}",
    "opening_hours": "مواعيد العمل",
    "hub.category.h2.photos": "أفضل الأماكن لالتقاط الصور في ⁦{0}⁩",
    "lbg.theme.most_wanted": "الأكثر طلبا",
    "blog.stories_container": "مقالات من مدونات",
    "hub.category.h2.budget": "أفضل الأماكن للسفر بميزانية بسيطة في ⁦{0}⁩",
    "lbg.section_properties_theme.business": "الفائزون بجائزة المنشآت المناسبة لرجال الأعمال في {0}",
    "not_sure_where_to_stay": "لم تقرر بعد أين ستقيم؟",
    "category.information": "معلومات"
}
