import { useEffect, useState } from 'react';
import { useWindowSize } from '../common/helper';
import { checkValue } from '../functions';
import * as React from 'react';
import { UitkImage } from 'uitk-react-images';
import { Viewport, ViewLarge, ViewMedium, ViewSmall, ViewExtraLarge } from "@shared-ui/viewport-context";

interface ResponsiveImageProps {
    imageUrl: string;
    params: {
        size?: {
            mobile?: string;
            tablet?: string;
            desktop?: string;
        };
        quality?: string;
        position?: {
            align?: string;
            mode?: string;
        };
    };
}

export const getResponsiveImageUrl = (props: ResponsiveImageProps): string => {
    const { imageUrl, params } = props;

    const [windowSize, setWindowSize] = useState<string>('desktop');
    const imgParam = '?anchor=center&mode=crop';
    const sizeParams = params.size ? params.size?.[windowSize] : '';
    const qualityParam = params.quality ? params.quality : '';
    const [sourceImageUrl, setSourceImageUrl] = useState<string>(`${imageUrl}${imgParam}${sizeParams}${qualityParam}`);
    const [width] = useWindowSize();

    const screenSize = {
        s: 599,
        m: 749,
        l: 959,
    };

    useEffect(() => {
        let deviceView = 'mobile';

        if (width > screenSize.l) {
            deviceView = 'desktop';
        } else if (width > screenSize.m) {
            deviceView = 'tablet';
        }

        if (deviceView !== windowSize) {
            setWindowSize(deviceView);
        }

        const sizeParams = params.size ? params.size?.[windowSize] : '';
        const newImageUrl = checkValue(imageUrl) ? `${imageUrl}${imgParam}${sizeParams}${qualityParam}` : '';

        if (newImageUrl !== sourceImageUrl) {
            setSourceImageUrl(newImageUrl);
        }
    }, [width, imageUrl, params, qualityParam, imgParam, windowSize, sourceImageUrl]);

    return sourceImageUrl;
};


interface RenderImageProps {
    imgProps: {
        image: string;
        alt?: string;
        params: {
            size: {
                small?: string;
                medium?: string;
                large?: string;
                exLarge?: string;
            };
        };
    }
}
export const RenderImage: React.FC<RenderImageProps> = ({ imgProps }) => {
    const { image, params, alt } = imgProps;
    const imageUrl = image
    const imgParam = '?anchor=center&mode=crop';
    const imgQuality = '&quality=50';
    const { small, medium, large, exLarge } = params.size;

    const imageSourceSmall = checkValue(small) ? `${imageUrl}${imgParam}${small}${imgQuality}` : null;
    const imageSourceMedium = checkValue(medium) ? `${imageUrl}${imgParam}${medium}${imgQuality}` : null;
    const imageSourceLarge = checkValue(large) ? `${imageUrl}${imgParam}${large}${imgQuality}` : null;
    const imageSourceExLarge = checkValue(exLarge) ? `${imageUrl}${imgParam}${exLarge}${imgQuality}` : null;

    return (
        <Viewport>
            {imageSourceSmall && (
                <ViewSmall>
                    <UitkImage blankDefault={false} lazyLoading='lazy' alt={alt} src={imageSourceSmall} />
                </ViewSmall>
            )}
            {imageSourceMedium && (
                <ViewMedium>
                    <UitkImage blankDefault={false} lazyLoading='lazy' alt={alt} src={imageSourceMedium} />
                </ViewMedium>
            )}
            {imageSourceLarge && (
                <ViewLarge>
                    <UitkImage blankDefault={false} lazyLoading='lazy' alt={alt} src={imageSourceLarge} />
                </ViewLarge>
            )}
            {imageSourceExLarge && (
                <ViewExtraLarge>
                    <UitkImage blankDefault={false} lazyLoading='lazy' alt={alt} src={imageSourceExLarge} />
                </ViewExtraLarge>
            )}
        </Viewport>
    );
};