export default {
    "current_deal": "Сьогоднішня пропозиція",
    "lbg.section_properties_theme.pet_friendly": "Переможці в категорії «Доступ з домашніми улюбленцями» — {0}",
    "address": "Адреса",
    "hub.homepage.h2": "Так багато цікавих подорожей, так мало часу...",
    "search_hotels_near_this_landmark": "Шукати готелі<span class=\"extra-label\"> біля цієї визначної пам’ятки</span>",
    "hub.category.h1.things_to_do": "{0}: чим зайнятися",
    "lbg.h2.vip_access": "Помешкання VIP Access обрані через високий рейтинг і бездоганні умови.",
    "lbg.theme.cleanliness": "Чистота",
    "lbg.theme.lgbtq": "ЛГБТК",
    "load_more": "Більше",
    "link_long_form_to_listiicle_multiple": "{0} входить до списку {1} і {2} (дізнайтеся більше про м. {3} тут).",
    "lbg.section_properties_theme.boutique": "Переможці в категорії «Бутік» — {0}",
    "lbg.h2.luxury": "Зануртеся в розкіш і дізнайтеся, чому гостям так подобаються ці помешкання.",
    "hub.category.h1.information": "{0}: інформація та поради",
    "families": "Сім’ї",
    "trending_now": "Популярне зараз",
    "lbg.from": "Від",
    "hub.category.h1.road_trips": "{0}: найкращі маршрути для подорожі автомобілем",
    "lbg.h2.cleanliness": "Ці помешкання мають чудові загальні оцінки відвідувачів, а також найвищі оцінки за чистоту.",
    "single": "Соло-мадрівники",
    "couples": "Пари",
    "trust_module.price_guarantee.find_out_more": "Докладніше",
    "top_destinations_in": "{0}: найпопулярніші напрямки",
    "hub.category.h1.couples": "{0}: для закоханих",
    "hub.category.h2.tours": "{0}: найкращі екскурсії",
    "hub.category.h1.photos": "{0}: для фотографів і аматорів",
    "category.photos": "Фотографії",
    "hub.category.h1.budget": "{0}: бюджетні поїздки",
    "hub.category.h2.ski": "{0}: найкращі гірськолижні курорти",
    "lbg.section_theme_world_winners.most_wanted": "Переможці в категорії «Безперечний хіт»",
    "lbg.theme.home_and_apartments": "Будинки й квартири",
    "hub.category.h1.luxury": "{0}: розкішні подорожі",
    "hub.category.h2.adventure": "{0}: найкраще для активного відпочинку",
    "hotels_in": "Готелі в місті {0}",
    "lbg.section_search_winners": "Пошук інших переможців",
    "see_more_hotels_in": "{0}: більше готелів",
    "hub.category.h1.beach": "{0}: пляжі",
    "open": "Години роботи:",
    "enter_your_destination": "Введіть напрямок",
    "best_of": "{0}: найцікавіші місця і пам’ятки",
    "top10_hotels_missing_properties": "Деякі помешкання можуть не відображатися. Перепрошуємо за незручності.",
    "adventure": "Активний відпочинок",
    "lbg.h1.destination_theme.sustainable": "Переможці в категорії «Турбота про довкілля» — {0}",
    "luxury": "Розкіш",
    "expand_map_view": "Збільшити мапу",
    "book_now": "Забронювати",
    "lbg.h1.destination_theme.ski": "Переможці в категорії «Лижі» — {0}",
    "hub.category.h1.food": "{0}: харчування",
    "category.blogs": "Блог",
    "budget": "Бюджетний відпочинок",
    "popular_neighbourhoods_in": "Популярні райони у м. {0}",
    "hub.category.h1.unusual": "{0}: приховані скарби",
    "see_all_accommodation_in": "{0}: усі помешкання",
    "hub.category.h1.adventure": "{0}: активний відпочинок",
    "category_highlight.see_all.shopping": "Детальніше про шопінг",
    "lbg.section_most_popular_destination": "Найпопулярніші місця",
    "lbg.section_properties_theme.luxury": "Переможці в категорії «Розкіш» — {0}",
    "unusual": "Незвичний відпочинок",
    "category.shopping": "Шопінг",
    "make_the_most_of_your_trip": "Зробіть свою мандрівку незабутньою",
    "x_review": "{0,choice,0#|1#{0} відгук|2#{0} відгуки|2<{0} відгуки|4<{0} відгуків|10#{0} відгуків|10<{0} відгуків}",
    "hub.category.h1.single": "{0}: мандрівки соло",
    "hub.category.h2.custom": "Популярно зараз",
    "lbg.section_theme_world_winners.lgbt": "Переможці в категорії «Привітність до ЛГБТК+»",
    "lbg.section_award_winners_theme_other": "Інші категорії переможців — {0}",
    "lbg.supplier_collected_charges": "+ {0} до сплати в помешканні",
    "in_destination": " {0}",
    "lbg.section_properties_theme.vip_access": "{0}: переможці в категорії «VIP Access»",
    "link_long_form_to_listiicle_single": "{0} входить до списку {1} (дізнайтеся більше про м. {2} тут).",
    "lbg.section_theme_world_winners.boutique": "Переможці в категорії «Бутік»",
    "lbg.section_properties_theme.spa_hotel": "Переможці в категорії «Спа» — {0}",
    "lbg.section_theme_world_winners.all_inclusive": "Переможці в категорії «Усе враховано»",
    "hub.category.h2": "{0}: путівник",
    "lbg.properties.price_tooltip": "Це найнижча ціна за добу на нашому сайті на наступні 14 діб.",
    "hub.category.h2.road_trips": "{0}: найкращі маршрути для подорожі автомобілем",
    "hub.category.h1.ski": "{0}: гірськолижні курорти",
    "food": "Їжа",
    "lbg.theme.pet_friendly": "Доступ з домашніми улюбленцями",
    "lbg.theme.business": "Відрядження",
    "hub.category.h1.nature": "{0}: найкращі природні маршрути",
    "map": "Карта",
    "category_highlight.see_all.information": "Додаткова інформація",
    "hotel_prices_in": "Ціни на готелі у районі {0}",
    "hub.category.h1.nightlife": "{0}: нічне життя",
    "trust_module.free_cancellation.most_hotels": "у більшості готелів*",
    "also_popular_in": "{0}: інші популярні помешкання",
    "lbg.h1.destination_theme.business": "Переможці в категорії «Відрядження» — {0}",
    "lbg.section_theme_world_winners.pet_friendly": "Переможці в категорії «Доступ з домашніми улюбленцями»",
    "hub.category.h1.maps": "{0}: карти",
    "lbg.section_properties_theme.most_wanted": "Переможці в категорії «Безперечний хіт» — {0}",
    "category.nightlife": "Нічне життя",
    "search_hotels_in_this_area": "Шукати готелі<span class=\"extra-label\"> у цьому районі</span>",
    "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 голос|2#2 голоси|2<{1} голоси|4<{1} голосів|10#10 голосів|10<{1} голосів})",
    "hub.category.h2.business": "{0}: найкраще для ділових людей",
    "category.things_to_do": "Розваги",
    "destination_travel_guide_by_category": "{0}: Тематичні путівники",
    "hub.category.h1.families": "{0}: Сімейний відпочинок",
    "lbg.section_theme_world_winners.ski": "Переможці в категорії «Лижі»",
    "you_may_also_like": "Вам також може сподобатись",
    "lbg.theme.sustainable": "Турбота про довкілля",
    "hub.category.h2.unusual": "{0}: найнезвичайніші приховані скарби",
    "hub.category.h2.football": "Підтримайте улюблену футбольну команду та отримайте від своєї мандрівки якомога більше!",
    "top_hotel": "Найпопулярніший готель",
    "lbg.h1.destination_theme.luxury": "Переможці в категорії «Розкіш» — {0}",
    "top10_hotels_empty_alert": "Зараз у нас виникла технічна проблема. <span>Перепрошуємо за незручності.</span>",
    "special_deal": "Спеціальна пропозиція",
    "lbg.h2.sustainable": "Якщо ви піклуєтеся про довкілля, зупиніться в одному з цих чудових помешкань.",
    "where_to_stay_in": "Де зупинитись у м. {0}",
    "start_planning_your_trip": "Почніть планувати свою мандрівку",
    "good_for": "Чим відзначається:",
    "category.hotels": "Помешкання",
    "x_accommodation_guide": "{0}: путівник помешканнями",
    "lbg.theme.luxury": "Розкіш",
    "hub.category.h2.sports": "{0}: найкраще для спортсменів і аматорів",
    "lbg.section_theme_world_winners.lgbtq": "Переможці в категорії «Привітність до ЛГБТК»",
    "see_more_neighbourhoods": "Інші райони",
    "category_highlight.title.nightlife": "{0}: куди тут можна піти й чим зайнятися вночі",
    "lbg.h1.destination_theme.lgbt": "{0}: переможці в категорії «Привітність до ЛГБТК+»",
    "hub.category.h1.blogs": "Блог про подорожі: {0} ",
    "lbg.h2.business": "Ці помешкання отримали високі оцінки від бізнес-мандрівників.",
    "hotels": "Готелі",
    "lbg.h2.pet_friendly": "Гості рекомендують ці помешкання, де можна зупинятися з тваринами.",
    "popular_landmarks_nearby": "Популярні визначні пам’ятки поблизу",
    "popular_extractions_in": "Популярні визначні місця у м. {0}",
    "lbg.h2.all_inclusive": "Ці помешкання з найвищим рейтингом знімають стрес.",
    "travel_guide_by_category_header": "Найкращі {0} у {1}",
    "lbg.travel_between": "Подорожуйте з {0} по {1}",
    "keep_exploring": "Вперед до нових відкриттів",
    "what_you_need_to_know_before_you_go": "Все, що вам потрібно знати",
    "read_it": "Читати далі",
    "view_on_map": "Проглянути на карті",
    "lbg.h1.destination_theme.lgbtq": "{0}: переможці в категорії «Привітність до ЛГБТК»",
    "lbg.h2.beach": "Гостям подобається відпочивати на пляжі, особливо в цих помешканнях.",
    "lbg.h1.destination_theme.vip_access": "{0}: переможці в категорії «VIP Access»",
    "see_all_accommodation": "Усі помешкання",
    "shoppers": "Шопінг",
    "whats_around": "{0}: що є поблизу",
    "group": "Група",
    "hub.category.h2.couples": "{0}: найкраще для закоханих",
    "hub.category.h1.shopping": "{0}: шопінг",
    "travel_community": "{0} <span>&#124;</span> <span>Спільнота мандрівників</span>",
    "hub.category.h2.group": "{0}: найкраще для подорожей гуртом",
    "listicle_legal_msg": "У цій статті викладено думки редакційної колегії Go Guides. Hotels.com виплачує авторам компенсацію за використання їхніх письмових матеріалів на цьому сайті. Така компенсація може включати оплату подорожей та інших витрат.",
    "lbg.h1.destination_theme.cleanliness": "{0}: переможці в категорії «Чистота»",
    "menu.more": "Більше",
    "lbg.section_properties_theme.lgbtq": "{0}: переможці в категорії «Привітність до ЛГБТК»",
    "lbg.section_award_winners_destination": "Переможці за напрямками",
    "lbg.terms_conditions": "Положення та умови",
    "hub.category.h2.nightlife": "{0}: поради про нічне життя",
    "hub.neighbourhood.h2": "{0}: що цікавого",
    "lbg.section_award_winners_theme_destination": "Переможці за категоріями — {0}",
    "lbg.section_award_world_winners": "Світові переможці",
    "things_to_see_and_do_in_other_cities": "Чим зайнятися в інших містах ",
    "button.backToTop": "Вгору",
    "lbg.section_theme_world_winners.family": "Переможці в категорії «Сімейний відпочинок»",
    "hotels_near": "Готелі поблизу {0}",
    "category.activity__sports_": "Тури",
    "trust_module.get_reward_night": "Отримайте бонусну добу",
    "hub.category.h1.business": "{0}: бізнес-подорожі",
    "category.food": "Їжа",
    "other_popular_destinations_in": "{0} – інші популярні напрямки",
    "browse_by_category": "{0}: шукати за категорією",
    "see_more_attactions": "Більше визначних пам’яток",
    "lbg.theme.lgbt": "Привітність до ЛГБТК+",
    "more_information_about": "{0}: більше інформації",
    "lbg.h1.destination_theme.most_wanted": "Переможці в категорії «Безперечний хіт» — {0}",
    "see_all_properties_in": "{0}: переглянути всі помешкання",
    "category.tours": "Екскурсії",
    "hub.category.h1.sports": "{0}: спорт і активний відпочинок",
    "where_will_you_go_next": "Куди далі?",
    "cities": "Міста",
    "lbg.theme.spa_hotel": "Спа",
    "see_guide": "Путівник",
    "lbg.section_properties_theme.ski": "Переможці в категорії «Лижі» — {0}",
    "related_stories": "Пов’язані путівники",
    "hub.destination.h2": "Все, що вам потрібно знати",
    "show_less": "Менше",
    "hub.category.h1.football": "{0}: повний путівник для справжніх фанатів футболу",
    "hub.category.h2.food": "{0}: найкращі ресторани і їжа",
    "fallback_language_alert": "Перепрошуємо, цю сторінку ще не перекладено вашою мовою...",
    "category_highlight.title.information": "Корисна інформація",
    "neighbourhood_guides_for_other_cities": "Путівники по районам інших міст",
    "lbg.h2.hubpage": "Ці неперевершені помешкання отримали високі оцінки й безліч відгуків від гостей.",
    "travel_advisory.title": "Повідомлення для мандрівників",
    "lbg.section_properties_theme.all_inclusive": "Переможці в категорії «Усе враховано» — {0}",
    "lbg.h2.boutique": "Якщо вам подобаються бутіки, відвідувачі рекомендують ці помешкання.",
    "lbg.section_properties_theme.family": "Переможці в категорії «Сімейний відпочинок» — {0}",
    "hub.category.h2.families": "{0}: Найкращі подорожі для родин",
    "from": "від",
    "lbg.theme.vip_access": "VIP Access",
    "lbg.section_properties_theme.lgbt": "{0}: переможці в категорії «Привітність до ЛГБТК+»",
    "x_star": "{0,choice,0#|1#{0}-зірковий|1.5#{0}-зірковий|2#{0}-зірковий|2.5#{0}-зірковий|3#{0}-зірковий|3.5#{0}-зірковий|4#{0}-зірковий|4.5#{0}-зірковий|5#{0}-зірковий}",
    "trust_module.free_cancellation": "Безкоштовне скасування",
    "lbg.section_properties_theme.home_and_apartments": "Переможці в категорії «Будинки й квартири» — {0}",
    "contributing_writer": "{0} <span>&#124;</span> <span>Автор</span>",
    "things_to_see_and_do_in": "Чим зайнятися <span class=\"next-line\">у м. {0}</span>",
    "lbg.check_price": "Перевірити ціну",
    "hub.category.h2.blogs": "за авторством Досвідчених мандрівників",
    "lbg.h2.home_and_apartments": "Шукаєте домівку поза своїм домом? Перегляньте ці популярні помешкання.",
    "hub.category.h2.information": "{0}: корисна інформація",
    "search_hotels_in": "Шукати готелі<span class=\"extra-label\"> у м. {0}</span>",
    "hub.category.h2.nature": "{0}: найкращі природні маршрути",
    "lbg.h1.destination_theme.home_and_apartments": "Переможці в категорії «Будинки й квартири» — {0}",
    "photo_by": "фото {0}",
    "more_things_to_do": "Інші варіанти",
    "lbg.section_properties_theme.beach": "Переможці в категорії «Пляж» — {0}",
    "read_less": "Згорнути",
    "lbg.h2.spa_hotel": "Розслабтеся в одному з цих рейтингових помешкань, відомих через свої спа.",
    "second_fold_related_stories.see_also": "Також перегляньте",
    "hub.category.h2.history": "{0}: найцікавіше для любителів історії",
    "lbg.section_properties_theme.cleanliness": "{0}: переможці в категорії «Чистота»",
    "lbg.h1.destination_theme.boutique": "Переможці в категорії «Бутік» — {0}",
    "nightlife_b595ae4": "Нічне життя",
    "lbg.h1.destination_theme.beach": "Переможці в категорії «Пляж» — {0}",
    "trust_module.free_cancellation.disclaimer": "Деякі готелі не дозволяють скасовувати бронювання менше ніж за 24-48 годин до реєстрації. Деталі наведено на нашому сайті.",
    "lbg.h2.family": "Ці помешкання мають високі оцінки та ідеально підходять для сімейної відпустки.",
    "lbg.h2.lgbtq": "Ці помешкання для ЛГБТК мають високі оцінки відвідувачів.",
    "hub.category.h1.group": "{0}: групові поїздки",
    "popular_stories": "Популярні блоґи",
    "toc": "Зміст",
    "hub.category.h2.shopping": "{0}: найкращі магазини",
    "lbg.section_theme_world_winners.beach": "Переможці в категорії «Пляж»",
    "page_score": "Рейтинг цієї сторінки",
    "lbg.section_theme_world_winners.sustainable": "Переможці в категорії «Турбота про довкілля»",
    "lbg.theme.boutique": "Бутік",
    "trust_module.price_guarantee": "Гарантія ціни",
    "lbg.h1.destination_theme.all_inclusive": "Переможці в категорії «Усе враховано» — {0}",
    "top_destinations": "Найпопулярніші напрямки",
    "hotel_prices_near": "{0} – ціни на готелі поблизу",
    "lbg.score.cleanliness": "Чистота:",
    "x_stars": "{0,choice,0#|1#{0}-зіркові|1<{0}-зіркові|4<{0}-зіркові|10<{0}-зіркові}",
    "lbg.theme.all_inclusive": "«Усе враховано»",
    "hub.category.h1.tours": "{0}: тури та одноденні екскурсії",
    "lbg.section_award_winners_theme": "Переможці за категоріями",
    "guest_writer": "{0} <span>&#124;</span> <span>Запрошений автор</span>",
    "start_planning_your_x_trip": "Почніть планувати свою мандрівку до м. {0}",
    "lbg.section_theme_world_winners.business": "Переможці в категорії «Відрядження»",
    "trending_now_in": "Найпопулярніше в м. {0}",
    "history": "Історія",
    "search_hotels": "Шукати готелі",
    "lbg.terms_conditions_details": "Усі помешкання, які отримали нагороду в {0} р., було обрано на основі таких критеріїв: високі оцінки відвідувачів у {1} р. і щонайменше 50 відгуків.",
    "see_nearby_properties": "Помешкання поблизу",
    "hub.category.h1.history": "{0}: історичні місця",
    "location": "Адреса:",
    "lbg.section_theme_world_winners.luxury": "Переможці в категорії «Розкіш»",
    "category_highlight.see_all.nightlife": "Детальніше про нічне життя",
    "also_popular": "Більше популярних напрямків",
    "hub.category.h2.things_to_do": "{0}: що побачити і куди сходити",
    "lbg.theme.family": "Сімейний відпочинок",
    "lbg.h1.destination_theme.pet_friendly": "Переможці в категорії «Доступ з домашніми улюбленцями» — {0}",
    "category_highlight.title.shopping": "{0}: куди вирушати на шопінг і що купувати",
    "price": "Ціна:",
    "hub.category.h2.beach": "{0}: найкращі пляжі",
    "neighbourhoods_in": "{0} і райони",
    "business": "Відрядження",
    "photo": "Фотографії",
    "blog.see_all_stories": "Дивитися всі історії",
    "category_highlight.see_all.food": "Детальніше про їжу та заклади харчування",
    "see_all_properties": "Всі помешкання",
    "lbg.theme.beach": "Пляж",
    "what_to_see_and_do_in": "{0}: на що варто подивитися й чим зайнятися",
    "hub.category.h2.single": "{0}: найкраще для мандрівок соло",
    "attractions_in": "Розваги в м. {0}",
    "read_more": "Читати далі",
    "show_more": "Більше",
    "hotels_near_this_landmark": "Готелі біля цієї визначної пам’ятки",
    "check_dates": "Перевірити дати",
    "featured_stories_and_fun_stuff": "Топ-історії та інші цікавинки",
    "phone": "Телефон:",
    "lbg.section_search_header": "Пошук переможців",
    "category_highlight.title.food": "{0}: де і що тут можна поїсти",
    "see_all_things_to_do": "Перегляньте всі можливості",
    "lbg.section_properties_theme.sustainable": "Переможці в категорії «Турбота про довкілля» — {0}",
    "lbg.h1.hubpage": "Переможці в категорії «Рекомендовано клієнтами» {0}!",
    "lbg.section_theme_world_winners.spa_hotel": "Переможці в категорії «Спа»",
    "category.maps": "Карти",
    "lbg.h1.destination_theme.family": "Переможці в категорії «Сімейний відпочинок» — {0}",
    "lbg.section_theme_world_winners.cleanliness": "Переможці в категорії «Чистота»",
    "lbg.section_theme_world_winners.vip_access": "Переможці в категорії «VIP Access»",
    "trust_module.get_reward_night.night_you_stay": "за кожні 10 діб проживання",
    "modified": "змінено",
    "lbg.section_properties": "Переможці — {0}",
    "trust_module.header": "Чому варто бронювати за допомогою Hotels.com?",
    "hub.category.h2.luxury": "{0}: найкращі розкішні подорожі",
    "lbg.theme.ski": "Лижі",
    "lbg.h1.destination_theme.spa_hotel": "Переможці в категорії «Спа» — {0}",
    "lbg.h2.most_wanted": "Гості рекомендують ці помешкання для ідеальної подорожі.",
    "top10_hotels_incomplete_alert": "Через високе навантаження системи певні варіанти помешкання можуть наразі не відображатися. <span>Перепрошуємо за незручності.</span>",
    "compulsive_traveller": "{0} <span>&#124;</span> <span>палкий мандрівник</span>",
    "lbg.section_theme_world_winners.home_and_apartments": "Переможці в категорії «Будинки й квартири»",
    "loved_by_guests_collection_in": "{0}: колекція «Клієнти рекомендують»",
    "title.suffix": "{0} — Go Guides",
    "hub.category.h2.maps": "{0}: корисні карти",
    "opening_hours": "Години роботи",
    "hub.category.h2.photos": "{0}: найкраще для фотографів і аматорів",
    "lbg.theme.most_wanted": "Безперечний хіт",
    "blog.stories_container": "Історії від дописувачів",
    "hub.category.h2.budget": "{0}: найкраще для бюджетних подорожей",
    "lbg.section_properties_theme.business": "Переможці в категорії «Відрядження» — {0}",
    "not_sure_where_to_stay": "Не знаєте, де зупинитись?",
    "category.information": "Інформація"
}
