export default {
    "current_deal": "Τρέχουσα προσφορά",
    "lbg.section_properties_theme.pet_friendly": "{0}: Οι νικητές στην κατηγορία «Καταλύματα που δέχονται κατοικίδια»",
    "address": "Διεύθυνση",
    "hub.homepage.h2": "Τόσα πολλά μέρη, τόσο λίγος χρόνος...",
    "search_hotels_near_this_landmark": "Αναζήτηση ξενοδοχείων<span class=\"extra-label\"> κοντά σε αυτό το σημείο ενδιαφέροντος</span>",
    "hub.category.h1.things_to_do": "{0}: Δραστηριότητες",
    "lbg.h2.vip_access": "Τα καταλύματα VIP Access έχουν λάβει υψηλή βαθμολογία για τις εξαιρετικές εμπειρίες διαμονής που προσφέρουν στους επισκέπτες.",
    "lbg.theme.cleanliness": "Καθαριότητα",
    "lbg.theme.lgbtq": "LGBTQ",
    "load_more": "Φόρτωση περισσότερων",
    "link_long_form_to_listiicle_multiple": "{0} - μια από τις ξεχωριστές ιδέες στις λίστες {1} και {2} ({3}: Διαβάστε τα πάντα για αυτόν τον προορισμό εδώ)",
    "lbg.section_properties_theme.boutique": "{0}: Οι νικητές στην κατηγορία «Boutique καταλύματα»",
    "lbg.h2.luxury": "Απολαύστε την πολυτέλεια, όπως δεν την έχετε ξαναδεί. Γι’ αυτό οι επισκέπτες απολαμβάνουν τη διαμονή τους σε αυτά τα καταλύματα.",
    "hub.category.h1.information": "Πληροφορίες - {0}",
    "families": "Οικογένειες",
    "trending_now": "Δημοφιλείς τάσεις τώρα",
    "lbg.from": "Από",
    "hub.category.h1.road_trips": "{0}: Τα καλύτερα ταξίδια με αυτοκίνητο",
    "lbg.h2.cleanliness": "Αυτά τα καταλύματα έχουν πάρα πολύ καλό συνολικό σκορ αξιολόγησης από τους πελάτες και κορυφαίο σκορ για την καθαριότητα.",
    "single": "Single",
    "couples": "Ζευγάρια",
    "trust_module.price_guarantee.find_out_more": "μάθετε περισσότερα",
    "top_destinations_in": "Κορυφαίοι προορισμοί σε {0}",
    "hub.category.h1.couples": "{0}: Ταξίδια για ζευγάρια",
    "hub.category.h2.tours": "{0}: Οι καλύτερες περιηγήσεις",
    "hub.category.h1.photos": "{0}: Ταξίδια για φωτογραφία",
    "category.photos": "Φωτογραφίες",
    "hub.category.h1.budget": "{0}: Οικονομικά ταξίδια",
    "hub.category.h2.ski": "{0}: Οι καλύτεροι προορισμοί για σκι",
    "lbg.section_theme_world_winners.most_wanted": "Οι νικητές στην κατηγορία «Πιο δημοφιλή»",
    "lbg.theme.home_and_apartments": "Σπίτια & Διαμερίσματα",
    "hub.category.h1.luxury": "{0}: Πολυτελή ταξίδια",
    "hub.category.h2.adventure": "{0}: Τα καλύτερα ταξίδια με δραστηριότητες",
    "hotels_in": "{0}: Ξενοδοχεία",
    "lbg.section_search_winners": "Αναζητήστε περισσότερους βραβευμένους νικητές",
    "see_more_hotels_in": "Δείτε περισσότερα ξενοδοχεία σε: {0}",
    "hub.category.h1.beach": "Παραλίες σε {0}",
    "open": "Ώρες λειτουργίας:",
    "enter_your_destination": "Εισαγάγετε τον προορισμό σας",
    "best_of": "Οι καλύτερες ιστορίες σε {0}",
    "top10_hotels_missing_properties": "Ορισμένα καταλύματα ίσως να μην εμφανίζονται αυτήν τη στιγμή. Λυπούμαστε για την ταλαιπωρία.",
    "adventure": "Περιπέτεια",
    "lbg.h1.destination_theme.sustainable": "{0}: Οι νικητές στην κατηγορία «Οικολογικά καταλύματα»",
    "luxury": "Πολυτέλεια",
    "expand_map_view": "Ανάπτυξη προβολής χάρτη",
    "book_now": "Κράτηση τώρα",
    "lbg.h1.destination_theme.ski": "{0}: Οι νικητές στην κατηγορία «Καταλύματα για σκι»",
    "hub.category.h1.food": "{0}: Φαγητό και εστιατόρια",
    "category.blogs": "Ιστολόγιο",
    "budget": "Οικονομική διαμονή",
    "popular_neighbourhoods_in": "Δημοφιλείς περιοχές: {0}",
    "hub.category.h1.unusual": "{0}: Κρυμμένα διαμάντια και ασυνήθιστα μέρη",
    "see_all_accommodation_in": "Δείτε όλα τα καταλύματα σε {0}",
    "hub.category.h1.adventure": "{0}: Ταξίδια με δραστηριότητες",
    "category_highlight.see_all.shopping": "Περισσότερα σχετικά με τις αγορές",
    "lbg.section_most_popular_destination": "Δημοφιλέστεροι προορισμοί",
    "lbg.section_properties_theme.luxury": "{0}: Οι νικητές στην κατηγορία «Καταλύματα πολυτελείας»",
    "unusual": "Ασυνήθιστα",
    "category.shopping": "Ψώνια",
    "make_the_most_of_your_trip": "Επωφεληθείτε πλήρως από το ταξίδι σας",
    "x_review": "{0,choice,0#|1#{0} σχόλιο|2#{0} σχόλια|2<{0} σχόλια|4<{0} σχόλια|10#{0} σχόλια|10<{0} σχόλια}",
    "hub.category.h1.single": "{0}: Ατομικά ταξίδια",
    "hub.category.h2.custom": "Δημοφιλείς τάσεις τώρα",
    "lbg.section_theme_world_winners.lgbt": "Νικητές βραβείων στην κατηγορία LGBTQ+",
    "lbg.section_award_winners_theme_other": "{0}: Περισσότερες κατηγορίες βραβείων",
    "lbg.supplier_collected_charges": "+ χρέωση {0} για εξόφληση στο κατάλυμα",
    "in_destination": " {0}",
    "lbg.section_properties_theme.vip_access": "Νικητές στην κατηγορία «VIP Access» - {0}",
    "link_long_form_to_listiicle_single": "{0} - μια από τις ξεχωριστές ιδέες στη λίστα {1} ({2}: Διαβάστε τα πάντα για αυτόν τον προορισμό εδώ)",
    "lbg.section_theme_world_winners.boutique": "Οι νικητές στην κατηγορία «Boutique καταλύματα»",
    "lbg.section_properties_theme.spa_hotel": "{0}: Οι νικητές στην κατηγορία «Καταλύματα για σπα»",
    "lbg.section_theme_world_winners.all_inclusive": "Οι νικητές στην κατηγορία «Καταλύματα all-inclusive»",
    "hub.category.h2": "Οδηγός ταξιδιού για {0}",
    "lbg.properties.price_tooltip": "Αυτή είναι η χαμηλότερη τιμή ανά διανυκτέρευση στην ιστοσελίδα μας για τις επόμενες 14 ημέρες.",
    "hub.category.h2.road_trips": "{0}: Τα καλύτερα ταξίδια με αυτοκίνητο",
    "hub.category.h1.ski": "{0}: Προορισμοί για σκι",
    "food": "Φαγητό",
    "lbg.theme.pet_friendly": "Καταλύματα που δέχονται κατοικίδια",
    "lbg.theme.business": "Business καταλύματα",
    "hub.category.h1.nature": "{0}: Τα καλύτερα ταξίδια για τους λάτρεις της φύσης",
    "map": "Χάρτης",
    "category_highlight.see_all.information": "Περισσότερες πληροφορίες",
    "hotel_prices_in": "Τιμές ξενοδοχείων: {0}",
    "hub.category.h1.nightlife": "Νυχτερινή ζωή - {0}",
    "trust_module.free_cancellation.most_hotels": "στα περισσότερα ξενοδοχεία*",
    "also_popular_in": "Άλλα γνωστά μέρη σε: {0}",
    "lbg.h1.destination_theme.business": "{0}: Οι νικητές στην κατηγορία «Business καταλύματα»",
    "lbg.section_theme_world_winners.pet_friendly": "Οι νικητές στην κατηγορία «Καταλύματα που δέχονται κατοικίδια»",
    "hub.category.h1.maps": "{0}: Χάρτες",
    "lbg.section_properties_theme.most_wanted": "{0}: Οι νικητές στην κατηγορία «Πιο δημοφιλή»",
    "category.nightlife": "Νυχτερινή ζωή",
    "search_hotels_in_this_area": "Αναζήτηση ξενοδοχείων<span class=\"extra-label\"> σε αυτή την περιοχή</span>",
    "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 ψήφος|2#2 ψήφοι|2<{1} ψήφοι|4<{1} ψήφοι|10#10 ψήφοι|10<{1} ψήφοι})",
    "hub.category.h2.business": "{0}: Τα καλύτερα επαγγελματικά ταξίδια",
    "category.things_to_do": "Αξιοθέατα",
    "destination_travel_guide_by_category": "Ταξιδιωτικός οδηγός ανά κατηγορία σε {0}",
    "hub.category.h1.families": "Οικογενειακό ταξίδι σε {0}",
    "lbg.section_theme_world_winners.ski": "Οι νικητές στην κατηγορία «Καταλύματα για σκι»",
    "you_may_also_like": "Ίσως σας αρέσουν επίσης",
    "lbg.theme.sustainable": "Οικολογικά καταλύματα",
    "hub.category.h2.unusual": "{0}: Τα καλύτερα κρυμμένα διαμάντια και ασυνήθιστα μέρη",
    "hub.category.h2.football": "Υποστηρίξτε την ποδοσφαιρική ομάδα σας και επωφεληθείτε στο έπακρο από το ταξίδι σας!",
    "top_hotel": "Κορυφαίο ξενοδοχείο",
    "lbg.h1.destination_theme.luxury": "{0}: Οι νικητές στην κατηγορία «Καταλύματα πολυτελείας»",
    "top10_hotels_empty_alert": "Αυτήν τη στιγμή αντιμετωπίζουμε τεχνικό πρόβλημα. <span>Λυπούμαστε για την ταλαιπωρία.</span>",
    "special_deal": "Ειδική προσφορά",
    "lbg.h2.sustainable": "Η βιωσιμότητα είναι πάρα πολύ σημαντική! Ρίξτε μια ματιά σε αυτά τα καταλύματα και απολαύστε μια υπέροχη διαμονή.",
    "where_to_stay_in": "Πού να μείνετε: {0}",
    "start_planning_your_trip": "Ξεκινήστε να οργανώνετε το ταξίδι σας",
    "good_for": "Καλό για:",
    "category.hotels": "Ξενοδοχεία",
    "x_accommodation_guide": "Οδηγός καταλυμάτων για {0}",
    "lbg.theme.luxury": "Καταλύματα πολυτελείας",
    "hub.category.h2.sports": "{0}: Τα καλύτερα αθλήματα και δραστηριότητες",
    "lbg.section_theme_world_winners.lgbtq": "Νικητές στην κατηγορία «LGBTQ»",
    "see_more_neighbourhoods": "Προβολή περισσότερων περιοχών",
    "category_highlight.title.nightlife": "{0}: Πού να πάτε και τι να κάνετε το βράδυ",
    "lbg.h1.destination_theme.lgbt": "Νικητές βραβείων στην κατηγορία LGBTQ+ σε {0}",
    "hub.category.h1.blogs": "Ταξιδιωτικό blog - {0}",
    "lbg.h2.business": "Αυτά τα καταλύματα έχουν λάβει υψηλή αξιολόγηση από τους business ταξιδιώτες.",
    "hotels": "Ξενοδοχεία",
    "lbg.h2.pet_friendly": "Οι επισκέπτες συνιστούν ανεπιφύλακτα αυτά τα καταλύματα, τα οποία δέχονται εσάς και το κατοικίδιό σας.",
    "popular_landmarks_nearby": "Δημοφιλή σημεία ενδιαφέροντος σε κοντινή απόσταση",
    "popular_extractions_in": "Δημοφιλή αξιοθέατα: {0}",
    "lbg.h2.all_inclusive": "Ταξιδέψτε χωρίς άγχος κάνοντας κράτηση σε αυτά τα καταλύματα με κορυφαία αξιολόγηση.",
    "travel_guide_by_category_header": "{1}: {0}",
    "lbg.travel_between": "Ταξιδέψτε μεταξύ {0} - {1}",
    "keep_exploring": "Συνεχίστε την εξερεύνηση",
    "what_you_need_to_know_before_you_go": "Τι πρέπει να γνωρίζετε, πριν ταξιδέψετε",
    "read_it": "Διαβάστε περισσότερα",
    "view_on_map": "Προβολή σε χάρτη",
    "lbg.h1.destination_theme.lgbtq": "Νικητές στην κατηγορία «LGBTQ» - {0}",
    "lbg.h2.beach": "Οι επισκέπτες λατρεύουν τις διακοπές στην παραλία, ειδικά σε αυτά τα καταλύματα.",
    "lbg.h1.destination_theme.vip_access": "Νικητές στην κατηγορία «VIP Access» - {0}",
    "see_all_accommodation": "Δείτε όλα τα καταλύματα",
    "shoppers": "Καταναλωτές",
    "whats_around": "{0}: Γύρω περιοχή",
    "group": "Ομάδα ταξιδιωτών",
    "hub.category.h2.couples": "{0}: Τα καλύτερα ταξίδια για ζευγάρια",
    "hub.category.h1.shopping": "Αγορές - {0}",
    "travel_community": "{0} <span>&#124;</span> <span>Κοινότητα ταξιδιωτών</span>",
    "hub.category.h2.group": "{0}: Τα καλύτερα ομαδικά ταξίδια",
    "listicle_legal_msg": "Το άρθρο αυτό περιλαμβάνει απόψεις της συντακτικής ομάδας Go Guides Η Hotels.com αποζημιώνει τους συντάκτες για τα κείμενά τους που εμφανίζονται στην ιστοσελίδα αυτή. Μια τέτοια αποζημίωση ενδέχεται να περιλαμβάνει ταξίδια και άλλα έξοδα.",
    "lbg.h1.destination_theme.cleanliness": "Νικητές στην κατηγορία «Καθαριότητα» - {0}",
    "menu.more": "Περισσότερα",
    "lbg.section_properties_theme.lgbtq": "Νικητές στην κατηγορία «LGBTQ» - {0}",
    "lbg.section_award_winners_destination": "Οι νικητές στην κατηγορία «Προορισμός»",
    "lbg.terms_conditions": "Όροι & Προϋποθέσεις",
    "hub.category.h2.nightlife": "{0}: Οι καλύτερες επιλογές για νυχτερινή διασκέδαση",
    "hub.neighbourhood.h2": "{0}: Τι μπορείτε να κάνετε εδώ",
    "lbg.section_award_winners_theme_destination": "{0}: Οι νικητές ανά κατηγορία",
    "lbg.section_award_world_winners": "Νικητές παγκοσμίως",
    "things_to_see_and_do_in_other_cities": "Τι να δείτε και να κάνετε σε άλλες πόλεις",
    "button.backToTop": "Επιστροφή στην αρχή",
    "lbg.section_theme_world_winners.family": "Οι νικητές στην κατηγορία «Οικογενειακά καταλύματα»",
    "hotels_near": "{0}: Ξενοδοχεία σε κοντινή απόσταση",
    "category.activity__sports_": "Περιηγήσεις",
    "trust_module.get_reward_night": "Κερδίστε μια διανυκτέρευση επιβράβευσης",
    "hub.category.h1.business": "{0}: Επαγγελματικά ταξίδια",
    "category.food": "Φαγητό",
    "other_popular_destinations_in": "Άλλοι δημοφιλείς προορισμοί σε: {0}",
    "browse_by_category": "{0}: Κάντε αναζήτηση ανά κατηγορία",
    "see_more_attactions": "Προβολή περισσότερων αξιοθέατων",
    "lbg.theme.lgbt": "LGBTQ+",
    "more_information_about": "Περισσότερες πληροφορίες για {0}",
    "lbg.h1.destination_theme.most_wanted": "{0}: Οι νικητές στην κατηγορία «Πιο δημοφιλή»",
    "see_all_properties_in": "Προβολή όλων των καταλυμάτων σε {0}",
    "category.tours": "Περιηγήσεις",
    "hub.category.h1.sports": "{0}: Αθλήματα και δραστηριότητες",
    "where_will_you_go_next": "Ποιος είναι ο επόμενος προορισμός σας;",
    "cities": "Πόλεις",
    "lbg.theme.spa_hotel": "Καταλύματα για σπα",
    "see_guide": "Προβολή οδηγού",
    "lbg.section_properties_theme.ski": "{0}: Οι νικητές στην κατηγορία «Καταλύματα για σκι»",
    "related_stories": "Σχετικά άρθρα",
    "hub.destination.h2": "Τι πρέπει να γνωρίζετε, πριν ταξιδέψετε",
    "show_less": "Εμφάνιση λιγότερων",
    "hub.category.h1.football": "{0}: Ο απόλυτος οδηγός για τους πραγματικούς λάτρεις του ποδοσφαίρου",
    "hub.category.h2.food": "{0}: Οι καλύτερες επιλογές για φαγητό και εστιατόρια",
    "fallback_language_alert": "Λυπούμαστε, αλλά αυτή η σελίδα δεν έχει μεταφραστεί ακόμη στη γλώσσα σας...",
    "category_highlight.title.information": "Χρήσιμες πληροφορίες",
    "neighbourhood_guides_for_other_cities": "Οδηγοί περιοχών για άλλες πόλεις",
    "lbg.h2.hubpage": "Αυτά τα εκπληκτικά καταλύματα έχουν υψηλά σκορ από τους πελάτες και πολλά σχόλια.",
    "travel_advisory.title": "Ταξιδιωτική ειδοποίηση",
    "lbg.section_properties_theme.all_inclusive": "{0}: Οι νικητές στην κατηγορία «Καταλύματα all-inclusive»",
    "lbg.h2.boutique": "Λατρεύετε τα boutique καταλύματα; Ρίξτε μια ματιά σε αυτά που προτείνουν ανεπιφύλακτα οι επισκέπτες.",
    "lbg.section_properties_theme.family": "{0}: Οι νικητές στην κατηγορία «Οικογενειακά καταλύματα»",
    "hub.category.h2.families": "Προτάσεις για το καλύτερο οικογενειακό ταξίδι σε {0}",
    "from": "από",
    "lbg.theme.vip_access": "VIP Access",
    "lbg.section_properties_theme.lgbt": "Νικητές βραβείων στην κατηγορία LGBTQ+ σε {0}",
    "x_star": "{0,choice,0#|1#{0} αστέρα|1.5#{0} αστέρα|2#{0} αστέρων|2.5#{0} αστέρων|3#{0} αστέρων|3.5#{0} αστέρων|4#{0} αστέρων|4.5#{0} αστέρων|5#{0} αστέρων}",
    "trust_module.free_cancellation": "Δωρεάν ακύρωση",
    "lbg.section_properties_theme.home_and_apartments": "{0}: Οι νικητές στην κατηγορία «Σπίτια & Διαμερίσματα»",
    "contributing_writer": "{0} <span>&#124;</span> <span> Συγγραφέας</span>",
    "things_to_see_and_do_in": "Τι να δείτε και να κάνετε:<span class=\"next-line\"> {0}</span>",
    "lbg.check_price": "Έλεγχος τιμής",
    "hub.category.h2.blogs": "από έμπειρους ταξιδιώτες",
    "lbg.h2.home_and_apartments": "Θέλετε να νιώθετε σαν στο σπίτι σας, ακόμη κι όταν ταξιδεύετε; Ρίξτε μια ματιά σε αυτά τα καταλύματα με πολύ υψηλή αξιολόγηση.",
    "hub.category.h2.information": "{0}: Χρήσιμες πληροφορίες",
    "search_hotels_in": "Αναζήτηση ξενοδοχείων<span class=\"extra-label\">: {0}</span>",
    "hub.category.h2.nature": "{0}: Τα καλύτερα ταξίδια στη φύση",
    "lbg.h1.destination_theme.home_and_apartments": "{0}: Οι νικητές στην κατηγορία «Σπίτια & Διαμερίσματα»",
    "photo_by": "φωτογραφία από {0}",
    "more_things_to_do": "Περισσότερα πράγματα να κάνετε",
    "lbg.section_properties_theme.beach": "{0}: Οι νικητές στην κατηγορία «Παραλιακά καταλύματα»",
    "read_less": "Διαβάστε λιγότερα",
    "lbg.h2.spa_hotel": "Χαλαρώστε και απολαύστε αυτά τα καταλύματα με την κορυφαία αξιολόγηση, τα οποία φημίζονται για τα σπα τους.",
    "second_fold_related_stories.see_also": "Δείτε επίσης",
    "hub.category.h2.history": "{0}: Οι καλύτερες ιστορικές τοποθεσίες",
    "lbg.section_properties_theme.cleanliness": "Νικητές στην κατηγορία «Καθαριότητα» - {0}",
    "lbg.h1.destination_theme.boutique": "{0}: Οι νικητές στην κατηγορία «Boutique καταλύματα»",
    "nightlife_b595ae4": "Νυχτερινή ζωή",
    "lbg.h1.destination_theme.beach": "{0}: Οι νικητές στην κατηγορία «Παραλιακά καταλύματα»",
    "trust_module.free_cancellation.disclaimer": "Ορισμένα ξενοδοχεία απαιτούν από εσάς να πραγματοποιήσετε ακύρωση τουλάχιστον 24-48 ώρες πριν από το check-in. Θα βρείτε λεπτομέρειες στη σελίδα.",
    "lbg.h2.family": "Αυτά τα καταλύματα έχουν λάβει υψηλή αξιολόγηση από τους επισκέπτες και είναι ιδανικά για οικογένειες.",
    "lbg.h2.lgbtq": "Αυτά τα καταλύματα που είναι φιλικά προς LGBTQ έχουν λάβει υψηλή αξιολόγηση από τους επισκέπτες.",
    "hub.category.h1.group": "{0}: Ομαδικά ταξίδια",
    "popular_stories": "Δημοφιλή άρθρα",
    "toc": "Περιεχόμενο",
    "hub.category.h2.shopping": "{0}: Οι καλύτερες επιλογές για ψώνια",
    "lbg.section_theme_world_winners.beach": "Οι νικητές στην κατηγορία «Παραλιακά καταλύματα»",
    "page_score": "Το σκορ αυτής της σελίδας",
    "lbg.section_theme_world_winners.sustainable": "Οι νικητές στην κατηγορία «Οικολογικά καταλύματα»",
    "lbg.theme.boutique": "Boutique καταλύματα",
    "trust_module.price_guarantee": "Η εγγύηση τιμής μας",
    "lbg.h1.destination_theme.all_inclusive": "{0}: Οι νικητές στην κατηγορία «Καταλύματα all-inclusive»",
    "top_destinations": "Κορυφαίοι προορισμοί",
    "hotel_prices_near": "Τιμές ξενοδοχείων κοντά σε: {0}",
    "lbg.score.cleanliness": "Καθαριότητα:",
    "x_stars": "{0,choice,0#|1#{0} αστέρα|1<{0} αστέρων|4<{0} αστέρων|10<{0} αστέρων}",
    "lbg.theme.all_inclusive": "Καταλύματα All-inclusive",
    "hub.category.h1.tours": "{0}: Τουρ και ημερήσιες εκδρομές",
    "lbg.section_award_winners_theme": "Οι νικητές ανά κατηγορία",
    "guest_writer": "{0} <span>&#124;</span> <span>Εξωτερικός συγγραφέας</span>",
    "start_planning_your_x_trip": "Ξεκινήστε να οργανώνετε το ταξίδι σας σε: {0}",
    "lbg.section_theme_world_winners.business": "Οι νικητές στην κατηγορία «Business καταλύματα»",
    "trending_now_in": "Δημοφιλές τώρα σε: {0}",
    "history": "Ιστορία",
    "search_hotels": "Αναζήτηση ξενοδοχείων",
    "lbg.terms_conditions_details": "Τα κριτήρια για την επιλογή των καταλυμάτων που κέρδισαν βραβεία το {0} βασίστηκαν στα υψηλά σκορ αξιολόγησης των επισκεπτών το {1} και τουλάχιστον σε 50 σχόλια επισκεπτών.",
    "see_nearby_properties": "Δείτε κοντινά καταλύματα",
    "hub.category.h1.history": "{0}: Ιστορικές τοποθεσίες",
    "location": "Τοποθεσία:",
    "lbg.section_theme_world_winners.luxury": "Οι νικητές στην κατηγορία «Καταλύματα πολυτελείας»",
    "category_highlight.see_all.nightlife": "Περισσότερα σχετικά με τη νυχτερινή ζωή",
    "also_popular": "Περισσότεροι δημοφιλείς προορισμοί",
    "hub.category.h2.things_to_do": "{0}: Οι καλύτερες δραστηριότητες",
    "lbg.theme.family": "Οικογενειακά καταλύματα",
    "lbg.h1.destination_theme.pet_friendly": "{0}: Οι νικητές στην κατηγορία «Καταλύματα που δέχονται κατοικίδια»",
    "category_highlight.title.shopping": "{0}: Πού να ψωνίσετε και τι να αγοράσετε",
    "price": "Τιμή:",
    "hub.category.h2.beach": "Οι καλύτερες παραλίες σε {0}",
    "neighbourhoods_in": "περιοχές σε {0}",
    "business": "Επιχειρήσεις",
    "photo": "Φωτογραφία",
    "blog.see_all_stories": "Δείτε όλα τα άρθρα",
    "category_highlight.see_all.food": "Περισσότερα σχετικά με το φαγητό και τα εστιατόρια",
    "see_all_properties": "Προβολή όλων των καταλυμάτων",
    "lbg.theme.beach": "Παραλιακά καταλύματα",
    "what_to_see_and_do_in": "{0}: Τι να δείτε και τι να κάνετε",
    "hub.category.h2.single": "{0}: Τα καλύτερα ατομικά ταξίδια",
    "attractions_in": "{0}: Αξιοθέατα",
    "read_more": "Διαβάστε περισσότερα",
    "show_more": "Εμφάνιση περισσότερων",
    "hotels_near_this_landmark": "Ξενοδοχεία κοντά σε αυτό το σημείο ενδιαφέροντος",
    "check_dates": "Έλεγχος ημερομηνιών",
    "featured_stories_and_fun_stuff": "Κύριες ιστορίες και ενδιαφέροντα άρθρα",
    "phone": "Τηλέφωνο:",
    "lbg.section_search_header": "Αναζητήστε βραβευμένους νικητές",
    "category_highlight.title.food": "{0}: Πού και τι να φάτε",
    "see_all_things_to_do": "Δείτε όλα τα πράγματα που μπορείτε να κάνετε",
    "lbg.section_properties_theme.sustainable": "{0}: Οι νικητές στην κατηγορία «Οικολογικά καταλύματα»",
    "lbg.h1.hubpage": "Οι νικητές στην κατηγορία «Οι πελάτες το αγαπούν» {0}",
    "lbg.section_theme_world_winners.spa_hotel": "Οι νικητές στην κατηγορία «Καταλύματα για σπα»",
    "category.maps": "Χάρτες",
    "lbg.h1.destination_theme.family": "{0}: Οι νικητές στην κατηγορία «Οικογενειακά καταλύματα»",
    "lbg.section_theme_world_winners.cleanliness": "Νικητές στην κατηγορία «Καθαριότητα»",
    "lbg.section_theme_world_winners.vip_access": "Νικητές στην κατηγορία «VIP Access»",
    "trust_module.get_reward_night.night_you_stay": "για κάθε 10 διανυκτερεύσεις που ολοκληρώνετε",
    "modified": "τροποποιήθηκε",
    "lbg.section_properties": "{0}: Οι νικητές",
    "trust_module.header": "Γιατί να κάνετε κράτηση στη Hotels.com;",
    "hub.category.h2.luxury": "{0}: Τα καλύτερα πολυτελή ταξίδια",
    "lbg.theme.ski": "Καταλύματα για σκι",
    "lbg.h1.destination_theme.spa_hotel": "{0}: Οι νικητές στην κατηγορία «Καταλύματα για σπα»",
    "lbg.h2.most_wanted": "Οι επισκέπτες συνιστούν ανεπιφύλακτα αυτά τα καταλύματα για μια τέλεια διαμονή.",
    "top10_hotels_incomplete_alert": "Επί του παρόντος υπάρχει μεγάλη ζήτηση και επομένως ορισμένα καταλύματα ενδέχεται να μην εμφανίζονται αυτήν τη στιγμή. <span>Λυπούμαστε για την ταλαιπωρία.</span>",
    "compulsive_traveller": "{0} <span>&#124;</span> <span>Συχνός ταξιδιώτης</span>",
    "lbg.section_theme_world_winners.home_and_apartments": "Οι νικητές στην κατηγορία «Σπίτια & Διαμερίσματα»",
    "loved_by_guests_collection_in": "{0} - Συλλογή καταλυμάτων «Οι πελάτες το αγαπούν»",
    "title.suffix": "{0} - Go Guides",
    "hub.category.h2.maps": "{0}: Χρήσιμοι χάρτες",
    "opening_hours": "Ώρες λειτουργίας",
    "hub.category.h2.photos": "{0}: Τα καλύτερα ταξίδια για φωτογραφία",
    "lbg.theme.most_wanted": "Πιο δημοφιλή",
    "blog.stories_container": "Άρθρα στο ιστολόγιο",
    "hub.category.h2.budget": "{0}: Τα καλύτερα οικονομικά ταξίδια",
    "lbg.section_properties_theme.business": "{0}: Οι νικητές στην κατηγορία «Business καταλύματα»",
    "not_sure_where_to_stay": "Δεν είστε σίγουροι που θέλετε να διαμείνετε;",
    "category.information": "Πληροφορίες"
}
