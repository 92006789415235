import React from 'react';
import { CardItemProp } from 'src/components/types/goguides-types.d';
import { UitkFigure, UitkFigureAspectRatioType } from 'uitk-react-images';
import { UitkHeading } from 'uitk-react-text';
import { UitkLink } from 'uitk-react-link';
import { UitkCard } from 'uitk-react-cards';
import { RenderImage } from 'src/components/utils/responsive-image';
import { localization } from 'src/utils/localization';

interface CardSizeXLProps {
  data: CardItemProp
}

const CardSizeXL: React.FC<CardSizeXLProps> = (props) => {
  const { data } = props;
  const { name, targetUrl, snippetText, image, index } = data;

  const imageProps = {
    image: image.imageUrl,
    alt: name,
    params: {
      size: {
        small: '&width=800&height=533',
        medium: '&width=1600&height=1066',
      }
    }
  };
  const loc = new localization();
  const buttonKey = loc.getLocalizedText('read_more');

  return (
    <li key={index} className="col-12 highlighted">
      <UitkCard border={true} className='item-list-wrapper row'>
        <UitkFigure className="img-wrapper col-12 col-m8" ratio={UitkFigureAspectRatioType.R16_9}>
          <UitkLink>
            <a href={targetUrl}>
              <RenderImage imgProps={imageProps} />
            </a>
          </UitkLink>
        </UitkFigure>
        <div className="detail-wrapper col-12 col-m4">
          <div className="header-wrap">
            <UitkLink>
              <a href={targetUrl}>
                <UitkHeading size={5} tag="h3">
                  {name}
                </UitkHeading>
              </a>
            </UitkLink>
          </div>
          <div className="description-wrapper">
            <p dangerouslySetInnerHTML={{ __html: snippetText }} />
            <UitkLink className="read-more">
              <a href={targetUrl}>{buttonKey}</a>
            </UitkLink>
          </div>
        </div>
      </UitkCard>
    </li>
  );
};

export default CardSizeXL;
