export default {
    "current_deal": "現在の料金",
    "lbg.section_properties_theme.pet_friendly": "{0}のペットアワード受賞施設",
    "address": "住所",
    "hub.homepage.h2": "見どころを事前にチェックして、充実の旅を満喫",
    "search_hotels_near_this_landmark": "<span class=\"extra-label\">この観光スポット周辺の</span>ホテルを検索",
    "hub.category.h1.things_to_do": "{0}の観光スポット",
    "lbg.h2.vip_access": "VIP Access 提携施設は、宿泊経験者から高く評価されている優良施設です。",
    "lbg.theme.cleanliness": "清潔度",
    "lbg.theme.lgbtq": "セクシャルマイノリティ (LGBT+) ",
    "load_more": "さらに読み込む",
    "link_long_form_to_listiicle_multiple": "{0} - {1}、{2}の中でも注目の見どころ ({3}の全ガイドはこちら)",
    "lbg.section_properties_theme.boutique": "{0}のブティックアワード受賞施設",
    "lbg.h2.luxury": "本物の贅沢を体験してください。多くの旅行者が高く評価する高級施設をご紹介。",
    "hub.category.h1.information": "{0}に関する情報",
    "families": "家族",
    "trending_now": "人気の記事",
    "lbg.from": "最低価格",
    "hub.category.h1.road_trips": "{0}でおすすめのドライブ旅行",
    "lbg.h2.cleanliness": "総合的なゲスト評価が高く、清潔さでも最高水準の評価を獲得した宿泊施設をご覧ください。",
    "single": "1 人旅",
    "couples": "カップル",
    "trust_module.price_guarantee.find_out_more": "詳細を見る",
    "top_destinations_in": "{0}の人気目的地",
    "hub.category.h1.couples": "{0}でのカップル旅行",
    "hub.category.h2.tours": "{0}のおすすめ現地ツアー",
    "hub.category.h1.photos": "{0}での写真撮影旅行",
    "category.photos": "写真",
    "hub.category.h1.budget": "{0}での格安旅行",
    "hub.category.h2.ski": "{0}の人気スキー場",
    "lbg.section_theme_world_winners.most_wanted": "ゴールドアワード受賞施設",
    "lbg.theme.home_and_apartments": "バケーションレンタル",
    "hub.category.h1.luxury": "{0}での贅沢な旅行",
    "hub.category.h2.adventure": "{0}で人気のアクティブな旅行",
    "hotels_in": "{0}のホテル",
    "lbg.section_search_winners": "その他のアワード受賞施設を検索",
    "see_more_hotels_in": "{0}の宿泊施設をさらに表示",
    "hub.category.h1.beach": "{0}のビーチ",
    "open": "営業時間 :",
    "enter_your_destination": "目的地を入力",
    "best_of": "{0}の見どころ",
    "top10_hotels_missing_properties": "現在、一部の宿泊施設が表示されない場合があります。ご不便をおかけして申し訳ありません。",
    "adventure": "アドベンチャー",
    "lbg.h1.destination_theme.sustainable": "{0}のグリーンアワード受賞施設",
    "luxury": "高級ホテル",
    "expand_map_view": "地図を拡大する",
    "book_now": "今すぐ予約",
    "lbg.h1.destination_theme.ski": "{0}のスキーアワード受賞施設",
    "hub.category.h1.food": "{0}の料理・レストラン",
    "category.blogs": "ブログ",
    "budget": "格安",
    "popular_neighbourhoods_in": "{0}で人気のエリア",
    "hub.category.h1.unusual": "{0}の隠れた魅力 & 穴場スポット",
    "see_all_accommodation_in": "{0}の宿泊施設を見る",
    "hub.category.h1.adventure": "{0}でのアクティブな旅行",
    "category_highlight.see_all.shopping": "ショッピングの詳細を表示",
    "lbg.section_most_popular_destination": "人気都市",
    "lbg.section_properties_theme.luxury": "{0}のラグジュアリーアワード受賞施設",
    "unusual": "ユニーク",
    "category.shopping": "ショッピングスポット",
    "make_the_most_of_your_trip": "もっと旅を満喫しよう",
    "x_review": "{0,choice,0#|1#{0} 件の口コミ|2#{0} 件の口コミ|2<{0} 件の口コミ|4<{0} 件の口コミ|10#{0} 件の口コミ|10<{0} 件の口コミ}",
    "hub.category.h1.single": "{0}でのひとり旅",
    "hub.category.h2.custom": "人気の記事",
    "lbg.section_theme_world_winners.lgbt": "の LGBTQ+ アワード受賞施設",
    "lbg.section_award_winners_theme_other": "その他のテーマ別に{0}の受賞施設を見る",
    "lbg.supplier_collected_charges": "+ {0} (施設でお支払い)",
    "in_destination": "{0}",
    "lbg.section_properties_theme.vip_access": "{0}の VIP Access アワード受賞施設",
    "link_long_form_to_listiicle_single": "{0} - {1}の中でも注目の見どころ ({2}の全ガイドはこちら)",
    "lbg.section_theme_world_winners.boutique": "ブティックアワード受賞施設",
    "lbg.section_properties_theme.spa_hotel": "{0}のスパアワード受賞施設",
    "lbg.section_theme_world_winners.all_inclusive": "オールインクルーシブ アワード受賞施設",
    "hub.category.h2": "{0}旅行ガイド",
    "lbg.properties.price_tooltip": "この料金は、今後 14 日間に当サイトでご利用可能な 1 泊あたりの最低価格です。",
    "hub.category.h2.road_trips": "{0}でおすすめのドライブ旅行",
    "hub.category.h1.ski": "{0}のスキー場",
    "food": "食事",
    "lbg.theme.pet_friendly": "ペット",
    "lbg.theme.business": "ビジネス",
    "hub.category.h1.nature": "{0}でおすすめの自然探訪旅行",
    "map": "地図",
    "category_highlight.see_all.information": "詳細を表示",
    "hotel_prices_in": "{0}にあるホテルの料金",
    "hub.category.h1.nightlife": "{0}のナイトスポット",
    "trust_module.free_cancellation.most_hotels": "ほとんどのホテルが対象*",
    "also_popular_in": "{0}の人気目的地",
    "lbg.h1.destination_theme.business": "{0}のビジネスアワード受賞施設",
    "lbg.section_theme_world_winners.pet_friendly": "ペットアワード受賞施設",
    "hub.category.h1.maps": "{0}の地図",
    "lbg.section_properties_theme.most_wanted": "{0}のゴールドアワード受賞施設",
    "category.nightlife": "ナイトライフ",
    "search_hotels_in_this_area": "<span class=\"extra-label\">このエリアの</span>ホテルを検索",
    "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 件の投票|2#2 件の投票|2<{1} 件の投票|4<{1} 件の投票|10#10 件の投票|10<{1} 件の投票})",
    "hub.category.h2.business": "{0}で人気の出張",
    "category.things_to_do": "観光スポット",
    "destination_travel_guide_by_category": "{0}トラベルガイド (カテゴリー別)",
    "hub.category.h1.families": "{0}での家族旅行",
    "lbg.section_theme_world_winners.ski": "スキーアワード受賞施設",
    "you_may_also_like": "その他のおすすめ",
    "lbg.theme.sustainable": "グリーン",
    "hub.category.h2.unusual": "{0}で人気の隠れた魅力 & 穴場スポット",
    "hub.category.h2.football": "お気に入りのサッカーチームを応援しながら、旅を満喫しよう !",
    "top_hotel": "人気ホテル",
    "lbg.h1.destination_theme.luxury": "{0}のラグジュアリーアワード受賞施設",
    "top10_hotels_empty_alert": "現在、技術的な問題が発生しています。<span>ご不便をおかけして申し訳ありません。</span>",
    "special_deal": "お得情報",
    "lbg.h2.sustainable": "地球と人にやさしい宿泊施設で、安らかな休日をお過ごしください。",
    "where_to_stay_in": "{0}の旅行ガイド",
    "start_planning_your_trip": "旅行の計画をスタート !",
    "good_for": "おすすめ :",
    "category.hotels": "ホテル",
    "x_accommodation_guide": "{0}の宿泊施設ガイド",
    "lbg.theme.luxury": "ラグジュアリー",
    "hub.category.h2.sports": "{0}で人気のスポーツ & アクティビティ",
    "lbg.section_theme_world_winners.lgbtq": "LGBT+ アワード受賞施設",
    "see_more_neighbourhoods": "他のエリアを見る",
    "category_highlight.title.nightlife": "{0}のナイトライフスポット・ナイトアクティビティ",
    "lbg.h1.destination_theme.lgbt": "{0}の LGBTQ+ アワード受賞施設",
    "hub.category.h1.blogs": "{0}の旅行ブログ",
    "lbg.h2.business": "出張者に高評の優良施設が出揃いました。",
    "hotels": "ホテル",
    "lbg.h2.pet_friendly": "宿泊経験者が高く評価する優良施設で、ペットとの旅をお楽しみください。",
    "popular_landmarks_nearby": "周辺で人気の観光スポット",
    "popular_extractions_in": "{0}で人気の観光スポット",
    "lbg.h2.all_inclusive": "多くの旅行者から高く評価されている優良施設をご覧ください。",
    "travel_guide_by_category_header": "{1} - おすすめの{0}",
    "lbg.travel_between": "旅行期間 : {0} ～ {1}",
    "keep_exploring": "その他の目的地",
    "what_you_need_to_know_before_you_go": "便利な情報満載の旅行ガイド",
    "read_it": "続きを読む",
    "view_on_map": "地図を表示",
    "lbg.h1.destination_theme.lgbtq": "{0}の LGBT+ アワード受賞施設",
    "lbg.h2.beach": "ビーチで過ごす休日に理想的 ! 多くの旅行者が認めた優良施設です。",
    "lbg.h1.destination_theme.vip_access": "{0}の VIP Access アワード受賞施設",
    "see_all_accommodation": "宿泊施設をすべて見る",
    "shoppers": "ショッピング",
    "whats_around": "{0}の周辺情報",
    "group": "グループ",
    "hub.category.h2.couples": "{0}で人気のカップル旅行",
    "hub.category.h1.shopping": "{0}のショッピングスポット",
    "travel_community": "{0} <span>&#124;</span> <span>旅行コミュニティ</span>",
    "hub.category.h2.group": "{0}で人気のグループ旅行",
    "listicle_legal_msg": "この記事には、GoGuides 編集チームの意見が含まれています。Hotels.com は、当サイトに掲載された著作物に対して著者に報酬を提供しています。このような報酬には、旅費およびその他の費用が含まれる場合があります。",
    "lbg.h1.destination_theme.cleanliness": "{0}の清潔度アワード受賞施設",
    "menu.more": "その他",
    "lbg.section_properties_theme.lgbtq": "{0}の LGBT+ アワード受賞施設",
    "lbg.section_award_winners_destination": "目的地別に受賞施設を見る",
    "lbg.terms_conditions": "利用規約",
    "hub.category.h2.nightlife": "{0}のおすすめナイトスポット",
    "hub.neighbourhood.h2": "{0}観光ガイド",
    "lbg.section_award_winners_theme_destination": "テーマ別に{0}の受賞施設を見る",
    "lbg.section_award_world_winners": "世界のアワード受賞施設",
    "things_to_see_and_do_in_other_cities": "他の都市の観光スポット",
    "button.backToTop": "ページトップに戻る",
    "lbg.section_theme_world_winners.family": "ファミリーアワード受賞施設",
    "hotels_near": "{0}付近のホテル",
    "category.activity__sports_": "現地ツアー",
    "trust_module.get_reward_night": "ボーナスステイを獲得",
    "hub.category.h1.business": "{0}での出張",
    "category.food": "レストラン",
    "other_popular_destinations_in": "{0}のその他の人気都市",
    "browse_by_category": "カテゴリー別に{0}のガイドを表示",
    "see_more_attactions": "他の観光スポットを見る",
    "lbg.theme.lgbt": "LGBTQ+",
    "more_information_about": "{0}の詳細",
    "lbg.h1.destination_theme.most_wanted": "{0}のゴールドアワード受賞施設",
    "see_all_properties_in": "{0}の宿泊施設を見る",
    "category.tours": "現地ツアー",
    "hub.category.h1.sports": "{0}でのスポーツ & アクティビティ",
    "where_will_you_go_next": "次はどこに行きますか ?",
    "cities": "都市",
    "lbg.theme.spa_hotel": "スパ",
    "see_guide": "ガイドを見る",
    "lbg.section_properties_theme.ski": "{0}のスキーアワード受賞施設",
    "related_stories": "関連記事",
    "hub.destination.h2": "便利な情報満載の旅行ガイド",
    "show_less": "表示数を減らす",
    "hub.category.h1.football": "サッカーファン向けの究極の{0}ガイド",
    "hub.category.h2.food": "{0}のおすすめ料理とレストラン",
    "fallback_language_alert": "申し訳ありません。このページはまだ翻訳されていません。",
    "category_highlight.title.information": "お役立ち情報",
    "neighbourhood_guides_for_other_cities": "他の都市の旅行ガイド",
    "lbg.h2.hubpage": "お客様から高評価を獲得し、多数の口コミが寄せられた人気の宿泊施設です。",
    "travel_advisory.title": "旅行情報",
    "lbg.section_properties_theme.all_inclusive": "{0}のオールインクルーシブ アワード受賞施設",
    "lbg.h2.boutique": "ブティック施設ファンに大好評 ! 宿泊経験者イチオシの優良施設をご覧ください。",
    "lbg.section_properties_theme.family": "{0}のファミリーアワード受賞施設",
    "hub.category.h2.families": "{0}でのおすすめの家族旅行",
    "from": "最低料金",
    "lbg.theme.vip_access": "VIP Access ",
    "lbg.section_properties_theme.lgbt": "{0}の LGBTQ+ アワード受賞施設",
    "x_star": "{0,choice,0#|1#{0} つ星|1.5#{0} つ星|2#{0} つ星|2.5#{0} つ星|3#{0} つ星|3.5#{0} つ星|4#{0} つ星|4.5#{0} つ星|5#{0} つ星}",
    "trust_module.free_cancellation": "キャンセル料無料",
    "lbg.section_properties_theme.home_and_apartments": "{0}のバケーションレンタル アワード受賞施設",
    "contributing_writer": "{0} <span>&#124;</span> <span>コントリビューティングライター</span>",
    "things_to_see_and_do_in": "<span class=\"next-line\">{0}の</span>観光スポット",
    "lbg.check_price": "料金を確認する",
    "hub.category.h2.blogs": "旅好きイチオシの情報が満載",
    "lbg.h2.home_and_apartments": "休日の家をお探しですか ? 宿泊経験者に好評の宿泊施設をご覧ください。",
    "hub.category.h2.information": "{0}のお役立ち情報",
    "search_hotels_in": "<span class=\"extra-label\">{0}の</span>ホテルを検索",
    "hub.category.h2.nature": "{0}でおすすめの自然探訪旅行",
    "lbg.h1.destination_theme.home_and_apartments": "{0}のバケーションレンタル アワード受賞施設",
    "photo_by": "写真提供 {0}",
    "more_things_to_do": "その他の観光スポット",
    "lbg.section_properties_theme.beach": "{0}のビーチアワード受賞施設",
    "read_less": "詳細を隠す",
    "lbg.h2.spa_hotel": "宿泊経験者が高く評価する優良施設のスパで、至福のひとときをお過ごしください。",
    "second_fold_related_stories.see_also": "その他のおすすめ情報",
    "hub.category.h2.history": "{0}で人気の歴史スポット",
    "lbg.section_properties_theme.cleanliness": "{0}の清潔度アワード受賞施設",
    "lbg.h1.destination_theme.boutique": "{0}のブティックアワード受賞施設",
    "nightlife_b595ae4": "ナイトライフ",
    "lbg.h1.destination_theme.beach": "{0}のビーチアワード受賞施設",
    "trust_module.free_cancellation.disclaimer": "一部のホテルは、チェックイン日の 24 ～ 48 時間前までにキャンセルすることを条件としています。詳細はウェブサイトでご覧ください。",
    "lbg.h2.family": "多くの旅行者が高く評価する家族向け宿泊施設をご覧ください。",
    "lbg.h2.lgbtq": "宿泊経験者に高く評価されている、セクシャルマイノリティ (LGBT+) への配慮が行き届いた施設をご紹介。",
    "hub.category.h1.group": "{0}でのグループ旅行",
    "popular_stories": "人気のブログ",
    "toc": "目次",
    "hub.category.h2.shopping": "{0}のおすすめショッピングスポット",
    "lbg.section_theme_world_winners.beach": "ビーチアワード受賞施設",
    "page_score": "ページスコア",
    "lbg.section_theme_world_winners.sustainable": "グリーンアワード受賞施設",
    "lbg.theme.boutique": "ブティック",
    "trust_module.price_guarantee": "価格保証",
    "lbg.h1.destination_theme.all_inclusive": "{0}のオールインクルーシブ アワード受賞施設",
    "top_destinations": "人気の目的地",
    "hotel_prices_near": "{0}周辺のホテルの料金",
    "lbg.score.cleanliness": "清潔度 :",
    "x_stars": "{0,choice,0#|1#{0} つ星|1<{0} つ星|4<{0} つ星|10<{0} つ星}",
    "lbg.theme.all_inclusive": "オールインクルーシブ",
    "hub.category.h1.tours": "{0}のツアー・日帰り旅行",
    "lbg.section_award_winners_theme": "テーマ別に受賞施設を見る",
    "guest_writer": "{0} <span>&#124;</span> <span>ゲストライター</span>",
    "start_planning_your_x_trip": "{0}旅行の計画をスタート !",
    "lbg.section_theme_world_winners.business": "ビジネスアワード受賞施設",
    "trending_now_in": "{0}で今注目の観光情報",
    "history": "歴史",
    "search_hotels": "ホテルを検索",
    "lbg.terms_conditions_details": "{1} 年のゲスト評価が高く、50 件以上の口コミが投稿されている宿泊施設の中から、{0} 年のアワード受賞施設が選出されます。",
    "see_nearby_properties": "周辺の宿泊施設を見る",
    "hub.category.h1.history": "{0}の歴史スポット",
    "location": "所在地 :",
    "lbg.section_theme_world_winners.luxury": "ラグジュアリーアワード受賞施設",
    "category_highlight.see_all.nightlife": "ナイトライフの詳細を表示",
    "also_popular": "その他の人気目的地",
    "hub.category.h2.things_to_do": "{0}のおすすめ観光スポット",
    "lbg.theme.family": "家族",
    "lbg.h1.destination_theme.pet_friendly": "{0}のペットアワード受賞施設",
    "category_highlight.title.shopping": "{0}のショッピングスポット・お土産",
    "price": "料金 :",
    "hub.category.h2.beach": "{0}の人気ビーチ",
    "neighbourhoods_in": "{0}の観光スポット",
    "business": "ビジネス",
    "photo": "写真",
    "blog.see_all_stories": "ブログ記事をすべて表示",
    "category_highlight.see_all.food": "料理・レストランの詳細を表示",
    "see_all_properties": "すべての宿泊施設を見る",
    "lbg.theme.beach": "ビーチ",
    "what_to_see_and_do_in": "{0}の観光スポット・アクティビティ",
    "hub.category.h2.single": "{0}で人気のひとり旅",
    "attractions_in": "{0}の観光スポット",
    "read_more": "もっと読む",
    "show_more": "もっと見る",
    "hotels_near_this_landmark": "この観光スポット周辺のホテルを見る",
    "check_dates": "日付を確認",
    "featured_stories_and_fun_stuff": "話題のスポット & アクティビティ",
    "phone": "電話番号 :",
    "lbg.section_search_header": "アワード受賞施設を検索",
    "category_highlight.title.food": "{0}のレストラン・料理",
    "see_all_things_to_do": "すべての現地ツアーを表示",
    "lbg.section_properties_theme.sustainable": "{0}のグリーンアワード受賞施設",
    "lbg.h1.hubpage": "お客様が選ぶ人気宿アワード {0} 受賞施設 !",
    "lbg.section_theme_world_winners.spa_hotel": "スパアワード受賞施設",
    "category.maps": "地図",
    "lbg.h1.destination_theme.family": "{0}のファミリーアワード受賞施設",
    "lbg.section_theme_world_winners.cleanliness": "清潔度アワード受賞施設",
    "lbg.section_theme_world_winners.vip_access": "VIP Access アワード受賞施設",
    "trust_module.get_reward_night.night_you_stay": "10 泊するごとに獲得",
    "modified": "修正済",
    "lbg.section_properties": "{0}のアワード受賞施設",
    "trust_module.header": "Hotels.com で予約するメリット",
    "hub.category.h2.luxury": "{0}で人気の贅沢な旅行",
    "lbg.theme.ski": "スキー",
    "lbg.h1.destination_theme.spa_hotel": "{0}のスパアワード受賞施設",
    "lbg.h2.most_wanted": "宿泊経験者が高く評価する優良施設で、至福の休日をお過ごしください。",
    "top10_hotels_incomplete_alert": "現在、予約が増加しているため、一部の宿泊施設が表示されない場合があります。<span>ご不便をおかけして申し訳ありません。</span>",
    "compulsive_traveller": "{0} <span>|</span> <span>旅のプロ</span>",
    "lbg.section_theme_world_winners.home_and_apartments": "バケーションレンタル アワード受賞施設",
    "loved_by_guests_collection_in": "宿泊者が選ぶベストホテル アワード : {0}の受賞施設",
    "title.suffix": "{0} - Go Guides",
    "hub.category.h2.maps": "{0}のお役立ちマップ",
    "opening_hours": "営業時間",
    "hub.category.h2.photos": "{0}で人気の写真撮影旅行",
    "lbg.theme.most_wanted": "ゴールドアワード",
    "blog.stories_container": "ブログ記事",
    "hub.category.h2.budget": "{0}で人気の格安旅行",
    "lbg.section_properties_theme.business": "{0}のビジネスアワード受賞施設",
    "not_sure_where_to_stay": "宿泊先でお悩みですか ? ",
    "category.information": "情報"
}
