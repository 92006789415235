export default {
    "current_deal": "Įprasta kaina",
    "lbg.section_properties_theme.pet_friendly": "Geriausiomis pripažintos apgyvendinimo įstaigos svečiams su augintiniais – {0}",
    "address": "Adresas:",
    "hub.homepage.h2": "Tiek daug vietų, tiek mažai laiko...",
    "search_hotels_near_this_landmark": "Ieškoti viešbučių<span class=\"extra-label\"> prie šio lankytino objekto</span>",
    "hub.category.h1.things_to_do": "Ką nuveikti | {0}",
    "lbg.h2.vip_access": "„VIP Access“ apgyvendinimo įstaigos atrinktos dėl puikių viešnagių vertinimų ir privilegijuotų potyrių.",
    "lbg.theme.cleanliness": "Švara",
    "lbg.theme.lgbtq": "LGBTQ",
    "load_more": "Įkelti daugiau",
    "link_long_form_to_listiicle_multiple": "{0} – vienas svarbiausių {1} ir {2} (Skaitykite viską apie miestą {3} čia)",
    "lbg.section_properties_theme.boutique": "Geriausiomis pripažintos butiko stiliaus apgyvendinimo įstaigos – {0}",
    "lbg.h2.luxury": "Atraskite didžiausią prabangą. Štai kodėl svečiams patiko šios apgyvendinimo įstaigos.",
    "hub.category.h1.information": "{0} iš arčiau",
    "families": "Šeimoms",
    "trending_now": "Populiarios kelionių kryptys",
    "lbg.from": "Nuo",
    "hub.category.h1.road_trips": "{0} − geriausios kelionės automobiliu",
    "lbg.h2.cleanliness": "Šios apgyvendinimo įstaigos svečių įvertintos geriausiai visomis prasmėmis ir dėl švaros.",
    "single": "Pavieniams keliautojams",
    "couples": "Poroms",
    "trust_module.price_guarantee.find_out_more": "sužinoti daugiau",
    "top_destinations_in": "Populiariausios kelionių kryptys, {0}",
    "hub.category.h1.couples": "Romantiškos kelionės ({0})",
    "hub.category.h2.tours": "Geriausios ekskursijos | {0}",
    "hub.category.h1.photos": "Fotografuotinos kelionės ({0})",
    "category.photos": "Nuotraukos",
    "hub.category.h1.budget": "Ekonomiškos kelionės ({0})",
    "hub.category.h2.ski": "Geriausios slidinėjimo vietovės – {0}",
    "lbg.section_theme_world_winners.most_wanted": "Geidžiamiausiomis pripažintos apgyvendinimo įstaigos",
    "lbg.theme.home_and_apartments": "Būstai ir apartamentai",
    "hub.category.h1.luxury": "Prabangios kelionės ({0})",
    "hub.category.h2.adventure": "Geriausios aktyvios kelionės ({0})",
    "hotels_in": "Apgyvendinimo įstaigos – {0}",
    "lbg.section_search_winners": "Ieškoti daugiau geriausiomis pripažintų apgyvendinimo įstaigų",
    "see_more_hotels_in": "Žiūrėti daugiau apgyvendinimo įstaigų – {0}",
    "hub.category.h1.beach": "{0}: paplūdimiai",
    "open": "Darbo laikas:",
    "enter_your_destination": "Įveskite kelionės kryptį.",
    "best_of": "Geriausia, ką siūlo {0}",
    "top10_hotels_missing_properties": "Šiuo metu gali būti rodomos ne visos apgyvendinimo įstaigos. „Hotels.com“ atsiprašo už susidariusius nepatogumus.",
    "adventure": "Nuotykiai",
    "lbg.h1.destination_theme.sustainable": "Geriausiomis pripažintos tvarios apgyvendinimo įstaigos – {0}",
    "luxury": "Prabanga",
    "expand_map_view": "Padidinti žemėlapio rodinį",
    "book_now": "Užsakyti dabar",
    "lbg.h1.destination_theme.ski": "Geriausiomis pripažintos apgyvendinimo įstaigos slidinėjimo atostogoms – {0}",
    "hub.category.h1.food": "Maistas ir restoranai | {0}",
    "category.blogs": "Tinklaraštis",
    "budget": "Ekonominė klasė",
    "popular_neighbourhoods_in": "{0} – populiarūs rajonai",
    "hub.category.h1.unusual": "Neatrasti perliukai ir neįprastos vietos ({0})",
    "see_all_accommodation_in": "Rodyti apgyvendinimo įstaigas mieste {0}",
    "hub.category.h1.adventure": "Aktyvios kelionės ({0})",
    "category_highlight.see_all.shopping": "Daugiau apie apsipirkimą",
    "lbg.section_most_popular_destination": "Populiariausios kelionių kryptys",
    "lbg.section_properties_theme.luxury": "Geriausiomis pripažintos prabangios apgyvendinimo įstaigos – {0}",
    "unusual": "Netradicinė aplinka",
    "category.shopping": "Parduotuvės",
    "make_the_most_of_your_trip": "Parsivežkite neišdildomų įspūdžių!",
    "x_review": "{0,choice,0#|1#{0} atsiliepimas|2#{0} atsiliepimai|2<{0} atsiliepimai|4<{0} atsiliepimai|10#{0} atsiliepimų|10<{0} atsiliepimų}",
    "hub.category.h1.single": "Kelionės pavieniui ({0})",
    "hub.category.h2.custom": "Populiarios kelionių kryptys",
    "lbg.section_theme_world_winners.lgbt": "Geriausiomis pripažintos LGBTQ+ draugiškos apgyvendinimo įstaigos",
    "lbg.section_award_winners_theme_other": "Kitų tipų geriausiomis pripažintos apgyvendinimo įstaigos – {0}",
    "lbg.supplier_collected_charges": "+ apgyvendinimo įstaigoje mokėtina {0} rinkliavų suma",
    "in_destination": " {0}",
    "lbg.section_properties_theme.vip_access": "Geriausiomis pripažintos verslo klasės apgyvendinimo įstaigos – {0}",
    "link_long_form_to_listiicle_single": "{0} – vienas svarbiausių {1} (Skaitykite viską apie miestą {2} čia)",
    "lbg.section_theme_world_winners.boutique": "Geriausiomis pripažintos butiko stiliaus apgyvendinimo įstaigos",
    "lbg.section_properties_theme.spa_hotel": "Geriausiomis pripažintos apgyvendinimo įstaigos su SPA centru – {0}",
    "lbg.section_theme_world_winners.all_inclusive": "Geriausiomis pripažintos „viskas įskaičiuota“ tipo apgyvendinimo įstaigos",
    "hub.category.h2": "Kelionių vadovas",
    "lbg.properties.price_tooltip": "Svetainėje „Hotels.com“ tai yra mažiausia nakvynės kaina artimiausias 14 dienų. ",
    "hub.category.h2.road_trips": "{0} − geriausios kelionės automobiliu",
    "hub.category.h1.ski": "Slidinėjimo vietovės – {0}",
    "food": "Maistas",
    "lbg.theme.pet_friendly": "Svečiams su augintiniais",
    "lbg.theme.business": "Verslo klasės",
    "hub.category.h1.nature": "{0} − geriausios pabėgimo į gamtą kelionės",
    "map": "Žemėlapis",
    "category_highlight.see_all.information": "Daugiau informacijos",
    "hotel_prices_in": "{0} – viešbučių kainos",
    "hub.category.h1.nightlife": "{0}. Naktinės pramogos ",
    "trust_module.free_cancellation.most_hotels": "daugelyje viešbučių*",
    "also_popular_in": "{0}. Kitos populiarios kryptys",
    "lbg.h1.destination_theme.business": "Geriausiomis pripažintos verslo klasės apgyvendinimo įstaigos – {0}",
    "lbg.section_theme_world_winners.pet_friendly": "Geriausiomis pripažintos apgyvendinimo įstaigos svečiams su augintiniais",
    "hub.category.h1.maps": "Žemėlapiai ({0})",
    "lbg.section_properties_theme.most_wanted": "Geidžiamiausiomis pripažintos apgyvendinimo įstaigos – {0}",
    "category.nightlife": "Naktinės pramogos",
    "search_hotels_in_this_area": "Ieškoti viešbučių<span class=\"extra-label\"> šiame rajone</span>",
    "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 balas|2#2 balai|2<{1} balai|4<{1} balai|10#10 balų|10<{1} balai (-ų})",
    "hub.category.h2.business": "Geriausios verslo kelionės ({0})",
    "category.things_to_do": "Ką nuveikti",
    "destination_travel_guide_by_category": "{0}. Kelionių vadovas pagal kategoriją",
    "hub.category.h1.families": "Šeimos kelionė – {0}",
    "lbg.section_theme_world_winners.ski": "Geriausiomis pripažintos apgyvendinimo įstaigos slidinėjimo atostogoms",
    "you_may_also_like": "Panašūs straipsniai",
    "lbg.theme.sustainable": "Tvarios",
    "hub.category.h2.unusual": "Geriausi neatrasti perliukai ir neįprastos vietos ({0})",
    "hub.category.h2.football": "Palaikykite savo futbolo komandą ir pasimėgaukite kelione!",
    "top_hotel": "Populiariausia apgyvendinimo įstaiga",
    "lbg.h1.destination_theme.luxury": "Geriausiomis pripažintos prabangios apgyvendinimo įstaigos – {0}",
    "top10_hotels_empty_alert": "Deja, iškilo techninė kliūtis. <span>Atsiprašome už nepatogumus.</span>",
    "special_deal": "Ypatingas pasiūlymas",
    "lbg.h2.sustainable": "Mums rūpi tvarumas! Peržiūrėkite šias apgyvendinimo įstaigas, kuriose viešnagė bus puiki.",
    "where_to_stay_in": "{0} – kur apsistoti",
    "start_planning_your_trip": "Planuoti viešnagę",
    "good_for": "Tinka:",
    "category.hotels": "Apgyvendinimo įstaigos",
    "x_accommodation_guide": "Apgyvendinimo įstaigų gidas – {0}",
    "lbg.theme.luxury": "Prabangios",
    "hub.category.h2.sports": "Geriausi sportiniai užsiėmimai ir veiklos ({0})",
    "lbg.section_theme_world_winners.lgbtq": "Geriausiomis pripažintos LGBTQ draugiškos apgyvendinimo įstaigos",
    "see_more_neighbourhoods": "Rodyti daugiau rajonų",
    "category_highlight.title.nightlife": "Kur eiti ir ką veikti vakare ({0})",
    "lbg.h1.destination_theme.lgbt": "Geriausiomis pripažintos LGBTQ+ draugiškos apgyvendinimo įstaigos – {0}",
    "hub.category.h1.blogs": "{0}. Kelionių tinklaraštis",
    "lbg.h2.business": "Šias apgyvendinimo įstaigas puikiai įvertino keliaujantieji darbo reikalais.",
    "hotels": "Apgyvendinimo įstaigos",
    "lbg.h2.pet_friendly": "Svečiai labai rekomenduoja šias apgyvendinimo įstaigas, kuriose galima apsistoti su savo augintiniais.",
    "popular_landmarks_nearby": "Populiarūs lankytini objektai netoliese",
    "popular_extractions_in": "{0} – populiarūs lankytini objektai",
    "lbg.h2.all_inclusive": "Šiose geriausiai įvertintose apgyvendinimo įstaigose pamiršite visus savo rūpesčius.",
    "travel_guide_by_category_header": "{0} – {1}",
    "lbg.travel_between": "Keliaujant nuo {0} iki {1}",
    "keep_exploring": "Pažintis su pasauliu",
    "what_you_need_to_know_before_you_go": "Ką reikėtų žinoti prieš vykstant",
    "read_it": "Skaityti",
    "view_on_map": "Žiūrėti žemėlapyje",
    "lbg.h1.destination_theme.lgbtq": "Geriausiomis pripažintos LGBTQ draugiškos apgyvendinimo įstaigos – {0}",
    "lbg.h2.beach": "Svečiai mėgsta apsistoti prie paplūdimio, ypač šiose apgyvendinimo įstaigose.",
    "lbg.h1.destination_theme.vip_access": "Geriausiomis pripažintos verslo klasės apgyvendinimo įstaigos – {0}",
    "see_all_accommodation": "Rodyti visas apgyvendinimo įstaigas",
    "shoppers": "Parduotuvės",
    "whats_around": "Netoliese",
    "group": "Grupėms",
    "hub.category.h2.couples": "Geriausios romantiškos kelionės ({0})",
    "hub.category.h1.shopping": "{0}. Parduotuvės",
    "travel_community": "{0} <span>&#124;</span> <span>Autorė / autorius</span>",
    "hub.category.h2.group": "Geriausios grupinės kelionės ({0})",
    "listicle_legal_msg": "Šiame straipsnyje pateikiamos „Go Guides“ redakcijos darbuotojų nuomonės. „Hotels.com“ suteikia kompensacijas autoriams už rašinius šioje svetainėje. Kompensacijos gali būti ir kelionės arba kitų išlaidų padengimas.",
    "lbg.h1.destination_theme.cleanliness": "Švariausiomis pripažintos apgyvendinimo įstaigos – {0}",
    "menu.more": "Daugiau",
    "lbg.section_properties_theme.lgbtq": "Geriausiomis pripažintos LGBTQ draugiškos apgyvendinimo įstaigos – {0}",
    "lbg.section_award_winners_destination": "Geriausiomis pripažintos apgyvendinimo įstaigos pagal kelionės kryptį",
    "lbg.terms_conditions": "Bendrosios sąlygos",
    "hub.category.h2.nightlife": "Geriausias naktinis gyvenimas | {0}",
    "hub.neighbourhood.h2": "Ką nuveikti",
    "lbg.section_award_winners_theme_destination": "Geriausiomis pripažintos apgyvendinimo įstaigos pagal tipą – {0}",
    "lbg.section_award_world_winners": "Geriausiomis pripažintos apgyvendinimo įstaigos visame pasaulyje",
    "things_to_see_and_do_in_other_cities": "Ką pamatyti ir nuveikti kituose miestuose",
    "button.backToTop": "Grįžti į viršų",
    "lbg.section_theme_world_winners.family": "Geriausiomis pripažintos apgyvendinimo įstaigos šeimoms",
    "hotels_near": "Apgyvendinimo įstaigos – {0}",
    "category.activity__sports_": "Ką aplankyti",
    "trust_module.get_reward_night": "Gaukite premijinę nakvynę",
    "hub.category.h1.business": "Verslo kelionės ({0})",
    "category.food": "Maistas",
    "other_popular_destinations_in": "Kitos populiarios kryptys – {0}",
    "browse_by_category": "{0} pagal kategorijas",
    "see_more_attactions": "Rodyti daugiau lankytinų objektų",
    "lbg.theme.lgbt": "LGBTQ+ draugiškos",
    "more_information_about": "{0} iš arčiau",
    "lbg.h1.destination_theme.most_wanted": "Geidžiamiausiomis pripažintos apgyvendinimo įstaigos – {0}",
    "see_all_properties_in": "Žiūrėti apgyvendinimo įstaigas – {0}",
    "category.tours": "Ekskursijos",
    "hub.category.h1.sports": "Sportiniai užsiėmimai ir veiklos ({0})",
    "where_will_you_go_next": "Kur keliausite toliau?",
    "cities": "Miestai",
    "lbg.theme.spa_hotel": "Su SPA centru",
    "see_guide": "Žiūrėti vadovą",
    "lbg.section_properties_theme.ski": "Geriausiomis pripažintos apgyvendinimo įstaigos slidinėjimo atostogoms – {0}",
    "related_stories": "Susiję straipsniai",
    "hub.destination.h2": "Ką reikėtų žinoti prieš vykstant",
    "show_less": "Rodyti mažiau",
    "hub.category.h1.football": "Naujausias vadovas tikriems futbolo aistruoliams – {0}",
    "hub.category.h2.food": "Geriausias maistas ir restoranai, {0}",
    "fallback_language_alert": "Deja, šis puslapis dar neišverstas į lietuvių kalbą...",
    "category_highlight.title.information": "Naudinga informacija",
    "neighbourhood_guides_for_other_cities": "Kitų miestų rajonų kelionių vadovai",
    "lbg.h2.hubpage": "Šias nuostabias apgyvendinimo įstaigas daugybėje atsiliepimų puikiai vertina svečiai.",
    "travel_advisory.title": "Kelionės pranešimas",
    "lbg.section_properties_theme.all_inclusive": "Geriausiomis pripažintos „viskas įskaičiuota“ tipo apgyvendinimo įstaigos – {0}",
    "lbg.h2.boutique": "Jei renkatės butiko stiliaus apgyvendinimo įstaigas, apsistokite šiose svečių rekomenduojamose įstaigose.",
    "lbg.section_properties_theme.family": "Geriausiomis pripažintos apgyvendinimo įstaigos šeimoms – {0}",
    "hub.category.h2.families": "Geriausia šeimos kelionė – {0}",
    "from": "Nuo",
    "lbg.theme.vip_access": "„VIP Access“",
    "lbg.section_properties_theme.lgbt": "Geriausiomis pripažintos LGBTQ+ draugiškos apgyvendinimo įstaigos – {0}",
    "x_star": "{0,choice,0#|1#{0} žvaigždutės|1.5#{0} žvaigždutės|2#{0} žvaigždučių|2.5#{0} žvaigždutės|3#{0} žvaigždučių|3.5#{0} žvaigždutės|4#{0} žvaigždučių|4.5#{0} žvaigždutės|5#{0} žvaigždučių}",
    "trust_module.free_cancellation": "Nemokamas atšaukimas",
    "lbg.section_properties_theme.home_and_apartments": "Geriausiais pripažinti būstai ir apartamentai – {0}",
    "contributing_writer": "{0} <span>&#124;</span> <span>Autorė / autorius</span>",
    "things_to_see_and_do_in": "{0} – <span class=\"next-line\">ką pamatyti ir nuveikti</span>",
    "lbg.check_price": "Sužinoti kainą",
    "hub.category.h2.blogs": "Autoriai: patyrę keliautojai",
    "lbg.h2.home_and_apartments": "Dairotės apgyvendinimo įstaigų, kur jaustumėtės kaip namuose? Peržiūrėkite šias puikiai įvertintas apgyvendinimo įstaigas.",
    "hub.category.h2.information": "Naudinga informacija | {0}",
    "search_hotels_in": "Ieškoti viešbučių<span class=\"extra-label\"> – {0}</span>",
    "hub.category.h2.nature": "{0} − geriausios kelionės po gamtą",
    "lbg.h1.destination_theme.home_and_apartments": "Geriausiais pripažinti būstai ir apartamentai – {0}",
    "photo_by": "Nuotraukos autorius: {0}",
    "more_things_to_do": "Ką daugiau nuveikti",
    "lbg.section_properties_theme.beach": "Geriausiomis pripažintos apgyvendinimo įstaigos prie paplūdimio – {0}",
    "read_less": "Rodyti mažiau",
    "lbg.h2.spa_hotel": "Atsipalaiduokite šiose geriausiai įvertintose apgyvendinimo įstaigose, kuriose teikiamos SPA paslaugos.",
    "second_fold_related_stories.see_also": "Taip pat žiūrėti",
    "hub.category.h2.history": "Geriausios istorinės vietovės ({0})",
    "lbg.section_properties_theme.cleanliness": "Švariausiomis pripažintos apgyvendinimo įstaigos – {0}",
    "lbg.h1.destination_theme.boutique": "Geriausiomis pripažintos butiko stiliaus apgyvendinimo įstaigos – {0}",
    "nightlife_b595ae4": "Naktinės pramogos",
    "lbg.h1.destination_theme.beach": "Geriausiomis pripažintos apgyvendinimo įstaigos prie paplūdimio – {0}",
    "trust_module.free_cancellation.disclaimer": "Kai kurie viešbučiai prašo atšaukti likus bent 24–48 val. iki atvykimo. Daugiau informacijos rasite svetainėje.",
    "lbg.h2.family": "Šios svečių puikiai įvertintos apgyvendinimo įstaigos atitinka šeimų poreikius.",
    "lbg.h2.lgbtq": "Svečiai puikiai įvertino šias LGBTQ draugiškas apgyvendinimo įstaigas.",
    "hub.category.h1.group": "Grupinės kelionės ({0})",
    "popular_stories": "Populiarūs pasakojimai",
    "toc": "Turinys",
    "hub.category.h2.shopping": "Geriausios parduotuvės | {0}",
    "lbg.section_theme_world_winners.beach": "Geriausiomis pripažintos apgyvendinimo įstaigos prie paplūdimio",
    "page_score": "Šio puslapio įvertis",
    "lbg.section_theme_world_winners.sustainable": "Geriausiomis pripažintos tvarios apgyvendinimo įstaigos",
    "lbg.theme.boutique": "Butiko stiliaus",
    "trust_module.price_guarantee": "Garantuota kaina",
    "lbg.h1.destination_theme.all_inclusive": "Geriausiomis pripažintos „viskas įskaičiuota“ tipo apgyvendinimo įstaigos – {0}",
    "top_destinations": "Populiariausios kelionių kryptys",
    "hotel_prices_near": "{0} – gretimų viešbučių kainos",
    "lbg.score.cleanliness": "Švara:",
    "x_stars": "{0,choice,0#|1#{0} žvaigždutės|1<{0} žvaigždučių|4<{0} žvaigždučių|10<{0} žvaigždučių}",
    "lbg.theme.all_inclusive": "„Viskas įskaičiuota“ tipo",
    "hub.category.h1.tours": "Dienos ekskursijos ir pažintinės išvykos | {0}",
    "lbg.section_award_winners_theme": "Geriausiomis pripažintos apgyvendinimo įstaigos pagal tipą",
    "guest_writer": "{0} <span>&#124;</span> <span>Autorė / autorius</span>",
    "start_planning_your_x_trip": "Planuoti viešnagę – {0}",
    "lbg.section_theme_world_winners.business": "Geriausiomis pripažintos verslo klasės apgyvendinimo įstaigos",
    "trending_now_in": "Populiarūs straipsniai – {0}",
    "history": "Istorija",
    "search_hotels": "Ieškoti viešbučių",
    "lbg.terms_conditions_details": "Apdovanojimą {0}-aisiais pelnė tos apgyvendinimo įstaigos, kurios buvo geriausiai įvertintos svečių ir apie kurias per {1}-uosius buvo pateikta mažiausiai 50 atsiliepimų.",
    "see_nearby_properties": "Rodyti netoliese esančias apgyvendinimo įstaigas",
    "hub.category.h1.history": "Istorinės vietovės ({0})",
    "location": "Vieta:",
    "lbg.section_theme_world_winners.luxury": "Geriausiomis pripažintos prabangios apgyvendinimo įstaigos",
    "category_highlight.see_all.nightlife": "Daugiau apie naktinį gyvenimą",
    "also_popular": "Kitos populiarios kryptys",
    "hub.category.h2.things_to_do": "Geriausi užsiėmimai | {0}",
    "lbg.theme.family": "Šeimoms",
    "lbg.h1.destination_theme.pet_friendly": "Geriausiomis pripažintos apgyvendinimo įstaigos svečiams su augintiniais – {0}",
    "category_highlight.title.shopping": "Kur apsipirkti ir ką įsigyti ({0})",
    "price": "Kaina:",
    "hub.category.h2.beach": "{0}: geriausi paplūdimiai",
    "neighbourhoods_in": "rajonai, {0}",
    "business": "Verslo klasė",
    "photo": "Nuotraukoms",
    "blog.see_all_stories": "Rodyti visas istorijas",
    "category_highlight.see_all.food": "Daugiau apie maistą ir gėrimus",
    "see_all_properties": "Žiūrėti visas apgyvendinimo įstaigas",
    "lbg.theme.beach": "Prie paplūdimio",
    "what_to_see_and_do_in": "Ką pamatyti ir nuveikti ({0})",
    "hub.category.h2.single": "Geriausios kelionės pavieniui ({0})",
    "attractions_in": "Pramogos – {0}",
    "read_more": "Skaityti daugiau",
    "show_more": "Rodyti daugiau",
    "hotels_near_this_landmark": "Apgyvendinimo įstaigos netoliese",
    "check_dates": "Tikrinti datas",
    "featured_stories_and_fun_stuff": "Naudinga ir įdomu",
    "phone": "Telefonas:",
    "lbg.section_search_header": "Ieškoti šį apdovanojimą pelniusių apgyvendinimo įstaigų",
    "category_highlight.title.food": "Kur ir ką valgyti ({0})",
    "see_all_things_to_do": "Rodyti visus užsiėmimus",
    "lbg.section_properties_theme.sustainable": "Geriausiomis pripažintos tvarios apgyvendinimo įstaigos – {0}",
    "lbg.h1.hubpage": "{0} m. svečių mėgstamiausiomis pripažintos apgyvendinimo įstaigos",
    "lbg.section_theme_world_winners.spa_hotel": "Geriausiomis pripažintos apgyvendinimo įstaigos su SPA centru",
    "category.maps": "Žemėlapiai",
    "lbg.h1.destination_theme.family": "Geriausiomis pripažintos apgyvendinimo įstaigos šeimoms – {0}",
    "lbg.section_theme_world_winners.cleanliness": "Švariausiomis pripažintos apgyvendinimo įstaigos",
    "lbg.section_theme_world_winners.vip_access": "Geriausiomis pripažintos verslo klasės apgyvendinimo įstaigos",
    "trust_module.get_reward_night.night_you_stay": "už kiekvienas 10 viešnagės nakvynių",
    "modified": "Pakeista",
    "lbg.section_properties": "Geriausiomis pripažintos apgyvendinimo įstaigos – {0}",
    "trust_module.header": "Kodėl verta užsakyti svetainėje „Hotels.com“?",
    "hub.category.h2.luxury": "Geriausios prabangios kelionės ({0})",
    "lbg.theme.ski": "Slidinėjimo atostogoms",
    "lbg.h1.destination_theme.spa_hotel": "Geriausiomis pripažintos apgyvendinimo įstaigos su SPA centru – {0}",
    "lbg.h2.most_wanted": "Svečiai labai rekomenduoja šias apgyvendinimo įstaigas įsimintinai viešnagei.",
    "top10_hotels_incomplete_alert": "Šiuo metu apgyvendinimo įstaigas žiūri itin daug vartotojų, todėl gali būti, kad jos rodomos ne visos. <span>Atsiprašome už nepatogumus.</span>",
    "compulsive_traveller": "{0} <span>&#124;</span> <span>prisiekęs keliautojas</span>",
    "lbg.section_theme_world_winners.home_and_apartments": "Geriausiais pripažinti būstai ir apartamentai",
    "loved_by_guests_collection_in": "Kolekcija „Svečiams patinka!“ – {0}",
    "title.suffix": "{0} – „Go Guides“",
    "hub.category.h2.maps": "Naudingi žemėlapiai | {0}",
    "opening_hours": "Darbo laikas:",
    "hub.category.h2.photos": "Geriausios fotografuotinos kelionės ({0})",
    "lbg.theme.most_wanted": "Geidžiamiausios",
    "blog.stories_container": "Bendradarbių istorijos",
    "hub.category.h2.budget": "Geriausios ekonomiškos kelionės ({0})",
    "lbg.section_properties_theme.business": "Geriausiomis pripažintos verslo klasės apgyvendinimo įstaigos – {0}",
    "not_sure_where_to_stay": "Neapsisprendžiate, kur apsistoti?",
    "category.information": "Informacija"
}
