import { MasterPage } from 'components/common/template/master-page';
import GoGuidesAlsoPopularComponent from 'components/modules/also-popular/also-popular';
import GoGuidesContributorsStoriesComponent from 'components/modules/contributors-stories/contributors-stories';
import React from 'react';
import GoGuidesFeaturedStoriesComponent from 'components/modules/featured-stories/featured-stories';
import GoGuidesFreetextComponent from 'components/modules/free-text/free-text';
import GoGuidesHeroComponent from 'components/modules/hero/hero';
import GoGuidesIntroComponent from 'components/modules/intro/intro';
import GoGuidesKeepExploringComponent from 'components/modules/keep-exploring/keep-exploring';
import GoGuidesOtherCategoriesComponent from 'components/modules/other-categories/other-categories';
import GoGuidesOtherNeighbourhoodsComponent from 'components/modules/other-neighbourhoods/other-neighbourhoods';
import GoGuidesTopDestinationComponent from 'components/modules/top-destination/top-destination';
import GoGuidesWhatsAroundComponent from 'src/components/modules/whats-around/whats-around';
import GoGuidesSearchWizard from 'components/modules/search-wizard/search-wizard';
import GoGuidesBackToTop from '../modules/back-to-top/back-to-top';
import GoGuidesTableOfContent from '../modules/table-of-content/toc';
import GoGuidesCategoryHighLightStoriesComponent from '../modules/highlight-stories/category-highlight-stories';
import { RecommendationModule } from '../modules/recommendation-module/recommendation';
import { PageLayout } from '../types/goguides-types.d';

const DestinationHubPage: React.FC = () => {
  return (
    <MasterPage>
      <div className="page-container">
        <GoGuidesHeroComponent />
        <div className="hub-body">
          <GoGuidesTableOfContent tagElement='h2' parentClassName='hub-body' needWrapper={true} />
          <GoGuidesIntroComponent />
          <GoGuidesTopDestinationComponent />
          <GoGuidesFeaturedStoriesComponent />
          <GoGuidesCategoryHighLightStoriesComponent {...{"category": "THINGS_TO_DO", "maxContent": 9}} />
          <GoGuidesWhatsAroundComponent />
          <GoGuidesCategoryHighLightStoriesComponent {...{"category": "RESTAURANT"}} />
          <GoGuidesCategoryHighLightStoriesComponent {...{"category": "SHOPPING"}} />
          <GoGuidesCategoryHighLightStoriesComponent {...{"category": "NIGHTLIFE"}} />
          <GoGuidesCategoryHighLightStoriesComponent {...{"category": "INFORMATION"}} />
          <GoGuidesContributorsStoriesComponent />
          <GoGuidesOtherCategoriesComponent />
          <RecommendationModule pageLayout={PageLayout.ONE_COLUMN} />
          <GoGuidesOtherNeighbourhoodsComponent />
          <GoGuidesFreetextComponent />
          <GoGuidesSearchWizard />
          <GoGuidesAlsoPopularComponent />
          <GoGuidesKeepExploringComponent /> 
        </div>
        <GoGuidesBackToTop />
      </div>
    </MasterPage>
  );
};

export default DestinationHubPage;
