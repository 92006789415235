import * as React from 'react';
import { useStores } from 'utils/useStore';
import TopDestinationInner from './top-destination-inner';
import { EgClickstreamData, EgClickstreamImpressionTracker } from '@shared-ui/clickstream-analytics-context';
import { checkValue } from 'src/components/functions';

const GoGuidesTopDestinationComponent: React.FC = () => {
  const { goGuidesStore } = useStores();
  const contentData = goGuidesStore.fetchTopDestinations(goGuidesStore.pageUrlData);

  const impressionTrackingObject: EgClickstreamData = {
    event: {
      event_name: 'go_guides_module.presented',
      event_type: 'Impression',
      event_version: '1.0.0',
      event_category: 'go_guides',
    },
    experience: goGuidesStore.ClicksteamExperience,
    module: {
      module_name: 'top destination',
    },
  };

  return (
    <>
      {checkValue(contentData) && (
        <EgClickstreamImpressionTracker eventData={impressionTrackingObject}>
          <TopDestinationInner {...contentData} />
        </EgClickstreamImpressionTracker>
      )}
    </>
  );
};

export default GoGuidesTopDestinationComponent;
