import React, { useRef, useState } from 'react';
import { UitkIcon } from 'uitk-react-icons';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { useStores } from 'utils/useStore';
import { localization } from 'src/utils/localization';

interface MobileNavigationProps {
  menu: any;
  iconMapping: any;
  handleClick: (status) => void;
}

const MobileNavigation: React.FC<MobileNavigationProps> = (props) => {
  const { menu, handleClick, iconMapping } = props;
  const { goGuidesStore } = useStores();
  const track = useEgClickstream();
  const menuButton = useRef(null);

  if (!menu.category || menu.category.length == 0) {
    return null;
  }

  const handleClickOutSide = (event) => {
    const isClickInside = menuButton.current.contains(event.target);

    if (!isClickInside) {
      handleClick(false);
      document.removeEventListener('click', handleClickOutSide);
    }
  };

  const trackObject = {
    event: {
      event_version: '1.0.0',
      event_type: 'Interaction',
      event_name: 'more_button.selected',
      event_category: 'go_guides',
      action_location: 'toolbar',
    },
    experience: goGuidesStore.ClicksteamExperience,
  };

  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    document.addEventListener('click', handleClickOutSide);
    handleClick(true);

    // send Clickstream tracking
    track(trackObject);
  };
  const loc = new localization();

  // if we have more than 3 category menu first 3 menu has empty class name
  // if less than that use 'menu{x}'
  const menuClass = menu.category.length < 3 ? `menu${menu.category.length}` : '';
  const buttonClass =
    menu.category.length == 1 ? menuClass : menu.category.length == 2 ? `fixed ${menuClass}` : 'fixed';
  const subMenu = menu.category.map((item, index) => {
    const itemClass = index >= 3 ? 'hide' : menuClass;
    const target = item.categoryKey == 'hotels' ? '_blank' : '_self';
    const categoryName = loc.getLocalizedText(`category.${item.categoryKey}`);
    
    return (
      <li className={itemClass} key={index}>
        <a className={`nav-menu ${item.categoryKey}`} href={item.targetUrl} target={target}>
          <UitkIcon name={iconMapping[item.categoryKey]} />
          {categoryName}
        </a>
      </li>
    );
  });
  const menuLabel = loc.getLocalizedText('menu.more');

  return (
    <ul className="mobile-tab">
      {subMenu}
      <li className={buttonClass}>
        <a className="open-button" onClick={onClick} ref={menuButton}>
          <span>
            <UitkIcon name="more_horiz" /> {menuLabel}
          </span>
        </a>
      </li>
    </ul>
  );
};

export default MobileNavigation;
