import React from 'react';
import { useStores } from 'utils/useStore';
import { checkValue } from 'components/functions';
import { GoGuidesRelatedStoriesItemProps, GoGuidesRelatedStoriesProps } from 'components/types/goguides-types.d';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { UitkFigure, UitkImage, UitkFigureAspectRatioType } from 'uitk-react-images';
import { UitkSpacingHr } from 'uitk-react-spacing';
import { EgClickstreamData, EgClickstreamImpressionTracker } from '@shared-ui/clickstream-analytics-context';
import { RenderImage } from 'src/components/utils/responsive-image';
import { localization } from 'src/utils/localization';
import { UitkCard, UitkCardLink } from 'uitk-react-cards';

const GoGuidesRelatedStoriesComponent: React.FC<GoGuidesRelatedStoriesProps> = props => {
  const { goGuidesStore } = useStores();
  const loc = new localization();

  const contentData = goGuidesStore.fetchRelatedStories(goGuidesStore.pageUrlData);

  const { seeAlso } = props;
  let stories;
  let renderSeeAlso;

  if (checkValue(contentData)) {
    const { relatedStories: relatedStoriesData, showSeeAlso } = contentData;
    renderSeeAlso = checkValue(showSeeAlso) && checkValue(seeAlso);

    if (renderSeeAlso) {
      stories = relatedStoriesData.slice(0, 3);
    } else if (!checkValue(seeAlso)) {
      stories = relatedStoriesData;
    } else {
      stories = [];
    }
  }

  const trackingObject: EgClickstreamData = {
    event: {
      event_name: 'go_guides_module.presented',
      event_type: 'Impression',
      event_version: '1.0.0',
      event_category: 'go_guides',
    },
    experience: goGuidesStore.ClicksteamExperience,
    module: {
      module_name: seeAlso ? 'see also' : 'related stories',
    },
  };

  const CardItem = ({ story, seeAlso = false }: { story: GoGuidesRelatedStoriesItemProps; seeAlso?: boolean }) => {

    const imageProps = {
      image: story.image?.imageUrl,
      alt: story.name,
      params: {
        size: {
          small: '&width=400&height=267',
          medium: '&width=800&height=533',
          large: '&width=600&height=400',
        }
      }
    };

    return (
      <li className={`item-wrap ${seeAlso ? 'col-12 col-m4' : 'col-6 col-m6 col-l3'}`}>
        <UitkCard border={true}>
          <div className="item-wrap-inner">
            <UitkFigure className="img" ratio={UitkFigureAspectRatioType.R3_2}>
              <RenderImage imgProps={imageProps} />
              {story.isBlog && (
                <UitkText className="red-tab">{loc.getLocalizedText('category_blogs')}</UitkText>
              )}
            </UitkFigure>
            <div className="detail-wrap">
              <UitkHeading tag="h3">{story.name}</UitkHeading>
              <UitkText>
                <UitkIcon className="icon" name="place" size={UitkIconSize.EXTRA_SMALL} />
                {story.countryName}
              </UitkText>
            </div>
          </div>
          <UitkCardLink>
            <a href={story.targetUrl}></a>
          </UitkCardLink>
        </UitkCard>
      </li>
    );
  };

  return (
    <>
      {checkValue(stories) && stories.length > 0 && (
        <EgClickstreamImpressionTracker eventData={trackingObject}>
          {renderSeeAlso ? (
            <section className={`related-content see-also`}>
              <UitkHeading tag="h4">{loc.getLocalizedText('second_fold_related_stories.see_also')}</UitkHeading>
              <UitkSpacingHr blockMargin="three" />
              <ul className="row">
                {stories.map((story, index) => {
                  return <CardItem key={index} story={story} seeAlso={true} />;
                })}
              </ul>
            </section>
          ) : (
            <section className={`related-content`}>
              <UitkSpacingHr blockMargin="eight" />
              <UitkHeading tag="h2">{loc.getLocalizedText('related_stories')}</UitkHeading>
              <ul className="row">
                {stories.map((story, index) => {
                  return <CardItem key={index} story={story} />
                })}
              </ul>
            </section>
          )}
        </EgClickstreamImpressionTracker>
      )}
    </>
  )
};

export default GoGuidesRelatedStoriesComponent;
