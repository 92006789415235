export default {
    "current_deal": "Şu anki fırsat",
    "lbg.section_properties_theme.pet_friendly": "{0} bölgesinde evcil hayvan dostu ödülü sahipleri",
    "address": "Adres",
    "hub.homepage.h2": "Az zamanda çok yer...",
    "search_hotels_near_this_landmark": "<span class=\"extra-label\">Bu merkezi noktaya yakın</span> otel ara",
    "hub.category.h1.things_to_do": "{0} Bölgesinde Yapılacak Şeyler",
    "lbg.h2.vip_access": "VIP Access otelleri, yüksek puan alan konaklama hizmeti ve üstün deneyim sunmaları nedeniyle seçilmiştir.",
    "lbg.theme.cleanliness": "Temizlik",
    "lbg.theme.lgbtq": "LGBTQ",
    "load_more": "Daha fazla yükle",
    "link_long_form_to_listiicle_multiple": "{0} - {1} ve {2} ile ilgili öne çıkan noktalardan biri ({3} hakkındaki tüm bilgileri buradan okuyun)",
    "lbg.section_properties_theme.boutique": "{0} bölgesinde butik ödülü sahipleri",
    "lbg.h2.luxury": "İhtişamın en iyisini keşfedin. İşte bu yüzden misafirler bu otellerde kalmayı seviyor.",
    "hub.category.h1.information": "{0} hakkında bilgi",
    "families": "Aileler",
    "trending_now": "Popüler tercihler",
    "lbg.from": "Nereden",
    "hub.category.h1.road_trips": "{0} Bölgesindeki En İyi Yol Seyahatleri",
    "lbg.h2.cleanliness": "Bu oteller, yüksek genel misafir puanının yanı sıra temizlik açısından en yüksek misafir puanına sahiptir.",
    "single": "Tek başına seyahat edenler",
    "couples": "Çiftler",
    "trust_module.price_guarantee.find_out_more": "daha detaylı bilgi",
    "top_destinations_in": "{0} bölgesinde en popüler yerler",
    "hub.category.h1.couples": "{0} konumunda Çift Olarak Tatil",
    "hub.category.h2.tours": "{0} bölgesindeki en iyi turlar",
    "hub.category.h1.photos": "{0} konumunda Fotoğraf Seyahati",
    "category.photos": "Fotoğraflar",
    "hub.category.h1.budget": "{0} konumunda Bütçeye Uygun Tatil",
    "hub.category.h2.ski": "En iyi {0} kayak merkezleri",
    "lbg.section_theme_world_winners.most_wanted": "En çok aranan ödülü sahipleri",
    "lbg.theme.home_and_apartments": "Evler ve Apart Daireler",
    "hub.category.h1.luxury": "{0} konumunda Lüks Tatil",
    "hub.category.h2.adventure": "{0} konumundaki en iyi aktivitelerle dolu tatil",
    "hotels_in": "{0} otelleri",
    "lbg.section_search_winners": "Daha fazla ödül sahibi ara",
    "see_more_hotels_in": "Daha fazla {0} oteli göster",
    "hub.category.h1.beach": "{0} bölgesindeki plajlar",
    "open": "Açık olduğu saatler:",
    "enter_your_destination": "Gideceğiniz yeri girin",
    "best_of": "{0} seyahatinde görülmesi gereken yerler",
    "top10_hotels_missing_properties": "Bazı oteller ve kiralık tatil evleri şu anda gözükmeyebilir. Bu durum için özür dileriz.",
    "adventure": "Macera",
    "lbg.h1.destination_theme.sustainable": "{0} bölgesinde Sürdürülebilirlik Ödülü Sahipleri",
    "luxury": "Lüks",
    "expand_map_view": "Harita görünümünü genişlet",
    "book_now": "Hemen rezervasyon yap",
    "lbg.h1.destination_theme.ski": "{0} bölgesinde Kayak Ödülü Sahipleri",
    "hub.category.h1.food": "{0} Bölgesindeki Yemekler ve Yemek Servisi",
    "category.blogs": "Blog",
    "budget": "Ekonomik",
    "popular_neighbourhoods_in": "{0} bölgesindeki popüler semtler",
    "hub.category.h1.unusual": "{0} konumundaki Keşfedilmemiş Güzellikler ve Az Bilinen Yerler",
    "see_all_accommodation_in": "{0} içindeki tüm konaklama yerlerini göster",
    "hub.category.h1.adventure": "{0} konumunda Aktivite Dolu Tatil",
    "category_highlight.see_all.shopping": "Alışveriş hakkında daha fazla bilgi",
    "lbg.section_most_popular_destination": "En popüler tatil noktaları",
    "lbg.section_properties_theme.luxury": "{0} bölgesinde lüks ödülü sahipleri",
    "unusual": "Sıra dışı",
    "category.shopping": "Alışveriş",
    "make_the_most_of_your_trip": "Seyahatinizden en iyi şekilde yararlanın",
    "x_review": "{0,choice,0#|1#{0} yorum|2#{0} yorum|2<{0} yorum|4<{0} yorum|10#{0} yorum|10<{0} yorum}",
    "hub.category.h1.single": "{0} konumunda Tek Kişilik Tatil",
    "hub.category.h2.custom": "Popüler tercihler",
    "lbg.section_theme_world_winners.lgbt": "LGBTQ+ ödül kazananları",
    "lbg.section_award_winners_theme_other": "{0} bölgesinde diğer ödül sahibi temaları",
    "lbg.supplier_collected_charges": "+ {0} masraf bedeli otelde/kiralık tatil evinde ödenecek",
    "in_destination": " {0}",
    "lbg.section_properties_theme.vip_access": "{0} bölgesindeki VIP Access ödülü sahipleri",
    "link_long_form_to_listiicle_single": "{0} - {1} ile ilgili öne çıkan noktalardan biri ({2} hakkındaki tüm bilgileri buradan okuyun)",
    "lbg.section_theme_world_winners.boutique": "Butik ödülü sahipleri",
    "lbg.section_properties_theme.spa_hotel": "{0} bölgesinde spa ödülü sahipleri",
    "lbg.section_theme_world_winners.all_inclusive": "Her şey dâhil ödülü sahipleri",
    "hub.category.h2": "{0} seyahat kılavuzu",
    "lbg.properties.price_tooltip": "Bu fiyat, önümüzdeki 14 gün için sitemizdeki gecelik en düşük fiyattır.",
    "hub.category.h2.road_trips": "{0} Bölgesindeki En İyi Yol Seyahatleri",
    "hub.category.h1.ski": "{0} kayak merkezleri",
    "food": "Yemek",
    "lbg.theme.pet_friendly": "Evcil Hayvan Dostu",
    "lbg.theme.business": "İş Seyahati",
    "hub.category.h1.nature": "{0} Bölgesindeki En İyi Doğaya Kaçış Seyahatleri",
    "map": "Harita",
    "category_highlight.see_all.information": "Daha fazla bilgi",
    "hotel_prices_in": "{0} otel fiyatları",
    "hub.category.h1.nightlife": "{0} bölgesindeki gece yaşamı",
    "trust_module.free_cancellation.most_hotels": "çoğu otelde*",
    "also_popular_in": "{0} bölgesindeki diğer popüler yerler",
    "lbg.h1.destination_theme.business": "{0} İş Seyahati Ödülü Sahipleri",
    "lbg.section_theme_world_winners.pet_friendly": "Evcil hayvan dostu ödülü sahipleri",
    "hub.category.h1.maps": "{0} konumunun Haritaları",
    "lbg.section_properties_theme.most_wanted": "{0} bölgesinde en çok aranan ödülü sahipleri",
    "category.nightlife": "Gece yaşamı",
    "search_hotels_in_this_area": "<span class=\"extra-label\">Bu bölgede</span> otel arayın",
    "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 oy|2#2 oy|2<{1} oy|4<{1} oy|10#10 oy|10<{1} oy})",
    "hub.category.h2.business": "{0} konumundaki en iyi iş seyahati",
    "category.things_to_do": "Yapılacak şeyler",
    "destination_travel_guide_by_category": "Kategoriye Göre {0} Seyahat Rehberi",
    "hub.category.h1.families": "{0} Aile Seyahati",
    "lbg.section_theme_world_winners.ski": "Kayak ödülü sahipleri",
    "you_may_also_like": "Bunlar da ilginizi çekebilir",
    "lbg.theme.sustainable": "Sürdürülebilir",
    "hub.category.h2.unusual": "{0} konumundaki en iyi keşfedilmemiş güzellikler ve az bilinen yerler",
    "hub.category.h2.football": "Futbol takımınızı destekleyin ve seyahatinizden en iyi şekilde yararlanın!",
    "top_hotel": "En İyi Otel",
    "lbg.h1.destination_theme.luxury": "{0} bölgesinde Lüks Ödülü Sahipleri",
    "top10_hotels_empty_alert": "Şu anda teknik bir sorun yaşamaktayız. <span>Bu durum için özür dileriz.</span>",
    "special_deal": "Özel fiyat",
    "lbg.h2.sustainable": "Her şey sürdürülebilirlikle ilgili! Harika bir konaklama hizmeti sunan bu otellere göz atın.",
    "where_to_stay_in": "<span class=\"next-line\">{0} bölgesinde</span> nerede kalmalı?",
    "start_planning_your_trip": "Seyahatinizi planlamaya başlayın",
    "good_for": "Şunlar için uygun:",
    "category.hotels": "Oteller",
    "x_accommodation_guide": "{0} Konaklama Yeri Rehberi",
    "lbg.theme.luxury": "Lüks",
    "hub.category.h2.sports": "{0} konumundaki en iyi spor olanakları ve etkinlikler",
    "lbg.section_theme_world_winners.lgbtq": "LGBTQ ödülü sahipleri",
    "see_more_neighbourhoods": "Daha fazla bölge göster",
    "category_highlight.title.nightlife": "{0} şehrinde gece gidilecek yerler ve yapılacaklar",
    "lbg.h1.destination_theme.lgbt": "{0} bölgesi LGBTQ+ ödül kazananları",
    "hub.category.h1.blogs": "{0} Seyahat Blogu",
    "lbg.h2.business": "Bu oteller, iş seyahatindeki misafirlerden yüksek puan almış.",
    "hotels": "Oteller",
    "lbg.h2.pet_friendly": "Misafirler, sizi ve evcil hayvanınızı kabul eden bu otelleri önemle tavsiye ediyor.",
    "popular_landmarks_nearby": "Yakında görülmesi gereken popüler yerler",
    "popular_extractions_in": "{0} bölgesinde görülecek popüler yerler",
    "lbg.h2.all_inclusive": "Bu birinci sınıf oteller tüm stresinizi alıp götürür.",
    "travel_guide_by_category_header": "{1}: {0}",
    "lbg.travel_between": "{0}-{1} tarihleri arasında seyahat edin",
    "keep_exploring": "Keşfe devam",
    "what_you_need_to_know_before_you_go": "Gitmeden Önce Bilmeniz Gerekenler",
    "read_it": "Devamını oku",
    "view_on_map": "Haritada görüntüle",
    "lbg.h1.destination_theme.lgbtq": "{0} bölgesindeki LGBTQ ödülü sahipleri",
    "lbg.h2.beach": "Misafirler özellikle bu gibi plaj otellerinde konaklamayı sever.",
    "lbg.h1.destination_theme.vip_access": "{0} bölgesindeki VIP Access ödülü sahipleri",
    "see_all_accommodation": "Tüm konaklama yerlerini göster",
    "shoppers": "Alışveriş tutkunları",
    "whats_around": "{0} çevresindekiler",
    "group": "Grup",
    "hub.category.h2.couples": "{0} konumundaki en iyi çift olarak tatil",
    "hub.category.h1.shopping": "{0} bölgesindeki alışveriş noktaları",
    "travel_community": "{0} <span>&#124;</span><span>Seyahat Topluluğu</span>",
    "hub.category.h2.group": "{0} konumundaki en iyi grup tatili",
    "listicle_legal_msg": "Bu makale, Go Guides yayın ekibinin görüşlerini içermektedir. Hotels.com, bu sitede görünen yazıların karşılığında yazarları tazmin eder. Bu, seyahat masraflarının veya diğer masrafların karşılanması şeklinde olabilir.",
    "lbg.h1.destination_theme.cleanliness": "{0} bölgesindeki temizlik ödülü sahipleri",
    "menu.more": "Diğer",
    "lbg.section_properties_theme.lgbtq": "{0} bölgesindeki LGBTQ ödülü sahipleri",
    "lbg.section_award_winners_destination": "Tatil noktasına göre ödül sahipleri",
    "lbg.terms_conditions": "Hüküm ve Koşullar",
    "hub.category.h2.nightlife": "{0} bölgesindeki en iyi gece yaşamı",
    "hub.neighbourhood.h2": "{0} bölgesinde yapılması gerekenler",
    "lbg.section_award_winners_theme_destination": "{0} bölgesinde temaya göre ödül sahipleri",
    "lbg.section_award_world_winners": "Dünya çapındaki ödül sahipleri",
    "things_to_see_and_do_in_other_cities": "Diğer şehirlerde görülecek yerler ve yapılacak şeyler",
    "button.backToTop": "Yukarıya dön",
    "lbg.section_theme_world_winners.family": "Aile ödülü sahipleri",
    "hotels_near": "{0} yakınındaki oteller",
    "category.activity__sports_": "Etkinlikler",
    "trust_module.get_reward_night": "Bonus gece kazanın",
    "hub.category.h1.business": "{0} konumunda İş Seyahati",
    "category.food": "Yemek",
    "other_popular_destinations_in": "{0} bölgesindeki diğer popüler yerler",
    "browse_by_category": "Kategoriye göre {0} bölgesinde ara",
    "see_more_attactions": "Daha fazla gezilecek yer göster",
    "lbg.theme.lgbt": "LGBTQ+",
    "more_information_about": "{0} hakkında daha fazla bilgi",
    "lbg.h1.destination_theme.most_wanted": "{0} bölgesinde En Çok Aranan Ödülü Sahipleri",
    "see_all_properties_in": "Tüm {0} otellerini görün",
    "category.tours": "Turlar",
    "hub.category.h1.sports": "{0} konumundaki Spor Olanakları ve Etkinlikler",
    "where_will_you_go_next": "Gelecek seyahatiniz nereye?",
    "cities": "Şehirler",
    "lbg.theme.spa_hotel": "Spa",
    "see_guide": "Rehberi göster",
    "lbg.section_properties_theme.ski": "{0} bölgesinde kayak ödülü sahipleri",
    "related_stories": "İlgili yazılar",
    "hub.destination.h2": "Gitmeden önce bilmeniz gerekenler",
    "show_less": "Daha az göster",
    "hub.category.h1.football": "Gerçek Futbol Taraftarları İçin {0} Kılavuzu",
    "hub.category.h2.food": "{0} bölgesindeki en iyi yemekler ve yemek servisi",
    "fallback_language_alert": "Özür dileriz, bu sayfa henüz dilinize çevrilmedi...",
    "category_highlight.title.information": "Yararlı bilgiler",
    "neighbourhood_guides_for_other_cities": "Diğer şehirler için bölge rehberleri",
    "lbg.h2.hubpage": "Bu harika oteller için pek çok misafir yorum yazdı ve yüksek puan verdi.",
    "travel_advisory.title": "Seyahat uyarısı",
    "lbg.section_properties_theme.all_inclusive": "{0} bölgesinde her şey dâhil ödülü sahipleri",
    "lbg.h2.boutique": "Butik otelleri seviyorsanız misafirler burada kalmanızı önemle tavsiye ediyor.",
    "lbg.section_properties_theme.family": "{0} bölgesinde aile ödülü sahipleri",
    "hub.category.h2.families": "{0} içindeki en iyi aile seyahati",
    "from": "başlangıç fiyatı:",
    "lbg.theme.vip_access": "VIP Access",
    "lbg.section_properties_theme.lgbt": "{0} bölgesi LGBTQ+ ödül kazananları",
    "x_star": "{0,choice,0#|1#{0} yıldızlı|1.5#{0} yıldızlı|2#{0} yıldızlı|2.5#{0} yıldızlı|3#{0} yıldızlı|3.5#{0} yıldızlı|4#{0} yıldızlı|4.5#{0} yıldızlı|5#{0} yıldızlı}",
    "trust_module.free_cancellation": "Ücretsiz iptal",
    "lbg.section_properties_theme.home_and_apartments": "{0} bölgesinde evler ve apart daireler ödülü sahipleri",
    "contributing_writer": "{0} <span>&#124;</span><span>Katkı Sunan Yazar</span>",
    "things_to_see_and_do_in": "<span class=\"next-line\">{0} bölgesinde</span> görülecek yerler ve yapılacak şeyler",
    "lbg.check_price": "Fiyatı kontrol et",
    "hub.category.h2.blogs": "Deneyimli gezginlerin tavsiyeleriyle",
    "lbg.h2.home_and_apartments": "Evden uzakta ev konforu mu arıyorsunuz? Bu yüksek puan alan otellere göz atın.",
    "hub.category.h2.information": "{0} hakkında yararlı bilgiler",
    "search_hotels_in": "<span class=\"extra-label\">{0} bölgesinde</span> otel arayın",
    "hub.category.h2.nature": "{0} Bölgesindeki En İyi Doğa Seyahatleri",
    "lbg.h1.destination_theme.home_and_apartments": "{0} bölgesinde Evler ve Apart Daireler Ödülü Sahipleri",
    "photo_by": "fotoğraf: {0}",
    "more_things_to_do": "Yapılacak diğer şeyler",
    "lbg.section_properties_theme.beach": "{0} bölgesinde plaj ödülü sahipleri",
    "read_less": "Daha az oku",
    "lbg.h2.spa_hotel": "Spa alanlarıyla tanınan bu en beğenilen otellerde dinlenin ve rahatlayın.",
    "second_fold_related_stories.see_also": "Ayrıca bkz.",
    "hub.category.h2.history": "{0} konumundaki en iyi tarihi yerler",
    "lbg.section_properties_theme.cleanliness": "{0} bölgesindeki temizlik ödülü sahipleri",
    "lbg.h1.destination_theme.boutique": "{0} bölgesinde Butik Ödülü Sahipleri",
    "nightlife_b595ae4": "Gece Yaşamı",
    "lbg.h1.destination_theme.beach": "{0} bölgesinde Plaj Ödülü Sahipleri",
    "trust_module.free_cancellation.disclaimer": "Bazı oteller, rezervasyonunuzu girişten en az 24-48 saat önce iptal etmenizi gerekli kılabilir. Daha fazla bilgi için siteyi ziyaret edin.",
    "lbg.h2.family": "Bu oteller, misafirlerden yüksek puan almış ve aileler için mükemmel.",
    "lbg.h2.lgbtq": "Bu LGBTQ dostu oteller, misafirlerden yüksek puan almış.",
    "hub.category.h1.group": "{0} konumunda Grup Tatili",
    "popular_stories": "Popüler hikayeler",
    "toc": "İçerik",
    "hub.category.h2.shopping": "{0} bölgesindeki en iyi alışveriş merkezleri",
    "lbg.section_theme_world_winners.beach": "Plaj ödülü sahipleri",
    "page_score": "Bu sayfanın aldığı puan",
    "lbg.section_theme_world_winners.sustainable": "Sürdürülebilirlik ödülü sahipleri",
    "lbg.theme.boutique": "Butik",
    "trust_module.price_guarantee": "Fiyat garantimiz",
    "lbg.h1.destination_theme.all_inclusive": "{0} bölgesinde Her Şey Dâhil Ödülü Sahipleri",
    "top_destinations": "En popüler tatil merkezleri",
    "hotel_prices_near": "{0} yakınındaki otel fiyatları",
    "lbg.score.cleanliness": "Temizlik:",
    "x_stars": "{0,choice,0#|1#{0} yıldızlı|1<{0} yıldızlı|4<{0} yıldızlı|10<{0} yıldızlı}",
    "lbg.theme.all_inclusive": "Her Şey Dâhil",
    "hub.category.h1.tours": "{0} Bölgesindeki Turlar ve Günübirlik Geziler",
    "lbg.section_award_winners_theme": "Temaya göre ödül sahipleri",
    "guest_writer": "{0} <span>&#124;</span><span>Misafir Yazar</span>",
    "start_planning_your_x_trip": "{0} seyahatinizi planlamaya başlayın",
    "lbg.section_theme_world_winners.business": "İş Seyahati ödülü sahipleri",
    "trending_now_in": "Şu anda {0} için en popüler tercihler",
    "history": "Tarih",
    "search_hotels": "Otel arayın",
    "lbg.terms_conditions_details": "{0} yılında ödüle layık görülen oteller seçilirken, {1} yılında yüksek misafir puanı almış olmaları ve en az 50 misafir yorumuna sahip olmaları şartları arandı.",
    "see_nearby_properties": "Yakındaki konaklama yerlerini göster",
    "hub.category.h1.history": "{0} konumundaki Tarihi Yerler",
    "location": "Konum:",
    "lbg.section_theme_world_winners.luxury": "Lüks ödülü sahipleri",
    "category_highlight.see_all.nightlife": "Gece hayatı hakkında daha fazla bilgi",
    "also_popular": "Diğer popüler yerler",
    "hub.category.h2.things_to_do": "{0} bölgesinde yapılacak en iyi şeyler",
    "lbg.theme.family": "Aile",
    "lbg.h1.destination_theme.pet_friendly": "{0} bölgesinde Evcil Hayvan Dostu Ödülü Sahipleri",
    "category_highlight.title.shopping": "{0} şehrinde alışveriş yapılacak yerler ve alınacaklar",
    "price": "Fiyat:",
    "hub.category.h2.beach": "{0} bölgesindeki en iyi plajlar",
    "neighbourhoods_in": "{0} bölgesindeki semtler",
    "business": "İş",
    "photo": "Fotoğraf",
    "blog.see_all_stories": "Tüm hikayeleri görün",
    "category_highlight.see_all.food": "Yemek servisi ve yemekler hakkında daha fazla bilgi",
    "see_all_properties": "Tüm otelleri görün",
    "lbg.theme.beach": "Plaj",
    "what_to_see_and_do_in": "{0} şehrinde görülecekler ve yapılacaklar",
    "hub.category.h2.single": "{0} konumundaki en iyi tek kişilik tatil",
    "attractions_in": "{0} bölgesinde görülecek yerler",
    "read_more": "Devamı",
    "show_more": "Daha fazla göster",
    "hotels_near_this_landmark": "Bu görülecek yere yakın oteller",
    "check_dates": "Tarihleri kontrol et",
    "featured_stories_and_fun_stuff": "Öne çıkan yazılar ve eğlenceli bilgiler",
    "phone": "Telefon:",
    "lbg.section_search_header": "Ödül sahiplerini arayın",
    "category_highlight.title.food": "{0} şehrinde nerede ne yenir?",
    "see_all_things_to_do": "Yapılacak her şeyi görün",
    "lbg.section_properties_theme.sustainable": "{0} bölgesinde sürdürülebilirlik ödülü sahipleri",
    "lbg.h1.hubpage": "{0} Misafirlerin Gözdesi Ödülü Sahipleri!",
    "lbg.section_theme_world_winners.spa_hotel": "Spa ödülü sahipleri",
    "category.maps": "Haritalar",
    "lbg.h1.destination_theme.family": "{0} bölgesinde Aile Ödülü Sahipleri",
    "lbg.section_theme_world_winners.cleanliness": "Temizlik ödülü sahipleri",
    "lbg.section_theme_world_winners.vip_access": "VIP Access ödülü sahipleri",
    "trust_module.get_reward_night.night_you_stay": "her 10 gecelik konaklamanız için",
    "modified": "değiştirildi",
    "lbg.section_properties": "{0} bölgesinde ödül sahipleri",
    "trust_module.header": "Neden Hotels.com ile rezervasyon yapmalıyım?",
    "hub.category.h2.luxury": "{0} konumundaki en iyi lüks tatil",
    "lbg.theme.ski": "Kayak",
    "lbg.h1.destination_theme.spa_hotel": "{0} bölgesinde Spa Ödülü Sahipleri",
    "lbg.h2.most_wanted": "Misafirler, mükemmel bir konaklama için bu otelleri önemle tavsiye ediyor.",
    "top10_hotels_incomplete_alert": "Şu anda talep yoğunluğu yaşandığı için bazı oteller ve konaklama yerleri gösterilmeyebilir. <span>Bu durum için özür dileriz.</span>",
    "compulsive_traveller": "{0} <span>&#124;</span> <span>Seyahat Tutkunu</span>",
    "lbg.section_theme_world_winners.home_and_apartments": "Ev ve apart daire ödülü sahipleri",
    "loved_by_guests_collection_in": "{0} bölgesinde Loved by Guests Koleksiyonu",
    "title.suffix": "{0} - Go Guides",
    "hub.category.h2.maps": "Yararlı {0} haritaları",
    "opening_hours": "Çalışma saatleri",
    "hub.category.h2.photos": "{0} konumundaki en iyi fotoğraf seyahati",
    "lbg.theme.most_wanted": "En Çok Aranan",
    "blog.stories_container": "Katkıda bulunanların hikayeleri",
    "hub.category.h2.budget": "{0} konumundaki en iyi bütçeye uygun tatil",
    "lbg.section_properties_theme.business": "{0} iş seyahati ödülü sahipleri",
    "not_sure_where_to_stay": "Nerede kalacağınıza karar veremediniz mi?",
    "category.information": "Bilgi"
}
