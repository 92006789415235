import { checkValue } from 'components/functions';
import { GoGuidesKeepExploringProps } from 'components/types/goguides-types.d';
import React from 'react';
import { UitkSubheading } from 'uitk-react-text';
import { useStores } from 'utils/useStore';
import KeepExploringCountry from './keep-exploring-country';
import KeepExploringContinent from './keep-exploring-continent';
import { EgClickstreamData, EgClickstreamImpressionTracker } from '@shared-ui/clickstream-analytics-context';
import { getLocalizedText } from 'src/utils/helper';

const GoGuidesKeepExploringComponent: React.FC<GoGuidesKeepExploringProps> = props => {
  const { goGuidesStore } = useStores();
  const { isHomePage } = props;

  const contentData = goGuidesStore.fetchKeepExploring(goGuidesStore.pageUrlData);
  if (!checkValue(contentData)) {
    return null;
  }
  
  const { destinationList, worldLevel } = contentData;

  const trackingObject: EgClickstreamData = {
    event: {
      event_name: 'keep_exploring.presented',
      event_type: 'Impression',
      event_version: '1.0.0',
      event_category: 'go_guides',
    },
    experience: goGuidesStore.ClicksteamExperience,
  };

  return (
    <>
      {checkValue(destinationList) && (
        <EgClickstreamImpressionTracker eventData={trackingObject}>
          <section className="destinations-list">
            <div className="container-wrap row">
              <div className="row-inner col-12">
                <div className="destinations-list-container">
                  <UitkSubheading tag="h2" className={``}>
                    {getLocalizedText('keep_exploring')}
                  </UitkSubheading>
                  <KeepExploringCountry listing={destinationList} />
                  <KeepExploringContinent listing={destinationList} isHomePage={isHomePage} worldLevel={worldLevel} />
                </div>
              </div>
            </div>
          </section>
        </EgClickstreamImpressionTracker>
      )}
    </>
  );
};

export default GoGuidesKeepExploringComponent;
