import React from 'react';
import { UitkLink } from 'uitk-react-link';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { SegmentItem } from 'src/components/types/goguides-types.d';
import { checkValue } from 'src/components/functions';
import { localization } from 'utils/localization';

interface SegmentComponentProps {
  data: SegmentItem[];
  i18nKey?: string;
  pill?: boolean;
}

const SegmentComponent: React.FC<SegmentComponentProps> = ({ data, i18nKey, pill = false }) => {
  const pillClass = checkValue(pill) ? 'view-pill' : '';
  const loc = new localization();

  return (
    <>
      <div className={`tag-container ${pillClass}`}>
        {checkValue(i18nKey) ? (
          <label>
            <UitkIcon className="icon" name="local_offer" size={UitkIconSize.MEDIUM} />
            {i18nKey}
          </label>
        ) : (
          <UitkIcon className="icon" name="local_offer" size={UitkIconSize.MEDIUM} />
        )}
        <ul>
          {data.map((item, index) => {
            const { segmentCode } = item;
            const segmentLabel = checkValue(pill) 
              ? loc.getSpecificLocText('en_gb', `category.${segmentCode}`)
              : loc.getLocalizedText(segmentCode);

            return (
              checkValue(segmentLabel) && (
                <li key={index}>
                  {item.targetUrl ? (
                    <UitkLink inline={true}>
                      <a href={item.targetUrl}>
                        <span>{segmentLabel}</span>
                      </a>
                    </UitkLink>
                  ) : (
                    <span>{segmentLabel}</span>
                  )}
                </li>
              )
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default SegmentComponent;
